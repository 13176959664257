import { Button, ListItemButton as ListItemButtonMui, ListItem as ListItemMui, MenuItem as MenuItemMui } from '@mui/material';
import styled from 'styled-components';

const ListItem = styled(ListItemMui)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  marginTop: 4,
  borderRadius: 4,
  overflow: 'hidden',
}));

const MenuItem = styled(MenuItemMui)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  marginTop: 4,
  borderRadius: 4,
  overflow: 'hidden',
}));

const ListItemButton = styled(ListItemButtonMui)(({ theme }) => ({
  '&.Mui-selected': {
    backgroundColor: theme.palette.background.dark,
  },
  '&.Mui-selected:hover': {
    backgroundColor: theme.palette.background.dark,
  },
}));

export const FormButton = styled(Button)`
  color: ${(props) => props.theme.palette.text.dark};
  box-shadow: ${(props) => props.theme.shadows[1]};
  background: ${(props) => props.theme.palette.background.paper};
  border: none;
  display: block;
  text-align: left;
  padding-top: 10px;
  padding-bottom: 10px;
  &:hover {
    color: ${(props) => props.theme.palette.text.black};
    background: ${(props) => props.theme.palette.background.paper};
    border: none;
  }
`;

export default {
  ListItem,
  ListItemButton,
  FormButton,
  MenuItem,
};
