import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { Box } from '@mui/system';
import React, { ReactElement, useEffect } from 'react';
import useSearchParams from '../../hooks/useSearchParams';
import { listProducts, selectProductsMapped } from '../../redux/slices/products/productsSlice';
import { useDispatch, useSelector } from '../../redux/store';

type ProductIdFilterProps = {
  viewMode?: boolean;
  showAll?: boolean;
};

const payload = { owned: 'true', limit: 250, offset: 0 };

export default function ProductIdFilter({ viewMode, showAll }: ProductIdFilterProps): ReactElement {
  const dispatch = useDispatch();
  const [params, setURLParams] = useSearchParams();
  const products = useSelector(selectProductsMapped(payload));

  useEffect(() => {
    dispatch(listProducts(payload));
  }, [dispatch]);

  const productsOptions =
    products?.result?.reduce((partProducts, product) => [...partProducts, { label: product.product_name, value: product.product_id }], [
      { label: 'All', value: 'all' },
    ] as {
      label?: string;
      value?: string;
    }[]) ?? [];

  const currentOption = productsOptions.find((option) => option.value === params.productId);

  const handleProductSelect = (event: SelectChangeEvent) => {
    setURLParams({ newParams: { productId: event.target.value === 'all' ? '' : event.target.value } });
  };

  return viewMode ? (
    <React.Fragment>
      {(currentOption?.value || showAll) && (
        <Box component="span">
          Product: <strong>{currentOption?.label ?? 'All'}</strong>
        </Box>
      )}
    </React.Fragment>
  ) : (
    <FormControl sx={{ minWidth: 150 }}>
      <InputLabel id="product-label" size="small" shrink>
        Product
      </InputLabel>
      <Select labelId="product-label" value={params.productId || 'all'} onChange={handleProductSelect} label="Product" size="small">
        {productsOptions.map((item) => (
          <MenuItem key={item.label} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
