import { DateTime } from 'luxon';
import { FilterTypes, Row } from 'react-table';

export const dateBetweenFilterFn = (rows: Row[], id: string[], filterValues: FilterTypes<Record<string, unknown>>): Row[] => {
  const sd = filterValues[0] ? DateTime.fromJSDate(filterValues[0] as unknown as Date).startOf('day') : filterValues[0];
  const ed = filterValues[1] ? DateTime.fromJSDate(filterValues[1] as unknown as Date).endOf('day') : filterValues[1];

  if (ed || sd) {
    return rows.filter((row: { original: { first_visit?: string; last_visit?: string } }) => {
      const cellFrom = row.original.first_visit ? DateTime.fromISO(row.original.first_visit).startOf('day') : undefined;
      const cellTo = row.original.last_visit ? DateTime.fromISO(row.original.last_visit).endOf('day') : undefined;

      if (ed && sd && cellFrom && cellTo) {
        return cellFrom >= sd && cellTo <= ed;
      } else if (sd && cellFrom) {
        return cellFrom >= sd;
      } else if (ed && cellTo) {
        return cellTo <= ed;
      }
      return true;
    });
  } else {
    return rows;
  }
};
