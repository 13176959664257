import { Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { imageBase } from '../../api/client';
import LocationFilterSelect from '../../component/filters/LocationFilterSelect';
import LinearProgress from '../../component/linearProgress/LinearProgress';
import Table from '../../component/table/Table';
import { tagsDate } from '../../helpers/dataHelper';
import prepareParams from '../../helpers/prepareParams';
import useCurrentRoute from '../../hooks/useCurrentRoute';
import usePeriodFilter from '../../hooks/usePeriodFilter';
import useSearchParams from '../../hooks/useSearchParams';
import useTablePagination from '../../hooks/useTablePagination';
import { QueryItemActivityMapped } from '../../redux/slices/tools/toolsInterface';
import {
  getActivityDecodes,
  getListItemActivity,
  getListItemActivityFetching,
  listActivityDecodes,
  listItemActivity,
} from '../../redux/slices/tools/toolsSlice';
import { getUserAbilities } from '../../redux/slices/users/usersSlice';
import { listVehicles } from '../../redux/slices/vehicle/vehicleSlice';
import { useDispatch } from '../../redux/store';
import { Card } from '../../styled/components';
import { LocationVisitsProps } from './interface';

export const ToolUsageContainer: React.FC<LocationVisitsProps> = ({
  withFilters,
  withExport = true,
  locationVisitId,
  itemId,
  vehicleId,
  siteId,
  dense,
}: LocationVisitsProps) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
  const route = useCurrentRoute();
  const [params, setURLParams] = useSearchParams();
  const abilities = useSelector(getUserAbilities);
  const activityDecodes = useSelector(getActivityDecodes);

  const paramsToUse = useMemo(
    () =>
      Object.keys(params).reduce((partParams, key) => ({ ...partParams, [key]: prepareParams[key] ? prepareParams[key](params[key]) : params[key] }), {
        ...(locationVisitId && { locationVisitId }),
        ...(itemId && { itemId }),
        ...(vehicleId && { vehicleId }),
        ...(siteId && { siteId }),
      }),
    [params, locationVisitId, itemId, vehicleId, siteId],
  );

  const locations = useSelector(getListItemActivity(paramsToUse));
  const fetching = useSelector(getListItemActivityFetching);
  const [pagination, paginationToGet, paginationControl, paginationToGetAll] = useTablePagination({ paginationDirty: locations.pagination });
  const locationsExport = useSelector(getListItemActivity({ ...paramsToUse, ...paginationToGetAll }));

  useEffect(() => {
    dispatch(
      listItemActivity({
        ...paramsToUse,
        ...paginationToGet,
      }),
    );
  }, [dispatch, paramsToUse, paginationToGet]);

  const getExportData = useCallback(async () => {
    if (withExport) {
      await dispatch(
        listItemActivity({
          ...paramsToUse,
          ...paginationToGetAll,
        }),
      );
    }
  }, [dispatch, paramsToUse, paginationToGetAll, withExport]);

  useEffect(() => {
    dispatch(listVehicles({}));
    dispatch(listActivityDecodes());
  }, [dispatch]);

  const handleFiltersClear = useCallback(() => {
    setURLParams({ clearAll: true });
  }, [setURLParams]);

  const PeriodFilter = usePeriodFilter({ params, setURLParams, beginKey: 'activityBegin', endKey: 'activityEnd' });

  const columns = useMemo(
    () => [
      ...(isSmUp
        ? [
            {
              accessor: 'image_src',
              Header: 'Image',
              Cell: ({ row }: { row: { original: QueryItemActivityMapped } }) =>
                row.original.image_key && (
                  <Link to={`/tools/items/${row.original.item_id}/details`}>
                    <img src={`${imageBase(row.original.image_key)}?height=36`} key={row.original.item_id} alt={row.original.product_name} />
                  </Link>
                ),
              disableSortBy: true,
              width: 50,
              style: { paddingTop: 2, paddingBottom: 2, textAlign: 'center' },
            },
          ]
        : []),
      {
        Header: 'Event Date',
        accessor: 'activity_date',
        width: 250,
        Cell: ({ row }: { row: { original: QueryItemActivityMapped } }) => tagsDate(row.original.activity_date, 'DATETIME_MED'),
        getCellExportValue: (row: { original: QueryItemActivityMapped }) => tagsDate(row.original.activity_date, 'DATETIME_MED'),
        ...(withFilters && { Filter: PeriodFilter }),
      },
      ...(!vehicleId
        ? [
            {
              Header: 'Vehicle',
              accessor: 'vehicle_name',
              Cell: ({ row }: { row: { original: QueryItemActivityMapped } }) =>
                row.original.vehicle_id && row.original.vehicle_id !== '00000000-0000-0000-0000-000000000000' ? (
                  <Link to={`/company/vehicles/${row.original.vehicle_id}/details`}>{row.original.vehicle_name}</Link>
                ) : (
                  row.original.vehicle_name
                ),
            },
          ]
        : []),
      ...(!locationVisitId
        ? [
            {
              Header: 'Site',
              accessor: 'site_name',
              sortBy: 'site_name',
              Cell: ({ row }: { row: { original: QueryItemActivityMapped } }) =>
                row.original.site_id ? (
                  <Link to={`/customers/job-sites/${row.original.site_id}/details`}>{row.original.site_name}</Link>
                ) : (
                  row.original.site_name
                ),
              ellipsis: true,
              width: '20%',
            },
          ]
        : []),
      ...(!itemId
        ? [
            {
              Header: abilities.can('Container') ? 'Container' : 'Product & Identifier',
              accessor: 'productIdentifierMapped',
              sortBy: 'product_name',
              Cell: ({ row }: { row: { original: QueryItemActivityMapped } }) => {
                const ellipsis = (
                  <Grid container wrap="nowrap" spacing={2} sx={{ whiteSpace: 'nowrap', maxWidth: 300 }}>
                    {abilities.can('Container') ? (
                      <Grid item sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
                        {row.original.identifier}
                      </Grid>
                    ) : (
                      <React.Fragment>
                        <Grid item sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
                          {row.original.product_name}
                        </Grid>
                        {row.original.identifier && (
                          <>
                            <Grid item> — </Grid>
                            <Grid item sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
                              {row.original.identifier}
                            </Grid>
                          </>
                        )}
                      </React.Fragment>
                    )}
                  </Grid>
                );
                return row.original.site_id ? <Link to={`/tools/items/${row.original.item_id}/details`}>{ellipsis}</Link> : ellipsis;
              },
            },
          ]
        : []),
      {
        Header: 'Event Type',
        accessor: 'activity_type',
        width: 150,
        ...(withFilters && {
          Filter: LocationFilterSelect,
          filterProps: {
            options: [{ label: 'All', value: -2 }, ...activityDecodes.map((item) => ({ label: item.decode, value: item.type_id }))],
            paramKey: 'activityTypeIds',
          },
        }),
      },
      ...(!locationVisitId
        ? [
            {
              Header: 'Site Visit',
              accessor: 'location_visit_id',
              Cell: ({ row }: { row: { original: QueryItemActivityMapped } }) =>
                row.original.site_id ? (
                  <Link to={`/activity/site-visits/${row.original.location_visit_id}/details`}>{row.original.periodMapped}</Link>
                ) : (
                  row.original.site_name
                ),
              getCellExportValue: (row: { original: QueryItemActivityMapped }) => (row.original.site_id ? row.original.periodMapped : row.original.site_name),
              ellipsis: true,
              width: '20%',
            },
          ]
        : []),
    ],
    [withFilters, PeriodFilter, locationVisitId, itemId, vehicleId, abilities, isSmUp, activityDecodes],
  );

  return (
    <>
      {fetching && <LinearProgress />}
      <Card>
        <Table
          columns={columns}
          data={locations.result}
          {...(withExport && { dataExport: locationsExport?.result, exportFileName: route?.route?.path, getExportData: getExportData })}
          loading={fetching}
          {...(withFilters && { showDefaultFilters: true, onFiltersClear: handleFiltersClear })}
          paginationControl={paginationControl}
          pagination={pagination}
          {...(dense && { size: 'small' })}
        />
      </Card>
    </>
  );
};
