import { MarkerWithLabel, MarkerWithLabelOptions } from '@googlemaps/markerwithlabel';
import { Loop as LoopIcon } from '@mui/icons-material';
import WrongLocationOutlinedIcon from '@mui/icons-material/WrongLocationOutlined';
import { Box, Button, Paper, Stack, Typography } from '@mui/material';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { default as GoogleMapReact, Maps } from 'google-map-react';
import { groupBy, isEmpty, some } from 'lodash';
import React, { RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { QueryVehicle } from '../../api/schema';
import iconJobSite from '../../assets/images/job-site-invert.svg';
import logo from '../../assets/images/the-tag-system-logo.svg';
import { tagsDate } from '../../helpers/dataHelper';
import { QueryVehicleDashboardSummaryMapped } from '../../page/dashboard/interface';
import { getLastRefresh, setLastRefresh } from '../../redux/slices/app/appSlice';
import { Coordinates } from '../../redux/slices/vehicle/vehicleInterface';
import { ToggleButton } from '../../styled/components';
import { Tooltip } from '../tooltip/Tooltip';
import './Common.css';

export type Marker = {
  key: string;
  lat: number;
  lng: number;
  label?: string;
  locationType?: string;
  locationVisitId?: string;
  vehicleName?: string;
  vehicleId?: string;
  siteName?: string;
  siteId?: string;
  vehicles?: QueryVehicleDashboardSummaryMapped[];
  processedAt?: string;
  processedAtWithDay?: string;
  payloadCount?: number;
  payloadId?: string | number;
};

export type ControlType = {
  position: 'TOP_LEFT' | 'LEFT_TOP' | 'LEFT_CENTER' | 'RIGHT_CENTER';
  ref: RefObject<HTMLDivElement | undefined>;
  fullscreen?: boolean;
};

interface IProps {
  markers: { [key: string]: Marker[] };
  centerMap?: Coordinates;
  height?: number | string;
  fullScreenControl?: boolean;
  withTraffic?: boolean;
  withTrafficFullScreen?: boolean;
  center?: {
    lng: number;
    lat: number;
  };
  controls?: ControlType[];
  onFullScreen?: (fullScreen: boolean) => void;
  onZoomAnimationEnd?(args: any, center: any): void;
  onDragEnd?(map: any): void;
  defaultZoom?: number;
  onMapInited?: VoidFunction;
  vehicles?: QueryVehicle[];
}

const GoogleMapReactWrapper = styled.div`
  width: 100%;
  min-height: 220px;
  height: 100%;
`;

const bootstrapURLKeys = {
  key: (
    window as unknown as {
      _env_: {
        REACT_APP_GOOGLE_MAP_API_KEY: string;
      };
    }
  )._env_.REACT_APP_GOOGLE_MAP_API_KEY,
  libraries: ['drawing', 'geometry'],
  v: '3.59',
};

type Position = {
  lat: number;
  lng: number;
};

type MarkerWithLabelWithKey = MarkerWithLabel & {
  key: string;
};

let lines: { [key: string]: any[] } = {};

const colors = ['#FF0000', '#0000FF', '#00FF00', '#FFFF00', '#FFA500', '#800080', '#00FFFF', '#FF00FF', '#008080', '#FFC0CB'];

function getMiddle(prop: string, markers: { lat: number; lng: number }[]) {
  const markersValues = markers.map((m) => m[prop]);

  let values = isEmpty(markersValues) ? [prop === 'lat' ? 40.7042975 : -73.7751181] : markersValues;
  let min = Math.min(...values);
  let max = Math.max(...values);

  if (prop === 'lng' && max - min > 180) {
    values = values.map((val) => (val < max - 180 ? val + 360 : val));
    min = Math.min(...values);
    max = Math.max(...values);
  }
  let result = (min + max) / 2;
  if (prop === 'lng' && result > 180) {
    result -= 360;
  }
  return result;
}

function findCenter(markers: { lat: number; lng: number }[]) {
  return {
    lat: getMiddle('lat', markers),
    lng: getMiddle('lng', markers),
  };
}

export const FleetMap: React.FC<IProps> = ({
  markers,
  defaultZoom,
  centerMap,
  height,
  fullScreenControl = true,
  withTraffic,
  withTrafficFullScreen,
  center,
  controls,
  onFullScreen,
  onZoomAnimationEnd,
  onDragEnd,
  onMapInited,
  vehicles,
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  // eslint-disable-next-line
  const mapRef = useRef<any>();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'), { noSsr: true });
  const lastRefresh = useSelector(getLastRefresh);
  const markersRef = useRef<{ [key: string]: MarkerWithLabelWithKey[] }>({});
  const positionsRef = useRef<{ [key: string]: Position[] }>({});
  const mapLoadedRef = useRef<boolean>(false);
  const imgRef = useRef<HTMLImageElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const trafficRef = useRef<HTMLButtonElement>(null);
  const mapViewRef = useRef<HTMLButtonElement>(null);
  const vehicleRef = useRef<HTMLDivElement>(null);
  const vehiclesRef = useRef<HTMLDivElement>(null);
  const [fullScreen, setFullScreen] = useState(false);
  const [trafficView, setTrafficView] = useState(false);
  const [mapView, setMapView] = useState('ROADMAP');
  const [selectedVehicleId, setSelectedVehicleId] = useState<string>('');

  const colorByVehicle =
    vehicles?.reduce(
      (partColors, vehicle, index) => (!vehicle.id ? partColors : { ...partColors, [vehicle.id]: colors[index] }),
      {} as { [key: string]: string },
    ) ?? {};

  const mapOptions = useCallback(
    (maps) => ({
      fullscreenControl: isMdUp && fullScreenControl,
      mapTypeControl: false,
      mapTypeId: maps.MapTypeId.ROADMAP,
      scaleControl: isMdUp,
      scrollwheel: isMdUp,
      streetViewControl: false,
      draggable: true,
      disableDoubleClickZoom: !isMdUp,
      zoomControl: isMdUp,
    }),
    [isMdUp, fullScreenControl],
  );
  useEffect(() => {
    if (onFullScreen) {
      onFullScreen(fullScreen);
    }
  }, [fullScreen, onFullScreen]);

  useEffect(() => {
    const onFullscreenChange = () => {
      const map = mapRef.current.map_;
      const maps = mapRef.current.maps_;
      const targetDiv = map?.getDiv()?.children[0];
      const fullScreen = document.fullscreenElement === targetDiv;
      if (targetDiv) {
        setFullScreen(fullScreen);
        map.setOptions(mapOptions(maps));
        targetDiv.style.zIndex = '0';
        if (!fullScreen) {
          map.trafficLayer.setMap(null);
          setTrafficView(false);
        }

        controls?.forEach((control) => {
          if (fullScreen && control.fullscreen) {
            map.controls[maps.ControlPosition[control.position]].push(control.ref.current);
          } else if (!fullScreen && control.fullscreen) {
            map.controls[maps.ControlPosition[control.position]].clear();
          }
        });
      }
    };
    document.addEventListener('fullscreenchange', onFullscreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', onFullscreenChange, false);
    };
  }, [mapOptions, controls]);

  const getMapOptions = useCallback(
    (maps: Maps) => {
      return mapOptions(maps);
    },
    [mapOptions],
  );

  const flashPolyline = (vehicleId?: string) => {
    Object.keys(markersRef.current).forEach((vehicle) => {
      markersRef.current[vehicle].forEach((marker) => {
        marker.setMap(vehicle === vehicleId ? mapRef.current.map_ : null);
      });
    });

    Object.keys(lines).forEach((vehicle) => {
      const strokeColor = vehicle === vehicleId ? '#000000' : colorByVehicle[vehicle];
      const opacity = vehicle === vehicleId ? 1 : 0.4;
      const strokeOpacity = vehicleId ? opacity : 1;
      const zIndex = vehicle === vehicleId ? 10 : 1;
      const strokeWeight = vehicle === vehicleId ? 5 : 3;
      lines[vehicle].forEach((line) => {
        line.setOptions({
          strokeColor,
          strokeOpacity,
          zIndex,
          strokeWeight,
        });
      });
    });
  };

  const renderMarkers = (skipCenter?: boolean) => {
    if (!mapRef.current?.map_) {
      return;
    }
    const map = mapRef.current.map_;
    const maps = mapRef.current.maps_;

    const bounds = new maps.LatLngBounds();

    Object.keys(markersRef.current).forEach((key) => {
      markersRef.current[key].forEach((marker) => {
        marker.setMap(null);
      });
    });
    markersRef.current = {};
    positionsRef.current = {};

    Object.keys(markers)
      .filter((vehicleId) => !isEmpty(markers[vehicleId]))
      .forEach((vehicleId) => {
        markers[vehicleId].forEach((marker, index) => {
          const position = {
            lat: marker.lat,
            lng: marker.lng,
          };
          if (!Array.isArray(positionsRef.current[vehicleId])) {
            positionsRef.current[vehicleId] = [];
          }
          positionsRef.current[vehicleId].push(position);
          bounds.extend(position);

          const mapMarker = new MarkerWithLabel({
            position,
            map,
            icon: new maps.MarkerImage(iconJobSite, null, null, new maps.Point(11, 22), new maps.Size(22, 22)),
            labelContent: '',
            labelAnchor: new maps.Point(0, 10),
            labelClass: 'label',
            key: marker.key,
          } as MarkerWithLabelOptions);
          if (!Array.isArray(markersRef.current[vehicleId])) {
            markersRef.current[vehicleId] = [];
          }
          if (index === 0 || (index === markers[vehicleId].length - 1 && markers[vehicleId].length > 1)) {
            markersRef.current[vehicleId].push(mapMarker as MarkerWithLabelWithKey);
          }
          mapMarker.setMap(null);
        });
      });

    maps.event.addListener(map, 'click', () => {
      flashPolyline();
      setSelectedVehicleId('');
    });

    Object.keys(lines).forEach((vehicleId) => {
      lines[vehicleId].forEach((line) => {
        line.setMap(null);
      });
    });
    lines = {};

    Object.keys(positionsRef.current).forEach((vehicleId) => {
      if (!Array.isArray(lines[vehicleId])) {
        lines[vehicleId] = [];
      }
      for (let i = 0; i < positionsRef.current[vehicleId].length - 1; i++) {
        const line = new maps.Polyline({
          path: positionsRef.current[vehicleId].slice(i, i + 2),
          strokeColor: colorByVehicle[vehicleId],
          strokeOpacity: 1,
          strokeWeight: 3,
          clickable: false,
        });
        line.setMap(map);

        lines[vehicleId].push(line);
      }
    });

    const gotLocations = some(markers, (value) => some(value));
    if (!skipCenter && gotLocations) {
      map.fitBounds(bounds);
    }
  };

  const handleGoogleApiLoaded = async () => {
    if (!mapRef.current?.map_) {
      return;
    }
    mapLoadedRef.current = true;

    const map = mapRef.current.map_;
    const maps = mapRef.current.maps_;

    map.controls[maps.ControlPosition.TOP_RIGHT].push(buttonRef.current);
    map.controls[maps.ControlPosition.TOP_LEFT].push(imgRef.current);
    map.controls[maps.ControlPosition.TOP_CENTER].push(vehicleRef.current);
    map.controls[maps.ControlPosition.BOTTOM_CENTER].push(mapViewRef.current);
    map.controls[maps.ControlPosition.BOTTOM_CENTER].push(trafficRef.current);
    if (controls) {
      await Promise.all(
        controls?.map(async (control) => {
          if (!control.fullscreen) {
            await map.controls[maps.ControlPosition[control.position]].push(control.ref.current);
          }
        }),
      );
    }
    map.controls[maps.ControlPosition.LEFT_TOP].push(vehiclesRef.current);
    mapViewRef.current?.addEventListener('click', (event) => {
      const target = event.target as HTMLTextAreaElement;
      map.setMapTypeId(maps.MapTypeId[target.value]);
      setMapView(target.value);
    });

    map.trafficLayer = new maps.TrafficLayer();
    trafficRef.current?.addEventListener('click', () => {
      if (!map.trafficLayer.getMap()) {
        map.trafficLayer.setMap(map);
        setTrafficView(true);
      } else {
        map.trafficLayer.setMap(null);
        setTrafficView(false);
      }
    });
    map.mapTypes.hybrid.name = 'Satellite';

    renderMarkers(!!defaultZoom || !!center);
    if (onMapInited) {
      onMapInited();
    }
  };

  const centerToUse = useMemo(() => {
    if (center) {
      return center;
    }

    const currentCenter = findCenter(Object.keys(markers).reduce((partMarkers, vehicleId) => [...partMarkers, ...markers[vehicleId]], [] as Marker[]));
    return markers ? currentCenter : { lat: 40.7042975, lng: -73.7751181 };
  }, []); // eslint-disable-line

  useEffect(() => {
    if (centerMap) {
      mapRef.current?.map_?.panTo(centerMap);
    }
  }, [centerMap]);

  useEffect(() => {
    if (mapLoadedRef.current) {
      renderMarkers();
    }
  }, [fullScreen]); // eslint-disable-line

  const handleLoadData = () => {
    dispatch(setLastRefresh(new Date().toISOString()));
  };

  useEffect(() => {
    renderMarkers();
  }, [markers]); // eslint-disable-line

  const vehiclesById = groupBy(vehicles, 'id');
  const selectedVehicle = vehiclesById[selectedVehicleId]?.[0];

  const handleSelectVehicle = (vehicleId?: string) => {
    flashPolyline(vehicleId);
    setSelectedVehicleId(vehicleId ?? '');
  };

  const labelByLengt = {
    0: 'No Data',
    1: 'No Movement',
    default: '',
  };

  return (
    <Box component="span">
      <GoogleMapReactWrapper style={{ height }}>
        <GoogleMapReact
          ref={mapRef}
          yesIWantToUseGoogleMapApiInternals
          options={getMapOptions}
          bootstrapURLKeys={bootstrapURLKeys}
          defaultCenter={centerToUse}
          defaultZoom={defaultZoom}
          onGoogleApiLoaded={handleGoogleApiLoaded}
          onDragEnd={onDragEnd}
          onZoomAnimationEnd={(zoom: number) => {
            if (onZoomAnimationEnd) {
              onZoomAnimationEnd(zoom, mapRef.current?.map_?.getCenter());
            }
          }}
        />
      </GoogleMapReactWrapper>
      <img
        ref={imgRef}
        src={logo}
        style={{ height: isMdUp ? 64 : 28, marginTop: 8, marginLeft: isMdUp ? 16 : 8, display: fullScreen ? 'inline-block' : 'none' }}
        alt="The Tag System"
      />
      <Button
        ref={buttonRef}
        sx={{ mt: 2, mr: 2, whiteSpace: 'nowrap', display: fullScreen ? 'inline-flex' : 'none' }}
        startIcon={<LoopIcon />}
        size={isMdUp ? 'large' : 'small'}
        onClick={handleLoadData}
        variant="contained"
      >
        {tagsDate(lastRefresh, 'TIME_WITH_SECONDS')}
      </Button>
      <ToggleButtonGroup
        ref={mapViewRef}
        value={mapView}
        exclusive
        sx={{
          marginBottom: isMdUp ? '10px' : '60px',
          display: (withTrafficFullScreen && fullScreen) || withTraffic ? 'inline-flex' : 'none',
        }}
      >
        <ToggleButton value="ROADMAP">Map</ToggleButton>
        <ToggleButton value="HYBRID">Satellite</ToggleButton>
      </ToggleButtonGroup>
      <ToggleButton
        ref={trafficRef}
        value="check"
        sx={{
          marginBottom: isMdUp ? '10px' : '60px',
          ml: 2,
          display: (withTrafficFullScreen && fullScreen) || withTraffic ? 'inline-flex' : 'none',
        }}
        color="secondary"
        selected={trafficView}
      >
        Traffic
      </ToggleButton>
      <Box ref={vehicleRef} sx={{ minWidth: 200 }}>
        <Paper
          variant="elevation"
          sx={{ p: 2, textAlign: 'center', mt: 5, boxShadow: (theme) => `${theme.shadows[1]} !important` }}
          elevation={1}
          hidden={!selectedVehicle}
        >
          <Typography variant="h6" component="span">
            {selectedVehicle?.name}
            {selectedVehicle?.id && markers[selectedVehicle.id]?.length <= 1 && ` (${labelByLengt[markers?.[selectedVehicle.id].length]})`}
          </Typography>
        </Paper>
      </Box>
      <Stack gap={2} ref={vehiclesRef} sx={{ ml: 2, mt: 10 }}>
        {Object.keys(markers).map((vehicleId) => (
          <Tooltip title={markers?.[vehicleId] && (labelByLengt[markers?.[vehicleId].length] ?? labelByLengt.default)} placement="right">
            <ToggleButton
              key={vehicleId}
              value={vehicleId}
              sx={{
                position: 'relative',
                overflow: 'hidden',
                minWidth: 150,
                justifyContent: 'flex-start',
                pl: 4,
                '&:before': {
                  position: 'absolute',
                  width: 8,
                  left: 0,
                  top: 0,
                  bottom: 0,
                  content: '""',
                  backgroundColor: selectedVehicle?.id === vehicleId ? '#000000' : colorByVehicle[vehicleId],
                },
              }}
              color="secondary"
              selected={selectedVehicle?.id === vehicleId}
              onClick={() => handleSelectVehicle(selectedVehicle?.id === vehicleId ? undefined : vehicleId)}
            >
              {vehiclesById[vehicleId]?.[0]?.name}
              {markers?.[vehicleId]?.length <= 1 && <WrongLocationOutlinedIcon fontSize="small" sx={{ ml: 2 }} />}
            </ToggleButton>
          </Tooltip>
        ))}
      </Stack>
    </Box>
  );
};

export default FleetMap;
