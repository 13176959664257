import { Box } from '@mui/material';
import styled from 'styled-components';

const BoxDropZone = styled(Box).withConfig({
  shouldForwardProp: (prop) => !['$isFocused', '$isDragAccept', '$isDragReject'].includes(prop),
})<{ $isFocused: boolean; $isDragAccept: boolean; $isDragReject: boolean }>(({ $isFocused, $isDragAccept, $isDragReject, theme }) => ({
  backgroundColor: theme.palette.background.default,
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: 20,
  borderWidth: 2,
  borderRadius: 2,
  borderColor: theme.palette.background.grey,
  borderStyle: 'dashed',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  ...($isFocused && { borderColor: theme.palette.background.blueGrey }),
  ...($isDragAccept && { borderColor: theme.palette.text.green }),
  ...($isDragReject && { borderColor: theme.palette.text.red }),
}));

export default {
  BoxDropZone,
};
