import { useMediaQuery } from '@mui/material';
import { Box, useTheme } from '@mui/system';
import { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { imageBase } from '../../api/client';
import { tagsDate } from '../../helpers/dataHelper';
import usePeriodFilter from '../../hooks/usePeriodFilter';
import useSearchParams from '../../hooks/useSearchParams';
import { QueryToolDetailsMapped } from '../../redux/slices/tools/toolsInterface';
import { Card } from '../../styled/components';
import ManufacturerFilter from '../filters/ManufacturerFilter';
import Table from '../table/Table';
import { StockInstalledType } from './interface';

const manufacturerFilter = { productTypeId: '2' };

export const StockInstalledList: React.FC<StockInstalledType> = ({
  items,
  itemsExport,
  title,
  withFilters,
  paginationControl,
  pagination,
  dense,
  fetching,
  withPreset,
  exportFileName,
  getExportData,
}: StockInstalledType) => {
  const [params, setURLParams] = useSearchParams();
  const handleFiltersClear = useCallback(() => {
    setURLParams({ clearAll: true });
  }, [setURLParams]);
  const theme = useTheme();
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
  const PeriodFilter = usePeriodFilter({ params, setURLParams, withPreset });

  const columns = useMemo(
    () => [
      ...(isSmUp
        ? [
            {
              accessor: 'image_key',
              Header: 'Image',
              Cell: ({ row }: { row: { original: QueryToolDetailsMapped } }) => (
                <Link to={`/stock/items/${row.original.item_id}/details`}>
                  {row.original.image_key && (
                    <img src={`${imageBase(row.original.image_key)}?height=36`} key={row.original.item_id} alt={row.original.product_name} />
                  )}
                </Link>
              ),
              disableSortBy: true,
              width: 50,
              style: { paddingTop: 2, paddingBottom: 2, textAlign: 'center' },
            },
          ]
        : []),
      {
        Header: 'Item - Product',
        accessor: 'identifier',
        ellipsis: true,
        Cell: ({ row }: { row: { original: QueryToolDetailsMapped } }) => (
          <Box
            sx={{
              borderRadius: 1,
              borderWidth: 1,
              borderStyle: 'solid',
              borderColor: 'transparent',
            }}
          >
            <Link to={`/stock/items/${row.original.item_id}/details`}>
              {row.original.identifier} - {row.original.product_name}
            </Link>
          </Box>
        ),
      },
      {
        Header: 'Manufacturer',
        accessor: 'manufacturer_name',
        ellipsis: true,
        Cell: ({ row }: { row: { original: QueryToolDetailsMapped } }) => (
          <Link to={`/tools/manufacturers/${row.original.manufacturer_id}/details`}>{row.original.manufacturer_name}</Link>
        ),
        ...(withFilters && { Filter: ManufacturerFilter, filterProps: { filters: manufacturerFilter } }),
      },
      {
        Header: 'Last Seen',
        accessor: 'processed_at',
        Cell: ({ row }: { row: { original: QueryToolDetailsMapped } }) => (row.original.processed_at ? tagsDate(row.original.processed_at) : ''),
      },
      {
        Header: 'Delivered By',
        accessor: 'payload_warehouse_name',
      },
      {
        Header: 'Site Visit',
        accessor: 'tracking_site_name',
        ...(withFilters && { Filter: PeriodFilter, filterOrder: -1 }),
      },
    ],
    [withFilters, PeriodFilter, isSmUp],
  );

  return (
    <Card>
      <Table
        columns={columns}
        data={items?.result}
        dataExport={itemsExport?.result}
        title={title}
        loading={fetching}
        {...(withFilters && { showDefaultFilters: true, onFiltersClear: handleFiltersClear })}
        paginationControl={paginationControl}
        pagination={pagination}
        {...(dense && { size: 'small', noGlobalFilter: true })}
        exportFileName={exportFileName}
        getExportData={getExportData}
      />
    </Card>
  );
};
