import { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { StockInstalledList } from '../../component/stock/StockInstalledList';
import prepareParams from '../../helpers/prepareParams';
import useCurrentRoute from '../../hooks/useCurrentRoute';
import useSearchParams from '../../hooks/useSearchParams';
import useTablePagination from '../../hooks/useTablePagination';
import { getProductToolsById, listProductTools, productToolsFetching } from '../../redux/slices/tools/toolsSlice';
import { useDispatch } from '../../redux/store';

type StockInstalledProps = {
  filters?: { [key: string]: string };
  noManufacturer?: boolean;
  noVehicle?: boolean;
  noProduct?: boolean;
  dense?: boolean;
  withPreset?: boolean;
};
export const StockInstalled: React.FC<StockInstalledProps> = ({ filters, dense, withPreset }: StockInstalledProps) => {
  const dispatch = useDispatch();
  const route = useCurrentRoute();
  const [params] = useSearchParams();
  const paramsToUse = useMemo(
    () => ({
      productTypeId: '2',
      scanStatus: '6',
      ...Object.keys(params).reduce((partParams, key) => ({ ...partParams, [key]: prepareParams[key] ? prepareParams[key](params[key]) : params[key] }), {}),
      ...(filters || {}),
    }),
    [params, filters],
  );

  const stockInstalled = useSelector(getProductToolsById(paramsToUse));
  const fetching = useSelector(productToolsFetching);
  const [pagination, paginationToGet, paginationControl, paginationToGetAll] = useTablePagination({ paginationDirty: stockInstalled.pagination });
  const stockInstalledExport = useSelector(getProductToolsById({ ...paramsToUse, ...paginationToGetAll }));

  useEffect(() => {
    dispatch(
      listProductTools({
        ...paramsToUse,
        ...paginationToGet,
      }),
    );
  }, [dispatch, paramsToUse, paginationToGet]);

  const getExportData = useCallback(async () => {
    await dispatch(
      listProductTools({
        ...paramsToUse,
        ...paginationToGetAll,
      }),
    );
  }, [dispatch, paramsToUse, paginationToGetAll]);

  return (
    <StockInstalledList
      items={stockInstalled}
      itemsExport={stockInstalledExport}
      fetching={fetching}
      withFilters
      paginationControl={paginationControl}
      pagination={pagination}
      dense={dense}
      withPreset={withPreset}
      exportFileName={route?.route?.path}
      getExportData={getExportData}
    />
  );
};
