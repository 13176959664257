/* tslint:disable */
/* eslint-disable */
/**
 * The Tag Server API
 * ...
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface AlertEventListResponse
 */
export interface AlertEventListResponse {
    /**
     * 
     * @type {number}
     * @memberof AlertEventListResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof AlertEventListResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof AlertEventListResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof AlertEventListResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof AlertEventListResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof AlertEventListResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof AlertEventListResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {Array<QueryAlertEvent>}
     * @memberof AlertEventListResponse
     */
    'result'?: Array<QueryAlertEvent>;
}
/**
 * 
 * @export
 * @interface AlertEventMonitorListResponse
 */
export interface AlertEventMonitorListResponse {
    /**
     * 
     * @type {number}
     * @memberof AlertEventMonitorListResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof AlertEventMonitorListResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof AlertEventMonitorListResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof AlertEventMonitorListResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof AlertEventMonitorListResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof AlertEventMonitorListResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof AlertEventMonitorListResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {Array<QueryAlertEventMonitor>}
     * @memberof AlertEventMonitorListResponse
     */
    'result'?: Array<QueryAlertEventMonitor>;
}
/**
 * 
 * @export
 * @interface AlertEventMonitorsRequest
 */
export interface AlertEventMonitorsRequest {
    /**
     * 
     * @type {Array<ModelAlertEventMonitor>}
     * @memberof AlertEventMonitorsRequest
     */
    'monitors'?: Array<ModelAlertEventMonitor>;
}
/**
 * 
 * @export
 * @interface AlertEventTypeListResponse
 */
export interface AlertEventTypeListResponse {
    /**
     * 
     * @type {number}
     * @memberof AlertEventTypeListResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof AlertEventTypeListResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof AlertEventTypeListResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof AlertEventTypeListResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof AlertEventTypeListResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof AlertEventTypeListResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof AlertEventTypeListResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {Array<QueryAlertEventType>}
     * @memberof AlertEventTypeListResponse
     */
    'result'?: Array<QueryAlertEventType>;
}
/**
 * 
 * @export
 * @interface AssignmentAssignmentResponse
 */
export interface AssignmentAssignmentResponse {
    /**
     * 
     * @type {number}
     * @memberof AssignmentAssignmentResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof AssignmentAssignmentResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof AssignmentAssignmentResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof AssignmentAssignmentResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof AssignmentAssignmentResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof AssignmentAssignmentResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof AssignmentAssignmentResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {QueryAssignment}
     * @memberof AssignmentAssignmentResponse
     */
    'result'?: QueryAssignment;
}
/**
 * 
 * @export
 * @interface AssignmentCreateRequest
 */
export interface AssignmentCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof AssignmentCreateRequest
     */
    'job_site_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssignmentCreateRequest
     */
    'notes'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssignmentCreateRequest
     */
    'organization_id'?: string;
}
/**
 * 
 * @export
 * @interface AssignmentDeliveryOptionsRequest
 */
export interface AssignmentDeliveryOptionsRequest {
    /**
     * 
     * @type {string}
     * @memberof AssignmentDeliveryOptionsRequest
     */
    'delivery_time'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssignmentDeliveryOptionsRequest
     */
    'organization_id'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AssignmentDeliveryOptionsRequest
     */
    'skip_transaction_control'?: boolean;
}
/**
 * 
 * @export
 * @interface AssignmentDeliveryResponse
 */
export interface AssignmentDeliveryResponse {
    /**
     * 
     * @type {number}
     * @memberof AssignmentDeliveryResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof AssignmentDeliveryResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof AssignmentDeliveryResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof AssignmentDeliveryResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof AssignmentDeliveryResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof AssignmentDeliveryResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof AssignmentDeliveryResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {string}
     * @memberof AssignmentDeliveryResponse
     */
    'result'?: string;
}
/**
 * 
 * @export
 * @interface AssignmentDetailResponse
 */
export interface AssignmentDetailResponse {
    /**
     * 
     * @type {number}
     * @memberof AssignmentDetailResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof AssignmentDetailResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof AssignmentDetailResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof AssignmentDetailResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof AssignmentDetailResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof AssignmentDetailResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof AssignmentDetailResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {QueryItemDetails}
     * @memberof AssignmentDetailResponse
     */
    'result'?: QueryItemDetails;
}
/**
 * 
 * @export
 * @interface AssignmentKPIsResponse
 */
export interface AssignmentKPIsResponse {
    /**
     * 
     * @type {number}
     * @memberof AssignmentKPIsResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof AssignmentKPIsResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof AssignmentKPIsResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof AssignmentKPIsResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof AssignmentKPIsResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof AssignmentKPIsResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof AssignmentKPIsResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {QueryAssignmentKPIs}
     * @memberof AssignmentKPIsResponse
     */
    'result'?: QueryAssignmentKPIs;
}
/**
 * 
 * @export
 * @interface AssignmentListResponse
 */
export interface AssignmentListResponse {
    /**
     * 
     * @type {number}
     * @memberof AssignmentListResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof AssignmentListResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof AssignmentListResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof AssignmentListResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof AssignmentListResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof AssignmentListResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof AssignmentListResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {Array<QueryAssignmentSummary>}
     * @memberof AssignmentListResponse
     */
    'result'?: Array<QueryAssignmentSummary>;
}
/**
 * 
 * @export
 * @interface AssignmentRequestItemDeliver
 */
export interface AssignmentRequestItemDeliver {
    /**
     * 
     * @type {string}
     * @memberof AssignmentRequestItemDeliver
     */
    'site_id'?: string;
}
/**
 * 
 * @export
 * @interface AssignmentRequestUpdate
 */
export interface AssignmentRequestUpdate {
    /**
     * 
     * @type {Array<string>}
     * @memberof AssignmentRequestUpdate
     */
    'deleted_items'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AssignmentRequestUpdate
     */
    'id'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AssignmentRequestUpdate
     */
    'new_items'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AssignmentRequestUpdate
     */
    'notes'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssignmentRequestUpdate
     */
    'organization_id'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const AuthIdentityType = {
    IdentityTypeEmail: 'email',
    IdentityTypePhone: 'phone',
    IdentityTypeUnit: 'unit'
} as const;

export type AuthIdentityType = typeof AuthIdentityType[keyof typeof AuthIdentityType];


/**
 * 
 * @export
 * @interface AuthJWTResponse
 */
export interface AuthJWTResponse {
    /**
     * 
     * @type {number}
     * @memberof AuthJWTResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof AuthJWTResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof AuthJWTResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof AuthJWTResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof AuthJWTResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof AuthJWTResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof AuthJWTResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {AuthJWTResponseResult}
     * @memberof AuthJWTResponse
     */
    'result'?: AuthJWTResponseResult;
}
/**
 * 
 * @export
 * @interface AuthJWTResponseResult
 */
export interface AuthJWTResponseResult {
    /**
     * 
     * @type {string}
     * @memberof AuthJWTResponseResult
     */
    'expire'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AuthJWTResponseResult
     */
    'features'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AuthJWTResponseResult
     */
    'organization_id'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AuthJWTResponseResult
     */
    'roles'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AuthJWTResponseResult
     */
    'token'?: string;
}
/**
 * 
 * @export
 * @interface AuthLoginRequest
 */
export interface AuthLoginRequest {
    /**
     * 
     * @type {string}
     * @memberof AuthLoginRequest
     */
    'identity'?: string;
    /**
     * 
     * @type {AuthIdentityType}
     * @memberof AuthLoginRequest
     */
    'identity_type'?: AuthIdentityType;
    /**
     * 
     * @type {string}
     * @memberof AuthLoginRequest
     */
    'password'?: string;
}


/**
 * 
 * @export
 * @interface CustomerCustomerRequest
 */
export interface CustomerCustomerRequest {
    /**
     * 
     * @type {boolean}
     * @memberof CustomerCustomerRequest
     */
    'active'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CustomerCustomerRequest
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerCustomerRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerCustomerRequest
     */
    'organization_id'?: string;
}
/**
 * 
 * @export
 * @interface CustomerCustomerResponse
 */
export interface CustomerCustomerResponse {
    /**
     * 
     * @type {number}
     * @memberof CustomerCustomerResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof CustomerCustomerResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof CustomerCustomerResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof CustomerCustomerResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof CustomerCustomerResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerCustomerResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerCustomerResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {QueryCustomer}
     * @memberof CustomerCustomerResponse
     */
    'result'?: QueryCustomer;
}
/**
 * 
 * @export
 * @interface CustomerDependenciesResponse
 */
export interface CustomerDependenciesResponse {
    /**
     * 
     * @type {number}
     * @memberof CustomerDependenciesResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof CustomerDependenciesResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof CustomerDependenciesResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof CustomerDependenciesResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof CustomerDependenciesResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerDependenciesResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerDependenciesResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {QueryDependencies}
     * @memberof CustomerDependenciesResponse
     */
    'result'?: QueryDependencies;
}
/**
 * 
 * @export
 * @interface DashboardActivitySummaryResponse
 */
export interface DashboardActivitySummaryResponse {
    /**
     * 
     * @type {number}
     * @memberof DashboardActivitySummaryResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof DashboardActivitySummaryResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof DashboardActivitySummaryResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof DashboardActivitySummaryResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof DashboardActivitySummaryResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof DashboardActivitySummaryResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof DashboardActivitySummaryResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {Array<QueryActivitySummary>}
     * @memberof DashboardActivitySummaryResponse
     */
    'result'?: Array<QueryActivitySummary>;
}
/**
 * 
 * @export
 * @interface DashboardAlertEventsResponse
 */
export interface DashboardAlertEventsResponse {
    /**
     * 
     * @type {number}
     * @memberof DashboardAlertEventsResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof DashboardAlertEventsResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof DashboardAlertEventsResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof DashboardAlertEventsResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof DashboardAlertEventsResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof DashboardAlertEventsResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof DashboardAlertEventsResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {Array<QueryAlertEvent>}
     * @memberof DashboardAlertEventsResponse
     */
    'result'?: Array<QueryAlertEvent>;
}
/**
 * 
 * @export
 * @interface DashboardAllSummaryResponse
 */
export interface DashboardAllSummaryResponse {
    /**
     * 
     * @type {number}
     * @memberof DashboardAllSummaryResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof DashboardAllSummaryResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof DashboardAllSummaryResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof DashboardAllSummaryResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof DashboardAllSummaryResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof DashboardAllSummaryResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof DashboardAllSummaryResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {Array<QueryVehicleDashboardSummary>}
     * @memberof DashboardAllSummaryResponse
     */
    'result'?: Array<QueryVehicleDashboardSummary>;
}
/**
 * 
 * @export
 * @interface DashboardGroupSummaryResponse
 */
export interface DashboardGroupSummaryResponse {
    /**
     * 
     * @type {number}
     * @memberof DashboardGroupSummaryResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof DashboardGroupSummaryResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof DashboardGroupSummaryResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof DashboardGroupSummaryResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof DashboardGroupSummaryResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof DashboardGroupSummaryResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof DashboardGroupSummaryResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {Array<QueryGroupSummary>}
     * @memberof DashboardGroupSummaryResponse
     */
    'result'?: Array<QueryGroupSummary>;
}
/**
 * 
 * @export
 * @interface DashboardKpisResponse
 */
export interface DashboardKpisResponse {
    /**
     * 
     * @type {number}
     * @memberof DashboardKpisResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof DashboardKpisResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof DashboardKpisResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof DashboardKpisResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof DashboardKpisResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof DashboardKpisResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof DashboardKpisResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {Array<QueryKPI>}
     * @memberof DashboardKpisResponse
     */
    'result'?: Array<QueryKPI>;
}
/**
 * 
 * @export
 * @interface DashboardSummaryResponse
 */
export interface DashboardSummaryResponse {
    /**
     * 
     * @type {number}
     * @memberof DashboardSummaryResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof DashboardSummaryResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof DashboardSummaryResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof DashboardSummaryResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof DashboardSummaryResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof DashboardSummaryResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof DashboardSummaryResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {QueryVehicleDashboardSummary}
     * @memberof DashboardSummaryResponse
     */
    'result'?: QueryVehicleDashboardSummary;
}
/**
 * 
 * @export
 * @interface DeviceDependenciesResponse
 */
export interface DeviceDependenciesResponse {
    /**
     * 
     * @type {number}
     * @memberof DeviceDependenciesResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof DeviceDependenciesResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof DeviceDependenciesResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof DeviceDependenciesResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof DeviceDependenciesResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof DeviceDependenciesResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof DeviceDependenciesResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {QueryDependencies}
     * @memberof DeviceDependenciesResponse
     */
    'result'?: QueryDependencies;
}
/**
 * 
 * @export
 * @interface DeviceDeviceAssignRequest
 */
export interface DeviceDeviceAssignRequest {
    /**
     * 
     * @type {string}
     * @memberof DeviceDeviceAssignRequest
     */
    'device_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceDeviceAssignRequest
     */
    'warehouse_id'?: string;
}
/**
 * 
 * @export
 * @interface DeviceDeviceAssignedBuildListResponse
 */
export interface DeviceDeviceAssignedBuildListResponse {
    /**
     * 
     * @type {number}
     * @memberof DeviceDeviceAssignedBuildListResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof DeviceDeviceAssignedBuildListResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof DeviceDeviceAssignedBuildListResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof DeviceDeviceAssignedBuildListResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof DeviceDeviceAssignedBuildListResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof DeviceDeviceAssignedBuildListResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof DeviceDeviceAssignedBuildListResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {Array<QueryDeviceBuildInfo>}
     * @memberof DeviceDeviceAssignedBuildListResponse
     */
    'result'?: Array<QueryDeviceBuildInfo>;
}
/**
 * 
 * @export
 * @interface DeviceDeviceBuildSetRequest
 */
export interface DeviceDeviceBuildSetRequest {
    /**
     * 
     * @type {string}
     * @memberof DeviceDeviceBuildSetRequest
     */
    'build_tag'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceDeviceBuildSetRequest
     */
    'identifier'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceDeviceBuildSetRequest
     */
    'software'?: string;
}
/**
 * 
 * @export
 * @interface DeviceDeviceBuildSetResponse
 */
export interface DeviceDeviceBuildSetResponse {
    /**
     * 
     * @type {number}
     * @memberof DeviceDeviceBuildSetResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof DeviceDeviceBuildSetResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof DeviceDeviceBuildSetResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof DeviceDeviceBuildSetResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof DeviceDeviceBuildSetResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof DeviceDeviceBuildSetResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof DeviceDeviceBuildSetResponse
     */
    'record_offset'?: number;
}
/**
 * 
 * @export
 * @interface DeviceDeviceBuildsResponse
 */
export interface DeviceDeviceBuildsResponse {
    /**
     * 
     * @type {number}
     * @memberof DeviceDeviceBuildsResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof DeviceDeviceBuildsResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof DeviceDeviceBuildsResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof DeviceDeviceBuildsResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof DeviceDeviceBuildsResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof DeviceDeviceBuildsResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof DeviceDeviceBuildsResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {QueryDeviceBuilds}
     * @memberof DeviceDeviceBuildsResponse
     */
    'result'?: QueryDeviceBuilds;
}
/**
 * 
 * @export
 * @interface DeviceDeviceConfigurationGetResponse
 */
export interface DeviceDeviceConfigurationGetResponse {
    /**
     * 
     * @type {number}
     * @memberof DeviceDeviceConfigurationGetResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof DeviceDeviceConfigurationGetResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof DeviceDeviceConfigurationGetResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof DeviceDeviceConfigurationGetResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof DeviceDeviceConfigurationGetResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof DeviceDeviceConfigurationGetResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof DeviceDeviceConfigurationGetResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {ModelDeviceConfiguration}
     * @memberof DeviceDeviceConfigurationGetResponse
     */
    'result'?: ModelDeviceConfiguration;
}
/**
 * 
 * @export
 * @interface DeviceDeviceConfigurationSetRequest
 */
export interface DeviceDeviceConfigurationSetRequest {
    /**
     * 
     * @type {number}
     * @memberof DeviceDeviceConfigurationSetRequest
     */
    'accelerometer_force'?: number;
    /**
     * 
     * @type {number}
     * @memberof DeviceDeviceConfigurationSetRequest
     */
    'active_sleep'?: number;
    /**
     * 
     * @type {number}
     * @memberof DeviceDeviceConfigurationSetRequest
     */
    'antennas'?: number;
    /**
     * 
     * @type {number}
     * @memberof DeviceDeviceConfigurationSetRequest
     */
    'cycle_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof DeviceDeviceConfigurationSetRequest
     */
    'event_reporting'?: number;
    /**
     * 
     * @type {number}
     * @memberof DeviceDeviceConfigurationSetRequest
     */
    'saving_sleep'?: number;
    /**
     * 
     * @type {number}
     * @memberof DeviceDeviceConfigurationSetRequest
     */
    'scan_duration'?: number;
    /**
     * 
     * @type {number}
     * @memberof DeviceDeviceConfigurationSetRequest
     */
    'scan_power'?: number;
}
/**
 * 
 * @export
 * @interface DeviceDeviceConfigurationSetResponse
 */
export interface DeviceDeviceConfigurationSetResponse {
    /**
     * 
     * @type {number}
     * @memberof DeviceDeviceConfigurationSetResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof DeviceDeviceConfigurationSetResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof DeviceDeviceConfigurationSetResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof DeviceDeviceConfigurationSetResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof DeviceDeviceConfigurationSetResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof DeviceDeviceConfigurationSetResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof DeviceDeviceConfigurationSetResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {string}
     * @memberof DeviceDeviceConfigurationSetResponse
     */
    'result'?: string;
}
/**
 * 
 * @export
 * @interface DeviceDeviceListResponse
 */
export interface DeviceDeviceListResponse {
    /**
     * 
     * @type {number}
     * @memberof DeviceDeviceListResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof DeviceDeviceListResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof DeviceDeviceListResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof DeviceDeviceListResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof DeviceDeviceListResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof DeviceDeviceListResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof DeviceDeviceListResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {Array<QueryDevice>}
     * @memberof DeviceDeviceListResponse
     */
    'result'?: Array<QueryDevice>;
}
/**
 * 
 * @export
 * @interface DeviceDeviceMetricsResponse
 */
export interface DeviceDeviceMetricsResponse {
    /**
     * 
     * @type {number}
     * @memberof DeviceDeviceMetricsResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof DeviceDeviceMetricsResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof DeviceDeviceMetricsResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof DeviceDeviceMetricsResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof DeviceDeviceMetricsResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof DeviceDeviceMetricsResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof DeviceDeviceMetricsResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {QueryDeviceMetrics}
     * @memberof DeviceDeviceMetricsResponse
     */
    'result'?: QueryDeviceMetrics;
}
/**
 * 
 * @export
 * @interface DeviceDeviceRegisterRequest
 */
export interface DeviceDeviceRegisterRequest {
    /**
     * 
     * @type {string}
     * @memberof DeviceDeviceRegisterRequest
     */
    'components'?: string;
    /**
     * 
     * @type {number}
     * @memberof DeviceDeviceRegisterRequest
     */
    'device_type_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof DeviceDeviceRegisterRequest
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceDeviceRegisterRequest
     */
    'mac_address'?: string;
}
/**
 * 
 * @export
 * @interface DeviceDeviceRegisterResponse
 */
export interface DeviceDeviceRegisterResponse {
    /**
     * 
     * @type {number}
     * @memberof DeviceDeviceRegisterResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof DeviceDeviceRegisterResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof DeviceDeviceRegisterResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof DeviceDeviceRegisterResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof DeviceDeviceRegisterResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof DeviceDeviceRegisterResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof DeviceDeviceRegisterResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {DeviceDeviceRegistration}
     * @memberof DeviceDeviceRegisterResponse
     */
    'result'?: DeviceDeviceRegistration;
}
/**
 * 
 * @export
 * @interface DeviceDeviceRegistration
 */
export interface DeviceDeviceRegistration {
    /**
     * 
     * @type {string}
     * @memberof DeviceDeviceRegistration
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceDeviceRegistration
     */
    'identifier'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceDeviceRegistration
     */
    'mac_address'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceDeviceRegistration
     */
    'password'?: string;
}
/**
 * 
 * @export
 * @interface DeviceDeviceResponse
 */
export interface DeviceDeviceResponse {
    /**
     * 
     * @type {number}
     * @memberof DeviceDeviceResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof DeviceDeviceResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof DeviceDeviceResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof DeviceDeviceResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof DeviceDeviceResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof DeviceDeviceResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof DeviceDeviceResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {QueryDevice}
     * @memberof DeviceDeviceResponse
     */
    'result'?: QueryDevice;
}
/**
 * 
 * @export
 * @interface DeviceDeviceSetActiveConfigurationRequest
 */
export interface DeviceDeviceSetActiveConfigurationRequest {
    /**
     * 
     * @type {string}
     * @memberof DeviceDeviceSetActiveConfigurationRequest
     */
    'configuration_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceDeviceSetActiveConfigurationRequest
     */
    'identifier'?: string;
}
/**
 * 
 * @export
 * @interface DeviceEventListResponse
 */
export interface DeviceEventListResponse {
    /**
     * 
     * @type {number}
     * @memberof DeviceEventListResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof DeviceEventListResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof DeviceEventListResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof DeviceEventListResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof DeviceEventListResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof DeviceEventListResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof DeviceEventListResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {Array<QueryDeviceEvent>}
     * @memberof DeviceEventListResponse
     */
    'result'?: Array<QueryDeviceEvent>;
}
/**
 * 
 * @export
 * @interface DeviceEventRequestEvent
 */
export interface DeviceEventRequestEvent {
    /**
     * 
     * @type {string}
     * @memberof DeviceEventRequestEvent
     */
    'batch_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceEventRequestEvent
     */
    'error'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceEventRequestEvent
     */
    'event'?: string;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof DeviceEventRequestEvent
     */
    'parameters'?: { [key: string]: any; };
    /**
     * 
     * @type {string}
     * @memberof DeviceEventRequestEvent
     */
    'processed_at'?: string;
    /**
     * 
     * @type {number}
     * @memberof DeviceEventRequestEvent
     */
    'return_code'?: number;
    /**
     * 
     * @type {string}
     * @memberof DeviceEventRequestEvent
     */
    'source'?: string;
}
/**
 * 
 * @export
 * @interface DeviceEventRequestNewDeviceEvents
 */
export interface DeviceEventRequestNewDeviceEvents {
    /**
     * 
     * @type {string}
     * @memberof DeviceEventRequestNewDeviceEvents
     */
    'device_identifier'?: string;
    /**
     * 
     * @type {Array<DeviceEventRequestEvent>}
     * @memberof DeviceEventRequestNewDeviceEvents
     */
    'events'?: Array<DeviceEventRequestEvent>;
}
/**
 * 
 * @export
 * @interface DeviceRequestDeviceUpdate
 */
export interface DeviceRequestDeviceUpdate {
    /**
     * 
     * @type {boolean}
     * @memberof DeviceRequestDeviceUpdate
     */
    'active'?: boolean;
}
/**
 * 
 * @export
 * @interface DeviceVersionResponse
 */
export interface DeviceVersionResponse {
    /**
     * 
     * @type {number}
     * @memberof DeviceVersionResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof DeviceVersionResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof DeviceVersionResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof DeviceVersionResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof DeviceVersionResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof DeviceVersionResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof DeviceVersionResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {Array<QueryDeviceVersion>}
     * @memberof DeviceVersionResponse
     */
    'result'?: Array<QueryDeviceVersion>;
}
/**
 * 
 * @export
 * @interface EmbeddedBuildDependenciesResponse
 */
export interface EmbeddedBuildDependenciesResponse {
    /**
     * 
     * @type {number}
     * @memberof EmbeddedBuildDependenciesResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof EmbeddedBuildDependenciesResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof EmbeddedBuildDependenciesResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof EmbeddedBuildDependenciesResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof EmbeddedBuildDependenciesResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof EmbeddedBuildDependenciesResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof EmbeddedBuildDependenciesResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {QueryDependencies}
     * @memberof EmbeddedBuildDependenciesResponse
     */
    'result'?: QueryDependencies;
}
/**
 * 
 * @export
 * @interface EmbeddedBuildEmbeddedBuildAssignmentResponse
 */
export interface EmbeddedBuildEmbeddedBuildAssignmentResponse {
    /**
     * 
     * @type {number}
     * @memberof EmbeddedBuildEmbeddedBuildAssignmentResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof EmbeddedBuildEmbeddedBuildAssignmentResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof EmbeddedBuildEmbeddedBuildAssignmentResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof EmbeddedBuildEmbeddedBuildAssignmentResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof EmbeddedBuildEmbeddedBuildAssignmentResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof EmbeddedBuildEmbeddedBuildAssignmentResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof EmbeddedBuildEmbeddedBuildAssignmentResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {Array<QueryBuildAssignment>}
     * @memberof EmbeddedBuildEmbeddedBuildAssignmentResponse
     */
    'result'?: Array<QueryBuildAssignment>;
}
/**
 * 
 * @export
 * @interface EmbeddedBuildEmbeddedBuildResponse
 */
export interface EmbeddedBuildEmbeddedBuildResponse {
    /**
     * 
     * @type {number}
     * @memberof EmbeddedBuildEmbeddedBuildResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof EmbeddedBuildEmbeddedBuildResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof EmbeddedBuildEmbeddedBuildResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof EmbeddedBuildEmbeddedBuildResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof EmbeddedBuildEmbeddedBuildResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof EmbeddedBuildEmbeddedBuildResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof EmbeddedBuildEmbeddedBuildResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {QueryEmbeddedBuild}
     * @memberof EmbeddedBuildEmbeddedBuildResponse
     */
    'result'?: QueryEmbeddedBuild;
}
/**
 * 
 * @export
 * @interface EmbeddedBuildRequestGitSync
 */
export interface EmbeddedBuildRequestGitSync {
    /**
     * 
     * @type {string}
     * @memberof EmbeddedBuildRequestGitSync
     */
    'repo_name'?: string;
}
/**
 * 
 * @export
 * @interface EmbeddedBuildRequestNewEmbeddedBuild
 */
export interface EmbeddedBuildRequestNewEmbeddedBuild {
    /**
     * 
     * @type {string}
     * @memberof EmbeddedBuildRequestNewEmbeddedBuild
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmbeddedBuildRequestNewEmbeddedBuild
     */
    'hash'?: string;
    /**
     * 
     * @type {number}
     * @memberof EmbeddedBuildRequestNewEmbeddedBuild
     */
    'major'?: number;
    /**
     * 
     * @type {number}
     * @memberof EmbeddedBuildRequestNewEmbeddedBuild
     */
    'minor'?: number;
    /**
     * 
     * @type {number}
     * @memberof EmbeddedBuildRequestNewEmbeddedBuild
     */
    'release'?: number;
    /**
     * 
     * @type {string}
     * @memberof EmbeddedBuildRequestNewEmbeddedBuild
     */
    'repo_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmbeddedBuildRequestNewEmbeddedBuild
     */
    'tag'?: string;
}
/**
 * 
 * @export
 * @interface EmbeddedBuildSyncListResponse
 */
export interface EmbeddedBuildSyncListResponse {
    /**
     * 
     * @type {number}
     * @memberof EmbeddedBuildSyncListResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof EmbeddedBuildSyncListResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof EmbeddedBuildSyncListResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof EmbeddedBuildSyncListResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof EmbeddedBuildSyncListResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof EmbeddedBuildSyncListResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof EmbeddedBuildSyncListResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {Array<ModelEmbeddedBuild>}
     * @memberof EmbeddedBuildSyncListResponse
     */
    'result'?: Array<ModelEmbeddedBuild>;
}
/**
 * 
 * @export
 * @interface EndpointScanResponse
 */
export interface EndpointScanResponse {
    /**
     * 
     * @type {number}
     * @memberof EndpointScanResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof EndpointScanResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof EndpointScanResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof EndpointScanResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof EndpointScanResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof EndpointScanResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof EndpointScanResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {string}
     * @memberof EndpointScanResponse
     */
    'result'?: string;
}
/**
 * 
 * @export
 * @interface EndpointScanStatus
 */
export interface EndpointScanStatus {
    /**
     * 
     * @type {string}
     * @memberof EndpointScanStatus
     */
    'configuration_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof EndpointScanStatus
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof EndpointScanStatus
     */
    'location_type_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof EndpointScanStatus
     */
    'payload_id'?: string;
}
/**
 * 
 * @export
 * @interface EndpointScanStatusResponse
 */
export interface EndpointScanStatusResponse {
    /**
     * 
     * @type {number}
     * @memberof EndpointScanStatusResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof EndpointScanStatusResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof EndpointScanStatusResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof EndpointScanStatusResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof EndpointScanStatusResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof EndpointScanStatusResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof EndpointScanStatusResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {EndpointScanStatus}
     * @memberof EndpointScanStatusResponse
     */
    'result'?: EndpointScanStatus;
}
/**
 * 
 * @export
 * @interface EpcEpcSetRequest
 */
export interface EpcEpcSetRequest {
    /**
     * 
     * @type {number}
     * @memberof EpcEpcSetRequest
     */
    'length'?: number;
}
/**
 * 
 * @export
 * @interface EpcEpcSetResponse
 */
export interface EpcEpcSetResponse {
    /**
     * 
     * @type {number}
     * @memberof EpcEpcSetResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof EpcEpcSetResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof EpcEpcSetResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof EpcEpcSetResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof EpcEpcSetResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof EpcEpcSetResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof EpcEpcSetResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof EpcEpcSetResponse
     */
    'result'?: Array<string>;
}
/**
 * 
 * @export
 * @interface EpcEpcValidationResponse
 */
export interface EpcEpcValidationResponse {
    /**
     * 
     * @type {number}
     * @memberof EpcEpcValidationResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof EpcEpcValidationResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof EpcEpcValidationResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof EpcEpcValidationResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof EpcEpcValidationResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof EpcEpcValidationResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof EpcEpcValidationResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof EpcEpcValidationResponse
     */
    'result'?: boolean;
}
/**
 * 
 * @export
 * @interface FeatureDependenciesResponse
 */
export interface FeatureDependenciesResponse {
    /**
     * 
     * @type {number}
     * @memberof FeatureDependenciesResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof FeatureDependenciesResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof FeatureDependenciesResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof FeatureDependenciesResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof FeatureDependenciesResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof FeatureDependenciesResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof FeatureDependenciesResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {QueryDependencies}
     * @memberof FeatureDependenciesResponse
     */
    'result'?: QueryDependencies;
}
/**
 * 
 * @export
 * @interface FeatureListResponse
 */
export interface FeatureListResponse {
    /**
     * 
     * @type {number}
     * @memberof FeatureListResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof FeatureListResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof FeatureListResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof FeatureListResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof FeatureListResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof FeatureListResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof FeatureListResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {Array<QueryFeature>}
     * @memberof FeatureListResponse
     */
    'result'?: Array<QueryFeature>;
}
/**
 * 
 * @export
 * @interface FeatureObjectResponse
 */
export interface FeatureObjectResponse {
    /**
     * 
     * @type {number}
     * @memberof FeatureObjectResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof FeatureObjectResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof FeatureObjectResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof FeatureObjectResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof FeatureObjectResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof FeatureObjectResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof FeatureObjectResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {QueryFeature}
     * @memberof FeatureObjectResponse
     */
    'result'?: QueryFeature;
}
/**
 * 
 * @export
 * @interface FeatureRequestFeature
 */
export interface FeatureRequestFeature {
    /**
     * 
     * @type {string}
     * @memberof FeatureRequestFeature
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface HandlerResponse
 */
export interface HandlerResponse {
    /**
     * 
     * @type {number}
     * @memberof HandlerResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof HandlerResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof HandlerResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof HandlerResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof HandlerResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof HandlerResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof HandlerResponse
     */
    'record_offset'?: number;
}
/**
 * 
 * @export
 * @interface ImageCreateResponse
 */
export interface ImageCreateResponse {
    /**
     * 
     * @type {number}
     * @memberof ImageCreateResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof ImageCreateResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof ImageCreateResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ImageCreateResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof ImageCreateResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof ImageCreateResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof ImageCreateResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {ModelImageMeta}
     * @memberof ImageCreateResponse
     */
    'result'?: ModelImageMeta;
}
/**
 * 
 * @export
 * @interface ImageImageResponse
 */
export interface ImageImageResponse {
    /**
     * 
     * @type {number}
     * @memberof ImageImageResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof ImageImageResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof ImageImageResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ImageImageResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof ImageImageResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof ImageImageResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof ImageImageResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {Array<ModelImageMeta>}
     * @memberof ImageImageResponse
     */
    'result'?: Array<ModelImageMeta>;
}
/**
 * 
 * @export
 * @interface ImageRequestUpdateItem
 */
export interface ImageRequestUpdateItem {
    /**
     * 
     * @type {string}
     * @memberof ImageRequestUpdateItem
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ImageRequestUpdateItem
     */
    'image_data'?: string;
    /**
     * 
     * @type {string}
     * @memberof ImageRequestUpdateItem
     */
    'mime_type'?: string;
}
/**
 * 
 * @export
 * @interface ItemBatchReportRun
 */
export interface ItemBatchReportRun {
    /**
     * 
     * @type {string}
     * @memberof ItemBatchReportRun
     */
    'id'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ItemBatchReportRun
     */
    'logs'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ItemBatchReportRun
     */
    'report_name'?: string;
}
/**
 * 
 * @export
 * @interface ItemBatchReportRunCreateParams
 */
export interface ItemBatchReportRunCreateParams {
    /**
     * 
     * @type {string}
     * @memberof ItemBatchReportRunCreateParams
     */
    'organization_id'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ItemBatchReportRunCreateParams
     */
    'send_email'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ItemBatchReportRunCreateParams
     */
    'short_id'?: string;
}
/**
 * 
 * @export
 * @interface ItemBatchReportRunCreateResponse
 */
export interface ItemBatchReportRunCreateResponse {
    /**
     * 
     * @type {number}
     * @memberof ItemBatchReportRunCreateResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof ItemBatchReportRunCreateResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ItemBatchReportRunCreateResponse
     */
    'exec_start'?: string;
    /**
     * 
     * @type {string}
     * @memberof ItemBatchReportRunCreateResponse
     */
    'exec_time'?: string;
    /**
     * 
     * @type {number}
     * @memberof ItemBatchReportRunCreateResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ItemBatchReportRunCreateResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof ItemBatchReportRunCreateResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof ItemBatchReportRunCreateResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof ItemBatchReportRunCreateResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {Array<ItemBatchReportRun>}
     * @memberof ItemBatchReportRunCreateResponse
     */
    'runs'?: Array<ItemBatchReportRun>;
}
/**
 * 
 * @export
 * @interface ItemBatchReportRunDetailResponse
 */
export interface ItemBatchReportRunDetailResponse {
    /**
     * 
     * @type {number}
     * @memberof ItemBatchReportRunDetailResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof ItemBatchReportRunDetailResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof ItemBatchReportRunDetailResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ItemBatchReportRunDetailResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof ItemBatchReportRunDetailResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof ItemBatchReportRunDetailResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof ItemBatchReportRunDetailResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {QueryBatchReportRun}
     * @memberof ItemBatchReportRunDetailResponse
     */
    'result'?: QueryBatchReportRun;
}
/**
 * 
 * @export
 * @interface ItemBatchReportRunHistoryResponse
 */
export interface ItemBatchReportRunHistoryResponse {
    /**
     * 
     * @type {number}
     * @memberof ItemBatchReportRunHistoryResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof ItemBatchReportRunHistoryResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof ItemBatchReportRunHistoryResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ItemBatchReportRunHistoryResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof ItemBatchReportRunHistoryResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof ItemBatchReportRunHistoryResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof ItemBatchReportRunHistoryResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {Array<QueryBatchReportRunDetail>}
     * @memberof ItemBatchReportRunHistoryResponse
     */
    'result'?: Array<QueryBatchReportRunDetail>;
}
/**
 * 
 * @export
 * @interface ItemBatchReportRunListResponse
 */
export interface ItemBatchReportRunListResponse {
    /**
     * 
     * @type {number}
     * @memberof ItemBatchReportRunListResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof ItemBatchReportRunListResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof ItemBatchReportRunListResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ItemBatchReportRunListResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof ItemBatchReportRunListResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof ItemBatchReportRunListResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof ItemBatchReportRunListResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {Array<QueryBatchReportRun>}
     * @memberof ItemBatchReportRunListResponse
     */
    'result'?: Array<QueryBatchReportRun>;
}
/**
 * 
 * @export
 * @interface ItemBatchReportRunURLResponse
 */
export interface ItemBatchReportRunURLResponse {
    /**
     * 
     * @type {number}
     * @memberof ItemBatchReportRunURLResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof ItemBatchReportRunURLResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof ItemBatchReportRunURLResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ItemBatchReportRunURLResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof ItemBatchReportRunURLResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof ItemBatchReportRunURLResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof ItemBatchReportRunURLResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {string}
     * @memberof ItemBatchReportRunURLResponse
     */
    'run_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ItemBatchReportRunURLResponse
     */
    'url'?: string;
}
/**
 * 
 * @export
 * @interface ItemCategoryListResponse
 */
export interface ItemCategoryListResponse {
    /**
     * 
     * @type {number}
     * @memberof ItemCategoryListResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof ItemCategoryListResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof ItemCategoryListResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ItemCategoryListResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof ItemCategoryListResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof ItemCategoryListResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof ItemCategoryListResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {Array<QueryItemCategory>}
     * @memberof ItemCategoryListResponse
     */
    'result'?: Array<QueryItemCategory>;
}
/**
 * 
 * @export
 * @interface ItemCreateResponse
 */
export interface ItemCreateResponse {
    /**
     * 
     * @type {number}
     * @memberof ItemCreateResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof ItemCreateResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof ItemCreateResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ItemCreateResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof ItemCreateResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof ItemCreateResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof ItemCreateResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {ItemItem}
     * @memberof ItemCreateResponse
     */
    'result'?: ItemItem;
}
/**
 * 
 * @export
 * @interface ItemDependenciesResponse
 */
export interface ItemDependenciesResponse {
    /**
     * 
     * @type {number}
     * @memberof ItemDependenciesResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof ItemDependenciesResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof ItemDependenciesResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ItemDependenciesResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof ItemDependenciesResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof ItemDependenciesResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof ItemDependenciesResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {QueryDependencies}
     * @memberof ItemDependenciesResponse
     */
    'result'?: QueryDependencies;
}
/**
 * 
 * @export
 * @interface ItemExcelReportRequest
 */
export interface ItemExcelReportRequest {
    /**
     * 
     * @type {string}
     * @memberof ItemExcelReportRequest
     */
    'id'?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ItemExcelReportRequest
     */
    'parameters'?: { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface ItemItem
 */
export interface ItemItem {
    /**
     * 
     * @type {boolean}
     * @memberof ItemItem
     */
    'active'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ItemItem
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ItemItem
     */
    'identifier'?: string;
    /**
     * 
     * @type {string}
     * @memberof ItemItem
     */
    'job_site_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ItemItem
     */
    'notes'?: string;
    /**
     * 
     * @type {string}
     * @memberof ItemItem
     */
    'organization_id'?: string;
    /**
     * 
     * @type {ModelProduct}
     * @memberof ItemItem
     */
    'product'?: ModelProduct;
    /**
     * 
     * @type {string}
     * @memberof ItemItem
     */
    'product_id'?: string;
    /**
     * 
     * @type {Array<ModelTag>}
     * @memberof ItemItem
     */
    'tags'?: Array<ModelTag>;
    /**
     * 
     * @type {string}
     * @memberof ItemItem
     */
    'warehouse_id'?: string;
}
/**
 * 
 * @export
 * @interface ItemListActivityDecodesResponse
 */
export interface ItemListActivityDecodesResponse {
    /**
     * 
     * @type {number}
     * @memberof ItemListActivityDecodesResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof ItemListActivityDecodesResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof ItemListActivityDecodesResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ItemListActivityDecodesResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof ItemListActivityDecodesResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof ItemListActivityDecodesResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof ItemListActivityDecodesResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {Array<QueryActivityDecode>}
     * @memberof ItemListActivityDecodesResponse
     */
    'result'?: Array<QueryActivityDecode>;
}
/**
 * 
 * @export
 * @interface ItemListActivityResponse
 */
export interface ItemListActivityResponse {
    /**
     * 
     * @type {number}
     * @memberof ItemListActivityResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof ItemListActivityResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof ItemListActivityResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ItemListActivityResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof ItemListActivityResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof ItemListActivityResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof ItemListActivityResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {Array<QueryItemActivity>}
     * @memberof ItemListActivityResponse
     */
    'result'?: Array<QueryItemActivity>;
}
/**
 * 
 * @export
 * @interface ItemListAggregateResponse
 */
export interface ItemListAggregateResponse {
    /**
     * 
     * @type {number}
     * @memberof ItemListAggregateResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof ItemListAggregateResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof ItemListAggregateResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ItemListAggregateResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof ItemListAggregateResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof ItemListAggregateResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof ItemListAggregateResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {Array<QueryItemAggregate>}
     * @memberof ItemListAggregateResponse
     */
    'result'?: Array<QueryItemAggregate>;
}
/**
 * 
 * @export
 * @interface ItemListReportRecipientResponse
 */
export interface ItemListReportRecipientResponse {
    /**
     * 
     * @type {number}
     * @memberof ItemListReportRecipientResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof ItemListReportRecipientResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof ItemListReportRecipientResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ItemListReportRecipientResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof ItemListReportRecipientResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof ItemListReportRecipientResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof ItemListReportRecipientResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {Array<QueryReportRecipient>}
     * @memberof ItemListReportRecipientResponse
     */
    'result'?: Array<QueryReportRecipient>;
}
/**
 * 
 * @export
 * @interface ItemListReportScheduleResponse
 */
export interface ItemListReportScheduleResponse {
    /**
     * 
     * @type {number}
     * @memberof ItemListReportScheduleResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof ItemListReportScheduleResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof ItemListReportScheduleResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ItemListReportScheduleResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof ItemListReportScheduleResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof ItemListReportScheduleResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof ItemListReportScheduleResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {Array<QueryReportSchedule>}
     * @memberof ItemListReportScheduleResponse
     */
    'result'?: Array<QueryReportSchedule>;
}
/**
 * 
 * @export
 * @interface ItemReportRecipientRequest
 */
export interface ItemReportRecipientRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof ItemReportRecipientRequest
     */
    'add'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ItemReportRecipientRequest
     */
    'remove'?: Array<string>;
}
/**
 * 
 * @export
 * @interface ItemReportScheduleResponse
 */
export interface ItemReportScheduleResponse {
    /**
     * 
     * @type {number}
     * @memberof ItemReportScheduleResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof ItemReportScheduleResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof ItemReportScheduleResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ItemReportScheduleResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof ItemReportScheduleResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof ItemReportScheduleResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof ItemReportScheduleResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {QueryReportSchedule}
     * @memberof ItemReportScheduleResponse
     */
    'result'?: QueryReportSchedule;
}
/**
 * 
 * @export
 * @interface ItemRequestNewItem
 */
export interface ItemRequestNewItem {
    /**
     * 
     * @type {string}
     * @memberof ItemRequestNewItem
     */
    'born_on'?: string;
    /**
     * 
     * @type {string}
     * @memberof ItemRequestNewItem
     */
    'identifier'?: string;
    /**
     * 
     * @type {string}
     * @memberof ItemRequestNewItem
     */
    'job_site_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ItemRequestNewItem
     */
    'notes'?: string;
    /**
     * 
     * @type {string}
     * @memberof ItemRequestNewItem
     */
    'organization_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ItemRequestNewItem
     */
    'product_id'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ItemRequestNewItem
     */
    'tags'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ItemRequestNewItem
     */
    'warehouse_id'?: string;
}
/**
 * 
 * @export
 * @interface ItemRequestUpdateItem
 */
export interface ItemRequestUpdateItem {
    /**
     * 
     * @type {boolean}
     * @memberof ItemRequestUpdateItem
     */
    'active'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ItemRequestUpdateItem
     */
    'born_on'?: string;
    /**
     * 
     * @type {string}
     * @memberof ItemRequestUpdateItem
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ItemRequestUpdateItem
     */
    'identifier'?: string;
    /**
     * 
     * @type {string}
     * @memberof ItemRequestUpdateItem
     */
    'job_site_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ItemRequestUpdateItem
     */
    'notes'?: string;
    /**
     * 
     * @type {string}
     * @memberof ItemRequestUpdateItem
     */
    'organization_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ItemRequestUpdateItem
     */
    'product_id'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ItemRequestUpdateItem
     */
    'tags'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ItemRequestUpdateItem
     */
    'warehouse_id'?: string;
}
/**
 * 
 * @export
 * @interface ItemSerialNumberResponse
 */
export interface ItemSerialNumberResponse {
    /**
     * 
     * @type {number}
     * @memberof ItemSerialNumberResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof ItemSerialNumberResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof ItemSerialNumberResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ItemSerialNumberResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof ItemSerialNumberResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof ItemSerialNumberResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof ItemSerialNumberResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof ItemSerialNumberResponse
     */
    'result'?: number;
}
/**
 * 
 * @export
 * @interface LocationvisitListItemResponse
 */
export interface LocationvisitListItemResponse {
    /**
     * 
     * @type {number}
     * @memberof LocationvisitListItemResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof LocationvisitListItemResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof LocationvisitListItemResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof LocationvisitListItemResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof LocationvisitListItemResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof LocationvisitListItemResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof LocationvisitListItemResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {Array<QueryLocationVisitItem>}
     * @memberof LocationvisitListItemResponse
     */
    'result'?: Array<QueryLocationVisitItem>;
}
/**
 * 
 * @export
 * @interface LocationvisitListResponse
 */
export interface LocationvisitListResponse {
    /**
     * 
     * @type {number}
     * @memberof LocationvisitListResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof LocationvisitListResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof LocationvisitListResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof LocationvisitListResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof LocationvisitListResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof LocationvisitListResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof LocationvisitListResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {Array<QueryLocationVisitSummary>}
     * @memberof LocationvisitListResponse
     */
    'result'?: Array<QueryLocationVisitSummary>;
}
/**
 * 
 * @export
 * @interface LocationvisitObjectResponse
 */
export interface LocationvisitObjectResponse {
    /**
     * 
     * @type {number}
     * @memberof LocationvisitObjectResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof LocationvisitObjectResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof LocationvisitObjectResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof LocationvisitObjectResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof LocationvisitObjectResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof LocationvisitObjectResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof LocationvisitObjectResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {QueryLocationVisit}
     * @memberof LocationvisitObjectResponse
     */
    'result'?: QueryLocationVisit;
}
/**
 * 
 * @export
 * @interface LocationvisitRequestUpdateVisit
 */
export interface LocationvisitRequestUpdateVisit {
    /**
     * 
     * @type {string}
     * @memberof LocationvisitRequestUpdateVisit
     */
    'job_site_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof LocationvisitRequestUpdateVisit
     */
    'location_visit_id'?: string;
}
/**
 * 
 * @export
 * @interface MaintenanceEventListRecurrenceTypeResponse
 */
export interface MaintenanceEventListRecurrenceTypeResponse {
    /**
     * 
     * @type {number}
     * @memberof MaintenanceEventListRecurrenceTypeResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof MaintenanceEventListRecurrenceTypeResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof MaintenanceEventListRecurrenceTypeResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof MaintenanceEventListRecurrenceTypeResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof MaintenanceEventListRecurrenceTypeResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof MaintenanceEventListRecurrenceTypeResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof MaintenanceEventListRecurrenceTypeResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {Array<ModelRecurrenceType>}
     * @memberof MaintenanceEventListRecurrenceTypeResponse
     */
    'result'?: Array<ModelRecurrenceType>;
}
/**
 * 
 * @export
 * @interface MaintenanceEventListResponse
 */
export interface MaintenanceEventListResponse {
    /**
     * 
     * @type {number}
     * @memberof MaintenanceEventListResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof MaintenanceEventListResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof MaintenanceEventListResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof MaintenanceEventListResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof MaintenanceEventListResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof MaintenanceEventListResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof MaintenanceEventListResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {Array<QueryMaintenanceEvent>}
     * @memberof MaintenanceEventListResponse
     */
    'result'?: Array<QueryMaintenanceEvent>;
}
/**
 * 
 * @export
 * @interface MaintenanceEventObjectResponse
 */
export interface MaintenanceEventObjectResponse {
    /**
     * 
     * @type {number}
     * @memberof MaintenanceEventObjectResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof MaintenanceEventObjectResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof MaintenanceEventObjectResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof MaintenanceEventObjectResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof MaintenanceEventObjectResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof MaintenanceEventObjectResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof MaintenanceEventObjectResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {QueryMaintenanceEvent}
     * @memberof MaintenanceEventObjectResponse
     */
    'result'?: QueryMaintenanceEvent;
}
/**
 * 
 * @export
 * @interface MaintenanceEventRequestNewEvent
 */
export interface MaintenanceEventRequestNewEvent {
    /**
     * 
     * @type {boolean}
     * @memberof MaintenanceEventRequestNewEvent
     */
    'completed'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MaintenanceEventRequestNewEvent
     */
    'details'?: string;
    /**
     * 
     * @type {string}
     * @memberof MaintenanceEventRequestNewEvent
     */
    'due_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof MaintenanceEventRequestNewEvent
     */
    'item_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MaintenanceEventRequestNewEvent
     */
    'organization_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof MaintenanceEventRequestNewEvent
     */
    'recurrence_type_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof MaintenanceEventRequestNewEvent
     */
    'summary'?: string;
}
/**
 * 
 * @export
 * @interface MaintenanceEventRequestUpdateEvent
 */
export interface MaintenanceEventRequestUpdateEvent {
    /**
     * 
     * @type {boolean}
     * @memberof MaintenanceEventRequestUpdateEvent
     */
    'completed'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MaintenanceEventRequestUpdateEvent
     */
    'details'?: string;
    /**
     * 
     * @type {string}
     * @memberof MaintenanceEventRequestUpdateEvent
     */
    'due_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof MaintenanceEventRequestUpdateEvent
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MaintenanceEventRequestUpdateEvent
     */
    'item_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MaintenanceEventRequestUpdateEvent
     */
    'organization_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof MaintenanceEventRequestUpdateEvent
     */
    'recurrence_type_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof MaintenanceEventRequestUpdateEvent
     */
    'summary'?: string;
}
/**
 * 
 * @export
 * @interface ManufacturerCreateResponse
 */
export interface ManufacturerCreateResponse {
    /**
     * 
     * @type {number}
     * @memberof ManufacturerCreateResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerCreateResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof ManufacturerCreateResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ManufacturerCreateResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof ManufacturerCreateResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof ManufacturerCreateResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof ManufacturerCreateResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {QueryManufacturer}
     * @memberof ManufacturerCreateResponse
     */
    'result'?: QueryManufacturer;
}
/**
 * 
 * @export
 * @interface ManufacturerDependenciesResponse
 */
export interface ManufacturerDependenciesResponse {
    /**
     * 
     * @type {number}
     * @memberof ManufacturerDependenciesResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerDependenciesResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof ManufacturerDependenciesResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ManufacturerDependenciesResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof ManufacturerDependenciesResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof ManufacturerDependenciesResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof ManufacturerDependenciesResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {QueryDependencies}
     * @memberof ManufacturerDependenciesResponse
     */
    'result'?: QueryDependencies;
}
/**
 * 
 * @export
 * @interface ManufacturerListResponse
 */
export interface ManufacturerListResponse {
    /**
     * 
     * @type {number}
     * @memberof ManufacturerListResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerListResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof ManufacturerListResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ManufacturerListResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof ManufacturerListResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof ManufacturerListResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof ManufacturerListResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {Array<QueryManufacturer>}
     * @memberof ManufacturerListResponse
     */
    'result'?: Array<QueryManufacturer>;
}
/**
 * 
 * @export
 * @interface ManufacturerObjectResponse
 */
export interface ManufacturerObjectResponse {
    /**
     * 
     * @type {number}
     * @memberof ManufacturerObjectResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerObjectResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof ManufacturerObjectResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ManufacturerObjectResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof ManufacturerObjectResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof ManufacturerObjectResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof ManufacturerObjectResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {QueryManufacturer}
     * @memberof ManufacturerObjectResponse
     */
    'result'?: QueryManufacturer;
}
/**
 * 
 * @export
 * @interface ManufacturerRequestManufacturer
 */
export interface ManufacturerRequestManufacturer {
    /**
     * 
     * @type {boolean}
     * @memberof ManufacturerRequestManufacturer
     */
    'active'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerRequestManufacturer
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerRequestManufacturer
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface ManufacturerRequestNewManufacturer
 */
export interface ManufacturerRequestNewManufacturer {
    /**
     * 
     * @type {string}
     * @memberof ManufacturerRequestNewManufacturer
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface ModelAlertEventMonitor
 */
export interface ModelAlertEventMonitor {
    /**
     * 
     * @type {boolean}
     * @memberof ModelAlertEventMonitor
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ModelAlertEventMonitor
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelAlertEventMonitor
     */
    'organization_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelAlertEventMonitor
     */
    'target_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelAlertEventMonitor
     */
    'user_id'?: string;
}
/**
 * 
 * @export
 * @interface ModelCustomer
 */
export interface ModelCustomer {
    /**
     * 
     * @type {boolean}
     * @memberof ModelCustomer
     */
    'active'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ModelCustomer
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelCustomer
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelCustomer
     */
    'name'?: string;
    /**
     * 
     * @type {ModelOrganization}
     * @memberof ModelCustomer
     */
    'organization'?: ModelOrganization;
    /**
     * 
     * @type {string}
     * @memberof ModelCustomer
     */
    'organization_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelCustomer
     */
    'updated_at'?: string;
}
/**
 * 
 * @export
 * @interface ModelDeviceConfiguration
 */
export interface ModelDeviceConfiguration {
    /**
     * 
     * @type {number}
     * @memberof ModelDeviceConfiguration
     */
    'accelerometer_force'?: number;
    /**
     * 
     * @type {number}
     * @memberof ModelDeviceConfiguration
     */
    'active_sleep'?: number;
    /**
     * 
     * @type {number}
     * @memberof ModelDeviceConfiguration
     */
    'antennas'?: number;
    /**
     * 
     * @type {string}
     * @memberof ModelDeviceConfiguration
     */
    'configuration_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelDeviceConfiguration
     */
    'created_at'?: string;
    /**
     * 
     * @type {number}
     * @memberof ModelDeviceConfiguration
     */
    'cycle_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof ModelDeviceConfiguration
     */
    'event_reporting'?: number;
    /**
     * 
     * @type {number}
     * @memberof ModelDeviceConfiguration
     */
    'saving_sleep'?: number;
    /**
     * 
     * @type {number}
     * @memberof ModelDeviceConfiguration
     */
    'scan_duration'?: number;
    /**
     * 
     * @type {number}
     * @memberof ModelDeviceConfiguration
     */
    'scan_power'?: number;
}
/**
 * 
 * @export
 * @interface ModelEmbeddedBuild
 */
export interface ModelEmbeddedBuild {
    /**
     * 
     * @type {string}
     * @memberof ModelEmbeddedBuild
     */
    'build_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelEmbeddedBuild
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelEmbeddedBuild
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelEmbeddedBuild
     */
    'git_hash'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelEmbeddedBuild
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof ModelEmbeddedBuild
     */
    'major'?: number;
    /**
     * 
     * @type {number}
     * @memberof ModelEmbeddedBuild
     */
    'minor'?: number;
    /**
     * 
     * @type {number}
     * @memberof ModelEmbeddedBuild
     */
    'release'?: number;
    /**
     * 
     * @type {string}
     * @memberof ModelEmbeddedBuild
     */
    'repo_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelEmbeddedBuild
     */
    'tags'?: string;
}
/**
 * 
 * @export
 * @interface ModelImageMeta
 */
export interface ModelImageMeta {
    /**
     * 
     * @type {string}
     * @memberof ModelImageMeta
     */
    'image_key'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelImageMeta
     */
    'mime_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelImageMeta
     */
    'organization_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelImageMeta
     */
    'owner_id'?: string;
}
/**
 * 
 * @export
 * @interface ModelItem
 */
export interface ModelItem {
    /**
     * 
     * @type {boolean}
     * @memberof ModelItem
     */
    'active'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ModelItem
     */
    'born_on'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelItem
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelItem
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelItem
     */
    'identifier'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelItem
     */
    'last_payload_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelItem
     */
    'notes'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelItem
     */
    'organization_id'?: string;
    /**
     * 
     * @type {ModelProduct}
     * @memberof ModelItem
     */
    'product'?: ModelProduct;
    /**
     * 
     * @type {string}
     * @memberof ModelItem
     */
    'product_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof ModelItem
     */
    'scan_status'?: number;
    /**
     * 
     * @type {Array<ModelTag>}
     * @memberof ModelItem
     */
    'tags'?: Array<ModelTag>;
    /**
     * 
     * @type {string}
     * @memberof ModelItem
     */
    'tracking_warehouse_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelItem
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelItem
     */
    'warehouse_id'?: string;
}
/**
 * 
 * @export
 * @interface ModelManufacturer
 */
export interface ModelManufacturer {
    /**
     * 
     * @type {boolean}
     * @memberof ModelManufacturer
     */
    'active'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ModelManufacturer
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelManufacturer
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelManufacturer
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelManufacturer
     */
    'updated_at'?: string;
}
/**
 * 
 * @export
 * @interface ModelOrganization
 */
export interface ModelOrganization {
    /**
     * 
     * @type {boolean}
     * @memberof ModelOrganization
     */
    'active'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ModelOrganization
     */
    'address'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelOrganization
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelOrganization
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelOrganization
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelOrganization
     */
    'name'?: string;
    /**
     * 
     * @type {ModelOrganizationType}
     * @memberof ModelOrganization
     */
    'organizationType'?: ModelOrganizationType;
    /**
     * 
     * @type {number}
     * @memberof ModelOrganization
     */
    'organization_type_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ModelOrganization
     */
    'state'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelOrganization
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelOrganization
     */
    'vertical_market_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelOrganization
     */
    'zip'?: string;
}
/**
 * 
 * @export
 * @interface ModelOrganizationType
 */
export interface ModelOrganizationType {
    /**
     * 
     * @type {number}
     * @memberof ModelOrganizationType
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ModelOrganizationType
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface ModelParameter
 */
export interface ModelParameter {
    /**
     * 
     * @type {string}
     * @memberof ModelParameter
     */
    'label'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelParameter
     */
    'name'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ModelParameter
     */
    'required'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ModelParameter
     */
    'type'?: string;
    /**
     * 
     * @type {object}
     * @memberof ModelParameter
     */
    'value'?: object;
}
/**
 * 
 * @export
 * @interface ModelPayloadStatus
 */
export interface ModelPayloadStatus {
    /**
     * 
     * @type {number}
     * @memberof ModelPayloadStatus
     */
    'payload_count'?: number;
    /**
     * 
     * @type {string}
     * @memberof ModelPayloadStatus
     */
    'processed_at_max'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelPayloadStatus
     */
    'processed_at_min'?: string;
}
/**
 * 
 * @export
 * @interface ModelPoint
 */
export interface ModelPoint {
    /**
     * 
     * @type {number}
     * @memberof ModelPoint
     */
    'latitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof ModelPoint
     */
    'longitude'?: number;
}
/**
 * 
 * @export
 * @interface ModelProduct
 */
export interface ModelProduct {
    /**
     * 
     * @type {boolean}
     * @memberof ModelProduct
     */
    'active'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ModelProduct
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelProduct
     */
    'id'?: string;
    /**
     * 
     * @type {ModelManufacturer}
     * @memberof ModelProduct
     */
    'manufacturer'?: ModelManufacturer;
    /**
     * 
     * @type {string}
     * @memberof ModelProduct
     */
    'manufacturer_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelProduct
     */
    'manufacturer_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelProduct
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelProduct
     */
    'product_category'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelProduct
     */
    'product_category_id'?: string;
    /**
     * 
     * @type {ModelProductType}
     * @memberof ModelProduct
     */
    'product_type'?: ModelProductType;
    /**
     * 
     * @type {number}
     * @memberof ModelProduct
     */
    'product_type_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ModelProduct
     */
    'sku'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelProduct
     */
    'updated_at'?: string;
}
/**
 * 
 * @export
 * @interface ModelProductType
 */
export interface ModelProductType {
    /**
     * 
     * @type {number}
     * @memberof ModelProductType
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ModelProductType
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface ModelPropertyData
 */
export interface ModelPropertyData {
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof ModelPropertyData
     */
    'environment'?: { [key: string]: any; };
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof ModelPropertyData
     */
    'hardware'?: { [key: string]: any; };
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof ModelPropertyData
     */
    'operation'?: { [key: string]: any; };
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof ModelPropertyData
     */
    'software'?: { [key: string]: any; };
}
/**
 * 
 * @export
 * @interface ModelRecurrenceType
 */
export interface ModelRecurrenceType {
    /**
     * 
     * @type {number}
     * @memberof ModelRecurrenceType
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ModelRecurrenceType
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface ModelReport
 */
export interface ModelReport {
    /**
     * 
     * @type {string}
     * @memberof ModelReport
     */
    'category'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelReport
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelReport
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelReport
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelReport
     */
    'name'?: string;
    /**
     * 
     * @type {Array<ModelParameter>}
     * @memberof ModelReport
     */
    'parameters'?: Array<ModelParameter>;
}
/**
 * 
 * @export
 * @interface ModelRoomType
 */
export interface ModelRoomType {
    /**
     * 
     * @type {number}
     * @memberof ModelRoomType
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ModelRoomType
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface ModelSite
 */
export interface ModelSite {
    /**
     * 
     * @type {boolean}
     * @memberof ModelSite
     */
    'active'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ModelSite
     */
    'address1'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelSite
     */
    'boundary_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelSite
     */
    'city'?: string;
    /**
     * 
     * @type {Array<ModelPoint>}
     * @memberof ModelSite
     */
    'coordinates'?: Array<ModelPoint>;
    /**
     * 
     * @type {string}
     * @memberof ModelSite
     */
    'created_at'?: string;
    /**
     * 
     * @type {ModelCustomer}
     * @memberof ModelSite
     */
    'customer'?: ModelCustomer;
    /**
     * 
     * @type {string}
     * @memberof ModelSite
     */
    'customer_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelSite
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof ModelSite
     */
    'latitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof ModelSite
     */
    'longitude'?: number;
    /**
     * 
     * @type {string}
     * @memberof ModelSite
     */
    'name'?: string;
    /**
     * 
     * @type {ModelOrganization}
     * @memberof ModelSite
     */
    'organization'?: ModelOrganization;
    /**
     * 
     * @type {string}
     * @memberof ModelSite
     */
    'organization_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelSite
     */
    'postal'?: string;
    /**
     * 
     * @type {ModelSiteType}
     * @memberof ModelSite
     */
    'site_type'?: ModelSiteType;
    /**
     * 
     * @type {number}
     * @memberof ModelSite
     */
    'site_type_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ModelSite
     */
    'state'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelSite
     */
    'updated_at'?: string;
}
/**
 * 
 * @export
 * @interface ModelSiteType
 */
export interface ModelSiteType {
    /**
     * 
     * @type {number}
     * @memberof ModelSiteType
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ModelSiteType
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface ModelTag
 */
export interface ModelTag {
    /**
     * 
     * @type {string}
     * @memberof ModelTag
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelTag
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelTag
     */
    'item_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelTag
     */
    'rfid'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelTag
     */
    'updated_at'?: string;
}
/**
 * 
 * @export
 * @interface ModelWarehouse
 */
export interface ModelWarehouse {
    /**
     * 
     * @type {string}
     * @memberof ModelWarehouse
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelWarehouse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelWarehouse
     */
    'location_visit_id'?: string;
    /**
     * 
     * @type {ModelOrganization}
     * @memberof ModelWarehouse
     */
    'organization'?: ModelOrganization;
    /**
     * 
     * @type {string}
     * @memberof ModelWarehouse
     */
    'organization_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelWarehouse
     */
    'owner_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelWarehouse
     */
    'payload_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelWarehouse
     */
    'updated_at'?: string;
    /**
     * 
     * @type {number}
     * @memberof ModelWarehouse
     */
    'warehouse_type_id'?: number;
}
/**
 * 
 * @export
 * @interface MonitoringPayloadsSubmittedResponse
 */
export interface MonitoringPayloadsSubmittedResponse {
    /**
     * 
     * @type {number}
     * @memberof MonitoringPayloadsSubmittedResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof MonitoringPayloadsSubmittedResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof MonitoringPayloadsSubmittedResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof MonitoringPayloadsSubmittedResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof MonitoringPayloadsSubmittedResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof MonitoringPayloadsSubmittedResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof MonitoringPayloadsSubmittedResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {Array<QueryVehiclePayloadSummary>}
     * @memberof MonitoringPayloadsSubmittedResponse
     */
    'result'?: Array<QueryVehiclePayloadSummary>;
}
/**
 * 
 * @export
 * @interface OperationsDelayedPayloadsResponse
 */
export interface OperationsDelayedPayloadsResponse {
    /**
     * 
     * @type {number}
     * @memberof OperationsDelayedPayloadsResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof OperationsDelayedPayloadsResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof OperationsDelayedPayloadsResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof OperationsDelayedPayloadsResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof OperationsDelayedPayloadsResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof OperationsDelayedPayloadsResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof OperationsDelayedPayloadsResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {Array<QueryDelayedPayload>}
     * @memberof OperationsDelayedPayloadsResponse
     */
    'result'?: Array<QueryDelayedPayload>;
}
/**
 * 
 * @export
 * @interface OperationsDeviceEventAggregateResponse
 */
export interface OperationsDeviceEventAggregateResponse {
    /**
     * 
     * @type {number}
     * @memberof OperationsDeviceEventAggregateResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof OperationsDeviceEventAggregateResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof OperationsDeviceEventAggregateResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof OperationsDeviceEventAggregateResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof OperationsDeviceEventAggregateResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof OperationsDeviceEventAggregateResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof OperationsDeviceEventAggregateResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {Array<QueryDeviceEventAggregate>}
     * @memberof OperationsDeviceEventAggregateResponse
     */
    'result'?: Array<QueryDeviceEventAggregate>;
}
/**
 * 
 * @export
 * @interface OperationsDeviceEventResponse
 */
export interface OperationsDeviceEventResponse {
    /**
     * 
     * @type {number}
     * @memberof OperationsDeviceEventResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof OperationsDeviceEventResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof OperationsDeviceEventResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof OperationsDeviceEventResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof OperationsDeviceEventResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof OperationsDeviceEventResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof OperationsDeviceEventResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {Array<QueryDeviceEvent>}
     * @memberof OperationsDeviceEventResponse
     */
    'result'?: Array<QueryDeviceEvent>;
}
/**
 * 
 * @export
 * @interface OperationsDeviceStatusesResponse
 */
export interface OperationsDeviceStatusesResponse {
    /**
     * 
     * @type {number}
     * @memberof OperationsDeviceStatusesResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof OperationsDeviceStatusesResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof OperationsDeviceStatusesResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof OperationsDeviceStatusesResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof OperationsDeviceStatusesResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof OperationsDeviceStatusesResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof OperationsDeviceStatusesResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {Array<QueryDeviceStatus>}
     * @memberof OperationsDeviceStatusesResponse
     */
    'result'?: Array<QueryDeviceStatus>;
}
/**
 * 
 * @export
 * @interface OperationsMetricsResponse
 */
export interface OperationsMetricsResponse {
    /**
     * 
     * @type {number}
     * @memberof OperationsMetricsResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof OperationsMetricsResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof OperationsMetricsResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof OperationsMetricsResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof OperationsMetricsResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof OperationsMetricsResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof OperationsMetricsResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {Array<QueryOperationsMetric>}
     * @memberof OperationsMetricsResponse
     */
    'result'?: Array<QueryOperationsMetric>;
}
/**
 * 
 * @export
 * @interface OperationsPhantomOnroadPayloadsResponse
 */
export interface OperationsPhantomOnroadPayloadsResponse {
    /**
     * 
     * @type {number}
     * @memberof OperationsPhantomOnroadPayloadsResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof OperationsPhantomOnroadPayloadsResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof OperationsPhantomOnroadPayloadsResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof OperationsPhantomOnroadPayloadsResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof OperationsPhantomOnroadPayloadsResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof OperationsPhantomOnroadPayloadsResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof OperationsPhantomOnroadPayloadsResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {Array<QueryPhantomPayload>}
     * @memberof OperationsPhantomOnroadPayloadsResponse
     */
    'result'?: Array<QueryPhantomPayload>;
}
/**
 * 
 * @export
 * @interface OperationsServiceStatusListResponse
 */
export interface OperationsServiceStatusListResponse {
    /**
     * 
     * @type {number}
     * @memberof OperationsServiceStatusListResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof OperationsServiceStatusListResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof OperationsServiceStatusListResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof OperationsServiceStatusListResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof OperationsServiceStatusListResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof OperationsServiceStatusListResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof OperationsServiceStatusListResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {Array<QueryServiceStatus>}
     * @memberof OperationsServiceStatusListResponse
     */
    'result'?: Array<QueryServiceStatus>;
}
/**
 * 
 * @export
 * @interface OrganizationFeaturesAddRequest
 */
export interface OrganizationFeaturesAddRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof OrganizationFeaturesAddRequest
     */
    'add'?: Array<string>;
}
/**
 * 
 * @export
 * @interface OrganizationFeaturesDeleteRequest
 */
export interface OrganizationFeaturesDeleteRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof OrganizationFeaturesDeleteRequest
     */
    'delete'?: Array<string>;
}
/**
 * 
 * @export
 * @interface OrganizationFeaturesListResponse
 */
export interface OrganizationFeaturesListResponse {
    /**
     * 
     * @type {number}
     * @memberof OrganizationFeaturesListResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof OrganizationFeaturesListResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof OrganizationFeaturesListResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof OrganizationFeaturesListResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof OrganizationFeaturesListResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof OrganizationFeaturesListResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof OrganizationFeaturesListResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {Array<QueryFeature>}
     * @memberof OrganizationFeaturesListResponse
     */
    'result'?: Array<QueryFeature>;
}
/**
 * 
 * @export
 * @interface OrganizationListResponse
 */
export interface OrganizationListResponse {
    /**
     * 
     * @type {number}
     * @memberof OrganizationListResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof OrganizationListResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof OrganizationListResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof OrganizationListResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof OrganizationListResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof OrganizationListResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof OrganizationListResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {Array<QueryOrganizationSummary>}
     * @memberof OrganizationListResponse
     */
    'result'?: Array<QueryOrganizationSummary>;
}
/**
 * 
 * @export
 * @interface OrganizationManufacturerListResponse
 */
export interface OrganizationManufacturerListResponse {
    /**
     * 
     * @type {number}
     * @memberof OrganizationManufacturerListResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof OrganizationManufacturerListResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof OrganizationManufacturerListResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof OrganizationManufacturerListResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof OrganizationManufacturerListResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof OrganizationManufacturerListResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof OrganizationManufacturerListResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {Array<QueryOrganizationManufacturer>}
     * @memberof OrganizationManufacturerListResponse
     */
    'result'?: Array<QueryOrganizationManufacturer>;
}
/**
 * 
 * @export
 * @interface OrganizationManufacturerSetRequest
 */
export interface OrganizationManufacturerSetRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof OrganizationManufacturerSetRequest
     */
    'add'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof OrganizationManufacturerSetRequest
     */
    'disable'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof OrganizationManufacturerSetRequest
     */
    'remove'?: Array<string>;
}
/**
 * 
 * @export
 * @interface OrganizationOrganization
 */
export interface OrganizationOrganization {
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationOrganization
     */
    'active'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OrganizationOrganization
     */
    'address'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationOrganization
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationOrganization
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationOrganization
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof OrganizationOrganization
     */
    'organization_type_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof OrganizationOrganization
     */
    'state'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationOrganization
     */
    'vertical_market_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationOrganization
     */
    'zip'?: string;
}
/**
 * 
 * @export
 * @interface OrganizationOrganizationDeleteResponse
 */
export interface OrganizationOrganizationDeleteResponse {
    /**
     * 
     * @type {number}
     * @memberof OrganizationOrganizationDeleteResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof OrganizationOrganizationDeleteResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof OrganizationOrganizationDeleteResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof OrganizationOrganizationDeleteResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof OrganizationOrganizationDeleteResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof OrganizationOrganizationDeleteResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof OrganizationOrganizationDeleteResponse
     */
    'record_offset'?: number;
}
/**
 * 
 * @export
 * @interface OrganizationOrganizationResponse
 */
export interface OrganizationOrganizationResponse {
    /**
     * 
     * @type {number}
     * @memberof OrganizationOrganizationResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof OrganizationOrganizationResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof OrganizationOrganizationResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof OrganizationOrganizationResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof OrganizationOrganizationResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof OrganizationOrganizationResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof OrganizationOrganizationResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {QueryOrganizationSummary}
     * @memberof OrganizationOrganizationResponse
     */
    'result'?: QueryOrganizationSummary;
}
/**
 * 
 * @export
 * @interface PayloadListResponse
 */
export interface PayloadListResponse {
    /**
     * 
     * @type {number}
     * @memberof PayloadListResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof PayloadListResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof PayloadListResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof PayloadListResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof PayloadListResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof PayloadListResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof PayloadListResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {Array<QueryPayloadSummary>}
     * @memberof PayloadListResponse
     */
    'result'?: Array<QueryPayloadSummary>;
}
/**
 * 
 * @export
 * @interface PayloadLocationListResponse
 */
export interface PayloadLocationListResponse {
    /**
     * 
     * @type {number}
     * @memberof PayloadLocationListResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof PayloadLocationListResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof PayloadLocationListResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof PayloadLocationListResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof PayloadLocationListResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof PayloadLocationListResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof PayloadLocationListResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {Array<QueryLocationSummary>}
     * @memberof PayloadLocationListResponse
     */
    'result'?: Array<QueryLocationSummary>;
}
/**
 * 
 * @export
 * @interface PayloadObjectResponse
 */
export interface PayloadObjectResponse {
    /**
     * 
     * @type {number}
     * @memberof PayloadObjectResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof PayloadObjectResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof PayloadObjectResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof PayloadObjectResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof PayloadObjectResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof PayloadObjectResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof PayloadObjectResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {QueryPayloadSummary}
     * @memberof PayloadObjectResponse
     */
    'result'?: QueryPayloadSummary;
}
/**
 * 
 * @export
 * @interface PayloadTagHealthItemListResponse
 */
export interface PayloadTagHealthItemListResponse {
    /**
     * 
     * @type {number}
     * @memberof PayloadTagHealthItemListResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof PayloadTagHealthItemListResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof PayloadTagHealthItemListResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof PayloadTagHealthItemListResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof PayloadTagHealthItemListResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof PayloadTagHealthItemListResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof PayloadTagHealthItemListResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {Array<QueryTagHealthItem>}
     * @memberof PayloadTagHealthItemListResponse
     */
    'result'?: Array<QueryTagHealthItem>;
}
/**
 * 
 * @export
 * @interface PayloadTagHealthTagListResponse
 */
export interface PayloadTagHealthTagListResponse {
    /**
     * 
     * @type {number}
     * @memberof PayloadTagHealthTagListResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof PayloadTagHealthTagListResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof PayloadTagHealthTagListResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof PayloadTagHealthTagListResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof PayloadTagHealthTagListResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof PayloadTagHealthTagListResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof PayloadTagHealthTagListResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {Array<QueryTagHealthTag>}
     * @memberof PayloadTagHealthTagListResponse
     */
    'result'?: Array<QueryTagHealthTag>;
}
/**
 * 
 * @export
 * @interface PipelineScan
 */
export interface PipelineScan {
    /**
     * 
     * @type {string}
     * @memberof PipelineScan
     */
    'configuration_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PipelineScan
     */
    'device_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PipelineScan
     */
    'id'?: string;
    /**
     * 
     * @type {Array<PipelineScanItem>}
     * @memberof PipelineScan
     */
    'items'?: Array<PipelineScanItem>;
    /**
     * 
     * @type {number}
     * @memberof PipelineScan
     */
    'latitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof PipelineScan
     */
    'longitude'?: number;
    /**
     * 
     * @type {string}
     * @memberof PipelineScan
     */
    'processedAt'?: string;
    /**
     * 
     * @type {ModelPropertyData}
     * @memberof PipelineScan
     */
    'properties'?: ModelPropertyData;
    /**
     * 
     * @type {string}
     * @memberof PipelineScan
     */
    'rawID'?: string;
    /**
     * 
     * @type {string}
     * @memberof PipelineScan
     */
    'time_local'?: string;
    /**
     * 
     * @type {string}
     * @memberof PipelineScan
     */
    'type'?: string;
}
/**
 * 
 * @export
 * @interface PipelineScanItem
 */
export interface PipelineScanItem {
    /**
     * ScanCount  int    `json:\"scan_count\"`
     * @type {Array<number>}
     * @memberof PipelineScanItem
     */
    'antennas'?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof PipelineScanItem
     */
    'rfid'?: string;
    /**
     * 
     * @type {number}
     * @memberof PipelineScanItem
     */
    'rssi'?: number;
    /**
     * 
     * @type {number}
     * @memberof PipelineScanItem
     */
    'total_reads'?: number;
}
/**
 * 
 * @export
 * @interface PkgHandlerCustomerListResponse
 */
export interface PkgHandlerCustomerListResponse {
    /**
     * 
     * @type {number}
     * @memberof PkgHandlerCustomerListResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof PkgHandlerCustomerListResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof PkgHandlerCustomerListResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof PkgHandlerCustomerListResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof PkgHandlerCustomerListResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof PkgHandlerCustomerListResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof PkgHandlerCustomerListResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {Array<QueryCustomerSummaryResponse>}
     * @memberof PkgHandlerCustomerListResponse
     */
    'result'?: Array<QueryCustomerSummaryResponse>;
}
/**
 * 
 * @export
 * @interface PkgHandlerEmbeddedBuildListResponse
 */
export interface PkgHandlerEmbeddedBuildListResponse {
    /**
     * 
     * @type {number}
     * @memberof PkgHandlerEmbeddedBuildListResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof PkgHandlerEmbeddedBuildListResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof PkgHandlerEmbeddedBuildListResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof PkgHandlerEmbeddedBuildListResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof PkgHandlerEmbeddedBuildListResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof PkgHandlerEmbeddedBuildListResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof PkgHandlerEmbeddedBuildListResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {Array<QueryEmbeddedBuild>}
     * @memberof PkgHandlerEmbeddedBuildListResponse
     */
    'result'?: Array<QueryEmbeddedBuild>;
}
/**
 * 
 * @export
 * @interface PkgHandlerItemDetailResponse
 */
export interface PkgHandlerItemDetailResponse {
    /**
     * 
     * @type {number}
     * @memberof PkgHandlerItemDetailResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof PkgHandlerItemDetailResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof PkgHandlerItemDetailResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof PkgHandlerItemDetailResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof PkgHandlerItemDetailResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof PkgHandlerItemDetailResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof PkgHandlerItemDetailResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {QueryItemDetails}
     * @memberof PkgHandlerItemDetailResponse
     */
    'result'?: QueryItemDetails;
}
/**
 * 
 * @export
 * @interface PkgHandlerItemListResponse
 */
export interface PkgHandlerItemListResponse {
    /**
     * 
     * @type {number}
     * @memberof PkgHandlerItemListResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof PkgHandlerItemListResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof PkgHandlerItemListResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof PkgHandlerItemListResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof PkgHandlerItemListResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof PkgHandlerItemListResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof PkgHandlerItemListResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {Array<QueryItemDetails>}
     * @memberof PkgHandlerItemListResponse
     */
    'result'?: Array<QueryItemDetails>;
}
/**
 * 
 * @export
 * @interface PkgHandlerItemObjectResponse
 */
export interface PkgHandlerItemObjectResponse {
    /**
     * 
     * @type {number}
     * @memberof PkgHandlerItemObjectResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof PkgHandlerItemObjectResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof PkgHandlerItemObjectResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof PkgHandlerItemObjectResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof PkgHandlerItemObjectResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof PkgHandlerItemObjectResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof PkgHandlerItemObjectResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {ModelItem}
     * @memberof PkgHandlerItemObjectResponse
     */
    'result'?: ModelItem;
}
/**
 * 
 * @export
 * @interface PkgHandlerReportListResponse
 */
export interface PkgHandlerReportListResponse {
    /**
     * 
     * @type {number}
     * @memberof PkgHandlerReportListResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof PkgHandlerReportListResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof PkgHandlerReportListResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof PkgHandlerReportListResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof PkgHandlerReportListResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof PkgHandlerReportListResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof PkgHandlerReportListResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {Array<ModelReport>}
     * @memberof PkgHandlerReportListResponse
     */
    'result'?: Array<ModelReport>;
}
/**
 * 
 * @export
 * @interface ProductDependenciesResponse
 */
export interface ProductDependenciesResponse {
    /**
     * 
     * @type {number}
     * @memberof ProductDependenciesResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductDependenciesResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductDependenciesResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductDependenciesResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof ProductDependenciesResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductDependenciesResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductDependenciesResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {QueryDependencies}
     * @memberof ProductDependenciesResponse
     */
    'result'?: QueryDependencies;
}
/**
 * 
 * @export
 * @interface ProductListCategoryResponse
 */
export interface ProductListCategoryResponse {
    /**
     * 
     * @type {number}
     * @memberof ProductListCategoryResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductListCategoryResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductListCategoryResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductListCategoryResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof ProductListCategoryResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductListCategoryResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductListCategoryResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {Array<QueryProductCategory>}
     * @memberof ProductListCategoryResponse
     */
    'result'?: Array<QueryProductCategory>;
}
/**
 * 
 * @export
 * @interface ProductListProductTypesResponse
 */
export interface ProductListProductTypesResponse {
    /**
     * 
     * @type {number}
     * @memberof ProductListProductTypesResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductListProductTypesResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductListProductTypesResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductListProductTypesResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof ProductListProductTypesResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductListProductTypesResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductListProductTypesResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {Array<ModelProductType>}
     * @memberof ProductListProductTypesResponse
     */
    'result'?: Array<ModelProductType>;
}
/**
 * 
 * @export
 * @interface ProductListResponse
 */
export interface ProductListResponse {
    /**
     * 
     * @type {number}
     * @memberof ProductListResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductListResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductListResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductListResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof ProductListResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductListResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductListResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {Array<QueryProduct>}
     * @memberof ProductListResponse
     */
    'result'?: Array<QueryProduct>;
}
/**
 * 
 * @export
 * @interface ProductObjectResponse
 */
export interface ProductObjectResponse {
    /**
     * 
     * @type {number}
     * @memberof ProductObjectResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductObjectResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductObjectResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductObjectResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof ProductObjectResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductObjectResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductObjectResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {ModelProduct}
     * @memberof ProductObjectResponse
     */
    'result'?: ModelProduct;
}
/**
 * 
 * @export
 * @interface ProductRequestNewProduct
 */
export interface ProductRequestNewProduct {
    /**
     * 
     * @type {string}
     * @memberof ProductRequestNewProduct
     */
    'category_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductRequestNewProduct
     */
    'manufacturer_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductRequestNewProduct
     */
    'manufacturer_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductRequestNewProduct
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductRequestNewProduct
     */
    'product_type_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductRequestNewProduct
     */
    'sku'?: string;
}
/**
 * 
 * @export
 * @interface ProductRequestProduct
 */
export interface ProductRequestProduct {
    /**
     * 
     * @type {boolean}
     * @memberof ProductRequestProduct
     */
    'active'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductRequestProduct
     */
    'category_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductRequestProduct
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductRequestProduct
     */
    'manufacturer_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductRequestProduct
     */
    'manufacturer_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductRequestProduct
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductRequestProduct
     */
    'product_type_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductRequestProduct
     */
    'sku'?: string;
}
/**
 * 
 * @export
 * @interface QueryActivityDecode
 */
export interface QueryActivityDecode {
    /**
     * 
     * @type {string}
     * @memberof QueryActivityDecode
     */
    'decode'?: string;
    /**
     * 
     * @type {number}
     * @memberof QueryActivityDecode
     */
    'type_id'?: number;
}
/**
 * 
 * @export
 * @interface QueryActivitySummary
 */
export interface QueryActivitySummary {
    /**
     * 
     * @type {number}
     * @memberof QueryActivitySummary
     */
    'latitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof QueryActivitySummary
     */
    'longitude'?: number;
    /**
     * 
     * @type {string}
     * @memberof QueryActivitySummary
     */
    'rec_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryActivitySummary
     */
    'site_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryActivitySummary
     */
    'site_name'?: string;
    /**
     * 
     * @type {number}
     * @memberof QueryActivitySummary
     */
    'tools_left_behind_cnt'?: number;
    /**
     * 
     * @type {number}
     * @memberof QueryActivitySummary
     */
    'vehicle_cnt'?: number;
    /**
     * 
     * @type {string}
     * @memberof QueryActivitySummary
     */
    'vehicle_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryActivitySummary
     */
    'vehicle_name'?: string;
}
/**
 * 
 * @export
 * @interface QueryAlertEvent
 */
export interface QueryAlertEvent {
    /**
     * 
     * @type {number}
     * @memberof QueryAlertEvent
     */
    'event_type_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof QueryAlertEvent
     */
    'event_type_name'?: string;
    /**
     * core
     * @type {string}
     * @memberof QueryAlertEvent
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryAlertEvent
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryAlertEvent
     */
    'object_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryAlertEvent
     */
    'organization_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryAlertEvent
     */
    'processed_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryAlertEvent
     */
    'target_id'?: string;
}
/**
 * 
 * @export
 * @interface QueryAlertEventMonitor
 */
export interface QueryAlertEventMonitor {
    /**
     * 
     * @type {boolean}
     * @memberof QueryAlertEventMonitor
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof QueryAlertEventMonitor
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryAlertEventMonitor
     */
    'organization_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryAlertEventMonitor
     */
    'target_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryAlertEventMonitor
     */
    'target_name'?: string;
    /**
     * 
     * @type {QueryUser}
     * @memberof QueryAlertEventMonitor
     */
    'user'?: QueryUser;
    /**
     * 
     * @type {string}
     * @memberof QueryAlertEventMonitor
     */
    'user_id'?: string;
}
/**
 * 
 * @export
 * @interface QueryAlertEventType
 */
export interface QueryAlertEventType {
    /**
     * 
     * @type {number}
     * @memberof QueryAlertEventType
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof QueryAlertEventType
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface QueryAlternateSite
 */
export interface QueryAlternateSite {
    /**
     * 
     * @type {string}
     * @memberof QueryAlternateSite
     */
    'address1'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryAlternateSite
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryAlternateSite
     */
    'customer_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryAlternateSite
     */
    'customer_name'?: string;
    /**
     * 
     * @type {number}
     * @memberof QueryAlternateSite
     */
    'distance'?: number;
    /**
     * 
     * @type {string}
     * @memberof QueryAlternateSite
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof QueryAlternateSite
     */
    'latitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof QueryAlternateSite
     */
    'longitude'?: number;
    /**
     * 
     * @type {string}
     * @memberof QueryAlternateSite
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryAlternateSite
     */
    'postal'?: string;
    /**
     * 
     * @type {ModelSiteType}
     * @memberof QueryAlternateSite
     */
    'site_type'?: ModelSiteType;
    /**
     * 
     * @type {number}
     * @memberof QueryAlternateSite
     */
    'site_type_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof QueryAlternateSite
     */
    'state'?: string;
}
/**
 * 
 * @export
 * @interface QueryAssignment
 */
export interface QueryAssignment {
    /**
     * 
     * @type {Array<QueryAssignmentAggregateItems>}
     * @memberof QueryAssignment
     */
    'aggregate_items'?: Array<QueryAssignmentAggregateItems>;
    /**
     * 
     * @type {string}
     * @memberof QueryAssignment
     */
    'created_at'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof QueryAssignment
     */
    'delivered'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof QueryAssignment
     */
    'delivered_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryAssignment
     */
    'id'?: string;
    /**
     * 
     * @type {Array<QueryAssignmentItem>}
     * @memberof QueryAssignment
     */
    'items'?: Array<QueryAssignmentItem>;
    /**
     * 
     * @type {string}
     * @memberof QueryAssignment
     */
    'job_site_address'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryAssignment
     */
    'job_site_city'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryAssignment
     */
    'job_site_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryAssignment
     */
    'job_site_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryAssignment
     */
    'job_site_postal'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryAssignment
     */
    'job_site_state'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryAssignment
     */
    'location_visit_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryAssignment
     */
    'notes'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryAssignment
     */
    'status'?: string;
}
/**
 * 
 * @export
 * @interface QueryAssignmentAggregateItems
 */
export interface QueryAssignmentAggregateItems {
    /**
     * 
     * @type {string}
     * @memberof QueryAssignmentAggregateItems
     */
    'assignment_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof QueryAssignmentAggregateItems
     */
    'count'?: number;
    /**
     * 
     * @type {string}
     * @memberof QueryAssignmentAggregateItems
     */
    'image_key'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryAssignmentAggregateItems
     */
    'image_src'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryAssignmentAggregateItems
     */
    'manufacturer_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryAssignmentAggregateItems
     */
    'manufacturer_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryAssignmentAggregateItems
     */
    'product_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryAssignmentAggregateItems
     */
    'product_name'?: string;
    /**
     * 
     * @type {number}
     * @memberof QueryAssignmentAggregateItems
     */
    'product_type_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof QueryAssignmentAggregateItems
     */
    'product_type_name'?: string;
}
/**
 * 
 * @export
 * @interface QueryAssignmentItem
 */
export interface QueryAssignmentItem {
    /**
     * 
     * @type {string}
     * @memberof QueryAssignmentItem
     */
    'assignment_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryAssignmentItem
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryAssignmentItem
     */
    'identifier'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryAssignmentItem
     */
    'image_key'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryAssignmentItem
     */
    'image_src'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryAssignmentItem
     */
    'item_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryAssignmentItem
     */
    'manufacturer_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryAssignmentItem
     */
    'manufacturer_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryAssignmentItem
     */
    'product_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryAssignmentItem
     */
    'product_name'?: string;
    /**
     * 
     * @type {number}
     * @memberof QueryAssignmentItem
     */
    'product_type_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof QueryAssignmentItem
     */
    'product_type_name'?: string;
}
/**
 * 
 * @export
 * @interface QueryAssignmentKPIs
 */
export interface QueryAssignmentKPIs {
    /**
     * 
     * @type {number}
     * @memberof QueryAssignmentKPIs
     */
    'delivered_cnt'?: number;
    /**
     * 
     * @type {number}
     * @memberof QueryAssignmentKPIs
     */
    'prepared_cnt'?: number;
}
/**
 * 
 * @export
 * @interface QueryAssignmentSummary
 */
export interface QueryAssignmentSummary {
    /**
     * 
     * @type {string}
     * @memberof QueryAssignmentSummary
     */
    'created_at'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof QueryAssignmentSummary
     */
    'delivered'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof QueryAssignmentSummary
     */
    'delivered_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryAssignmentSummary
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof QueryAssignmentSummary
     */
    'item_cnt'?: number;
    /**
     * 
     * @type {string}
     * @memberof QueryAssignmentSummary
     */
    'item_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryAssignmentSummary
     */
    'job_site_address'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryAssignmentSummary
     */
    'job_site_city'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryAssignmentSummary
     */
    'job_site_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryAssignmentSummary
     */
    'job_site_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryAssignmentSummary
     */
    'job_site_postal'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryAssignmentSummary
     */
    'job_site_state'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryAssignmentSummary
     */
    'location_visit_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryAssignmentSummary
     */
    'notes'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryAssignmentSummary
     */
    'status'?: string;
    /**
     * 
     * @type {number}
     * @memberof QueryAssignmentSummary
     */
    'total_rows'?: number;
}
/**
 * 
 * @export
 * @interface QueryBatchReportRun
 */
export interface QueryBatchReportRun {
    /**
     * 
     * @type {string}
     * @memberof QueryBatchReportRun
     */
    'batch_report_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryBatchReportRun
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryBatchReportRun
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryBatchReportRun
     */
    'mail_sent_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryBatchReportRun
     */
    'organization_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryBatchReportRun
     */
    'report_key'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryBatchReportRun
     */
    'uploaded_at'?: string;
}
/**
 * 
 * @export
 * @interface QueryBatchReportRunDetail
 */
export interface QueryBatchReportRunDetail {
    /**
     * 
     * @type {string}
     * @memberof QueryBatchReportRunDetail
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryBatchReportRunDetail
     */
    'error'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryBatchReportRunDetail
     */
    'log'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryBatchReportRunDetail
     */
    'mail_sent_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryBatchReportRunDetail
     */
    'organization_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryBatchReportRunDetail
     */
    'organization_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryBatchReportRunDetail
     */
    'report_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryBatchReportRunDetail
     */
    'report_key'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryBatchReportRunDetail
     */
    'report_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryBatchReportRunDetail
     */
    'report_run_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryBatchReportRunDetail
     */
    'short_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryBatchReportRunDetail
     */
    'uploaded_at'?: string;
}
/**
 * 
 * @export
 * @interface QueryBuildAssignment
 */
export interface QueryBuildAssignment {
    /**
     * 
     * @type {number}
     * @memberof QueryBuildAssignment
     */
    'assigned_cnt'?: number;
    /**
     * 
     * @type {string}
     * @memberof QueryBuildAssignment
     */
    'build_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryBuildAssignment
     */
    'build_tag'?: string;
    /**
     * 
     * @type {number}
     * @memberof QueryBuildAssignment
     */
    'running_cnt'?: number;
    /**
     * 
     * @type {string}
     * @memberof QueryBuildAssignment
     */
    'software'?: string;
}
/**
 * 
 * @export
 * @interface QueryCustomer
 */
export interface QueryCustomer {
    /**
     * 
     * @type {boolean}
     * @memberof QueryCustomer
     */
    'active'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof QueryCustomer
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryCustomer
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryCustomer
     */
    'image_key'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryCustomer
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryCustomer
     */
    'organization_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryCustomer
     */
    'updated_at'?: string;
}
/**
 * 
 * @export
 * @interface QueryCustomerSummaryResponse
 */
export interface QueryCustomerSummaryResponse {
    /**
     * 
     * @type {boolean}
     * @memberof QueryCustomerSummaryResponse
     */
    'active'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof QueryCustomerSummaryResponse
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryCustomerSummaryResponse
     */
    'first_visit'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryCustomerSummaryResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryCustomerSummaryResponse
     */
    'last_visit'?: string;
    /**
     * 
     * @type {number}
     * @memberof QueryCustomerSummaryResponse
     */
    'location_visit_count'?: number;
    /**
     * 
     * @type {string}
     * @memberof QueryCustomerSummaryResponse
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof QueryCustomerSummaryResponse
     */
    'site_count'?: number;
    /**
     * 
     * @type {string}
     * @memberof QueryCustomerSummaryResponse
     */
    'updated_at'?: string;
}
/**
 * 
 * @export
 * @interface QueryDelayedPayload
 */
export interface QueryDelayedPayload {
    /**
     * 
     * @type {string}
     * @memberof QueryDelayedPayload
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryDelayedPayload
     */
    'device_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryDelayedPayload
     */
    'device_name'?: string;
    /**
     * 
     * @type {number}
     * @memberof QueryDelayedPayload
     */
    'minute_delay'?: number;
    /**
     * 
     * @type {string}
     * @memberof QueryDelayedPayload
     */
    'organization_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryDelayedPayload
     */
    'organization_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryDelayedPayload
     */
    'payload_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryDelayedPayload
     */
    'prev_processed_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryDelayedPayload
     */
    'processed_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryDelayedPayload
     */
    'warehouse_id'?: string;
}
/**
 * 
 * @export
 * @interface QueryDependencies
 */
export interface QueryDependencies {
    /**
     * 
     * @type {number}
     * @memberof QueryDependencies
     */
    'connected_record_count'?: number;
    /**
     * 
     * @type {string}
     * @memberof QueryDependencies
     */
    'friendly_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryDependencies
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryDependencies
     */
    'object_type'?: string;
}
/**
 * 
 * @export
 * @interface QueryDevice
 */
export interface QueryDevice {
    /**
     * 
     * @type {boolean}
     * @memberof QueryDevice
     */
    'active'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof QueryDevice
     */
    'active_configuration_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryDevice
     */
    'components'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryDevice
     */
    'configuration_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryDevice
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryDevice
     */
    'device_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof QueryDevice
     */
    'device_type_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof QueryDevice
     */
    'errors_1_days'?: number;
    /**
     * 
     * @type {number}
     * @memberof QueryDevice
     */
    'errors_7_days'?: number;
    /**
     * 
     * @type {string}
     * @memberof QueryDevice
     */
    'hardware_version'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryDevice
     */
    'identifier'?: string;
    /**
     * 
     * @type {number}
     * @memberof QueryDevice
     */
    'iteration'?: number;
    /**
     * 
     * @type {string}
     * @memberof QueryDevice
     */
    'mac_address'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryDevice
     */
    'main_version'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryDevice
     */
    'organization_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryDevice
     */
    'organization_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryDevice
     */
    'owner_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryDevice
     */
    'processed_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryDevice
     */
    'reporting_status'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryDevice
     */
    'scanner_build_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryDevice
     */
    'scanner_build_tag'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryDevice
     */
    'scanner_running_build_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryDevice
     */
    'scanner_running_build_tag'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryDevice
     */
    'tested_by'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryDevice
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryDevice
     */
    'updater_build_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryDevice
     */
    'updater_build_tag'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryDevice
     */
    'updater_running_build_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryDevice
     */
    'updater_running_build_tag'?: string;
    /**
     * 
     * @type {number}
     * @memberof QueryDevice
     */
    'voltage'?: number;
    /**
     * 
     * @type {string}
     * @memberof QueryDevice
     */
    'warehouse_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryDevice
     */
    'warehouse_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryDevice
     */
    'warehouse_type'?: string;
    /**
     * 
     * @type {number}
     * @memberof QueryDevice
     */
    'warehouse_type_id'?: number;
}
/**
 * 
 * @export
 * @interface QueryDeviceBuildInfo
 */
export interface QueryDeviceBuildInfo {
    /**
     * 
     * @type {string}
     * @memberof QueryDeviceBuildInfo
     */
    'device_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryDeviceBuildInfo
     */
    'device_type'?: string;
    /**
     * 
     * @type {number}
     * @memberof QueryDeviceBuildInfo
     */
    'device_type_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof QueryDeviceBuildInfo
     */
    'identifier'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryDeviceBuildInfo
     */
    'organization_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryDeviceBuildInfo
     */
    'organization_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryDeviceBuildInfo
     */
    'scanner_build_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryDeviceBuildInfo
     */
    'scanner_build_tag'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryDeviceBuildInfo
     */
    'scanner_running_build_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryDeviceBuildInfo
     */
    'scanner_running_build_tag'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryDeviceBuildInfo
     */
    'updater_build_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryDeviceBuildInfo
     */
    'updater_build_tag'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryDeviceBuildInfo
     */
    'updater_running_build_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryDeviceBuildInfo
     */
    'updater_running_build_tag'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryDeviceBuildInfo
     */
    'warehouse_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryDeviceBuildInfo
     */
    'warehouse_type'?: string;
}
/**
 * 
 * @export
 * @interface QueryDeviceBuilds
 */
export interface QueryDeviceBuilds {
    /**
     * 
     * @type {string}
     * @memberof QueryDeviceBuilds
     */
    'git_token'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryDeviceBuilds
     */
    'identifier'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryDeviceBuilds
     */
    'scanner_build_tag'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryDeviceBuilds
     */
    'scanner_running_build_tag'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryDeviceBuilds
     */
    'updater_build_tag'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryDeviceBuilds
     */
    'updater_running_build_tag'?: string;
}
/**
 * 
 * @export
 * @interface QueryDeviceEvent
 */
export interface QueryDeviceEvent {
    /**
     * 
     * @type {string}
     * @memberof QueryDeviceEvent
     */
    'batch_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryDeviceEvent
     */
    'device_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryDeviceEvent
     */
    'error'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryDeviceEvent
     */
    'event'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryDeviceEvent
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryDeviceEvent
     */
    'organization_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryDeviceEvent
     */
    'organization_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryDeviceEvent
     */
    'owner_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryDeviceEvent
     */
    'owner_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryDeviceEvent
     */
    'parameters'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryDeviceEvent
     */
    'payload_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryDeviceEvent
     */
    'processed_at'?: string;
    /**
     * 
     * @type {number}
     * @memberof QueryDeviceEvent
     */
    'return_code'?: number;
    /**
     * 
     * @type {string}
     * @memberof QueryDeviceEvent
     */
    'source'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryDeviceEvent
     */
    'warehouse_type'?: string;
}
/**
 * 
 * @export
 * @interface QueryDeviceEventAggregate
 */
export interface QueryDeviceEventAggregate {
    /**
     * 
     * @type {number}
     * @memberof QueryDeviceEventAggregate
     */
    'count'?: number;
    /**
     * 
     * @type {string}
     * @memberof QueryDeviceEventAggregate
     */
    'event'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryDeviceEventAggregate
     */
    'first_event'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryDeviceEventAggregate
     */
    'last_event'?: string;
    /**
     * 
     * @type {number}
     * @memberof QueryDeviceEventAggregate
     */
    'return_code'?: number;
    /**
     * 
     * @type {string}
     * @memberof QueryDeviceEventAggregate
     */
    'source'?: string;
}
/**
 * 
 * @export
 * @interface QueryDeviceMetrics
 */
export interface QueryDeviceMetrics {
    /**
     * 
     * @type {number}
     * @memberof QueryDeviceMetrics
     */
    'event_cnt'?: number;
    /**
     * 
     * @type {number}
     * @memberof QueryDeviceMetrics
     */
    'event_cnt_1day'?: number;
    /**
     * 
     * @type {number}
     * @memberof QueryDeviceMetrics
     */
    'event_cnt_7day'?: number;
    /**
     * 
     * @type {number}
     * @memberof QueryDeviceMetrics
     */
    'event_error_cnt_1day'?: number;
    /**
     * 
     * @type {number}
     * @memberof QueryDeviceMetrics
     */
    'event_error_cnt_7day'?: number;
    /**
     * 
     * @type {number}
     * @memberof QueryDeviceMetrics
     */
    'payload_cnt'?: number;
    /**
     * 
     * @type {number}
     * @memberof QueryDeviceMetrics
     */
    'payload_cnt_1day'?: number;
    /**
     * 
     * @type {number}
     * @memberof QueryDeviceMetrics
     */
    'payload_cnt_7day'?: number;
    /**
     * 
     * @type {number}
     * @memberof QueryDeviceMetrics
     */
    'payload_error_cnt_7day'?: number;
}
/**
 * 
 * @export
 * @interface QueryDeviceStatus
 */
export interface QueryDeviceStatus {
    /**
     * 
     * @type {boolean}
     * @memberof QueryDeviceStatus
     */
    'active'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof QueryDeviceStatus
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryDeviceStatus
     */
    'device_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryDeviceStatus
     */
    'device_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryDeviceStatus
     */
    'device_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryDeviceStatus
     */
    'identifier'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryDeviceStatus
     */
    'organization_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryDeviceStatus
     */
    'organization_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryDeviceStatus
     */
    'owner_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryDeviceStatus
     */
    'payload_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryDeviceStatus
     */
    'processed_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryDeviceStatus
     */
    'reporting_status'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryDeviceStatus
     */
    'warehouse_id'?: string;
}
/**
 * 
 * @export
 * @interface QueryDeviceVersion
 */
export interface QueryDeviceVersion {
    /**
     * 
     * @type {string}
     * @memberof QueryDeviceVersion
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryDeviceVersion
     */
    'label'?: string;
}
/**
 * 
 * @export
 * @interface QueryEmbeddedBuild
 */
export interface QueryEmbeddedBuild {
    /**
     * 
     * @type {boolean}
     * @memberof QueryEmbeddedBuild
     */
    'active'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof QueryEmbeddedBuild
     */
    'assigned_device_cnt'?: number;
    /**
     * 
     * @type {string}
     * @memberof QueryEmbeddedBuild
     */
    'build_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryEmbeddedBuild
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryEmbeddedBuild
     */
    'description'?: string;
    /**
     * 
     * @type {Array<QueryDevice>}
     * @memberof QueryEmbeddedBuild
     */
    'devices'?: Array<QueryDevice>;
    /**
     * 
     * @type {string}
     * @memberof QueryEmbeddedBuild
     */
    'hash'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryEmbeddedBuild
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof QueryEmbeddedBuild
     */
    'major'?: number;
    /**
     * 
     * @type {number}
     * @memberof QueryEmbeddedBuild
     */
    'minor'?: number;
    /**
     * 
     * @type {number}
     * @memberof QueryEmbeddedBuild
     */
    'release'?: number;
    /**
     * 
     * @type {string}
     * @memberof QueryEmbeddedBuild
     */
    'repo_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryEmbeddedBuild
     */
    'repo_name'?: string;
    /**
     * 
     * @type {number}
     * @memberof QueryEmbeddedBuild
     */
    'running_device_cnt'?: number;
    /**
     * 
     * @type {string}
     * @memberof QueryEmbeddedBuild
     */
    'tag'?: string;
}
/**
 * 
 * @export
 * @interface QueryFeature
 */
export interface QueryFeature {
    /**
     * 
     * @type {string}
     * @memberof QueryFeature
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryFeature
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryFeature
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface QueryGroupSummary
 */
export interface QueryGroupSummary {
    /**
     * 
     * @type {string}
     * @memberof QueryGroupSummary
     */
    'item_cnt'?: string;
    /**
     * 
     * @type {number}
     * @memberof QueryGroupSummary
     */
    'latitude'?: number;
    /**
     * 
     * @type {string}
     * @memberof QueryGroupSummary
     */
    'location'?: string;
    /**
     * 
     * @type {number}
     * @memberof QueryGroupSummary
     */
    'longitude'?: number;
    /**
     * 
     * @type {string}
     * @memberof QueryGroupSummary
     */
    'processed_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryGroupSummary
     */
    'rec_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryGroupSummary
     */
    'target_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryGroupSummary
     */
    'target_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryGroupSummary
     */
    'warehouse_id'?: string;
}
/**
 * 
 * @export
 * @interface QueryItemActivity
 */
export interface QueryItemActivity {
    /**
     * 
     * @type {string}
     * @memberof QueryItemActivity
     */
    'activity_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryItemActivity
     */
    'activity_type'?: string;
    /**
     * 
     * @type {number}
     * @memberof QueryItemActivity
     */
    'activity_type_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof QueryItemActivity
     */
    'first_visit'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryItemActivity
     */
    'identifier'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryItemActivity
     */
    'image_key'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryItemActivity
     */
    'image_src'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryItemActivity
     */
    'item_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryItemActivity
     */
    'last_visit'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryItemActivity
     */
    'location_visit_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryItemActivity
     */
    'product_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryItemActivity
     */
    'site_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryItemActivity
     */
    'site_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryItemActivity
     */
    'vehicle_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryItemActivity
     */
    'vehicle_name'?: string;
}
/**
 * 
 * @export
 * @interface QueryItemAggregate
 */
export interface QueryItemAggregate {
    /**
     * 
     * @type {string}
     * @memberof QueryItemAggregate
     */
    'first_visit'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryItemAggregate
     */
    'last_visit'?: string;
    /**
     * 
     * @type {number}
     * @memberof QueryItemAggregate
     */
    'latitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof QueryItemAggregate
     */
    'longitude'?: number;
    /**
     * 
     * @type {string}
     * @memberof QueryItemAggregate
     */
    'site_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryItemAggregate
     */
    'site_name'?: string;
    /**
     * 
     * @type {number}
     * @memberof QueryItemAggregate
     */
    'tools_left_behind_cnt'?: number;
    /**
     * 
     * @type {string}
     * @memberof QueryItemAggregate
     */
    'vehicles'?: string;
}
/**
 * 
 * @export
 * @interface QueryItemCategory
 */
export interface QueryItemCategory {
    /**
     * 
     * @type {string}
     * @memberof QueryItemCategory
     */
    'filter_display'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryItemCategory
     */
    'filter_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryItemCategory
     */
    'filter_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryItemCategory
     */
    'group_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryItemCategory
     */
    'image_key'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryItemCategory
     */
    'item_count'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryItemCategory
     */
    'link_to_page'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryItemCategory
     */
    'sort'?: string;
}
/**
 * 
 * @export
 * @interface QueryItemDetails
 */
export interface QueryItemDetails {
    /**
     * 
     * @type {boolean}
     * @memberof QueryItemDetails
     */
    'active'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof QueryItemDetails
     */
    'assignment_site_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryItemDetails
     */
    'assignment_site_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryItemDetails
     */
    'born_on'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryItemDetails
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryItemDetails
     */
    'identifier'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryItemDetails
     */
    'image_key'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryItemDetails
     */
    'image_src'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryItemDetails
     */
    'item_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof QueryItemDetails
     */
    'latitude'?: number;
    /**
     * 
     * @type {string}
     * @memberof QueryItemDetails
     */
    'location_visit_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof QueryItemDetails
     */
    'longitude'?: number;
    /**
     * 
     * @type {string}
     * @memberof QueryItemDetails
     */
    'manufacturer_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryItemDetails
     */
    'manufacturer_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryItemDetails
     */
    'notes'?: string;
    /**
     * core
     * @type {string}
     * @memberof QueryItemDetails
     */
    'organization_id'?: string;
    /**
     * owner
     * @type {string}
     * @memberof QueryItemDetails
     */
    'owner_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryItemDetails
     */
    'owner_name'?: string;
    /**
     * 
     * @type {number}
     * @memberof QueryItemDetails
     */
    'owner_type_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof QueryItemDetails
     */
    'owner_type_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryItemDetails
     */
    'owner_warehouse_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryItemDetails
     */
    'payload_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof QueryItemDetails
     */
    'payload_type_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof QueryItemDetails
     */
    'payload_type_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryItemDetails
     */
    'payload_warehouse_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryItemDetails
     */
    'payload_warehouse_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryItemDetails
     */
    'processed_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryItemDetails
     */
    'product_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryItemDetails
     */
    'product_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryItemDetails
     */
    'product_sku'?: string;
    /**
     * 
     * @type {number}
     * @memberof QueryItemDetails
     */
    'product_type_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof QueryItemDetails
     */
    'product_type_name'?: string;
    /**
     * 
     * @type {number}
     * @memberof QueryItemDetails
     */
    'scan_status'?: number;
    /**
     * 
     * @type {string}
     * @memberof QueryItemDetails
     */
    'scan_status_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryItemDetails
     */
    'status_image'?: string;
    /**
     * 
     * @type {Array<ModelTag>}
     * @memberof QueryItemDetails
     */
    'tags'?: Array<ModelTag>;
    /**
     * 
     * @type {number}
     * @memberof QueryItemDetails
     */
    'total_reads'?: number;
    /**
     * 
     * @type {string}
     * @memberof QueryItemDetails
     */
    'tracking_site_address1'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryItemDetails
     */
    'tracking_site_city'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryItemDetails
     */
    'tracking_site_from'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryItemDetails
     */
    'tracking_site_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryItemDetails
     */
    'tracking_site_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryItemDetails
     */
    'tracking_site_postal'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryItemDetails
     */
    'tracking_site_state'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryItemDetails
     */
    'tracking_site_to'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryItemDetails
     */
    'tracking_vehicle_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryItemDetails
     */
    'tracking_vehicle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryItemDetails
     */
    'tracking_warehouse_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryItemDetails
     */
    'updated_at'?: string;
}
/**
 * 
 * @export
 * @interface QueryJobSiteAssignedStock
 */
export interface QueryJobSiteAssignedStock {
    /**
     * 
     * @type {number}
     * @memberof QueryJobSiteAssignedStock
     */
    'assigned_cnt'?: number;
    /**
     * 
     * @type {number}
     * @memberof QueryJobSiteAssignedStock
     */
    'error_cnt'?: number;
    /**
     * 
     * @type {number}
     * @memberof QueryJobSiteAssignedStock
     */
    'installed_cnt'?: number;
    /**
     * 
     * @type {string}
     * @memberof QueryJobSiteAssignedStock
     */
    'job_site_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryJobSiteAssignedStock
     */
    'job_site_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryJobSiteAssignedStock
     */
    'last_visit'?: string;
    /**
     * 
     * @type {number}
     * @memberof QueryJobSiteAssignedStock
     */
    'onsite_cnt'?: number;
}
/**
 * 
 * @export
 * @interface QueryJobSiteStockItem
 */
export interface QueryJobSiteStockItem {
    /**
     * 
     * @type {boolean}
     * @memberof QueryJobSiteStockItem
     */
    'active'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof QueryJobSiteStockItem
     */
    'delivery_error'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof QueryJobSiteStockItem
     */
    'delivery_status'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryJobSiteStockItem
     */
    'identifier'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryJobSiteStockItem
     */
    'image_key'?: string;
    /**
     * core
     * @type {string}
     * @memberof QueryJobSiteStockItem
     */
    'item_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryJobSiteStockItem
     */
    'job_site_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryJobSiteStockItem
     */
    'job_site_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryJobSiteStockItem
     */
    'manufacturer_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryJobSiteStockItem
     */
    'manufacturer_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryJobSiteStockItem
     */
    'processed_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryJobSiteStockItem
     */
    'product_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryJobSiteStockItem
     */
    'product_name'?: string;
}
/**
 * 
 * @export
 * @interface QueryKPI
 */
export interface QueryKPI {
    /**
     * 
     * @type {number}
     * @memberof QueryKPI
     */
    'int_64_value'?: number;
    /**
     * 
     * @type {string}
     * @memberof QueryKPI
     */
    'metric'?: string;
}
/**
 * 
 * @export
 * @interface QueryLocationSummary
 */
export interface QueryLocationSummary {
    /**
     * 
     * @type {number}
     * @memberof QueryLocationSummary
     */
    'heading'?: number;
    /**
     * 
     * @type {string}
     * @memberof QueryLocationSummary
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof QueryLocationSummary
     */
    'latitude'?: number;
    /**
     * 
     * @type {string}
     * @memberof QueryLocationSummary
     */
    'location_visit_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof QueryLocationSummary
     */
    'longitude'?: number;
    /**
     * 
     * @type {string}
     * @memberof QueryLocationSummary
     */
    'max_processed_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryLocationSummary
     */
    'min_processed_at'?: string;
    /**
     * 
     * @type {number}
     * @memberof QueryLocationSummary
     */
    'payload_count'?: number;
    /**
     * 
     * @type {string}
     * @memberof QueryLocationSummary
     */
    'payload_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof QueryLocationSummary
     */
    'payload_type_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof QueryLocationSummary
     */
    'payload_type_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryLocationSummary
     */
    'site_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryLocationSummary
     */
    'site_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryLocationSummary
     */
    'vehicle_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryLocationSummary
     */
    'vehicle_name'?: string;
    /**
     * 
     * @type {number}
     * @memberof QueryLocationSummary
     */
    'velocity'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof QueryLocationSummary
     */
    'waypoint'?: boolean;
}
/**
 * 
 * @export
 * @interface QueryLocationVisit
 */
export interface QueryLocationVisit {
    /**
     * 
     * @type {string}
     * @memberof QueryLocationVisit
     */
    'customer_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryLocationVisit
     */
    'customer_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryLocationVisit
     */
    'first_visit'?: string;
    /**
     * 
     * @type {Array<QueryLocationVisitItem>}
     * @memberof QueryLocationVisit
     */
    'items'?: Array<QueryLocationVisitItem>;
    /**
     * 
     * @type {string}
     * @memberof QueryLocationVisit
     */
    'last_visit'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryLocationVisit
     */
    'location_visit_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryLocationVisit
     */
    'site_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryLocationVisit
     */
    'site_image_key'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryLocationVisit
     */
    'site_name'?: string;
    /**
     * 
     * @type {number}
     * @memberof QueryLocationVisit
     */
    'site_type_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof QueryLocationVisit
     */
    'status'?: number;
    /**
     * 
     * @type {Array<QueryLocationVisitItem>}
     * @memberof QueryLocationVisit
     */
    'stock'?: Array<QueryLocationVisitItem>;
    /**
     * 
     * @type {number}
     * @memberof QueryLocationVisit
     */
    'stock_installed_cnt'?: number;
    /**
     * 
     * @type {number}
     * @memberof QueryLocationVisit
     */
    'tools_left_behind_cnt'?: number;
    /**
     * 
     * @type {number}
     * @memberof QueryLocationVisit
     */
    'tools_recovered_cnt'?: number;
    /**
     * 
     * @type {number}
     * @memberof QueryLocationVisit
     */
    'tools_used_cnt'?: number;
    /**
     * 
     * @type {string}
     * @memberof QueryLocationVisit
     */
    'vehicle_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryLocationVisit
     */
    'vehicle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryLocationVisit
     */
    'warehouse_id'?: string;
}
/**
 * 
 * @export
 * @interface QueryLocationVisitItem
 */
export interface QueryLocationVisitItem {
    /**
     * 
     * @type {string}
     * @memberof QueryLocationVisitItem
     */
    'customer_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryLocationVisitItem
     */
    'customer_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryLocationVisitItem
     */
    'first_visit'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryLocationVisitItem
     */
    'identifier'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryLocationVisitItem
     */
    'image_key'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryLocationVisitItem
     */
    'item_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryLocationVisitItem
     */
    'item_processed_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryLocationVisitItem
     */
    'last_visit'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryLocationVisitItem
     */
    'location_visit_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryLocationVisitItem
     */
    'manufacturer_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryLocationVisitItem
     */
    'manufacturer_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryLocationVisitItem
     */
    'product_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryLocationVisitItem
     */
    'product_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryLocationVisitItem
     */
    'site_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryLocationVisitItem
     */
    'site_image_key'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryLocationVisitItem
     */
    'site_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryLocationVisitItem
     */
    'usage_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryLocationVisitItem
     */
    'vehicle_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryLocationVisitItem
     */
    'vehicle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryLocationVisitItem
     */
    'warehouse_id'?: string;
}
/**
 * 
 * @export
 * @interface QueryLocationVisitSummary
 */
export interface QueryLocationVisitSummary {
    /**
     * 
     * @type {string}
     * @memberof QueryLocationVisitSummary
     */
    'customer_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryLocationVisitSummary
     */
    'customer_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryLocationVisitSummary
     */
    'first_visit'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryLocationVisitSummary
     */
    'last_visit'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryLocationVisitSummary
     */
    'location_visit_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryLocationVisitSummary
     */
    'site_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryLocationVisitSummary
     */
    'site_image_key'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryLocationVisitSummary
     */
    'site_name'?: string;
    /**
     * 
     * @type {number}
     * @memberof QueryLocationVisitSummary
     */
    'site_type_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof QueryLocationVisitSummary
     */
    'status'?: number;
    /**
     * 
     * @type {number}
     * @memberof QueryLocationVisitSummary
     */
    'stock_installed_cnt'?: number;
    /**
     * 
     * @type {number}
     * @memberof QueryLocationVisitSummary
     */
    'tools_left_behind_cnt'?: number;
    /**
     * 
     * @type {number}
     * @memberof QueryLocationVisitSummary
     */
    'tools_recovered_cnt'?: number;
    /**
     * 
     * @type {number}
     * @memberof QueryLocationVisitSummary
     */
    'tools_used_cnt'?: number;
    /**
     * 
     * @type {string}
     * @memberof QueryLocationVisitSummary
     */
    'vehicle_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryLocationVisitSummary
     */
    'vehicle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryLocationVisitSummary
     */
    'warehouse_id'?: string;
}
/**
 * 
 * @export
 * @interface QueryMaintenanceEvent
 */
export interface QueryMaintenanceEvent {
    /**
     * 
     * @type {boolean}
     * @memberof QueryMaintenanceEvent
     */
    'completed'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof QueryMaintenanceEvent
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryMaintenanceEvent
     */
    'details'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryMaintenanceEvent
     */
    'due_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryMaintenanceEvent
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryMaintenanceEvent
     */
    'identifier'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryMaintenanceEvent
     */
    'item_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryMaintenanceEvent
     */
    'organization_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryMaintenanceEvent
     */
    'product_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryMaintenanceEvent
     */
    'product_name'?: string;
    /**
     * 
     * @type {number}
     * @memberof QueryMaintenanceEvent
     */
    'recurrence_type_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof QueryMaintenanceEvent
     */
    'summary'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryMaintenanceEvent
     */
    'updated_at'?: string;
}
/**
 * 
 * @export
 * @interface QueryManufacturer
 */
export interface QueryManufacturer {
    /**
     * 
     * @type {boolean}
     * @memberof QueryManufacturer
     */
    'active'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof QueryManufacturer
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryManufacturer
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryManufacturer
     */
    'image_key'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryManufacturer
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof QueryManufacturer
     */
    'stock_products'?: number;
    /**
     * 
     * @type {number}
     * @memberof QueryManufacturer
     */
    'tool_products'?: number;
    /**
     * 
     * @type {string}
     * @memberof QueryManufacturer
     */
    'updated_at'?: string;
}
/**
 * 
 * @export
 * @interface QueryOperationsMetric
 */
export interface QueryOperationsMetric {
    /**
     * 
     * @type {number}
     * @memberof QueryOperationsMetric
     */
    'metric_count'?: number;
    /**
     * 
     * @type {string}
     * @memberof QueryOperationsMetric
     */
    'metric_name'?: string;
}
/**
 * 
 * @export
 * @interface QueryOrganizationManufacturer
 */
export interface QueryOrganizationManufacturer {
    /**
     * 
     * @type {boolean}
     * @memberof QueryOrganizationManufacturer
     */
    'active'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof QueryOrganizationManufacturer
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryOrganizationManufacturer
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryOrganizationManufacturer
     */
    'image_key'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryOrganizationManufacturer
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryOrganizationManufacturer
     */
    'source'?: string;
    /**
     * 
     * @type {number}
     * @memberof QueryOrganizationManufacturer
     */
    'stock_products'?: number;
    /**
     * 
     * @type {number}
     * @memberof QueryOrganizationManufacturer
     */
    'tool_products'?: number;
    /**
     * 
     * @type {string}
     * @memberof QueryOrganizationManufacturer
     */
    'updated_at'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof QueryOrganizationManufacturer
     */
    'visible'?: boolean;
}
/**
 * 
 * @export
 * @interface QueryOrganizationSummary
 */
export interface QueryOrganizationSummary {
    /**
     * 
     * @type {boolean}
     * @memberof QueryOrganizationSummary
     */
    'active'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof QueryOrganizationSummary
     */
    'address'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryOrganizationSummary
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryOrganizationSummary
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryOrganizationSummary
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof QueryOrganizationSummary
     */
    'organization_type_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof QueryOrganizationSummary
     */
    'organization_type_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryOrganizationSummary
     */
    'room_cnt'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryOrganizationSummary
     */
    'site_cnt'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryOrganizationSummary
     */
    'state'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryOrganizationSummary
     */
    'stock_cnt'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryOrganizationSummary
     */
    'tool_cnt'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryOrganizationSummary
     */
    'user_cnt'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryOrganizationSummary
     */
    'vehicle_cnt'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryOrganizationSummary
     */
    'vertical_market_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryOrganizationSummary
     */
    'vertical_market_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryOrganizationSummary
     */
    'zip'?: string;
}
/**
 * 
 * @export
 * @interface QueryPayloadSummary
 */
export interface QueryPayloadSummary {
    /**
     * 
     * @type {number}
     * @memberof QueryPayloadSummary
     */
    'average_reads'?: number;
    /**
     * 
     * @type {string}
     * @memberof QueryPayloadSummary
     */
    'device_id'?: string;
    /**
     * 
     * @type {QueryPayloadSummaryEnvironment}
     * @memberof QueryPayloadSummary
     */
    'environment'?: QueryPayloadSummaryEnvironment;
    /**
     * 
     * @type {number}
     * @memberof QueryPayloadSummary
     */
    'items_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof QueryPayloadSummary
     */
    'latitude'?: number;
    /**
     * 
     * @type {string}
     * @memberof QueryPayloadSummary
     */
    'location_visit_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof QueryPayloadSummary
     */
    'longitude'?: number;
    /**
     * 
     * @type {QueryPayloadSummaryOperation}
     * @memberof QueryPayloadSummary
     */
    'operation'?: QueryPayloadSummaryOperation;
    /**
     * 
     * @type {string}
     * @memberof QueryPayloadSummary
     */
    'organization_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryPayloadSummary
     */
    'owner_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryPayloadSummary
     */
    'owner_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryPayloadSummary
     */
    'payload_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryPayloadSummary
     */
    'payload_type'?: string;
    /**
     * 
     * @type {number}
     * @memberof QueryPayloadSummary
     */
    'payload_type_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof QueryPayloadSummary
     */
    'processed_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryPayloadSummary
     */
    'raw'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryPayloadSummary
     */
    'raw_device_id'?: string;
    /**
     * 
     * @type {Array<QueryScanEvent>}
     * @memberof QueryPayloadSummary
     */
    'scan_events'?: Array<QueryScanEvent>;
    /**
     * 
     * @type {string}
     * @memberof QueryPayloadSummary
     */
    'scan_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryPayloadSummary
     */
    'site_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryPayloadSummary
     */
    'site_name'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof QueryPayloadSummary
     */
    'two_scans_at_location'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof QueryPayloadSummary
     */
    'warehouse_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryPayloadSummary
     */
    'warehouse_type'?: string;
}
/**
 * 
 * @export
 * @interface QueryPayloadSummaryEnvironment
 */
export interface QueryPayloadSummaryEnvironment {
    /**
     * 
     * @type {string}
     * @memberof QueryPayloadSummaryEnvironment
     */
    'at_csq'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryPayloadSummaryEnvironment
     */
    'temperature_end'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryPayloadSummaryEnvironment
     */
    'temperature_start'?: string;
    /**
     * Calculated: voltage_submit > 13.0V ? \"running\" : \"off\"
     * @type {string}
     * @memberof QueryPayloadSummaryEnvironment
     */
    'vehicle_status'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryPayloadSummaryEnvironment
     */
    'version_mcu'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryPayloadSummaryEnvironment
     */
    'version_scanner'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryPayloadSummaryEnvironment
     */
    'version_updater'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryPayloadSummaryEnvironment
     */
    'voltage_start'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryPayloadSummaryEnvironment
     */
    'voltage_submit'?: string;
}
/**
 * 
 * @export
 * @interface QueryPayloadSummaryOperation
 */
export interface QueryPayloadSummaryOperation {
    /**
     * 
     * @type {number}
     * @memberof QueryPayloadSummaryOperation
     */
    'gps_attempts'?: number;
    /**
     * 
     * @type {number}
     * @memberof QueryPayloadSummaryOperation
     */
    'gps_satellite_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof QueryPayloadSummaryOperation
     */
    'iteration'?: number;
    /**
     * 
     * @type {string}
     * @memberof QueryPayloadSummaryOperation
     */
    'power_mode'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryPayloadSummaryOperation
     */
    'power_mode_end'?: string;
    /**
     * 
     * @type {number}
     * @memberof QueryPayloadSummaryOperation
     */
    'start_difference'?: number;
    /**
     * 
     * @type {string}
     * @memberof QueryPayloadSummaryOperation
     */
    'trigger'?: string;
}
/**
 * 
 * @export
 * @interface QueryPhantomPayload
 */
export interface QueryPhantomPayload {
    /**
     * 
     * @type {string}
     * @memberof QueryPhantomPayload
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryPhantomPayload
     */
    'device_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryPhantomPayload
     */
    'device_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryPhantomPayload
     */
    'device_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryPhantomPayload
     */
    'organization_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryPhantomPayload
     */
    'organization_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryPhantomPayload
     */
    'owner_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryPhantomPayload
     */
    'payload_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryPhantomPayload
     */
    'processed_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryPhantomPayload
     */
    'warehouse_id'?: string;
}
/**
 * 
 * @export
 * @interface QueryProduct
 */
export interface QueryProduct {
    /**
     * 
     * @type {boolean}
     * @memberof QueryProduct
     */
    'active'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof QueryProduct
     */
    'available_count'?: number;
    /**
     * 
     * @type {string}
     * @memberof QueryProduct
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryProduct
     */
    'image_key'?: string;
    /**
     * 
     * @type {number}
     * @memberof QueryProduct
     */
    'installed_count'?: number;
    /**
     * 
     * @type {string}
     * @memberof QueryProduct
     */
    'manufacturer_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryProduct
     */
    'manufacturer_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryProduct
     */
    'manufacturer_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryProduct
     */
    'product_category'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryProduct
     */
    'product_category_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryProduct
     */
    'product_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryProduct
     */
    'product_name'?: string;
    /**
     * 
     * @type {number}
     * @memberof QueryProduct
     */
    'product_type_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof QueryProduct
     */
    'product_type_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryProduct
     */
    'sku'?: string;
    /**
     * 
     * @type {number}
     * @memberof QueryProduct
     */
    'total_count'?: number;
    /**
     * 
     * @type {string}
     * @memberof QueryProduct
     */
    'updated_at'?: string;
}
/**
 * 
 * @export
 * @interface QueryProductCategory
 */
export interface QueryProductCategory {
    /**
     * 
     * @type {string}
     * @memberof QueryProductCategory
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryProductCategory
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface QueryReadSummaryItem
 */
export interface QueryReadSummaryItem {
    /**
     * 
     * @type {number}
     * @memberof QueryReadSummaryItem
     */
    'count'?: number;
    /**
     * 
     * @type {string}
     * @memberof QueryReadSummaryItem
     */
    'event_type'?: string;
    /**
     * core
     * @type {string}
     * @memberof QueryReadSummaryItem
     */
    'first_read'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryReadSummaryItem
     */
    'last_read'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryReadSummaryItem
     */
    'summary'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryReadSummaryItem
     */
    'warehouse_names'?: string;
}
/**
 * 
 * @export
 * @interface QueryReportRecipient
 */
export interface QueryReportRecipient {
    /**
     * 
     * @type {string}
     * @memberof QueryReportRecipient
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryReportRecipient
     */
    'first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryReportRecipient
     */
    'last_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryReportRecipient
     */
    'user_id'?: string;
}
/**
 * 
 * @export
 * @interface QueryReportSchedule
 */
export interface QueryReportSchedule {
    /**
     * 
     * @type {number}
     * @memberof QueryReportSchedule
     */
    'recipient_count'?: number;
    /**
     * 
     * @type {Array<QueryReportRecipient>}
     * @memberof QueryReportSchedule
     */
    'recipients'?: Array<QueryReportRecipient>;
    /**
     * 
     * @type {string}
     * @memberof QueryReportSchedule
     */
    'report_description'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryReportSchedule
     */
    'report_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryReportSchedule
     */
    'report_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryReportSchedule
     */
    'schedule'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryReportSchedule
     */
    'short_id'?: string;
}
/**
 * 
 * @export
 * @interface QueryRoom
 */
export interface QueryRoom {
    /**
     * 
     * @type {boolean}
     * @memberof QueryRoom
     */
    'active'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof QueryRoom
     */
    'configuration_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryRoom
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryRoom
     */
    'device_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryRoom
     */
    'device_identifier'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryRoom
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryRoom
     */
    'image_key'?: string;
    /**
     * 
     * @type {number}
     * @memberof QueryRoom
     */
    'item_cnt_reads'?: number;
    /**
     * 
     * @type {number}
     * @memberof QueryRoom
     */
    'latitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof QueryRoom
     */
    'longitude'?: number;
    /**
     * 
     * @type {string}
     * @memberof QueryRoom
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryRoom
     */
    'organization_id'?: string;
    /**
     * 
     * @type {ModelRoomType}
     * @memberof QueryRoom
     */
    'room_type'?: ModelRoomType;
    /**
     * 
     * @type {number}
     * @memberof QueryRoom
     */
    'room_type_id'?: number;
    /**
     * 
     * @type {ModelSite}
     * @memberof QueryRoom
     */
    'site'?: ModelSite;
    /**
     * 
     * @type {string}
     * @memberof QueryRoom
     */
    'site_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryRoom
     */
    'site_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryRoom
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryRoom
     */
    'warehouse_id'?: string;
}
/**
 * 
 * @export
 * @interface QueryScanEvent
 */
export interface QueryScanEvent {
    /**
     * 
     * @type {string}
     * @memberof QueryScanEvent
     */
    'event_type'?: string;
    /**
     * 
     * @type {number}
     * @memberof QueryScanEvent
     */
    'event_type_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof QueryScanEvent
     */
    'identifier'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryScanEvent
     */
    'item_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryScanEvent
     */
    'location_visit_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryScanEvent
     */
    'payload_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryScanEvent
     */
    'payload_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryScanEvent
     */
    'processed_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryScanEvent
     */
    'processing_warehouse_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryScanEvent
     */
    'product_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryScanEvent
     */
    'product_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryScanEvent
     */
    'scan_event_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryScanEvent
     */
    'site_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryScanEvent
     */
    'site_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryScanEvent
     */
    'tracking_warehouse_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryScanEvent
     */
    'vehicle_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryScanEvent
     */
    'vehicle_name'?: string;
}
/**
 * 
 * @export
 * @interface QuerySearchSummary
 */
export interface QuerySearchSummary {
    /**
     * 
     * @type {string}
     * @memberof QuerySearchSummary
     */
    'category'?: string;
    /**
     * 
     * @type {number}
     * @memberof QuerySearchSummary
     */
    'count'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof QuerySearchSummary
     */
    'direct'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof QuerySearchSummary
     */
    'id'?: string;
}
/**
 * 
 * @export
 * @interface QueryServiceStatus
 */
export interface QueryServiceStatus {
    /**
     * 
     * @type {string}
     * @memberof QueryServiceStatus
     */
    'error_message'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryServiceStatus
     */
    'name'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof QueryServiceStatus
     */
    'running_status'?: boolean;
}
/**
 * 
 * @export
 * @interface QuerySite
 */
export interface QuerySite {
    /**
     * 
     * @type {boolean}
     * @memberof QuerySite
     */
    'active'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof QuerySite
     */
    'address1'?: string;
    /**
     * 
     * @type {string}
     * @memberof QuerySite
     */
    'boundary_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof QuerySite
     */
    'city'?: string;
    /**
     * 
     * @type {Array<ModelPoint>}
     * @memberof QuerySite
     */
    'coordinates'?: Array<ModelPoint>;
    /**
     * 
     * @type {string}
     * @memberof QuerySite
     */
    'created_at'?: string;
    /**
     * 
     * @type {ModelCustomer}
     * @memberof QuerySite
     */
    'customer'?: ModelCustomer;
    /**
     * 
     * @type {string}
     * @memberof QuerySite
     */
    'customer_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QuerySite
     */
    'customer_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof QuerySite
     */
    'first_visit'?: string;
    /**
     * 
     * @type {string}
     * @memberof QuerySite
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QuerySite
     */
    'image_key'?: string;
    /**
     * 
     * @type {string}
     * @memberof QuerySite
     */
    'last_visit'?: string;
    /**
     * 
     * @type {number}
     * @memberof QuerySite
     */
    'latitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof QuerySite
     */
    'location_visit_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof QuerySite
     */
    'longitude'?: number;
    /**
     * 
     * @type {string}
     * @memberof QuerySite
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof QuerySite
     */
    'organization_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QuerySite
     */
    'organization_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof QuerySite
     */
    'postal'?: string;
    /**
     * 
     * @type {ModelSiteType}
     * @memberof QuerySite
     */
    'site_type'?: ModelSiteType;
    /**
     * 
     * @type {number}
     * @memberof QuerySite
     */
    'site_type_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof QuerySite
     */
    'state'?: string;
    /**
     * 
     * @type {Array<QueryJobSiteStockItem>}
     * @memberof QuerySite
     */
    'stock'?: Array<QueryJobSiteStockItem>;
    /**
     * 
     * @type {number}
     * @memberof QuerySite
     */
    'stock_assigned_cnt'?: number;
    /**
     * 
     * @type {number}
     * @memberof QuerySite
     */
    'stock_error_cnt'?: number;
    /**
     * 
     * @type {number}
     * @memberof QuerySite
     */
    'stock_installed_cnt'?: number;
    /**
     * 
     * @type {number}
     * @memberof QuerySite
     */
    'stock_on_site_cnt'?: number;
    /**
     * 
     * @type {number}
     * @memberof QuerySite
     */
    'tools_left_behind_cnt'?: number;
    /**
     * 
     * @type {number}
     * @memberof QuerySite
     */
    'tools_used_cnt'?: number;
    /**
     * 
     * @type {string}
     * @memberof QuerySite
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof QuerySite
     */
    'vehicles'?: string;
}
/**
 * 
 * @export
 * @interface QueryStockAggregate
 */
export interface QueryStockAggregate {
    /**
     * 
     * @type {string}
     * @memberof QueryStockAggregate
     */
    'image_key'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryStockAggregate
     */
    'manufacturer_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryStockAggregate
     */
    'manufacturer_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryStockAggregate
     */
    'owner_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryStockAggregate
     */
    'owner_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryStockAggregate
     */
    'product_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryStockAggregate
     */
    'product_name'?: string;
    /**
     * 
     * @type {number}
     * @memberof QueryStockAggregate
     */
    'quantity'?: number;
    /**
     * 
     * @type {number}
     * @memberof QueryStockAggregate
     */
    'warehouse_type_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof QueryStockAggregate
     */
    'warehouse_type_name'?: string;
}
/**
 * 
 * @export
 * @interface QueryStockInstalledItem
 */
export interface QueryStockInstalledItem {
    /**
     * 
     * @type {string}
     * @memberof QueryStockInstalledItem
     */
    'customer_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryStockInstalledItem
     */
    'customer_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryStockInstalledItem
     */
    'first_visit'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryStockInstalledItem
     */
    'image_key'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryStockInstalledItem
     */
    'last_visit'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryStockInstalledItem
     */
    'location_visit_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryStockInstalledItem
     */
    'manufacturer_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryStockInstalledItem
     */
    'manufacturer_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryStockInstalledItem
     */
    'product_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryStockInstalledItem
     */
    'product_name'?: string;
    /**
     * 
     * @type {number}
     * @memberof QueryStockInstalledItem
     */
    'quantity'?: number;
    /**
     * 
     * @type {string}
     * @memberof QueryStockInstalledItem
     */
    'site_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryStockInstalledItem
     */
    'site_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryStockInstalledItem
     */
    'vehicle_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryStockInstalledItem
     */
    'vehicle_name'?: string;
}
/**
 * 
 * @export
 * @interface QueryStorageUpload
 */
export interface QueryStorageUpload {
    /**
     * 
     * @type {string}
     * @memberof QueryStorageUpload
     */
    'content_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryStorageUpload
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryStorageUpload
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryStorageUpload
     */
    'download_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryStorageUpload
     */
    'file_key'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryStorageUpload
     */
    'file_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryStorageUpload
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryStorageUpload
     */
    'organization_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryStorageUpload
     */
    'owner_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryStorageUpload
     */
    'upload_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryStorageUpload
     */
    'uploaded_at'?: string;
}
/**
 * 
 * @export
 * @interface QueryTagHealthItem
 */
export interface QueryTagHealthItem {
    /**
     * 
     * @type {string}
     * @memberof QueryTagHealthItem
     */
    'device_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryTagHealthItem
     */
    'identifier'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryTagHealthItem
     */
    'item_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryTagHealthItem
     */
    'organization_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryTagHealthItem
     */
    'owner_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryTagHealthItem
     */
    'payload_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryTagHealthItem
     */
    'payload_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryTagHealthItem
     */
    'processed_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryTagHealthItem
     */
    'product_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryTagHealthItem
     */
    'product_name'?: string;
    /**
     * 
     * @type {number}
     * @memberof QueryTagHealthItem
     */
    'product_type_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof QueryTagHealthItem
     */
    'tag_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof QueryTagHealthItem
     */
    'total_reads'?: number;
    /**
     * 
     * @type {string}
     * @memberof QueryTagHealthItem
     */
    'warehouse_type'?: string;
}
/**
 * 
 * @export
 * @interface QueryTagHealthTag
 */
export interface QueryTagHealthTag {
    /**
     * 
     * @type {string}
     * @memberof QueryTagHealthTag
     */
    'antennas'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryTagHealthTag
     */
    'device_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryTagHealthTag
     */
    'epc'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryTagHealthTag
     */
    'identifier'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryTagHealthTag
     */
    'item_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryTagHealthTag
     */
    'organization_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryTagHealthTag
     */
    'owner_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryTagHealthTag
     */
    'payload_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryTagHealthTag
     */
    'payload_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryTagHealthTag
     */
    'processed_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryTagHealthTag
     */
    'product_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryTagHealthTag
     */
    'product_name'?: string;
    /**
     * 
     * @type {number}
     * @memberof QueryTagHealthTag
     */
    'reads'?: number;
    /**
     * 
     * @type {string}
     * @memberof QueryTagHealthTag
     */
    'warehouse_type'?: string;
}
/**
 * 
 * @export
 * @interface QueryUser
 */
export interface QueryUser {
    /**
     * 
     * @type {boolean}
     * @memberof QueryUser
     */
    'active'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof QueryUser
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryUser
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryUser
     */
    'email_invite_sent'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryUser
     */
    'email_invite_verified'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryUser
     */
    'email_reset_send'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryUser
     */
    'first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryUser
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryUser
     */
    'image_key'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof QueryUser
     */
    'is_editor'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof QueryUser
     */
    'last_login'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryUser
     */
    'last_name'?: string;
    /**
     * 
     * @type {ModelOrganization}
     * @memberof QueryUser
     */
    'organization'?: ModelOrganization;
    /**
     * 
     * @type {string}
     * @memberof QueryUser
     */
    'organization_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryUser
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryUser
     */
    'phone_confirm_sent'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryUser
     */
    'phone_confirm_verified'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryUser
     */
    'phone_invite_sent'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryUser
     */
    'roles'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryUser
     */
    'updated_at'?: string;
    /**
     * 
     * @type {ModelWarehouse}
     * @memberof QueryUser
     */
    'warehouse'?: ModelWarehouse;
    /**
     * 
     * @type {string}
     * @memberof QueryUser
     */
    'warehouse_id'?: string;
}
/**
 * 
 * @export
 * @interface QueryVehicle
 */
export interface QueryVehicle {
    /**
     * 
     * @type {boolean}
     * @memberof QueryVehicle
     */
    'active'?: boolean;
    /**
     * DeviceType       *string    `json:\"device_type\"`
     * @type {string}
     * @memberof QueryVehicle
     */
    'configuration_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryVehicle
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryVehicle
     */
    'device_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryVehicle
     */
    'device_identifier'?: string;
    /**
     * 
     * @type {ModelSite}
     * @memberof QueryVehicle
     */
    'home_site'?: ModelSite;
    /**
     * 
     * @type {string}
     * @memberof QueryVehicle
     */
    'home_site_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryVehicle
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryVehicle
     */
    'image_key'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryVehicle
     */
    'license_plate'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryVehicle
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryVehicle
     */
    'organization_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryVehicle
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryVehicle
     */
    'warehouse_id'?: string;
}
/**
 * 
 * @export
 * @interface QueryVehicleDashboardSummary
 */
export interface QueryVehicleDashboardSummary {
    /**
     * 
     * @type {boolean}
     * @memberof QueryVehicleDashboardSummary
     */
    'active'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof QueryVehicleDashboardSummary
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryVehicleDashboardSummary
     */
    'first_visit'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryVehicleDashboardSummary
     */
    'last_payload_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryVehicleDashboardSummary
     */
    'last_visit'?: string;
    /**
     * 
     * @type {number}
     * @memberof QueryVehicleDashboardSummary
     */
    'latitude'?: number;
    /**
     * 
     * @type {string}
     * @memberof QueryVehicleDashboardSummary
     */
    'location_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryVehicleDashboardSummary
     */
    'location_visit_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof QueryVehicleDashboardSummary
     */
    'longitude'?: number;
    /**
     * 
     * @type {string}
     * @memberof QueryVehicleDashboardSummary
     */
    'operating_mode'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryVehicleDashboardSummary
     */
    'owner_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryVehicleDashboardSummary
     */
    'primary_vehicle_image'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryVehicleDashboardSummary
     */
    'processed_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryVehicleDashboardSummary
     */
    'site_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryVehicleDashboardSummary
     */
    'site_name'?: string;
    /**
     * 
     * @type {number}
     * @memberof QueryVehicleDashboardSummary
     */
    'site_type_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof QueryVehicleDashboardSummary
     */
    'site_type_name'?: string;
    /**
     * 
     * @type {number}
     * @memberof QueryVehicleDashboardSummary
     */
    'stock_cnt_in_use'?: number;
    /**
     * 
     * @type {number}
     * @memberof QueryVehicleDashboardSummary
     */
    'stock_cnt_installed'?: number;
    /**
     * 
     * @type {number}
     * @memberof QueryVehicleDashboardSummary
     */
    'stock_cnt_total'?: number;
    /**
     * 
     * @type {number}
     * @memberof QueryVehicleDashboardSummary
     */
    'tool_cnt_in_use'?: number;
    /**
     * 
     * @type {number}
     * @memberof QueryVehicleDashboardSummary
     */
    'tool_cnt_left_behind'?: number;
    /**
     * 
     * @type {number}
     * @memberof QueryVehicleDashboardSummary
     */
    'tool_cnt_total'?: number;
    /**
     * 
     * @type {number}
     * @memberof QueryVehicleDashboardSummary
     */
    'total_cnt_reads'?: number;
    /**
     * 
     * @type {string}
     * @memberof QueryVehicleDashboardSummary
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryVehicleDashboardSummary
     */
    'vehicle_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryVehicleDashboardSummary
     */
    'vehicle_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryVehicleDashboardSummary
     */
    'warehouse_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryVehicleDashboardSummary
     */
    'warehouse_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryVehicleDashboardSummary
     */
    'warehouse_type'?: string;
}
/**
 * 
 * @export
 * @interface QueryVehiclePayloadSummary
 */
export interface QueryVehiclePayloadSummary {
    /**
     * 
     * @type {string}
     * @memberof QueryVehiclePayloadSummary
     */
    'last_scan'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryVehiclePayloadSummary
     */
    'organization_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryVehiclePayloadSummary
     */
    'organization_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryVehiclePayloadSummary
     */
    'owner_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryVehiclePayloadSummary
     */
    'owner_name'?: string;
    /**
     * 
     * @type {number}
     * @memberof QueryVehiclePayloadSummary
     */
    'scan_count'?: number;
}
/**
 * 
 * @export
 * @interface QueryVerticalMarket
 */
export interface QueryVerticalMarket {
    /**
     * 
     * @type {boolean}
     * @memberof QueryVerticalMarket
     */
    'active'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof QueryVerticalMarket
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryVerticalMarket
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryVerticalMarket
     */
    'market_name'?: string;
    /**
     * 
     * @type {number}
     * @memberof QueryVerticalMarket
     */
    'organization_count'?: number;
    /**
     * 
     * @type {Array<QueryOrganizationSummary>}
     * @memberof QueryVerticalMarket
     */
    'organizations'?: Array<QueryOrganizationSummary>;
    /**
     * 
     * @type {string}
     * @memberof QueryVerticalMarket
     */
    'updated_at'?: string;
}
/**
 * 
 * @export
 * @interface QueryWarehouse
 */
export interface QueryWarehouse {
    /**
     * 
     * @type {string}
     * @memberof QueryWarehouse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryWarehouse
     */
    'location_visit_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryWarehouse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryWarehouse
     */
    'owner_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryWarehouse
     */
    'payload_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof QueryWarehouse
     */
    'warehouse_type_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof QueryWarehouse
     */
    'warehouse_type_name'?: string;
}
/**
 * 
 * @export
 * @interface ReadSummaryListResponse
 */
export interface ReadSummaryListResponse {
    /**
     * 
     * @type {number}
     * @memberof ReadSummaryListResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReadSummaryListResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof ReadSummaryListResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ReadSummaryListResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof ReadSummaryListResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReadSummaryListResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReadSummaryListResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {Array<QueryReadSummaryItem>}
     * @memberof ReadSummaryListResponse
     */
    'result'?: Array<QueryReadSummaryItem>;
}
/**
 * 
 * @export
 * @interface RoomDependenciesResponse
 */
export interface RoomDependenciesResponse {
    /**
     * 
     * @type {number}
     * @memberof RoomDependenciesResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof RoomDependenciesResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof RoomDependenciesResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof RoomDependenciesResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof RoomDependenciesResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof RoomDependenciesResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof RoomDependenciesResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {QueryDependencies}
     * @memberof RoomDependenciesResponse
     */
    'result'?: QueryDependencies;
}
/**
 * 
 * @export
 * @interface RoomListResponse
 */
export interface RoomListResponse {
    /**
     * 
     * @type {number}
     * @memberof RoomListResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof RoomListResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof RoomListResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof RoomListResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof RoomListResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof RoomListResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof RoomListResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {Array<QueryRoom>}
     * @memberof RoomListResponse
     */
    'result'?: Array<QueryRoom>;
}
/**
 * 
 * @export
 * @interface RoomRequestRoomWithDevice
 */
export interface RoomRequestRoomWithDevice {
    /**
     * 
     * @type {string}
     * @memberof RoomRequestRoomWithDevice
     */
    'device_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof RoomRequestRoomWithDevice
     */
    'home_site_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof RoomRequestRoomWithDevice
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof RoomRequestRoomWithDevice
     */
    'organization_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof RoomRequestRoomWithDevice
     */
    'room_type_id'?: number;
}
/**
 * 
 * @export
 * @interface RoomRequestUpdateRoom
 */
export interface RoomRequestUpdateRoom {
    /**
     * 
     * @type {boolean}
     * @memberof RoomRequestUpdateRoom
     */
    'active'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RoomRequestUpdateRoom
     */
    'home_site_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof RoomRequestUpdateRoom
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof RoomRequestUpdateRoom
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof RoomRequestUpdateRoom
     */
    'room_type_id'?: number;
}
/**
 * 
 * @export
 * @interface RoomRoomResponse
 */
export interface RoomRoomResponse {
    /**
     * 
     * @type {number}
     * @memberof RoomRoomResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof RoomRoomResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof RoomRoomResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof RoomRoomResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof RoomRoomResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof RoomRoomResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof RoomRoomResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {QueryRoom}
     * @memberof RoomRoomResponse
     */
    'result'?: QueryRoom;
}
/**
 * 
 * @export
 * @interface ScaneventListResponse
 */
export interface ScaneventListResponse {
    /**
     * 
     * @type {number}
     * @memberof ScaneventListResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof ScaneventListResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof ScaneventListResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ScaneventListResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof ScaneventListResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof ScaneventListResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof ScaneventListResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {Array<QueryScanEvent>}
     * @memberof ScaneventListResponse
     */
    'result'?: Array<QueryScanEvent>;
}
/**
 * 
 * @export
 * @interface SearchListResponse
 */
export interface SearchListResponse {
    /**
     * 
     * @type {number}
     * @memberof SearchListResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof SearchListResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof SearchListResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchListResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof SearchListResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof SearchListResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof SearchListResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {Array<QuerySearchSummary>}
     * @memberof SearchListResponse
     */
    'result'?: Array<QuerySearchSummary>;
}
/**
 * 
 * @export
 * @interface SearchObjectResponse
 */
export interface SearchObjectResponse {
    /**
     * 
     * @type {number}
     * @memberof SearchObjectResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof SearchObjectResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof SearchObjectResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchObjectResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof SearchObjectResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof SearchObjectResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof SearchObjectResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {QuerySearchSummary}
     * @memberof SearchObjectResponse
     */
    'result'?: QuerySearchSummary;
}
/**
 * 
 * @export
 * @interface SiteDependenciesResponse
 */
export interface SiteDependenciesResponse {
    /**
     * 
     * @type {number}
     * @memberof SiteDependenciesResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof SiteDependenciesResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof SiteDependenciesResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof SiteDependenciesResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof SiteDependenciesResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof SiteDependenciesResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof SiteDependenciesResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {QueryDependencies}
     * @memberof SiteDependenciesResponse
     */
    'result'?: QueryDependencies;
}
/**
 * 
 * @export
 * @interface SiteKpisResponse
 */
export interface SiteKpisResponse {
    /**
     * 
     * @type {number}
     * @memberof SiteKpisResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof SiteKpisResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof SiteKpisResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof SiteKpisResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof SiteKpisResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof SiteKpisResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof SiteKpisResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {Array<QueryKPI>}
     * @memberof SiteKpisResponse
     */
    'result'?: Array<QueryKPI>;
}
/**
 * 
 * @export
 * @interface SiteRequestSite
 */
export interface SiteRequestSite {
    /**
     * 
     * @type {boolean}
     * @memberof SiteRequestSite
     */
    'active'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SiteRequestSite
     */
    'address_1'?: string;
    /**
     * 
     * @type {string}
     * @memberof SiteRequestSite
     */
    'address_2'?: string;
    /**
     * 
     * @type {string}
     * @memberof SiteRequestSite
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof SiteRequestSite
     */
    'customer_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SiteRequestSite
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SiteRequestSite
     */
    'latitude'?: string;
    /**
     * 
     * @type {string}
     * @memberof SiteRequestSite
     */
    'longitude'?: string;
    /**
     * 
     * @type {string}
     * @memberof SiteRequestSite
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof SiteRequestSite
     */
    'organization_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SiteRequestSite
     */
    'postal'?: string;
    /**
     * 
     * @type {number}
     * @memberof SiteRequestSite
     */
    'site_type_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof SiteRequestSite
     */
    'state'?: string;
}
/**
 * 
 * @export
 * @interface SiteRequestSiteGeofence
 */
export interface SiteRequestSiteGeofence {
    /**
     * 
     * @type {Array<ModelPoint>}
     * @memberof SiteRequestSiteGeofence
     */
    'coordinates'?: Array<ModelPoint>;
}
/**
 * 
 * @export
 * @interface SiteSiteAlternateListResponse
 */
export interface SiteSiteAlternateListResponse {
    /**
     * 
     * @type {number}
     * @memberof SiteSiteAlternateListResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof SiteSiteAlternateListResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof SiteSiteAlternateListResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof SiteSiteAlternateListResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof SiteSiteAlternateListResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof SiteSiteAlternateListResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof SiteSiteAlternateListResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {Array<QueryAlternateSite>}
     * @memberof SiteSiteAlternateListResponse
     */
    'result'?: Array<QueryAlternateSite>;
}
/**
 * 
 * @export
 * @interface SiteSiteListResponse
 */
export interface SiteSiteListResponse {
    /**
     * 
     * @type {number}
     * @memberof SiteSiteListResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof SiteSiteListResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof SiteSiteListResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof SiteSiteListResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof SiteSiteListResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof SiteSiteListResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof SiteSiteListResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {Array<QuerySite>}
     * @memberof SiteSiteListResponse
     */
    'result'?: Array<QuerySite>;
}
/**
 * 
 * @export
 * @interface SiteSiteMaxCreatedAtResponse
 */
export interface SiteSiteMaxCreatedAtResponse {
    /**
     * 
     * @type {number}
     * @memberof SiteSiteMaxCreatedAtResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof SiteSiteMaxCreatedAtResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof SiteSiteMaxCreatedAtResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof SiteSiteMaxCreatedAtResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof SiteSiteMaxCreatedAtResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof SiteSiteMaxCreatedAtResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof SiteSiteMaxCreatedAtResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {string}
     * @memberof SiteSiteMaxCreatedAtResponse
     */
    'result'?: string;
}
/**
 * 
 * @export
 * @interface SiteSiteResponse
 */
export interface SiteSiteResponse {
    /**
     * 
     * @type {number}
     * @memberof SiteSiteResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof SiteSiteResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof SiteSiteResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof SiteSiteResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof SiteSiteResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof SiteSiteResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof SiteSiteResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {QuerySite}
     * @memberof SiteSiteResponse
     */
    'result'?: QuerySite;
}
/**
 * 
 * @export
 * @interface StockAvailableListResponse
 */
export interface StockAvailableListResponse {
    /**
     * 
     * @type {number}
     * @memberof StockAvailableListResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof StockAvailableListResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof StockAvailableListResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof StockAvailableListResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof StockAvailableListResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof StockAvailableListResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof StockAvailableListResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {Array<QueryStockAggregate>}
     * @memberof StockAvailableListResponse
     */
    'result'?: Array<QueryStockAggregate>;
}
/**
 * 
 * @export
 * @interface StockCategoryListResponse
 */
export interface StockCategoryListResponse {
    /**
     * 
     * @type {number}
     * @memberof StockCategoryListResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof StockCategoryListResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof StockCategoryListResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof StockCategoryListResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof StockCategoryListResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof StockCategoryListResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof StockCategoryListResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {Array<QueryItemCategory>}
     * @memberof StockCategoryListResponse
     */
    'result'?: Array<QueryItemCategory>;
}
/**
 * 
 * @export
 * @interface StockInstalledListResponse
 */
export interface StockInstalledListResponse {
    /**
     * 
     * @type {number}
     * @memberof StockInstalledListResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof StockInstalledListResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof StockInstalledListResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof StockInstalledListResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof StockInstalledListResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof StockInstalledListResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof StockInstalledListResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {Array<QueryStockInstalledItem>}
     * @memberof StockInstalledListResponse
     */
    'result'?: Array<QueryStockInstalledItem>;
}
/**
 * 
 * @export
 * @interface StockListJobSiteAssignedStockResponse
 */
export interface StockListJobSiteAssignedStockResponse {
    /**
     * 
     * @type {number}
     * @memberof StockListJobSiteAssignedStockResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof StockListJobSiteAssignedStockResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof StockListJobSiteAssignedStockResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof StockListJobSiteAssignedStockResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof StockListJobSiteAssignedStockResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof StockListJobSiteAssignedStockResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof StockListJobSiteAssignedStockResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {Array<QueryJobSiteAssignedStock>}
     * @memberof StockListJobSiteAssignedStockResponse
     */
    'result'?: Array<QueryJobSiteAssignedStock>;
}
/**
 * 
 * @export
 * @interface StorageCreateStorageUploadRequest
 */
export interface StorageCreateStorageUploadRequest {
    /**
     * 
     * @type {string}
     * @memberof StorageCreateStorageUploadRequest
     */
    'content_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof StorageCreateStorageUploadRequest
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof StorageCreateStorageUploadRequest
     */
    'file_name'?: string;
}
/**
 * 
 * @export
 * @interface StorageCreateStorageUploadResponse
 */
export interface StorageCreateStorageUploadResponse {
    /**
     * 
     * @type {number}
     * @memberof StorageCreateStorageUploadResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof StorageCreateStorageUploadResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof StorageCreateStorageUploadResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof StorageCreateStorageUploadResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof StorageCreateStorageUploadResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof StorageCreateStorageUploadResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof StorageCreateStorageUploadResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {QueryStorageUpload}
     * @memberof StorageCreateStorageUploadResponse
     */
    'result'?: QueryStorageUpload;
}
/**
 * 
 * @export
 * @interface StorageDeleteStorageUploadResponse
 */
export interface StorageDeleteStorageUploadResponse {
    /**
     * 
     * @type {number}
     * @memberof StorageDeleteStorageUploadResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof StorageDeleteStorageUploadResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof StorageDeleteStorageUploadResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof StorageDeleteStorageUploadResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof StorageDeleteStorageUploadResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof StorageDeleteStorageUploadResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof StorageDeleteStorageUploadResponse
     */
    'record_offset'?: number;
}
/**
 * 
 * @export
 * @interface StorageFinishStorageUploadResponse
 */
export interface StorageFinishStorageUploadResponse {
    /**
     * 
     * @type {number}
     * @memberof StorageFinishStorageUploadResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof StorageFinishStorageUploadResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof StorageFinishStorageUploadResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof StorageFinishStorageUploadResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof StorageFinishStorageUploadResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof StorageFinishStorageUploadResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof StorageFinishStorageUploadResponse
     */
    'record_offset'?: number;
}
/**
 * 
 * @export
 * @interface StorageGetStorageUploadResponse
 */
export interface StorageGetStorageUploadResponse {
    /**
     * 
     * @type {number}
     * @memberof StorageGetStorageUploadResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof StorageGetStorageUploadResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof StorageGetStorageUploadResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof StorageGetStorageUploadResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof StorageGetStorageUploadResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof StorageGetStorageUploadResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof StorageGetStorageUploadResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {QueryStorageUpload}
     * @memberof StorageGetStorageUploadResponse
     */
    'result'?: QueryStorageUpload;
}
/**
 * 
 * @export
 * @interface StorageListStorageUploadResponse
 */
export interface StorageListStorageUploadResponse {
    /**
     * 
     * @type {number}
     * @memberof StorageListStorageUploadResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof StorageListStorageUploadResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof StorageListStorageUploadResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof StorageListStorageUploadResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof StorageListStorageUploadResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof StorageListStorageUploadResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof StorageListStorageUploadResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {Array<QueryStorageUpload>}
     * @memberof StorageListStorageUploadResponse
     */
    'result'?: Array<QueryStorageUpload>;
}
/**
 * 
 * @export
 * @interface StorageUpdateStorageUploadRequest
 */
export interface StorageUpdateStorageUploadRequest {
    /**
     * 
     * @type {string}
     * @memberof StorageUpdateStorageUploadRequest
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface StorageUpdateStorageUploadResponse
 */
export interface StorageUpdateStorageUploadResponse {
    /**
     * 
     * @type {number}
     * @memberof StorageUpdateStorageUploadResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof StorageUpdateStorageUploadResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof StorageUpdateStorageUploadResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof StorageUpdateStorageUploadResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof StorageUpdateStorageUploadResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof StorageUpdateStorageUploadResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof StorageUpdateStorageUploadResponse
     */
    'record_offset'?: number;
}
/**
 * 
 * @export
 * @interface TrackingLastProcessedResponse
 */
export interface TrackingLastProcessedResponse {
    /**
     * 
     * @type {number}
     * @memberof TrackingLastProcessedResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof TrackingLastProcessedResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof TrackingLastProcessedResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof TrackingLastProcessedResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof TrackingLastProcessedResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof TrackingLastProcessedResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof TrackingLastProcessedResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {ModelPayloadStatus}
     * @memberof TrackingLastProcessedResponse
     */
    'result'?: ModelPayloadStatus;
}
/**
 * 
 * @export
 * @interface UserCurrentUser
 */
export interface UserCurrentUser {
    /**
     * 
     * @type {string}
     * @memberof UserCurrentUser
     */
    'jwt'?: string;
    /**
     * 
     * @type {QueryUser}
     * @memberof UserCurrentUser
     */
    'user'?: QueryUser;
}
/**
 * 
 * @export
 * @interface UserCurrentUserResponse
 */
export interface UserCurrentUserResponse {
    /**
     * 
     * @type {number}
     * @memberof UserCurrentUserResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserCurrentUserResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof UserCurrentUserResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserCurrentUserResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof UserCurrentUserResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof UserCurrentUserResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof UserCurrentUserResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {UserCurrentUser}
     * @memberof UserCurrentUserResponse
     */
    'result'?: UserCurrentUser;
}
/**
 * 
 * @export
 * @interface UserEmailPasswordResponse
 */
export interface UserEmailPasswordResponse {
    /**
     * 
     * @type {number}
     * @memberof UserEmailPasswordResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserEmailPasswordResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof UserEmailPasswordResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserEmailPasswordResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof UserEmailPasswordResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof UserEmailPasswordResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof UserEmailPasswordResponse
     */
    'record_offset'?: number;
}
/**
 * 
 * @export
 * @interface UserListResponse
 */
export interface UserListResponse {
    /**
     * 
     * @type {number}
     * @memberof UserListResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserListResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof UserListResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserListResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof UserListResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof UserListResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof UserListResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {Array<QueryUser>}
     * @memberof UserListResponse
     */
    'result'?: Array<QueryUser>;
}
/**
 * 
 * @export
 * @interface UserReinviteUserRequest
 */
export interface UserReinviteUserRequest {
    /**
     * 
     * @type {string}
     * @memberof UserReinviteUserRequest
     */
    'organization_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserReinviteUserRequest
     */
    'user_id'?: string;
}
/**
 * 
 * @export
 * @interface UserRequestEmailPasswordResetConfirm
 */
export interface UserRequestEmailPasswordResetConfirm {
    /**
     * 
     * @type {string}
     * @memberof UserRequestEmailPasswordResetConfirm
     */
    'confirm_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserRequestEmailPasswordResetConfirm
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserRequestEmailPasswordResetConfirm
     */
    'password'?: string;
}
/**
 * 
 * @export
 * @interface UserRequestUpdateApnsToken
 */
export interface UserRequestUpdateApnsToken {
    /**
     * 
     * @type {string}
     * @memberof UserRequestUpdateApnsToken
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserRequestUpdateApnsToken
     */
    'token'?: string;
}
/**
 * 
 * @export
 * @interface UserRequestUserObject
 */
export interface UserRequestUserObject {
    /**
     * 
     * @type {boolean}
     * @memberof UserRequestUserObject
     */
    'active'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserRequestUserObject
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserRequestUserObject
     */
    'first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserRequestUserObject
     */
    'id'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserRequestUserObject
     */
    'is_editor'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserRequestUserObject
     */
    'last_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserRequestUserObject
     */
    'organization_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserRequestUserObject
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserRequestUserObject
     */
    'roles'?: string;
}
/**
 * 
 * @export
 * @interface UserRequestUserUpdateObject
 */
export interface UserRequestUserUpdateObject {
    /**
     * 
     * @type {boolean}
     * @memberof UserRequestUserUpdateObject
     */
    'active'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserRequestUserUpdateObject
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserRequestUserUpdateObject
     */
    'first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserRequestUserUpdateObject
     */
    'id'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserRequestUserUpdateObject
     */
    'is_editor'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserRequestUserUpdateObject
     */
    'last_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserRequestUserUpdateObject
     */
    'organization_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserRequestUserUpdateObject
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserRequestUserUpdateObject
     */
    'roles'?: string;
}
/**
 * 
 * @export
 * @interface UserSmsConfirmResponse
 */
export interface UserSmsConfirmResponse {
    /**
     * 
     * @type {number}
     * @memberof UserSmsConfirmResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserSmsConfirmResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof UserSmsConfirmResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserSmsConfirmResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof UserSmsConfirmResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof UserSmsConfirmResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof UserSmsConfirmResponse
     */
    'record_offset'?: number;
}
/**
 * 
 * @export
 * @interface UserUserDependenciesResponse
 */
export interface UserUserDependenciesResponse {
    /**
     * 
     * @type {number}
     * @memberof UserUserDependenciesResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserUserDependenciesResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof UserUserDependenciesResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserUserDependenciesResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof UserUserDependenciesResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof UserUserDependenciesResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof UserUserDependenciesResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {QueryDependencies}
     * @memberof UserUserDependenciesResponse
     */
    'result'?: QueryDependencies;
}
/**
 * 
 * @export
 * @interface UserUserEmailPasswordChangeRequest
 */
export interface UserUserEmailPasswordChangeRequest {
    /**
     * 
     * @type {string}
     * @memberof UserUserEmailPasswordChangeRequest
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUserEmailPasswordChangeRequest
     */
    'new_password'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUserEmailPasswordChangeRequest
     */
    'old_password'?: string;
}
/**
 * 
 * @export
 * @interface UserUserResponse
 */
export interface UserUserResponse {
    /**
     * 
     * @type {number}
     * @memberof UserUserResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserUserResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof UserUserResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserUserResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof UserUserResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof UserUserResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof UserUserResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {QueryUser}
     * @memberof UserUserResponse
     */
    'result'?: QueryUser;
}
/**
 * 
 * @export
 * @interface UserUserSmsCodeRequest
 */
export interface UserUserSmsCodeRequest {
    /**
     * 
     * @type {string}
     * @memberof UserUserSmsCodeRequest
     */
    'phone'?: string;
}
/**
 * 
 * @export
 * @interface UserUserSmsConfirmRequest
 */
export interface UserUserSmsConfirmRequest {
    /**
     * 
     * @type {string}
     * @memberof UserUserSmsConfirmRequest
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUserSmsConfirmRequest
     */
    'password'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUserSmsConfirmRequest
     */
    'phone'?: string;
}
/**
 * 
 * @export
 * @interface UserUserValidateEmailRequest
 */
export interface UserUserValidateEmailRequest {
    /**
     * 
     * @type {string}
     * @memberof UserUserValidateEmailRequest
     */
    'invite_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUserValidateEmailRequest
     */
    'new_password'?: string;
}
/**
 * 
 * @export
 * @interface UserValidateURLRequest
 */
export interface UserValidateURLRequest {
    /**
     * 
     * @type {string}
     * @memberof UserValidateURLRequest
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserValidateURLRequest
     */
    'type'?: string;
}
/**
 * 
 * @export
 * @interface UserValidateURLResponse
 */
export interface UserValidateURLResponse {
    /**
     * 
     * @type {number}
     * @memberof UserValidateURLResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserValidateURLResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof UserValidateURLResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserValidateURLResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof UserValidateURLResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof UserValidateURLResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof UserValidateURLResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UserValidateURLResponse
     */
    'result'?: boolean;
}
/**
 * 
 * @export
 * @interface VehicleCreateResponse
 */
export interface VehicleCreateResponse {
    /**
     * 
     * @type {number}
     * @memberof VehicleCreateResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof VehicleCreateResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof VehicleCreateResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleCreateResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof VehicleCreateResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof VehicleCreateResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof VehicleCreateResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {QueryVehicle}
     * @memberof VehicleCreateResponse
     */
    'result'?: QueryVehicle;
}
/**
 * 
 * @export
 * @interface VehicleDependenciesResponse
 */
export interface VehicleDependenciesResponse {
    /**
     * 
     * @type {number}
     * @memberof VehicleDependenciesResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof VehicleDependenciesResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof VehicleDependenciesResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleDependenciesResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof VehicleDependenciesResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof VehicleDependenciesResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof VehicleDependenciesResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {QueryDependencies}
     * @memberof VehicleDependenciesResponse
     */
    'result'?: QueryDependencies;
}
/**
 * 
 * @export
 * @interface VehicleKpisResponse
 */
export interface VehicleKpisResponse {
    /**
     * 
     * @type {number}
     * @memberof VehicleKpisResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof VehicleKpisResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof VehicleKpisResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleKpisResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof VehicleKpisResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof VehicleKpisResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof VehicleKpisResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {Array<QueryKPI>}
     * @memberof VehicleKpisResponse
     */
    'result'?: Array<QueryKPI>;
}
/**
 * 
 * @export
 * @interface VehicleListResponse
 */
export interface VehicleListResponse {
    /**
     * 
     * @type {number}
     * @memberof VehicleListResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof VehicleListResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof VehicleListResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleListResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof VehicleListResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof VehicleListResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof VehicleListResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {Array<QueryVehicle>}
     * @memberof VehicleListResponse
     */
    'result'?: Array<QueryVehicle>;
}
/**
 * 
 * @export
 * @interface VehicleRequestUpdateVehicle
 */
export interface VehicleRequestUpdateVehicle {
    /**
     * 
     * @type {boolean}
     * @memberof VehicleRequestUpdateVehicle
     */
    'active'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof VehicleRequestUpdateVehicle
     */
    'home_site_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleRequestUpdateVehicle
     */
    'license_plate'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleRequestUpdateVehicle
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleRequestUpdateVehicle
     */
    'vehicle_id'?: string;
}
/**
 * 
 * @export
 * @interface VehicleRequestVehicleWithDevice
 */
export interface VehicleRequestVehicleWithDevice {
    /**
     * 
     * @type {string}
     * @memberof VehicleRequestVehicleWithDevice
     */
    'device_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleRequestVehicleWithDevice
     */
    'home_site_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleRequestVehicleWithDevice
     */
    'license_plate'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleRequestVehicleWithDevice
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleRequestVehicleWithDevice
     */
    'organization_id'?: string;
}
/**
 * 
 * @export
 * @interface VehicleVehicleResponse
 */
export interface VehicleVehicleResponse {
    /**
     * 
     * @type {number}
     * @memberof VehicleVehicleResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof VehicleVehicleResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof VehicleVehicleResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleVehicleResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof VehicleVehicleResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof VehicleVehicleResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof VehicleVehicleResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {QueryVehicle}
     * @memberof VehicleVehicleResponse
     */
    'result'?: QueryVehicle;
}
/**
 * 
 * @export
 * @interface VerticalMarketDependenciesResponse
 */
export interface VerticalMarketDependenciesResponse {
    /**
     * 
     * @type {QueryDependencies}
     * @memberof VerticalMarketDependenciesResponse
     */
    'result'?: QueryDependencies;
}
/**
 * 
 * @export
 * @interface VerticalMarketListResponse
 */
export interface VerticalMarketListResponse {
    /**
     * 
     * @type {number}
     * @memberof VerticalMarketListResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof VerticalMarketListResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof VerticalMarketListResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof VerticalMarketListResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof VerticalMarketListResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof VerticalMarketListResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof VerticalMarketListResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {Array<QueryVerticalMarket>}
     * @memberof VerticalMarketListResponse
     */
    'result'?: Array<QueryVerticalMarket>;
}
/**
 * 
 * @export
 * @interface VerticalMarketManufacturerListResponse
 */
export interface VerticalMarketManufacturerListResponse {
    /**
     * 
     * @type {number}
     * @memberof VerticalMarketManufacturerListResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof VerticalMarketManufacturerListResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof VerticalMarketManufacturerListResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof VerticalMarketManufacturerListResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof VerticalMarketManufacturerListResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof VerticalMarketManufacturerListResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof VerticalMarketManufacturerListResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {Array<QueryManufacturer>}
     * @memberof VerticalMarketManufacturerListResponse
     */
    'result'?: Array<QueryManufacturer>;
}
/**
 * 
 * @export
 * @interface VerticalMarketManufacturerSetRequest
 */
export interface VerticalMarketManufacturerSetRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof VerticalMarketManufacturerSetRequest
     */
    'add'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof VerticalMarketManufacturerSetRequest
     */
    'remove'?: Array<string>;
}
/**
 * 
 * @export
 * @interface VerticalMarketObjectResponse
 */
export interface VerticalMarketObjectResponse {
    /**
     * 
     * @type {number}
     * @memberof VerticalMarketObjectResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof VerticalMarketObjectResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof VerticalMarketObjectResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof VerticalMarketObjectResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof VerticalMarketObjectResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof VerticalMarketObjectResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof VerticalMarketObjectResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {QueryVerticalMarket}
     * @memberof VerticalMarketObjectResponse
     */
    'result'?: QueryVerticalMarket;
}
/**
 * 
 * @export
 * @interface VerticalMarketRequestVerticalMarket
 */
export interface VerticalMarketRequestVerticalMarket {
    /**
     * 
     * @type {boolean}
     * @memberof VerticalMarketRequestVerticalMarket
     */
    'active'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof VerticalMarketRequestVerticalMarket
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface WarehouseAssignDeviceRequest
 */
export interface WarehouseAssignDeviceRequest {
    /**
     * 
     * @type {string}
     * @memberof WarehouseAssignDeviceRequest
     */
    'device_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof WarehouseAssignDeviceRequest
     */
    'warehouse_id'?: string;
}
/**
 * 
 * @export
 * @interface WarehouseListResponse
 */
export interface WarehouseListResponse {
    /**
     * 
     * @type {number}
     * @memberof WarehouseListResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof WarehouseListResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof WarehouseListResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof WarehouseListResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof WarehouseListResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof WarehouseListResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof WarehouseListResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {Array<QueryWarehouse>}
     * @memberof WarehouseListResponse
     */
    'result'?: Array<QueryWarehouse>;
}
/**
 * 
 * @export
 * @interface WarehouseObjectResponse
 */
export interface WarehouseObjectResponse {
    /**
     * 
     * @type {number}
     * @memberof WarehouseObjectResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof WarehouseObjectResponse
     */
    'correlation_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof WarehouseObjectResponse
     */
    'http_code'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof WarehouseObjectResponse
     */
    'messages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof WarehouseObjectResponse
     */
    'record_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof WarehouseObjectResponse
     */
    'record_limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof WarehouseObjectResponse
     */
    'record_offset'?: number;
    /**
     * 
     * @type {ModelWarehouse}
     * @memberof WarehouseObjectResponse
     */
    'result'?: ModelWarehouse;
}

/**
 * AdminApi - axios parameter creator
 * @export
 */
export const AdminApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * get item object by UUID  as admin
         * @summary get item object as admin
         * @param {string} itemId Item ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetItemById: async (itemId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            assertParamExists('adminGetItemById', 'itemId', itemId)
            const localVarPath = `/admin/items/{item_id}`
                .replace(`{${"item_id"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get list of all users from organization
         * @summary get list of all users from organization
         * @param {string} userUuid User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetUser: async (userUuid: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userUuid' is not null or undefined
            assertParamExists('adminGetUser', 'userUuid', userUuid)
            const localVarPath = `/admin/users/details/{user_uuid}`
                .replace(`{${"user_uuid"}}`, encodeURIComponent(String(userUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get list of all users from organization
         * @summary get list of all users from organization
         * @param {string} [id] User ID
         * @param {string} [organizationId] Organization ID
         * @param {string} [role] Role
         * @param {string} [firstName] First Name
         * @param {string} [search] Text wildcard search
         * @param {string} [active] active status of item (0/1 or true/false) - defaults to true
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminListUsers: async (id?: string, organizationId?: string, role?: string, firstName?: string, search?: string, active?: string, sortBy?: string, offset?: number, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (organizationId !== undefined) {
                localVarQueryParameter['organization_id'] = organizationId;
            }

            if (role !== undefined) {
                localVarQueryParameter['role'] = role;
            }

            if (firstName !== undefined) {
                localVarQueryParameter['first_name'] = firstName;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (active !== undefined) {
                localVarQueryParameter['active'] = active;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * update user handler
         * @summary update user record
         * @param {UserRequestUserUpdateObject} user User update request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateUser: async (user: UserRequestUserUpdateObject, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'user' is not null or undefined
            assertParamExists('adminUpdateUser', 'user', user)
            const localVarPath = `/admin/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(user, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get current authorized user
         * @summary get current user
         * @param {string} userUuid User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserDelete: async (userUuid: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userUuid' is not null or undefined
            assertParamExists('adminUserDelete', 'userUuid', userUuid)
            const localVarPath = `/admin/users/details/{user_uuid}`
                .replace(`{${"user_uuid"}}`, encodeURIComponent(String(userUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminApi - functional programming interface
 * @export
 */
export const AdminApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminApiAxiosParamCreator(configuration)
    return {
        /**
         * get item object by UUID  as admin
         * @summary get item object as admin
         * @param {string} itemId Item ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetItemById(itemId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PkgHandlerItemDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetItemById(itemId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AdminApi.adminGetItemById']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * get list of all users from organization
         * @summary get list of all users from organization
         * @param {string} userUuid User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetUser(userUuid: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetUser(userUuid, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AdminApi.adminGetUser']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * get list of all users from organization
         * @summary get list of all users from organization
         * @param {string} [id] User ID
         * @param {string} [organizationId] Organization ID
         * @param {string} [role] Role
         * @param {string} [firstName] First Name
         * @param {string} [search] Text wildcard search
         * @param {string} [active] active status of item (0/1 or true/false) - defaults to true
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminListUsers(id?: string, organizationId?: string, role?: string, firstName?: string, search?: string, active?: string, sortBy?: string, offset?: number, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminListUsers(id, organizationId, role, firstName, search, active, sortBy, offset, limit, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AdminApi.adminListUsers']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * update user handler
         * @summary update user record
         * @param {UserRequestUserUpdateObject} user User update request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUpdateUser(user: UserRequestUserUpdateObject, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUpdateUser(user, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AdminApi.adminUpdateUser']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * get current authorized user
         * @summary get current user
         * @param {string} userUuid User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUserDelete(userUuid: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserUserDependenciesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUserDelete(userUuid, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AdminApi.adminUserDelete']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * AdminApi - factory interface
 * @export
 */
export const AdminApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminApiFp(configuration)
    return {
        /**
         * get item object by UUID  as admin
         * @summary get item object as admin
         * @param {string} itemId Item ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetItemById(itemId: string, options?: any): AxiosPromise<PkgHandlerItemDetailResponse> {
            return localVarFp.adminGetItemById(itemId, options).then((request) => request(axios, basePath));
        },
        /**
         * get list of all users from organization
         * @summary get list of all users from organization
         * @param {string} userUuid User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetUser(userUuid: string, options?: any): AxiosPromise<UserUserResponse> {
            return localVarFp.adminGetUser(userUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * get list of all users from organization
         * @summary get list of all users from organization
         * @param {string} [id] User ID
         * @param {string} [organizationId] Organization ID
         * @param {string} [role] Role
         * @param {string} [firstName] First Name
         * @param {string} [search] Text wildcard search
         * @param {string} [active] active status of item (0/1 or true/false) - defaults to true
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminListUsers(id?: string, organizationId?: string, role?: string, firstName?: string, search?: string, active?: string, sortBy?: string, offset?: number, limit?: number, options?: any): AxiosPromise<UserListResponse> {
            return localVarFp.adminListUsers(id, organizationId, role, firstName, search, active, sortBy, offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * update user handler
         * @summary update user record
         * @param {UserRequestUserUpdateObject} user User update request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateUser(user: UserRequestUserUpdateObject, options?: any): AxiosPromise<UserUserResponse> {
            return localVarFp.adminUpdateUser(user, options).then((request) => request(axios, basePath));
        },
        /**
         * get current authorized user
         * @summary get current user
         * @param {string} userUuid User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserDelete(userUuid: string, options?: any): AxiosPromise<UserUserDependenciesResponse> {
            return localVarFp.adminUserDelete(userUuid, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminApi - object-oriented interface
 * @export
 * @class AdminApi
 * @extends {BaseAPI}
 */
export class AdminApi extends BaseAPI {
    /**
     * get item object by UUID  as admin
     * @summary get item object as admin
     * @param {string} itemId Item ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetItemById(itemId: string, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminGetItemById(itemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get list of all users from organization
     * @summary get list of all users from organization
     * @param {string} userUuid User ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetUser(userUuid: string, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminGetUser(userUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get list of all users from organization
     * @summary get list of all users from organization
     * @param {string} [id] User ID
     * @param {string} [organizationId] Organization ID
     * @param {string} [role] Role
     * @param {string} [firstName] First Name
     * @param {string} [search] Text wildcard search
     * @param {string} [active] active status of item (0/1 or true/false) - defaults to true
     * @param {string} [sortBy] First sort column
     * @param {number} [offset] Offset
     * @param {number} [limit] Limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminListUsers(id?: string, organizationId?: string, role?: string, firstName?: string, search?: string, active?: string, sortBy?: string, offset?: number, limit?: number, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminListUsers(id, organizationId, role, firstName, search, active, sortBy, offset, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * update user handler
     * @summary update user record
     * @param {UserRequestUserUpdateObject} user User update request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminUpdateUser(user: UserRequestUserUpdateObject, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminUpdateUser(user, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get current authorized user
     * @summary get current user
     * @param {string} userUuid User ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminUserDelete(userUuid: string, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminUserDelete(userUuid, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AlertEventsApi - axios parameter creator
 * @export
 */
export const AlertEventsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * set alert event monitors for organization
         * @summary set alert-event-monitors
         * @param {AlertEventMonitorsRequest} user Alert Event Monitors to set
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        alertEventMonitorsSet: async (user: AlertEventMonitorsRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'user' is not null or undefined
            assertParamExists('alertEventMonitorsSet', 'user', user)
            const localVarPath = `/alert-events/monitor`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(user, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * list alert event types
         * @summary list alert event types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        alertEventTypesList: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/alert-events/list-types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * list alert events
         * @summary list alert events
         * @param {string} [targetId] target_id to query
         * @param {string} [processedBegin] Begin time
         * @param {string} [processedEnd] End time
         * @param {number} [eventTypeId] Alert Event Type ID
         * @param {string} [vehicleName] Vehicle Name to filter on
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        alertEventsList: async (targetId?: string, processedBegin?: string, processedEnd?: string, eventTypeId?: number, vehicleName?: string, sortBy?: string, offset?: number, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/alert-events/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (targetId !== undefined) {
                localVarQueryParameter['target_id'] = targetId;
            }

            if (processedBegin !== undefined) {
                localVarQueryParameter['processed_begin'] = processedBegin;
            }

            if (processedEnd !== undefined) {
                localVarQueryParameter['processed_end'] = processedEnd;
            }

            if (eventTypeId !== undefined) {
                localVarQueryParameter['event_type_id'] = eventTypeId;
            }

            if (vehicleName !== undefined) {
                localVarQueryParameter['vehicle_name'] = vehicleName;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * monitor list alert events
         * @summary monitor list alert events
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        alertEventsMonitorsList: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/alert-events/monitor-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AlertEventsApi - functional programming interface
 * @export
 */
export const AlertEventsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AlertEventsApiAxiosParamCreator(configuration)
    return {
        /**
         * set alert event monitors for organization
         * @summary set alert-event-monitors
         * @param {AlertEventMonitorsRequest} user Alert Event Monitors to set
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async alertEventMonitorsSet(user: AlertEventMonitorsRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HandlerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.alertEventMonitorsSet(user, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AlertEventsApi.alertEventMonitorsSet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * list alert event types
         * @summary list alert event types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async alertEventTypesList(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AlertEventTypeListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.alertEventTypesList(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AlertEventsApi.alertEventTypesList']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * list alert events
         * @summary list alert events
         * @param {string} [targetId] target_id to query
         * @param {string} [processedBegin] Begin time
         * @param {string} [processedEnd] End time
         * @param {number} [eventTypeId] Alert Event Type ID
         * @param {string} [vehicleName] Vehicle Name to filter on
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async alertEventsList(targetId?: string, processedBegin?: string, processedEnd?: string, eventTypeId?: number, vehicleName?: string, sortBy?: string, offset?: number, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AlertEventListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.alertEventsList(targetId, processedBegin, processedEnd, eventTypeId, vehicleName, sortBy, offset, limit, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AlertEventsApi.alertEventsList']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * monitor list alert events
         * @summary monitor list alert events
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async alertEventsMonitorsList(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AlertEventMonitorListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.alertEventsMonitorsList(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AlertEventsApi.alertEventsMonitorsList']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * AlertEventsApi - factory interface
 * @export
 */
export const AlertEventsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AlertEventsApiFp(configuration)
    return {
        /**
         * set alert event monitors for organization
         * @summary set alert-event-monitors
         * @param {AlertEventMonitorsRequest} user Alert Event Monitors to set
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        alertEventMonitorsSet(user: AlertEventMonitorsRequest, options?: any): AxiosPromise<HandlerResponse> {
            return localVarFp.alertEventMonitorsSet(user, options).then((request) => request(axios, basePath));
        },
        /**
         * list alert event types
         * @summary list alert event types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        alertEventTypesList(options?: any): AxiosPromise<AlertEventTypeListResponse> {
            return localVarFp.alertEventTypesList(options).then((request) => request(axios, basePath));
        },
        /**
         * list alert events
         * @summary list alert events
         * @param {string} [targetId] target_id to query
         * @param {string} [processedBegin] Begin time
         * @param {string} [processedEnd] End time
         * @param {number} [eventTypeId] Alert Event Type ID
         * @param {string} [vehicleName] Vehicle Name to filter on
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        alertEventsList(targetId?: string, processedBegin?: string, processedEnd?: string, eventTypeId?: number, vehicleName?: string, sortBy?: string, offset?: number, limit?: number, options?: any): AxiosPromise<AlertEventListResponse> {
            return localVarFp.alertEventsList(targetId, processedBegin, processedEnd, eventTypeId, vehicleName, sortBy, offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * monitor list alert events
         * @summary monitor list alert events
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        alertEventsMonitorsList(options?: any): AxiosPromise<AlertEventMonitorListResponse> {
            return localVarFp.alertEventsMonitorsList(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AlertEventsApi - object-oriented interface
 * @export
 * @class AlertEventsApi
 * @extends {BaseAPI}
 */
export class AlertEventsApi extends BaseAPI {
    /**
     * set alert event monitors for organization
     * @summary set alert-event-monitors
     * @param {AlertEventMonitorsRequest} user Alert Event Monitors to set
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlertEventsApi
     */
    public alertEventMonitorsSet(user: AlertEventMonitorsRequest, options?: RawAxiosRequestConfig) {
        return AlertEventsApiFp(this.configuration).alertEventMonitorsSet(user, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * list alert event types
     * @summary list alert event types
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlertEventsApi
     */
    public alertEventTypesList(options?: RawAxiosRequestConfig) {
        return AlertEventsApiFp(this.configuration).alertEventTypesList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * list alert events
     * @summary list alert events
     * @param {string} [targetId] target_id to query
     * @param {string} [processedBegin] Begin time
     * @param {string} [processedEnd] End time
     * @param {number} [eventTypeId] Alert Event Type ID
     * @param {string} [vehicleName] Vehicle Name to filter on
     * @param {string} [sortBy] First sort column
     * @param {number} [offset] Offset
     * @param {number} [limit] Limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlertEventsApi
     */
    public alertEventsList(targetId?: string, processedBegin?: string, processedEnd?: string, eventTypeId?: number, vehicleName?: string, sortBy?: string, offset?: number, limit?: number, options?: RawAxiosRequestConfig) {
        return AlertEventsApiFp(this.configuration).alertEventsList(targetId, processedBegin, processedEnd, eventTypeId, vehicleName, sortBy, offset, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * monitor list alert events
     * @summary monitor list alert events
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlertEventsApi
     */
    public alertEventsMonitorsList(options?: RawAxiosRequestConfig) {
        return AlertEventsApiFp(this.configuration).alertEventsMonitorsList(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AssignmentsApi - axios parameter creator
 * @export
 */
export const AssignmentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * create assignment
         * @summary create assignment
         * @param {AssignmentCreateRequest} assignment Create assignment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAssignment: async (assignment: AssignmentCreateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assignment' is not null or undefined
            assertParamExists('createAssignment', 'assignment', assignment)
            const localVarPath = `/assignments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(assignment, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * delete assignment by ID
         * @summary delete assignment by ID
         * @param {string} assignmentId Assignment ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAssignment: async (assignmentId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assignmentId' is not null or undefined
            assertParamExists('deleteAssignment', 'assignmentId', assignmentId)
            const localVarPath = `/assignments/{assignment_id}`
                .replace(`{${"assignment_id"}}`, encodeURIComponent(String(assignmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * deliver assignment to jobs ite
         * @summary deliver assignment to jobs ite
         * @param {string} assignmentId Assignment ID
         * @param {AssignmentDeliveryOptionsRequest} [deliveryOptions] delivery options
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deliverAssignment: async (assignmentId: string, deliveryOptions?: AssignmentDeliveryOptionsRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assignmentId' is not null or undefined
            assertParamExists('deliverAssignment', 'assignmentId', assignmentId)
            const localVarPath = `/assignments/{assignment_id}`
                .replace(`{${"assignment_id"}}`, encodeURIComponent(String(assignmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deliveryOptions, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * deliver item to job site
         * @summary deliver item to job site
         * @param {string} itemId Item ID
         * @param {AssignmentRequestItemDeliver} deliveryTarget deliver item to job_site
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deliverItem: async (itemId: string, deliveryTarget: AssignmentRequestItemDeliver, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            assertParamExists('deliverItem', 'itemId', itemId)
            // verify required parameter 'deliveryTarget' is not null or undefined
            assertParamExists('deliverItem', 'deliveryTarget', deliveryTarget)
            const localVarPath = `/assignments/items/{item_id}/deliver`
                .replace(`{${"item_id"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deliveryTarget, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * deliver item to yard
         * @summary deliver item to yard
         * @param {string} itemId Item ID
         * @param {AssignmentRequestItemDeliver} deliveryTarget deliver item to yard
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deliverItemHome: async (itemId: string, deliveryTarget: AssignmentRequestItemDeliver, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            assertParamExists('deliverItemHome', 'itemId', itemId)
            // verify required parameter 'deliveryTarget' is not null or undefined
            assertParamExists('deliverItemHome', 'deliveryTarget', deliveryTarget)
            const localVarPath = `/assignments/items/{item_id}/deliver-home`
                .replace(`{${"item_id"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deliveryTarget, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get assignment object by ID
         * @summary get assignment object
         * @param {string} assignmentId Assignment ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssignmentById: async (assignmentId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assignmentId' is not null or undefined
            assertParamExists('getAssignmentById', 'assignmentId', assignmentId)
            const localVarPath = `/assignments/{assignment_id}`
                .replace(`{${"assignment_id"}}`, encodeURIComponent(String(assignmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * query assignment kpis
         * @summary query assignment kpis
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssignmentKpis: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/assignments/kpis`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get job site assignment
         * @summary get job site assignment
         * @param {string} siteId Job Site ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJobsiteAssignment: async (siteId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'siteId' is not null or undefined
            assertParamExists('getJobsiteAssignment', 'siteId', siteId)
            const localVarPath = `/sites/{site_id}/assignment`
                .replace(`{${"site_id"}}`, encodeURIComponent(String(siteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get list of all assignment
         * @summary get list of all assignments
         * @param {boolean} [delivered] delivered status of item (0/1 or true/false) - defaults to true
         * @param {string} [deliveredBegin] Begin time (2006-01-02 15:04)
         * @param {string} [deliveredEnd] End time (2006-01-02 15:04)
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAssignments: async (delivered?: boolean, deliveredBegin?: string, deliveredEnd?: string, sortBy?: string, offset?: number, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/assignments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (delivered !== undefined) {
                localVarQueryParameter['delivered'] = delivered;
            }

            if (deliveredBegin !== undefined) {
                localVarQueryParameter['delivered_begin'] = deliveredBegin;
            }

            if (deliveredEnd !== undefined) {
                localVarQueryParameter['delivered_end'] = deliveredEnd;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * lookup item for assignment object
         * @summary lookup item for assignment
         * @param {string} assignmentId Assignment ID
         * @param {string} identifier tag or identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lookupAssignmentItem: async (assignmentId: string, identifier: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assignmentId' is not null or undefined
            assertParamExists('lookupAssignmentItem', 'assignmentId', assignmentId)
            // verify required parameter 'identifier' is not null or undefined
            assertParamExists('lookupAssignmentItem', 'identifier', identifier)
            const localVarPath = `/assignments/{assignment_id}/lookup`
                .replace(`{${"assignment_id"}}`, encodeURIComponent(String(assignmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (identifier !== undefined) {
                localVarQueryParameter['identifier'] = identifier;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * update assignment object
         * @summary update assignment
         * @param {AssignmentRequestUpdate} assignment assignment object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAssignment: async (assignment: AssignmentRequestUpdate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assignment' is not null or undefined
            assertParamExists('updateAssignment', 'assignment', assignment)
            const localVarPath = `/assignments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(assignment, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AssignmentsApi - functional programming interface
 * @export
 */
export const AssignmentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AssignmentsApiAxiosParamCreator(configuration)
    return {
        /**
         * create assignment
         * @summary create assignment
         * @param {AssignmentCreateRequest} assignment Create assignment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAssignment(assignment: AssignmentCreateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssignmentAssignmentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAssignment(assignment, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AssignmentsApi.createAssignment']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * delete assignment by ID
         * @summary delete assignment by ID
         * @param {string} assignmentId Assignment ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAssignment(assignmentId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HandlerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAssignment(assignmentId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AssignmentsApi.deleteAssignment']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * deliver assignment to jobs ite
         * @summary deliver assignment to jobs ite
         * @param {string} assignmentId Assignment ID
         * @param {AssignmentDeliveryOptionsRequest} [deliveryOptions] delivery options
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deliverAssignment(assignmentId: string, deliveryOptions?: AssignmentDeliveryOptionsRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssignmentDeliveryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deliverAssignment(assignmentId, deliveryOptions, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AssignmentsApi.deliverAssignment']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * deliver item to job site
         * @summary deliver item to job site
         * @param {string} itemId Item ID
         * @param {AssignmentRequestItemDeliver} deliveryTarget deliver item to job_site
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deliverItem(itemId: string, deliveryTarget: AssignmentRequestItemDeliver, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssignmentDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deliverItem(itemId, deliveryTarget, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AssignmentsApi.deliverItem']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * deliver item to yard
         * @summary deliver item to yard
         * @param {string} itemId Item ID
         * @param {AssignmentRequestItemDeliver} deliveryTarget deliver item to yard
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deliverItemHome(itemId: string, deliveryTarget: AssignmentRequestItemDeliver, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssignmentDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deliverItemHome(itemId, deliveryTarget, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AssignmentsApi.deliverItemHome']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * get assignment object by ID
         * @summary get assignment object
         * @param {string} assignmentId Assignment ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAssignmentById(assignmentId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssignmentAssignmentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAssignmentById(assignmentId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AssignmentsApi.getAssignmentById']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * query assignment kpis
         * @summary query assignment kpis
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAssignmentKpis(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssignmentKPIsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAssignmentKpis(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AssignmentsApi.getAssignmentKpis']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * get job site assignment
         * @summary get job site assignment
         * @param {string} siteId Job Site ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getJobsiteAssignment(siteId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssignmentAssignmentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getJobsiteAssignment(siteId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AssignmentsApi.getJobsiteAssignment']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * get list of all assignment
         * @summary get list of all assignments
         * @param {boolean} [delivered] delivered status of item (0/1 or true/false) - defaults to true
         * @param {string} [deliveredBegin] Begin time (2006-01-02 15:04)
         * @param {string} [deliveredEnd] End time (2006-01-02 15:04)
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAssignments(delivered?: boolean, deliveredBegin?: string, deliveredEnd?: string, sortBy?: string, offset?: number, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssignmentListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAssignments(delivered, deliveredBegin, deliveredEnd, sortBy, offset, limit, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AssignmentsApi.listAssignments']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * lookup item for assignment object
         * @summary lookup item for assignment
         * @param {string} assignmentId Assignment ID
         * @param {string} identifier tag or identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async lookupAssignmentItem(assignmentId: string, identifier: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssignmentAssignmentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.lookupAssignmentItem(assignmentId, identifier, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AssignmentsApi.lookupAssignmentItem']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * update assignment object
         * @summary update assignment
         * @param {AssignmentRequestUpdate} assignment assignment object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAssignment(assignment: AssignmentRequestUpdate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssignmentAssignmentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAssignment(assignment, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AssignmentsApi.updateAssignment']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * AssignmentsApi - factory interface
 * @export
 */
export const AssignmentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AssignmentsApiFp(configuration)
    return {
        /**
         * create assignment
         * @summary create assignment
         * @param {AssignmentCreateRequest} assignment Create assignment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAssignment(assignment: AssignmentCreateRequest, options?: any): AxiosPromise<AssignmentAssignmentResponse> {
            return localVarFp.createAssignment(assignment, options).then((request) => request(axios, basePath));
        },
        /**
         * delete assignment by ID
         * @summary delete assignment by ID
         * @param {string} assignmentId Assignment ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAssignment(assignmentId: string, options?: any): AxiosPromise<HandlerResponse> {
            return localVarFp.deleteAssignment(assignmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * deliver assignment to jobs ite
         * @summary deliver assignment to jobs ite
         * @param {string} assignmentId Assignment ID
         * @param {AssignmentDeliveryOptionsRequest} [deliveryOptions] delivery options
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deliverAssignment(assignmentId: string, deliveryOptions?: AssignmentDeliveryOptionsRequest, options?: any): AxiosPromise<AssignmentDeliveryResponse> {
            return localVarFp.deliverAssignment(assignmentId, deliveryOptions, options).then((request) => request(axios, basePath));
        },
        /**
         * deliver item to job site
         * @summary deliver item to job site
         * @param {string} itemId Item ID
         * @param {AssignmentRequestItemDeliver} deliveryTarget deliver item to job_site
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deliverItem(itemId: string, deliveryTarget: AssignmentRequestItemDeliver, options?: any): AxiosPromise<AssignmentDetailResponse> {
            return localVarFp.deliverItem(itemId, deliveryTarget, options).then((request) => request(axios, basePath));
        },
        /**
         * deliver item to yard
         * @summary deliver item to yard
         * @param {string} itemId Item ID
         * @param {AssignmentRequestItemDeliver} deliveryTarget deliver item to yard
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deliverItemHome(itemId: string, deliveryTarget: AssignmentRequestItemDeliver, options?: any): AxiosPromise<AssignmentDetailResponse> {
            return localVarFp.deliverItemHome(itemId, deliveryTarget, options).then((request) => request(axios, basePath));
        },
        /**
         * get assignment object by ID
         * @summary get assignment object
         * @param {string} assignmentId Assignment ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssignmentById(assignmentId: string, options?: any): AxiosPromise<AssignmentAssignmentResponse> {
            return localVarFp.getAssignmentById(assignmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * query assignment kpis
         * @summary query assignment kpis
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssignmentKpis(options?: any): AxiosPromise<AssignmentKPIsResponse> {
            return localVarFp.getAssignmentKpis(options).then((request) => request(axios, basePath));
        },
        /**
         * get job site assignment
         * @summary get job site assignment
         * @param {string} siteId Job Site ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJobsiteAssignment(siteId: string, options?: any): AxiosPromise<AssignmentAssignmentResponse> {
            return localVarFp.getJobsiteAssignment(siteId, options).then((request) => request(axios, basePath));
        },
        /**
         * get list of all assignment
         * @summary get list of all assignments
         * @param {boolean} [delivered] delivered status of item (0/1 or true/false) - defaults to true
         * @param {string} [deliveredBegin] Begin time (2006-01-02 15:04)
         * @param {string} [deliveredEnd] End time (2006-01-02 15:04)
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAssignments(delivered?: boolean, deliveredBegin?: string, deliveredEnd?: string, sortBy?: string, offset?: number, limit?: number, options?: any): AxiosPromise<AssignmentListResponse> {
            return localVarFp.listAssignments(delivered, deliveredBegin, deliveredEnd, sortBy, offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * lookup item for assignment object
         * @summary lookup item for assignment
         * @param {string} assignmentId Assignment ID
         * @param {string} identifier tag or identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lookupAssignmentItem(assignmentId: string, identifier: string, options?: any): AxiosPromise<AssignmentAssignmentResponse> {
            return localVarFp.lookupAssignmentItem(assignmentId, identifier, options).then((request) => request(axios, basePath));
        },
        /**
         * update assignment object
         * @summary update assignment
         * @param {AssignmentRequestUpdate} assignment assignment object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAssignment(assignment: AssignmentRequestUpdate, options?: any): AxiosPromise<AssignmentAssignmentResponse> {
            return localVarFp.updateAssignment(assignment, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AssignmentsApi - object-oriented interface
 * @export
 * @class AssignmentsApi
 * @extends {BaseAPI}
 */
export class AssignmentsApi extends BaseAPI {
    /**
     * create assignment
     * @summary create assignment
     * @param {AssignmentCreateRequest} assignment Create assignment
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssignmentsApi
     */
    public createAssignment(assignment: AssignmentCreateRequest, options?: RawAxiosRequestConfig) {
        return AssignmentsApiFp(this.configuration).createAssignment(assignment, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * delete assignment by ID
     * @summary delete assignment by ID
     * @param {string} assignmentId Assignment ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssignmentsApi
     */
    public deleteAssignment(assignmentId: string, options?: RawAxiosRequestConfig) {
        return AssignmentsApiFp(this.configuration).deleteAssignment(assignmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * deliver assignment to jobs ite
     * @summary deliver assignment to jobs ite
     * @param {string} assignmentId Assignment ID
     * @param {AssignmentDeliveryOptionsRequest} [deliveryOptions] delivery options
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssignmentsApi
     */
    public deliverAssignment(assignmentId: string, deliveryOptions?: AssignmentDeliveryOptionsRequest, options?: RawAxiosRequestConfig) {
        return AssignmentsApiFp(this.configuration).deliverAssignment(assignmentId, deliveryOptions, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * deliver item to job site
     * @summary deliver item to job site
     * @param {string} itemId Item ID
     * @param {AssignmentRequestItemDeliver} deliveryTarget deliver item to job_site
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssignmentsApi
     */
    public deliverItem(itemId: string, deliveryTarget: AssignmentRequestItemDeliver, options?: RawAxiosRequestConfig) {
        return AssignmentsApiFp(this.configuration).deliverItem(itemId, deliveryTarget, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * deliver item to yard
     * @summary deliver item to yard
     * @param {string} itemId Item ID
     * @param {AssignmentRequestItemDeliver} deliveryTarget deliver item to yard
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssignmentsApi
     */
    public deliverItemHome(itemId: string, deliveryTarget: AssignmentRequestItemDeliver, options?: RawAxiosRequestConfig) {
        return AssignmentsApiFp(this.configuration).deliverItemHome(itemId, deliveryTarget, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get assignment object by ID
     * @summary get assignment object
     * @param {string} assignmentId Assignment ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssignmentsApi
     */
    public getAssignmentById(assignmentId: string, options?: RawAxiosRequestConfig) {
        return AssignmentsApiFp(this.configuration).getAssignmentById(assignmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * query assignment kpis
     * @summary query assignment kpis
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssignmentsApi
     */
    public getAssignmentKpis(options?: RawAxiosRequestConfig) {
        return AssignmentsApiFp(this.configuration).getAssignmentKpis(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get job site assignment
     * @summary get job site assignment
     * @param {string} siteId Job Site ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssignmentsApi
     */
    public getJobsiteAssignment(siteId: string, options?: RawAxiosRequestConfig) {
        return AssignmentsApiFp(this.configuration).getJobsiteAssignment(siteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get list of all assignment
     * @summary get list of all assignments
     * @param {boolean} [delivered] delivered status of item (0/1 or true/false) - defaults to true
     * @param {string} [deliveredBegin] Begin time (2006-01-02 15:04)
     * @param {string} [deliveredEnd] End time (2006-01-02 15:04)
     * @param {string} [sortBy] First sort column
     * @param {number} [offset] Offset
     * @param {number} [limit] Limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssignmentsApi
     */
    public listAssignments(delivered?: boolean, deliveredBegin?: string, deliveredEnd?: string, sortBy?: string, offset?: number, limit?: number, options?: RawAxiosRequestConfig) {
        return AssignmentsApiFp(this.configuration).listAssignments(delivered, deliveredBegin, deliveredEnd, sortBy, offset, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * lookup item for assignment object
     * @summary lookup item for assignment
     * @param {string} assignmentId Assignment ID
     * @param {string} identifier tag or identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssignmentsApi
     */
    public lookupAssignmentItem(assignmentId: string, identifier: string, options?: RawAxiosRequestConfig) {
        return AssignmentsApiFp(this.configuration).lookupAssignmentItem(assignmentId, identifier, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * update assignment object
     * @summary update assignment
     * @param {AssignmentRequestUpdate} assignment assignment object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssignmentsApi
     */
    public updateAssignment(assignment: AssignmentRequestUpdate, options?: RawAxiosRequestConfig) {
        return AssignmentsApiFp(this.configuration).updateAssignment(assignment, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * login user and return JWT token and organization
         * @summary login user by email or phone
         * @param {AuthLoginRequest} user Login user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginUser: async (user: AuthLoginRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'user' is not null or undefined
            assertParamExists('loginUser', 'user', user)
            const localVarPath = `/auth/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(user, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * logout user from the site
         * @summary logout user from the site
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logoutUser: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * refresh JWT token
         * @summary refresh JWT token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshJwtToken: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/refresh`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
    return {
        /**
         * login user and return JWT token and organization
         * @summary login user by email or phone
         * @param {AuthLoginRequest} user Login user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginUser(user: AuthLoginRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthJWTResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginUser(user, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AuthApi.loginUser']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * logout user from the site
         * @summary logout user from the site
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logoutUser(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HandlerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logoutUser(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AuthApi.logoutUser']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * refresh JWT token
         * @summary refresh JWT token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refreshJwtToken(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthJWTResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refreshJwtToken(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AuthApi.refreshJwtToken']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthApiFp(configuration)
    return {
        /**
         * login user and return JWT token and organization
         * @summary login user by email or phone
         * @param {AuthLoginRequest} user Login user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginUser(user: AuthLoginRequest, options?: any): AxiosPromise<AuthJWTResponse> {
            return localVarFp.loginUser(user, options).then((request) => request(axios, basePath));
        },
        /**
         * logout user from the site
         * @summary logout user from the site
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logoutUser(options?: any): AxiosPromise<HandlerResponse> {
            return localVarFp.logoutUser(options).then((request) => request(axios, basePath));
        },
        /**
         * refresh JWT token
         * @summary refresh JWT token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshJwtToken(options?: any): AxiosPromise<AuthJWTResponse> {
            return localVarFp.refreshJwtToken(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * login user and return JWT token and organization
     * @summary login user by email or phone
     * @param {AuthLoginRequest} user Login user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public loginUser(user: AuthLoginRequest, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).loginUser(user, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * logout user from the site
     * @summary logout user from the site
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public logoutUser(options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).logoutUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * refresh JWT token
     * @summary refresh JWT token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public refreshJwtToken(options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).refreshJwtToken(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CustomersApi - axios parameter creator
 * @export
 */
export const CustomersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * create customer object
         * @summary create customer
         * @param {CustomerCustomerRequest} customer CustomerRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCustomer: async (customer: CustomerCustomerRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customer' is not null or undefined
            assertParamExists('createCustomer', 'customer', customer)
            const localVarPath = `/customers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customer, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * delete customer by UUID
         * @summary delete organization by UUID
         * @param {string} customerUuid Customer ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCustomer: async (customerUuid: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerUuid' is not null or undefined
            assertParamExists('deleteCustomer', 'customerUuid', customerUuid)
            const localVarPath = `/customers/{customer_uuid}`
                .replace(`{${"customer_uuid"}}`, encodeURIComponent(String(customerUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get customer object by UUID
         * @summary get customer object
         * @param {string} customerUuid Customer ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerByUuid: async (customerUuid: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerUuid' is not null or undefined
            assertParamExists('getCustomerByUuid', 'customerUuid', customerUuid)
            const localVarPath = `/customers/id/{customer_uuid}`
                .replace(`{${"customer_uuid"}}`, encodeURIComponent(String(customerUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get customer dependencies
         * @summary Check for dependencies on customer
         * @param {string} customerId Customer ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerDependencies: async (customerId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('getCustomerDependencies', 'customerId', customerId)
            const localVarPath = `/customers/dependencies/{customer_id}`
                .replace(`{${"customer_id"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get list of all customers
         * @summary get list of all customers
         * @param {string} organizationUuid Organization ID
         * @param {string} [search] Text wildcard search
         * @param {string} [active] active status of item (0/1 or true/false) - defaults to true
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCustomers: async (organizationUuid: string, search?: string, active?: string, sortBy?: string, offset?: number, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationUuid' is not null or undefined
            assertParamExists('listCustomers', 'organizationUuid', organizationUuid)
            const localVarPath = `/customers/all/{organization_uuid}`
                .replace(`{${"organization_uuid"}}`, encodeURIComponent(String(organizationUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (active !== undefined) {
                localVarQueryParameter['active'] = active;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * update customer object
         * @summary update customer
         * @param {CustomerCustomerRequest} customer CustomerRequest object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomer: async (customer: CustomerCustomerRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customer' is not null or undefined
            assertParamExists('updateCustomer', 'customer', customer)
            const localVarPath = `/customers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customer, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomersApi - functional programming interface
 * @export
 */
export const CustomersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomersApiAxiosParamCreator(configuration)
    return {
        /**
         * create customer object
         * @summary create customer
         * @param {CustomerCustomerRequest} customer CustomerRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCustomer(customer: CustomerCustomerRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerCustomerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCustomer(customer, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CustomersApi.createCustomer']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * delete customer by UUID
         * @summary delete organization by UUID
         * @param {string} customerUuid Customer ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCustomer(customerUuid: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerCustomerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCustomer(customerUuid, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CustomersApi.deleteCustomer']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * get customer object by UUID
         * @summary get customer object
         * @param {string} customerUuid Customer ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomerByUuid(customerUuid: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerCustomerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomerByUuid(customerUuid, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CustomersApi.getCustomerByUuid']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * get customer dependencies
         * @summary Check for dependencies on customer
         * @param {string} customerId Customer ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomerDependencies(customerId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerDependenciesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomerDependencies(customerId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CustomersApi.getCustomerDependencies']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * get list of all customers
         * @summary get list of all customers
         * @param {string} organizationUuid Organization ID
         * @param {string} [search] Text wildcard search
         * @param {string} [active] active status of item (0/1 or true/false) - defaults to true
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCustomers(organizationUuid: string, search?: string, active?: string, sortBy?: string, offset?: number, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PkgHandlerCustomerListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCustomers(organizationUuid, search, active, sortBy, offset, limit, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CustomersApi.listCustomers']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * update customer object
         * @summary update customer
         * @param {CustomerCustomerRequest} customer CustomerRequest object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCustomer(customer: CustomerCustomerRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerCustomerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCustomer(customer, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CustomersApi.updateCustomer']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * CustomersApi - factory interface
 * @export
 */
export const CustomersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomersApiFp(configuration)
    return {
        /**
         * create customer object
         * @summary create customer
         * @param {CustomerCustomerRequest} customer CustomerRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCustomer(customer: CustomerCustomerRequest, options?: any): AxiosPromise<CustomerCustomerResponse> {
            return localVarFp.createCustomer(customer, options).then((request) => request(axios, basePath));
        },
        /**
         * delete customer by UUID
         * @summary delete organization by UUID
         * @param {string} customerUuid Customer ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCustomer(customerUuid: string, options?: any): AxiosPromise<CustomerCustomerResponse> {
            return localVarFp.deleteCustomer(customerUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * get customer object by UUID
         * @summary get customer object
         * @param {string} customerUuid Customer ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerByUuid(customerUuid: string, options?: any): AxiosPromise<CustomerCustomerResponse> {
            return localVarFp.getCustomerByUuid(customerUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * get customer dependencies
         * @summary Check for dependencies on customer
         * @param {string} customerId Customer ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerDependencies(customerId: string, options?: any): AxiosPromise<CustomerDependenciesResponse> {
            return localVarFp.getCustomerDependencies(customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * get list of all customers
         * @summary get list of all customers
         * @param {string} organizationUuid Organization ID
         * @param {string} [search] Text wildcard search
         * @param {string} [active] active status of item (0/1 or true/false) - defaults to true
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCustomers(organizationUuid: string, search?: string, active?: string, sortBy?: string, offset?: number, limit?: number, options?: any): AxiosPromise<PkgHandlerCustomerListResponse> {
            return localVarFp.listCustomers(organizationUuid, search, active, sortBy, offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * update customer object
         * @summary update customer
         * @param {CustomerCustomerRequest} customer CustomerRequest object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomer(customer: CustomerCustomerRequest, options?: any): AxiosPromise<CustomerCustomerResponse> {
            return localVarFp.updateCustomer(customer, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CustomersApi - object-oriented interface
 * @export
 * @class CustomersApi
 * @extends {BaseAPI}
 */
export class CustomersApi extends BaseAPI {
    /**
     * create customer object
     * @summary create customer
     * @param {CustomerCustomerRequest} customer CustomerRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public createCustomer(customer: CustomerCustomerRequest, options?: RawAxiosRequestConfig) {
        return CustomersApiFp(this.configuration).createCustomer(customer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * delete customer by UUID
     * @summary delete organization by UUID
     * @param {string} customerUuid Customer ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public deleteCustomer(customerUuid: string, options?: RawAxiosRequestConfig) {
        return CustomersApiFp(this.configuration).deleteCustomer(customerUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get customer object by UUID
     * @summary get customer object
     * @param {string} customerUuid Customer ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public getCustomerByUuid(customerUuid: string, options?: RawAxiosRequestConfig) {
        return CustomersApiFp(this.configuration).getCustomerByUuid(customerUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get customer dependencies
     * @summary Check for dependencies on customer
     * @param {string} customerId Customer ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public getCustomerDependencies(customerId: string, options?: RawAxiosRequestConfig) {
        return CustomersApiFp(this.configuration).getCustomerDependencies(customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get list of all customers
     * @summary get list of all customers
     * @param {string} organizationUuid Organization ID
     * @param {string} [search] Text wildcard search
     * @param {string} [active] active status of item (0/1 or true/false) - defaults to true
     * @param {string} [sortBy] First sort column
     * @param {number} [offset] Offset
     * @param {number} [limit] Limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public listCustomers(organizationUuid: string, search?: string, active?: string, sortBy?: string, offset?: number, limit?: number, options?: RawAxiosRequestConfig) {
        return CustomersApiFp(this.configuration).listCustomers(organizationUuid, search, active, sortBy, offset, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * update customer object
     * @summary update customer
     * @param {CustomerCustomerRequest} customer CustomerRequest object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public updateCustomer(customer: CustomerCustomerRequest, options?: RawAxiosRequestConfig) {
        return CustomersApiFp(this.configuration).updateCustomer(customer, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DashboardApi - axios parameter creator
 * @export
 */
export const DashboardApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * activity summary
         * @summary activity summary
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activitySummary: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/dashboards/activity-summary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * container
         * @summary container summary
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        containerSummary: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/dashboards/container-summary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * list alert events for feed
         * @summary list alert events for feed
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardAlertEvents: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/dashboard/alert-events`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get KPIs for the dashboard by roles
         * @summary get KPIs for the dashboard by roles
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKpisByRoles: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/dashboards/kpis-by-roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get PKIs for the dashboard
         * @summary get PKIs for dashboard
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPkis: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/dashboards/kpis`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * group summary of item locations
         * @summary group summary of item locations
         * @param {string} [productId] product_id to query
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupSummary: async (productId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/dashboards/group-summary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (productId !== undefined) {
                localVarQueryParameter['product_id'] = productId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * single vehicle dashboard
         * @summary single vehicle dashboard
         * @param {string} ownerId Owner ID (vehicle_id)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vehicleSummary: async (ownerId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ownerId' is not null or undefined
            assertParamExists('vehicleSummary', 'ownerId', ownerId)
            const localVarPath = `/dashboards/vehicles/{owner_id}`
                .replace(`{${"owner_id"}}`, encodeURIComponent(String(ownerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * all vehicles dashboard
         * @summary all vehicles dashboard
         * @param {number} [payloadTypeId] Payload Type ID
         * @param {number} [warehouseTypeId] Warehouse Type ID
         * @param {string} [active] Active flag (true, false)
         * @param {string} [search] Text wildcard search
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vehiclesDashboard: async (payloadTypeId?: number, warehouseTypeId?: number, active?: string, search?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/dashboards/all_vehicles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (payloadTypeId !== undefined) {
                localVarQueryParameter['payload_type_id'] = payloadTypeId;
            }

            if (warehouseTypeId !== undefined) {
                localVarQueryParameter['warehouse_type_id'] = warehouseTypeId;
            }

            if (active !== undefined) {
                localVarQueryParameter['active'] = active;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DashboardApi - functional programming interface
 * @export
 */
export const DashboardApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DashboardApiAxiosParamCreator(configuration)
    return {
        /**
         * activity summary
         * @summary activity summary
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activitySummary(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DashboardActivitySummaryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activitySummary(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DashboardApi.activitySummary']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * container
         * @summary container summary
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async containerSummary(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DashboardGroupSummaryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.containerSummary(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DashboardApi.containerSummary']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * list alert events for feed
         * @summary list alert events for feed
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dashboardAlertEvents(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DashboardAlertEventsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dashboardAlertEvents(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DashboardApi.dashboardAlertEvents']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * get KPIs for the dashboard by roles
         * @summary get KPIs for the dashboard by roles
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getKpisByRoles(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DashboardKpisResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getKpisByRoles(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DashboardApi.getKpisByRoles']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * get PKIs for the dashboard
         * @summary get PKIs for dashboard
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPkis(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DashboardKpisResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPkis(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DashboardApi.getPkis']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * group summary of item locations
         * @summary group summary of item locations
         * @param {string} [productId] product_id to query
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async groupSummary(productId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DashboardGroupSummaryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.groupSummary(productId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DashboardApi.groupSummary']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * single vehicle dashboard
         * @summary single vehicle dashboard
         * @param {string} ownerId Owner ID (vehicle_id)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async vehicleSummary(ownerId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DashboardSummaryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.vehicleSummary(ownerId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DashboardApi.vehicleSummary']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * all vehicles dashboard
         * @summary all vehicles dashboard
         * @param {number} [payloadTypeId] Payload Type ID
         * @param {number} [warehouseTypeId] Warehouse Type ID
         * @param {string} [active] Active flag (true, false)
         * @param {string} [search] Text wildcard search
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async vehiclesDashboard(payloadTypeId?: number, warehouseTypeId?: number, active?: string, search?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DashboardAllSummaryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.vehiclesDashboard(payloadTypeId, warehouseTypeId, active, search, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DashboardApi.vehiclesDashboard']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * DashboardApi - factory interface
 * @export
 */
export const DashboardApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DashboardApiFp(configuration)
    return {
        /**
         * activity summary
         * @summary activity summary
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activitySummary(options?: any): AxiosPromise<DashboardActivitySummaryResponse> {
            return localVarFp.activitySummary(options).then((request) => request(axios, basePath));
        },
        /**
         * container
         * @summary container summary
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        containerSummary(options?: any): AxiosPromise<DashboardGroupSummaryResponse> {
            return localVarFp.containerSummary(options).then((request) => request(axios, basePath));
        },
        /**
         * list alert events for feed
         * @summary list alert events for feed
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardAlertEvents(options?: any): AxiosPromise<DashboardAlertEventsResponse> {
            return localVarFp.dashboardAlertEvents(options).then((request) => request(axios, basePath));
        },
        /**
         * get KPIs for the dashboard by roles
         * @summary get KPIs for the dashboard by roles
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKpisByRoles(options?: any): AxiosPromise<DashboardKpisResponse> {
            return localVarFp.getKpisByRoles(options).then((request) => request(axios, basePath));
        },
        /**
         * get PKIs for the dashboard
         * @summary get PKIs for dashboard
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPkis(options?: any): AxiosPromise<DashboardKpisResponse> {
            return localVarFp.getPkis(options).then((request) => request(axios, basePath));
        },
        /**
         * group summary of item locations
         * @summary group summary of item locations
         * @param {string} [productId] product_id to query
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupSummary(productId?: string, options?: any): AxiosPromise<DashboardGroupSummaryResponse> {
            return localVarFp.groupSummary(productId, options).then((request) => request(axios, basePath));
        },
        /**
         * single vehicle dashboard
         * @summary single vehicle dashboard
         * @param {string} ownerId Owner ID (vehicle_id)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vehicleSummary(ownerId: string, options?: any): AxiosPromise<DashboardSummaryResponse> {
            return localVarFp.vehicleSummary(ownerId, options).then((request) => request(axios, basePath));
        },
        /**
         * all vehicles dashboard
         * @summary all vehicles dashboard
         * @param {number} [payloadTypeId] Payload Type ID
         * @param {number} [warehouseTypeId] Warehouse Type ID
         * @param {string} [active] Active flag (true, false)
         * @param {string} [search] Text wildcard search
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vehiclesDashboard(payloadTypeId?: number, warehouseTypeId?: number, active?: string, search?: string, options?: any): AxiosPromise<DashboardAllSummaryResponse> {
            return localVarFp.vehiclesDashboard(payloadTypeId, warehouseTypeId, active, search, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DashboardApi - object-oriented interface
 * @export
 * @class DashboardApi
 * @extends {BaseAPI}
 */
export class DashboardApi extends BaseAPI {
    /**
     * activity summary
     * @summary activity summary
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public activitySummary(options?: RawAxiosRequestConfig) {
        return DashboardApiFp(this.configuration).activitySummary(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * container
     * @summary container summary
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public containerSummary(options?: RawAxiosRequestConfig) {
        return DashboardApiFp(this.configuration).containerSummary(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * list alert events for feed
     * @summary list alert events for feed
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public dashboardAlertEvents(options?: RawAxiosRequestConfig) {
        return DashboardApiFp(this.configuration).dashboardAlertEvents(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get KPIs for the dashboard by roles
     * @summary get KPIs for the dashboard by roles
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public getKpisByRoles(options?: RawAxiosRequestConfig) {
        return DashboardApiFp(this.configuration).getKpisByRoles(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get PKIs for the dashboard
     * @summary get PKIs for dashboard
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public getPkis(options?: RawAxiosRequestConfig) {
        return DashboardApiFp(this.configuration).getPkis(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * group summary of item locations
     * @summary group summary of item locations
     * @param {string} [productId] product_id to query
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public groupSummary(productId?: string, options?: RawAxiosRequestConfig) {
        return DashboardApiFp(this.configuration).groupSummary(productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * single vehicle dashboard
     * @summary single vehicle dashboard
     * @param {string} ownerId Owner ID (vehicle_id)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public vehicleSummary(ownerId: string, options?: RawAxiosRequestConfig) {
        return DashboardApiFp(this.configuration).vehicleSummary(ownerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * all vehicles dashboard
     * @summary all vehicles dashboard
     * @param {number} [payloadTypeId] Payload Type ID
     * @param {number} [warehouseTypeId] Warehouse Type ID
     * @param {string} [active] Active flag (true, false)
     * @param {string} [search] Text wildcard search
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public vehiclesDashboard(payloadTypeId?: number, warehouseTypeId?: number, active?: string, search?: string, options?: RawAxiosRequestConfig) {
        return DashboardApiFp(this.configuration).vehiclesDashboard(payloadTypeId, warehouseTypeId, active, search, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DeviceEventsApi - axios parameter creator
 * @export
 */
export const DeviceEventsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * list device events
         * @summary list device events
         * @param {string} [deviceId] device_id to query for device events
         * @param {string} [processedBegin] Begin time
         * @param {string} [processedEnd] End time
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceEventsList: async (deviceId?: string, processedBegin?: string, processedEnd?: string, sortBy?: string, offset?: number, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/device-events/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (deviceId !== undefined) {
                localVarQueryParameter['device_id'] = deviceId;
            }

            if (processedBegin !== undefined) {
                localVarQueryParameter['processed_begin'] = processedBegin;
            }

            if (processedEnd !== undefined) {
                localVarQueryParameter['processed_end'] = processedEnd;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * submit list of device events
         * @summary submit device events
         * @param {DeviceEventRequestNewDeviceEvents} deviceEvents Device events
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitDeviceEvents: async (deviceEvents: DeviceEventRequestNewDeviceEvents, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceEvents' is not null or undefined
            assertParamExists('submitDeviceEvents', 'deviceEvents', deviceEvents)
            const localVarPath = `/device-events`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deviceEvents, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DeviceEventsApi - functional programming interface
 * @export
 */
export const DeviceEventsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DeviceEventsApiAxiosParamCreator(configuration)
    return {
        /**
         * list device events
         * @summary list device events
         * @param {string} [deviceId] device_id to query for device events
         * @param {string} [processedBegin] Begin time
         * @param {string} [processedEnd] End time
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deviceEventsList(deviceId?: string, processedBegin?: string, processedEnd?: string, sortBy?: string, offset?: number, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeviceEventListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deviceEventsList(deviceId, processedBegin, processedEnd, sortBy, offset, limit, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DeviceEventsApi.deviceEventsList']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * submit list of device events
         * @summary submit device events
         * @param {DeviceEventRequestNewDeviceEvents} deviceEvents Device events
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async submitDeviceEvents(deviceEvents: DeviceEventRequestNewDeviceEvents, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HandlerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.submitDeviceEvents(deviceEvents, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DeviceEventsApi.submitDeviceEvents']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * DeviceEventsApi - factory interface
 * @export
 */
export const DeviceEventsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DeviceEventsApiFp(configuration)
    return {
        /**
         * list device events
         * @summary list device events
         * @param {string} [deviceId] device_id to query for device events
         * @param {string} [processedBegin] Begin time
         * @param {string} [processedEnd] End time
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceEventsList(deviceId?: string, processedBegin?: string, processedEnd?: string, sortBy?: string, offset?: number, limit?: number, options?: any): AxiosPromise<DeviceEventListResponse> {
            return localVarFp.deviceEventsList(deviceId, processedBegin, processedEnd, sortBy, offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * submit list of device events
         * @summary submit device events
         * @param {DeviceEventRequestNewDeviceEvents} deviceEvents Device events
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitDeviceEvents(deviceEvents: DeviceEventRequestNewDeviceEvents, options?: any): AxiosPromise<HandlerResponse> {
            return localVarFp.submitDeviceEvents(deviceEvents, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DeviceEventsApi - object-oriented interface
 * @export
 * @class DeviceEventsApi
 * @extends {BaseAPI}
 */
export class DeviceEventsApi extends BaseAPI {
    /**
     * list device events
     * @summary list device events
     * @param {string} [deviceId] device_id to query for device events
     * @param {string} [processedBegin] Begin time
     * @param {string} [processedEnd] End time
     * @param {string} [sortBy] First sort column
     * @param {number} [offset] Offset
     * @param {number} [limit] Limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceEventsApi
     */
    public deviceEventsList(deviceId?: string, processedBegin?: string, processedEnd?: string, sortBy?: string, offset?: number, limit?: number, options?: RawAxiosRequestConfig) {
        return DeviceEventsApiFp(this.configuration).deviceEventsList(deviceId, processedBegin, processedEnd, sortBy, offset, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * submit list of device events
     * @summary submit device events
     * @param {DeviceEventRequestNewDeviceEvents} deviceEvents Device events
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceEventsApi
     */
    public submitDeviceEvents(deviceEvents: DeviceEventRequestNewDeviceEvents, options?: RawAxiosRequestConfig) {
        return DeviceEventsApiFp(this.configuration).submitDeviceEvents(deviceEvents, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DevicesApi - axios parameter creator
 * @export
 */
export const DevicesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * assign device to warehouse
         * @summary assign device to warehouse
         * @param {DeviceDeviceAssignRequest} request device to assign to warehouse
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignDevice: async (request: DeviceDeviceAssignRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('assignDevice', 'request', request)
            const localVarPath = `/devices/warehouse`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * check if mac address is free
         * @summary check if mac address is free
         * @param {string} deviceId mac address status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkDeviceMacAddress: async (deviceId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('checkDeviceMacAddress', 'deviceId', deviceId)
            const localVarPath = `/devices/{device_id}/mac-address`
                .replace(`{${"device_id"}}`, encodeURIComponent(String(deviceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * delete device object by UUID
         * @summary delete device object
         * @param {string} deviceId Device ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDeviceById: async (deviceId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('deleteDeviceById', 'deviceId', deviceId)
            const localVarPath = `/devices/{device_id}`
                .replace(`{${"device_id"}}`, encodeURIComponent(String(deviceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * force delete a system warehouse device by UUID
         * @summary force delete a system warehouse device by UUID
         * @param {string} deviceId Device ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDeviceForce: async (deviceId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('deleteDeviceForce', 'deviceId', deviceId)
            const localVarPath = `/devices/{device_id}/force`
                .replace(`{${"device_id"}}`, encodeURIComponent(String(deviceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * returns list of connect board versions
         * @summary returns list of connect board versions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceVersionList: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/devices/versions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get builds assigned to devices
         * @summary get builds assigned to devices
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeviceAssignedBuilds: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/devices/assigned-builds`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get device builds set and running
         * @summary get device builds set and running
         * @param {string} identifier device identifier requested for build set and running
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeviceBuilds: async (identifier: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'identifier' is not null or undefined
            assertParamExists('getDeviceBuilds', 'identifier', identifier)
            const localVarPath = `/devices/by-ident/{identifier}/builds`
                .replace(`{${"identifier"}}`, encodeURIComponent(String(identifier)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get device object by UUID
         * @summary get device object
         * @param {string} deviceId Device ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeviceById: async (deviceId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('getDeviceById', 'deviceId', deviceId)
            const localVarPath = `/devices/{device_id}/details`
                .replace(`{${"device_id"}}`, encodeURIComponent(String(deviceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get device configuration by id
         * @summary get device configuration by id
         * @param {string} configurationId configuration_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeviceConfigurationById: async (configurationId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'configurationId' is not null or undefined
            assertParamExists('getDeviceConfigurationById', 'configurationId', configurationId)
            const localVarPath = `/devices/configuration/{configuration_id}`
                .replace(`{${"configuration_id"}}`, encodeURIComponent(String(configurationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get device configuration by identifier
         * @summary get device configuration by identifier
         * @param {string} deviceId identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeviceConfigurationByIdentifier: async (deviceId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('getDeviceConfigurationByIdentifier', 'deviceId', deviceId)
            const localVarPath = `/devices/{device_id}/configuration-by-identifier`
                .replace(`{${"device_id"}}`, encodeURIComponent(String(deviceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get device dependencies
         * @summary Check for dependencies on device
         * @param {string} deviceId Device ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeviceDependencies: async (deviceId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('getDeviceDependencies', 'deviceId', deviceId)
            const localVarPath = `/devices/{device_id}/dependencies`
                .replace(`{${"device_id"}}`, encodeURIComponent(String(deviceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get device metrics object by UUID
         * @summary get devicemetrics object
         * @param {string} deviceId Device ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeviceMetricsById: async (deviceId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('getDeviceMetricsById', 'deviceId', deviceId)
            const localVarPath = `/devices/{device_id}/metrics`
                .replace(`{${"device_id"}}`, encodeURIComponent(String(deviceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get list of device objects
         * @summary get list of device objects
         * @param {string} [organizationId] Organization ID
         * @param {number} [deviceTypeId] Device Type ID
         * @param {string} [active] active status of item (0/1 or true/false) - defaults to true
         * @param {string} [boardTypeId] Connnect board type
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDevices: async (organizationId?: string, deviceTypeId?: number, active?: string, boardTypeId?: string, sortBy?: string, offset?: number, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/devices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (organizationId !== undefined) {
                localVarQueryParameter['organization_id'] = organizationId;
            }

            if (deviceTypeId !== undefined) {
                localVarQueryParameter['device_type_id'] = deviceTypeId;
            }

            if (active !== undefined) {
                localVarQueryParameter['active'] = active;
            }

            if (boardTypeId !== undefined) {
                localVarQueryParameter['board_type_id'] = boardTypeId;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get list of available device objects
         * @summary get list of available device objects
         * @param {number} [deviceTypeId] Device Type ID
         * @param {number} [detailed] Flag to show detailed (0/1)
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDevicesAvailable: async (deviceTypeId?: number, detailed?: number, sortBy?: string, offset?: number, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/devices/available`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (deviceTypeId !== undefined) {
                localVarQueryParameter['device_type_id'] = deviceTypeId;
            }

            if (detailed !== undefined) {
                localVarQueryParameter['detailed'] = detailed;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get ist of device with queued software updates
         * @summary get ist of device with queued software updates
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDevicesQueuedUpdates: async (sortBy?: string, offset?: number, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/devices/queued-updates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * register new device and return id/pwd
         * @summary register new device and return id/pwd
         * @param {DeviceDeviceRegisterRequest} request device information for registration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerDevice: async (request: DeviceDeviceRegisterRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('registerDevice', 'request', request)
            const localVarPath = `/devices/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * save device configuration by device id
         * @summary save device configuration by device id from Adin UI
         * @param {string} deviceId device_id
         * @param {DeviceDeviceConfigurationSetRequest} request device configuration to save
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveDeviceConfiguration: async (deviceId: string, request: DeviceDeviceConfigurationSetRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('saveDeviceConfiguration', 'deviceId', deviceId)
            // verify required parameter 'request' is not null or undefined
            assertParamExists('saveDeviceConfiguration', 'request', request)
            const localVarPath = `/devices/{device_id}/configuration`
                .replace(`{${"device_id"}}`, encodeURIComponent(String(deviceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * sets the ID of the current device configuration
         * @summary sets the ID of the current device configuration
         * @param {DeviceDeviceSetActiveConfigurationRequest} request device configuration to set active
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setActiveDeviceConfiguration: async (request: DeviceDeviceSetActiveConfigurationRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('setActiveDeviceConfiguration', 'request', request)
            const localVarPath = `/devices/set-active-device-configuration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * set device build
         * @summary set device build
         * @param {DeviceDeviceBuildSetRequest} request device build to set
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setDeviceBuild: async (request: DeviceDeviceBuildSetRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('setDeviceBuild', 'request', request)
            const localVarPath = `/devices/set-device-build`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * set running device build
         * @summary set running device build
         * @param {DeviceDeviceBuildSetRequest} request device running build to set
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setRunningDeviceBuild: async (request: DeviceDeviceBuildSetRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('setRunningDeviceBuild', 'request', request)
            const localVarPath = `/devices/set-running-device-build`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * update device object
         * @summary update device object
         * @param {string} deviceId Device ID
         * @param {DeviceRequestDeviceUpdate} device Device update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDeviceById: async (deviceId: string, device: DeviceRequestDeviceUpdate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('updateDeviceById', 'deviceId', deviceId)
            // verify required parameter 'device' is not null or undefined
            assertParamExists('updateDeviceById', 'device', device)
            const localVarPath = `/devices/{device_id}`
                .replace(`{${"device_id"}}`, encodeURIComponent(String(deviceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(device, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DevicesApi - functional programming interface
 * @export
 */
export const DevicesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DevicesApiAxiosParamCreator(configuration)
    return {
        /**
         * assign device to warehouse
         * @summary assign device to warehouse
         * @param {DeviceDeviceAssignRequest} request device to assign to warehouse
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assignDevice(request: DeviceDeviceAssignRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HandlerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assignDevice(request, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DevicesApi.assignDevice']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * check if mac address is free
         * @summary check if mac address is free
         * @param {string} deviceId mac address status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkDeviceMacAddress(deviceId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HandlerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkDeviceMacAddress(deviceId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DevicesApi.checkDeviceMacAddress']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * delete device object by UUID
         * @summary delete device object
         * @param {string} deviceId Device ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDeviceById(deviceId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HandlerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDeviceById(deviceId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DevicesApi.deleteDeviceById']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * force delete a system warehouse device by UUID
         * @summary force delete a system warehouse device by UUID
         * @param {string} deviceId Device ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDeviceForce(deviceId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HandlerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDeviceForce(deviceId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DevicesApi.deleteDeviceForce']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * returns list of connect board versions
         * @summary returns list of connect board versions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deviceVersionList(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeviceVersionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deviceVersionList(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DevicesApi.deviceVersionList']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * get builds assigned to devices
         * @summary get builds assigned to devices
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDeviceAssignedBuilds(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeviceDeviceAssignedBuildListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDeviceAssignedBuilds(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DevicesApi.getDeviceAssignedBuilds']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * get device builds set and running
         * @summary get device builds set and running
         * @param {string} identifier device identifier requested for build set and running
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDeviceBuilds(identifier: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeviceDeviceBuildsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDeviceBuilds(identifier, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DevicesApi.getDeviceBuilds']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * get device object by UUID
         * @summary get device object
         * @param {string} deviceId Device ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDeviceById(deviceId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeviceDeviceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDeviceById(deviceId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DevicesApi.getDeviceById']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * get device configuration by id
         * @summary get device configuration by id
         * @param {string} configurationId configuration_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDeviceConfigurationById(configurationId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeviceDeviceConfigurationGetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDeviceConfigurationById(configurationId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DevicesApi.getDeviceConfigurationById']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * get device configuration by identifier
         * @summary get device configuration by identifier
         * @param {string} deviceId identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDeviceConfigurationByIdentifier(deviceId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeviceDeviceConfigurationGetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDeviceConfigurationByIdentifier(deviceId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DevicesApi.getDeviceConfigurationByIdentifier']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * get device dependencies
         * @summary Check for dependencies on device
         * @param {string} deviceId Device ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDeviceDependencies(deviceId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeviceDependenciesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDeviceDependencies(deviceId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DevicesApi.getDeviceDependencies']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * get device metrics object by UUID
         * @summary get devicemetrics object
         * @param {string} deviceId Device ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDeviceMetricsById(deviceId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeviceDeviceMetricsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDeviceMetricsById(deviceId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DevicesApi.getDeviceMetricsById']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * get list of device objects
         * @summary get list of device objects
         * @param {string} [organizationId] Organization ID
         * @param {number} [deviceTypeId] Device Type ID
         * @param {string} [active] active status of item (0/1 or true/false) - defaults to true
         * @param {string} [boardTypeId] Connnect board type
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDevices(organizationId?: string, deviceTypeId?: number, active?: string, boardTypeId?: string, sortBy?: string, offset?: number, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeviceDeviceListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDevices(organizationId, deviceTypeId, active, boardTypeId, sortBy, offset, limit, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DevicesApi.getDevices']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * get list of available device objects
         * @summary get list of available device objects
         * @param {number} [deviceTypeId] Device Type ID
         * @param {number} [detailed] Flag to show detailed (0/1)
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDevicesAvailable(deviceTypeId?: number, detailed?: number, sortBy?: string, offset?: number, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeviceDeviceListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDevicesAvailable(deviceTypeId, detailed, sortBy, offset, limit, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DevicesApi.getDevicesAvailable']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * get ist of device with queued software updates
         * @summary get ist of device with queued software updates
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDevicesQueuedUpdates(sortBy?: string, offset?: number, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeviceDeviceListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDevicesQueuedUpdates(sortBy, offset, limit, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DevicesApi.getDevicesQueuedUpdates']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * register new device and return id/pwd
         * @summary register new device and return id/pwd
         * @param {DeviceDeviceRegisterRequest} request device information for registration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerDevice(request: DeviceDeviceRegisterRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeviceDeviceRegisterResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registerDevice(request, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DevicesApi.registerDevice']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * save device configuration by device id
         * @summary save device configuration by device id from Adin UI
         * @param {string} deviceId device_id
         * @param {DeviceDeviceConfigurationSetRequest} request device configuration to save
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveDeviceConfiguration(deviceId: string, request: DeviceDeviceConfigurationSetRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeviceDeviceConfigurationSetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveDeviceConfiguration(deviceId, request, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DevicesApi.saveDeviceConfiguration']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * sets the ID of the current device configuration
         * @summary sets the ID of the current device configuration
         * @param {DeviceDeviceSetActiveConfigurationRequest} request device configuration to set active
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setActiveDeviceConfiguration(request: DeviceDeviceSetActiveConfigurationRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HandlerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setActiveDeviceConfiguration(request, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DevicesApi.setActiveDeviceConfiguration']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * set device build
         * @summary set device build
         * @param {DeviceDeviceBuildSetRequest} request device build to set
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setDeviceBuild(request: DeviceDeviceBuildSetRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeviceDeviceBuildSetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setDeviceBuild(request, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DevicesApi.setDeviceBuild']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * set running device build
         * @summary set running device build
         * @param {DeviceDeviceBuildSetRequest} request device running build to set
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setRunningDeviceBuild(request: DeviceDeviceBuildSetRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeviceDeviceBuildSetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setRunningDeviceBuild(request, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DevicesApi.setRunningDeviceBuild']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * update device object
         * @summary update device object
         * @param {string} deviceId Device ID
         * @param {DeviceRequestDeviceUpdate} device Device update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDeviceById(deviceId: string, device: DeviceRequestDeviceUpdate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeviceDeviceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDeviceById(deviceId, device, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DevicesApi.updateDeviceById']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * DevicesApi - factory interface
 * @export
 */
export const DevicesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DevicesApiFp(configuration)
    return {
        /**
         * assign device to warehouse
         * @summary assign device to warehouse
         * @param {DeviceDeviceAssignRequest} request device to assign to warehouse
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignDevice(request: DeviceDeviceAssignRequest, options?: any): AxiosPromise<HandlerResponse> {
            return localVarFp.assignDevice(request, options).then((request) => request(axios, basePath));
        },
        /**
         * check if mac address is free
         * @summary check if mac address is free
         * @param {string} deviceId mac address status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkDeviceMacAddress(deviceId: string, options?: any): AxiosPromise<HandlerResponse> {
            return localVarFp.checkDeviceMacAddress(deviceId, options).then((request) => request(axios, basePath));
        },
        /**
         * delete device object by UUID
         * @summary delete device object
         * @param {string} deviceId Device ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDeviceById(deviceId: string, options?: any): AxiosPromise<HandlerResponse> {
            return localVarFp.deleteDeviceById(deviceId, options).then((request) => request(axios, basePath));
        },
        /**
         * force delete a system warehouse device by UUID
         * @summary force delete a system warehouse device by UUID
         * @param {string} deviceId Device ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDeviceForce(deviceId: string, options?: any): AxiosPromise<HandlerResponse> {
            return localVarFp.deleteDeviceForce(deviceId, options).then((request) => request(axios, basePath));
        },
        /**
         * returns list of connect board versions
         * @summary returns list of connect board versions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceVersionList(options?: any): AxiosPromise<DeviceVersionResponse> {
            return localVarFp.deviceVersionList(options).then((request) => request(axios, basePath));
        },
        /**
         * get builds assigned to devices
         * @summary get builds assigned to devices
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeviceAssignedBuilds(options?: any): AxiosPromise<DeviceDeviceAssignedBuildListResponse> {
            return localVarFp.getDeviceAssignedBuilds(options).then((request) => request(axios, basePath));
        },
        /**
         * get device builds set and running
         * @summary get device builds set and running
         * @param {string} identifier device identifier requested for build set and running
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeviceBuilds(identifier: string, options?: any): AxiosPromise<DeviceDeviceBuildsResponse> {
            return localVarFp.getDeviceBuilds(identifier, options).then((request) => request(axios, basePath));
        },
        /**
         * get device object by UUID
         * @summary get device object
         * @param {string} deviceId Device ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeviceById(deviceId: string, options?: any): AxiosPromise<DeviceDeviceResponse> {
            return localVarFp.getDeviceById(deviceId, options).then((request) => request(axios, basePath));
        },
        /**
         * get device configuration by id
         * @summary get device configuration by id
         * @param {string} configurationId configuration_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeviceConfigurationById(configurationId: string, options?: any): AxiosPromise<DeviceDeviceConfigurationGetResponse> {
            return localVarFp.getDeviceConfigurationById(configurationId, options).then((request) => request(axios, basePath));
        },
        /**
         * get device configuration by identifier
         * @summary get device configuration by identifier
         * @param {string} deviceId identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeviceConfigurationByIdentifier(deviceId: string, options?: any): AxiosPromise<DeviceDeviceConfigurationGetResponse> {
            return localVarFp.getDeviceConfigurationByIdentifier(deviceId, options).then((request) => request(axios, basePath));
        },
        /**
         * get device dependencies
         * @summary Check for dependencies on device
         * @param {string} deviceId Device ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeviceDependencies(deviceId: string, options?: any): AxiosPromise<DeviceDependenciesResponse> {
            return localVarFp.getDeviceDependencies(deviceId, options).then((request) => request(axios, basePath));
        },
        /**
         * get device metrics object by UUID
         * @summary get devicemetrics object
         * @param {string} deviceId Device ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeviceMetricsById(deviceId: string, options?: any): AxiosPromise<DeviceDeviceMetricsResponse> {
            return localVarFp.getDeviceMetricsById(deviceId, options).then((request) => request(axios, basePath));
        },
        /**
         * get list of device objects
         * @summary get list of device objects
         * @param {string} [organizationId] Organization ID
         * @param {number} [deviceTypeId] Device Type ID
         * @param {string} [active] active status of item (0/1 or true/false) - defaults to true
         * @param {string} [boardTypeId] Connnect board type
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDevices(organizationId?: string, deviceTypeId?: number, active?: string, boardTypeId?: string, sortBy?: string, offset?: number, limit?: number, options?: any): AxiosPromise<DeviceDeviceListResponse> {
            return localVarFp.getDevices(organizationId, deviceTypeId, active, boardTypeId, sortBy, offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * get list of available device objects
         * @summary get list of available device objects
         * @param {number} [deviceTypeId] Device Type ID
         * @param {number} [detailed] Flag to show detailed (0/1)
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDevicesAvailable(deviceTypeId?: number, detailed?: number, sortBy?: string, offset?: number, limit?: number, options?: any): AxiosPromise<DeviceDeviceListResponse> {
            return localVarFp.getDevicesAvailable(deviceTypeId, detailed, sortBy, offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * get ist of device with queued software updates
         * @summary get ist of device with queued software updates
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDevicesQueuedUpdates(sortBy?: string, offset?: number, limit?: number, options?: any): AxiosPromise<DeviceDeviceListResponse> {
            return localVarFp.getDevicesQueuedUpdates(sortBy, offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * register new device and return id/pwd
         * @summary register new device and return id/pwd
         * @param {DeviceDeviceRegisterRequest} request device information for registration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerDevice(request: DeviceDeviceRegisterRequest, options?: any): AxiosPromise<DeviceDeviceRegisterResponse> {
            return localVarFp.registerDevice(request, options).then((request) => request(axios, basePath));
        },
        /**
         * save device configuration by device id
         * @summary save device configuration by device id from Adin UI
         * @param {string} deviceId device_id
         * @param {DeviceDeviceConfigurationSetRequest} request device configuration to save
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveDeviceConfiguration(deviceId: string, request: DeviceDeviceConfigurationSetRequest, options?: any): AxiosPromise<DeviceDeviceConfigurationSetResponse> {
            return localVarFp.saveDeviceConfiguration(deviceId, request, options).then((request) => request(axios, basePath));
        },
        /**
         * sets the ID of the current device configuration
         * @summary sets the ID of the current device configuration
         * @param {DeviceDeviceSetActiveConfigurationRequest} request device configuration to set active
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setActiveDeviceConfiguration(request: DeviceDeviceSetActiveConfigurationRequest, options?: any): AxiosPromise<HandlerResponse> {
            return localVarFp.setActiveDeviceConfiguration(request, options).then((request) => request(axios, basePath));
        },
        /**
         * set device build
         * @summary set device build
         * @param {DeviceDeviceBuildSetRequest} request device build to set
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setDeviceBuild(request: DeviceDeviceBuildSetRequest, options?: any): AxiosPromise<DeviceDeviceBuildSetResponse> {
            return localVarFp.setDeviceBuild(request, options).then((request) => request(axios, basePath));
        },
        /**
         * set running device build
         * @summary set running device build
         * @param {DeviceDeviceBuildSetRequest} request device running build to set
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setRunningDeviceBuild(request: DeviceDeviceBuildSetRequest, options?: any): AxiosPromise<DeviceDeviceBuildSetResponse> {
            return localVarFp.setRunningDeviceBuild(request, options).then((request) => request(axios, basePath));
        },
        /**
         * update device object
         * @summary update device object
         * @param {string} deviceId Device ID
         * @param {DeviceRequestDeviceUpdate} device Device update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDeviceById(deviceId: string, device: DeviceRequestDeviceUpdate, options?: any): AxiosPromise<DeviceDeviceResponse> {
            return localVarFp.updateDeviceById(deviceId, device, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DevicesApi - object-oriented interface
 * @export
 * @class DevicesApi
 * @extends {BaseAPI}
 */
export class DevicesApi extends BaseAPI {
    /**
     * assign device to warehouse
     * @summary assign device to warehouse
     * @param {DeviceDeviceAssignRequest} request device to assign to warehouse
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevicesApi
     */
    public assignDevice(request: DeviceDeviceAssignRequest, options?: RawAxiosRequestConfig) {
        return DevicesApiFp(this.configuration).assignDevice(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * check if mac address is free
     * @summary check if mac address is free
     * @param {string} deviceId mac address status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevicesApi
     */
    public checkDeviceMacAddress(deviceId: string, options?: RawAxiosRequestConfig) {
        return DevicesApiFp(this.configuration).checkDeviceMacAddress(deviceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * delete device object by UUID
     * @summary delete device object
     * @param {string} deviceId Device ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevicesApi
     */
    public deleteDeviceById(deviceId: string, options?: RawAxiosRequestConfig) {
        return DevicesApiFp(this.configuration).deleteDeviceById(deviceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * force delete a system warehouse device by UUID
     * @summary force delete a system warehouse device by UUID
     * @param {string} deviceId Device ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevicesApi
     */
    public deleteDeviceForce(deviceId: string, options?: RawAxiosRequestConfig) {
        return DevicesApiFp(this.configuration).deleteDeviceForce(deviceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * returns list of connect board versions
     * @summary returns list of connect board versions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevicesApi
     */
    public deviceVersionList(options?: RawAxiosRequestConfig) {
        return DevicesApiFp(this.configuration).deviceVersionList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get builds assigned to devices
     * @summary get builds assigned to devices
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevicesApi
     */
    public getDeviceAssignedBuilds(options?: RawAxiosRequestConfig) {
        return DevicesApiFp(this.configuration).getDeviceAssignedBuilds(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get device builds set and running
     * @summary get device builds set and running
     * @param {string} identifier device identifier requested for build set and running
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevicesApi
     */
    public getDeviceBuilds(identifier: string, options?: RawAxiosRequestConfig) {
        return DevicesApiFp(this.configuration).getDeviceBuilds(identifier, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get device object by UUID
     * @summary get device object
     * @param {string} deviceId Device ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevicesApi
     */
    public getDeviceById(deviceId: string, options?: RawAxiosRequestConfig) {
        return DevicesApiFp(this.configuration).getDeviceById(deviceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get device configuration by id
     * @summary get device configuration by id
     * @param {string} configurationId configuration_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevicesApi
     */
    public getDeviceConfigurationById(configurationId: string, options?: RawAxiosRequestConfig) {
        return DevicesApiFp(this.configuration).getDeviceConfigurationById(configurationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get device configuration by identifier
     * @summary get device configuration by identifier
     * @param {string} deviceId identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevicesApi
     */
    public getDeviceConfigurationByIdentifier(deviceId: string, options?: RawAxiosRequestConfig) {
        return DevicesApiFp(this.configuration).getDeviceConfigurationByIdentifier(deviceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get device dependencies
     * @summary Check for dependencies on device
     * @param {string} deviceId Device ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevicesApi
     */
    public getDeviceDependencies(deviceId: string, options?: RawAxiosRequestConfig) {
        return DevicesApiFp(this.configuration).getDeviceDependencies(deviceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get device metrics object by UUID
     * @summary get devicemetrics object
     * @param {string} deviceId Device ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevicesApi
     */
    public getDeviceMetricsById(deviceId: string, options?: RawAxiosRequestConfig) {
        return DevicesApiFp(this.configuration).getDeviceMetricsById(deviceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get list of device objects
     * @summary get list of device objects
     * @param {string} [organizationId] Organization ID
     * @param {number} [deviceTypeId] Device Type ID
     * @param {string} [active] active status of item (0/1 or true/false) - defaults to true
     * @param {string} [boardTypeId] Connnect board type
     * @param {string} [sortBy] First sort column
     * @param {number} [offset] Offset
     * @param {number} [limit] Limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevicesApi
     */
    public getDevices(organizationId?: string, deviceTypeId?: number, active?: string, boardTypeId?: string, sortBy?: string, offset?: number, limit?: number, options?: RawAxiosRequestConfig) {
        return DevicesApiFp(this.configuration).getDevices(organizationId, deviceTypeId, active, boardTypeId, sortBy, offset, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get list of available device objects
     * @summary get list of available device objects
     * @param {number} [deviceTypeId] Device Type ID
     * @param {number} [detailed] Flag to show detailed (0/1)
     * @param {string} [sortBy] First sort column
     * @param {number} [offset] Offset
     * @param {number} [limit] Limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevicesApi
     */
    public getDevicesAvailable(deviceTypeId?: number, detailed?: number, sortBy?: string, offset?: number, limit?: number, options?: RawAxiosRequestConfig) {
        return DevicesApiFp(this.configuration).getDevicesAvailable(deviceTypeId, detailed, sortBy, offset, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get ist of device with queued software updates
     * @summary get ist of device with queued software updates
     * @param {string} [sortBy] First sort column
     * @param {number} [offset] Offset
     * @param {number} [limit] Limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevicesApi
     */
    public getDevicesQueuedUpdates(sortBy?: string, offset?: number, limit?: number, options?: RawAxiosRequestConfig) {
        return DevicesApiFp(this.configuration).getDevicesQueuedUpdates(sortBy, offset, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * register new device and return id/pwd
     * @summary register new device and return id/pwd
     * @param {DeviceDeviceRegisterRequest} request device information for registration
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevicesApi
     */
    public registerDevice(request: DeviceDeviceRegisterRequest, options?: RawAxiosRequestConfig) {
        return DevicesApiFp(this.configuration).registerDevice(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * save device configuration by device id
     * @summary save device configuration by device id from Adin UI
     * @param {string} deviceId device_id
     * @param {DeviceDeviceConfigurationSetRequest} request device configuration to save
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevicesApi
     */
    public saveDeviceConfiguration(deviceId: string, request: DeviceDeviceConfigurationSetRequest, options?: RawAxiosRequestConfig) {
        return DevicesApiFp(this.configuration).saveDeviceConfiguration(deviceId, request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * sets the ID of the current device configuration
     * @summary sets the ID of the current device configuration
     * @param {DeviceDeviceSetActiveConfigurationRequest} request device configuration to set active
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevicesApi
     */
    public setActiveDeviceConfiguration(request: DeviceDeviceSetActiveConfigurationRequest, options?: RawAxiosRequestConfig) {
        return DevicesApiFp(this.configuration).setActiveDeviceConfiguration(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * set device build
     * @summary set device build
     * @param {DeviceDeviceBuildSetRequest} request device build to set
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevicesApi
     */
    public setDeviceBuild(request: DeviceDeviceBuildSetRequest, options?: RawAxiosRequestConfig) {
        return DevicesApiFp(this.configuration).setDeviceBuild(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * set running device build
     * @summary set running device build
     * @param {DeviceDeviceBuildSetRequest} request device running build to set
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevicesApi
     */
    public setRunningDeviceBuild(request: DeviceDeviceBuildSetRequest, options?: RawAxiosRequestConfig) {
        return DevicesApiFp(this.configuration).setRunningDeviceBuild(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * update device object
     * @summary update device object
     * @param {string} deviceId Device ID
     * @param {DeviceRequestDeviceUpdate} device Device update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevicesApi
     */
    public updateDeviceById(deviceId: string, device: DeviceRequestDeviceUpdate, options?: RawAxiosRequestConfig) {
        return DevicesApiFp(this.configuration).updateDeviceById(deviceId, device, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * EmbeddedBuildsApi - axios parameter creator
 * @export
 */
export const EmbeddedBuildsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * create an embedded build
         * @summary create an embedded build
         * @param {EmbeddedBuildRequestNewEmbeddedBuild} embeddedBuild Embedded build
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEmbeddedBuild: async (embeddedBuild: EmbeddedBuildRequestNewEmbeddedBuild, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'embeddedBuild' is not null or undefined
            assertParamExists('createEmbeddedBuild', 'embeddedBuild', embeddedBuild)
            const localVarPath = `/embedded-builds`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(embeddedBuild, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * delete embedded build by UUID
         * @summary delete embedded build by UUID
         * @param {string} buildId Build ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEmbeddedBuild: async (buildId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'buildId' is not null or undefined
            assertParamExists('deleteEmbeddedBuild', 'buildId', buildId)
            const localVarPath = `/embedded-builds/{build_id}`
                .replace(`{${"build_id"}}`, encodeURIComponent(String(buildId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * delete development builds
         * @summary delete development builds
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        devDeleteGitRepo: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/embedded-builds/dev-delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get builds assigned to devices
         * @summary get builds assigned to devices
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmbeddedBuildAssignement: async (sortBy?: string, offset?: number, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/embedded-builds/assigned`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get single embedded build
         * @summary get single embedded build
         * @param {string} buildId Embedded Build ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmbeddedBuildById: async (buildId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'buildId' is not null or undefined
            assertParamExists('getEmbeddedBuildById', 'buildId', buildId)
            const localVarPath = `/embedded-builds/{build_id}/details`
                .replace(`{${"build_id"}}`, encodeURIComponent(String(buildId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get embedded build dependencies
         * @summary Check for dependencies on embedded build
         * @param {string} buildId Build ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmbeddedBuildDependencies: async (buildId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'buildId' is not null or undefined
            assertParamExists('getEmbeddedBuildDependencies', 'buildId', buildId)
            const localVarPath = `/embedded-builds/{build_id}/dependencies`
                .replace(`{${"build_id"}}`, encodeURIComponent(String(buildId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get embedded builds
         * @summary get embedded builds
         * @param {string} [software] Software to query - git repo name
         * @param {string} [assigned] has assigned devices
         * @param {string} [running] has running devices
         * @param {string} [active] active status of item (0/1 or true/false) - defaults to true
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmbeddedBuilds: async (software?: string, assigned?: string, running?: string, active?: string, sortBy?: string, offset?: number, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/embedded-builds`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (software !== undefined) {
                localVarQueryParameter['software'] = software;
            }

            if (assigned !== undefined) {
                localVarQueryParameter['assigned'] = assigned;
            }

            if (running !== undefined) {
                localVarQueryParameter['running'] = running;
            }

            if (active !== undefined) {
                localVarQueryParameter['active'] = active;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * sync database with git
         * @summary sync database with git
         * @param {EmbeddedBuildRequestGitSync} repo GitHub repo to sync with [scanner, updater]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncWithGitRepo: async (repo: EmbeddedBuildRequestGitSync, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'repo' is not null or undefined
            assertParamExists('syncWithGitRepo', 'repo', repo)
            const localVarPath = `/embedded-builds/git-sync`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(repo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EmbeddedBuildsApi - functional programming interface
 * @export
 */
export const EmbeddedBuildsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EmbeddedBuildsApiAxiosParamCreator(configuration)
    return {
        /**
         * create an embedded build
         * @summary create an embedded build
         * @param {EmbeddedBuildRequestNewEmbeddedBuild} embeddedBuild Embedded build
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createEmbeddedBuild(embeddedBuild: EmbeddedBuildRequestNewEmbeddedBuild, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmbeddedBuildEmbeddedBuildResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createEmbeddedBuild(embeddedBuild, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['EmbeddedBuildsApi.createEmbeddedBuild']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * delete embedded build by UUID
         * @summary delete embedded build by UUID
         * @param {string} buildId Build ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteEmbeddedBuild(buildId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HandlerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteEmbeddedBuild(buildId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['EmbeddedBuildsApi.deleteEmbeddedBuild']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * delete development builds
         * @summary delete development builds
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async devDeleteGitRepo(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HandlerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.devDeleteGitRepo(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['EmbeddedBuildsApi.devDeleteGitRepo']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * get builds assigned to devices
         * @summary get builds assigned to devices
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEmbeddedBuildAssignement(sortBy?: string, offset?: number, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmbeddedBuildEmbeddedBuildAssignmentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEmbeddedBuildAssignement(sortBy, offset, limit, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['EmbeddedBuildsApi.getEmbeddedBuildAssignement']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * get single embedded build
         * @summary get single embedded build
         * @param {string} buildId Embedded Build ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEmbeddedBuildById(buildId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmbeddedBuildEmbeddedBuildResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEmbeddedBuildById(buildId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['EmbeddedBuildsApi.getEmbeddedBuildById']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * get embedded build dependencies
         * @summary Check for dependencies on embedded build
         * @param {string} buildId Build ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEmbeddedBuildDependencies(buildId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmbeddedBuildDependenciesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEmbeddedBuildDependencies(buildId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['EmbeddedBuildsApi.getEmbeddedBuildDependencies']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * get embedded builds
         * @summary get embedded builds
         * @param {string} [software] Software to query - git repo name
         * @param {string} [assigned] has assigned devices
         * @param {string} [running] has running devices
         * @param {string} [active] active status of item (0/1 or true/false) - defaults to true
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEmbeddedBuilds(software?: string, assigned?: string, running?: string, active?: string, sortBy?: string, offset?: number, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PkgHandlerEmbeddedBuildListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEmbeddedBuilds(software, assigned, running, active, sortBy, offset, limit, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['EmbeddedBuildsApi.getEmbeddedBuilds']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * sync database with git
         * @summary sync database with git
         * @param {EmbeddedBuildRequestGitSync} repo GitHub repo to sync with [scanner, updater]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncWithGitRepo(repo: EmbeddedBuildRequestGitSync, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmbeddedBuildSyncListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncWithGitRepo(repo, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['EmbeddedBuildsApi.syncWithGitRepo']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * EmbeddedBuildsApi - factory interface
 * @export
 */
export const EmbeddedBuildsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EmbeddedBuildsApiFp(configuration)
    return {
        /**
         * create an embedded build
         * @summary create an embedded build
         * @param {EmbeddedBuildRequestNewEmbeddedBuild} embeddedBuild Embedded build
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEmbeddedBuild(embeddedBuild: EmbeddedBuildRequestNewEmbeddedBuild, options?: any): AxiosPromise<EmbeddedBuildEmbeddedBuildResponse> {
            return localVarFp.createEmbeddedBuild(embeddedBuild, options).then((request) => request(axios, basePath));
        },
        /**
         * delete embedded build by UUID
         * @summary delete embedded build by UUID
         * @param {string} buildId Build ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEmbeddedBuild(buildId: string, options?: any): AxiosPromise<HandlerResponse> {
            return localVarFp.deleteEmbeddedBuild(buildId, options).then((request) => request(axios, basePath));
        },
        /**
         * delete development builds
         * @summary delete development builds
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        devDeleteGitRepo(options?: any): AxiosPromise<HandlerResponse> {
            return localVarFp.devDeleteGitRepo(options).then((request) => request(axios, basePath));
        },
        /**
         * get builds assigned to devices
         * @summary get builds assigned to devices
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmbeddedBuildAssignement(sortBy?: string, offset?: number, limit?: number, options?: any): AxiosPromise<EmbeddedBuildEmbeddedBuildAssignmentResponse> {
            return localVarFp.getEmbeddedBuildAssignement(sortBy, offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * get single embedded build
         * @summary get single embedded build
         * @param {string} buildId Embedded Build ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmbeddedBuildById(buildId: string, options?: any): AxiosPromise<EmbeddedBuildEmbeddedBuildResponse> {
            return localVarFp.getEmbeddedBuildById(buildId, options).then((request) => request(axios, basePath));
        },
        /**
         * get embedded build dependencies
         * @summary Check for dependencies on embedded build
         * @param {string} buildId Build ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmbeddedBuildDependencies(buildId: string, options?: any): AxiosPromise<EmbeddedBuildDependenciesResponse> {
            return localVarFp.getEmbeddedBuildDependencies(buildId, options).then((request) => request(axios, basePath));
        },
        /**
         * get embedded builds
         * @summary get embedded builds
         * @param {string} [software] Software to query - git repo name
         * @param {string} [assigned] has assigned devices
         * @param {string} [running] has running devices
         * @param {string} [active] active status of item (0/1 or true/false) - defaults to true
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmbeddedBuilds(software?: string, assigned?: string, running?: string, active?: string, sortBy?: string, offset?: number, limit?: number, options?: any): AxiosPromise<PkgHandlerEmbeddedBuildListResponse> {
            return localVarFp.getEmbeddedBuilds(software, assigned, running, active, sortBy, offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * sync database with git
         * @summary sync database with git
         * @param {EmbeddedBuildRequestGitSync} repo GitHub repo to sync with [scanner, updater]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncWithGitRepo(repo: EmbeddedBuildRequestGitSync, options?: any): AxiosPromise<EmbeddedBuildSyncListResponse> {
            return localVarFp.syncWithGitRepo(repo, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EmbeddedBuildsApi - object-oriented interface
 * @export
 * @class EmbeddedBuildsApi
 * @extends {BaseAPI}
 */
export class EmbeddedBuildsApi extends BaseAPI {
    /**
     * create an embedded build
     * @summary create an embedded build
     * @param {EmbeddedBuildRequestNewEmbeddedBuild} embeddedBuild Embedded build
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmbeddedBuildsApi
     */
    public createEmbeddedBuild(embeddedBuild: EmbeddedBuildRequestNewEmbeddedBuild, options?: RawAxiosRequestConfig) {
        return EmbeddedBuildsApiFp(this.configuration).createEmbeddedBuild(embeddedBuild, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * delete embedded build by UUID
     * @summary delete embedded build by UUID
     * @param {string} buildId Build ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmbeddedBuildsApi
     */
    public deleteEmbeddedBuild(buildId: string, options?: RawAxiosRequestConfig) {
        return EmbeddedBuildsApiFp(this.configuration).deleteEmbeddedBuild(buildId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * delete development builds
     * @summary delete development builds
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmbeddedBuildsApi
     */
    public devDeleteGitRepo(options?: RawAxiosRequestConfig) {
        return EmbeddedBuildsApiFp(this.configuration).devDeleteGitRepo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get builds assigned to devices
     * @summary get builds assigned to devices
     * @param {string} [sortBy] First sort column
     * @param {number} [offset] Offset
     * @param {number} [limit] Limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmbeddedBuildsApi
     */
    public getEmbeddedBuildAssignement(sortBy?: string, offset?: number, limit?: number, options?: RawAxiosRequestConfig) {
        return EmbeddedBuildsApiFp(this.configuration).getEmbeddedBuildAssignement(sortBy, offset, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get single embedded build
     * @summary get single embedded build
     * @param {string} buildId Embedded Build ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmbeddedBuildsApi
     */
    public getEmbeddedBuildById(buildId: string, options?: RawAxiosRequestConfig) {
        return EmbeddedBuildsApiFp(this.configuration).getEmbeddedBuildById(buildId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get embedded build dependencies
     * @summary Check for dependencies on embedded build
     * @param {string} buildId Build ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmbeddedBuildsApi
     */
    public getEmbeddedBuildDependencies(buildId: string, options?: RawAxiosRequestConfig) {
        return EmbeddedBuildsApiFp(this.configuration).getEmbeddedBuildDependencies(buildId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get embedded builds
     * @summary get embedded builds
     * @param {string} [software] Software to query - git repo name
     * @param {string} [assigned] has assigned devices
     * @param {string} [running] has running devices
     * @param {string} [active] active status of item (0/1 or true/false) - defaults to true
     * @param {string} [sortBy] First sort column
     * @param {number} [offset] Offset
     * @param {number} [limit] Limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmbeddedBuildsApi
     */
    public getEmbeddedBuilds(software?: string, assigned?: string, running?: string, active?: string, sortBy?: string, offset?: number, limit?: number, options?: RawAxiosRequestConfig) {
        return EmbeddedBuildsApiFp(this.configuration).getEmbeddedBuilds(software, assigned, running, active, sortBy, offset, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * sync database with git
     * @summary sync database with git
     * @param {EmbeddedBuildRequestGitSync} repo GitHub repo to sync with [scanner, updater]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmbeddedBuildsApi
     */
    public syncWithGitRepo(repo: EmbeddedBuildRequestGitSync, options?: RawAxiosRequestConfig) {
        return EmbeddedBuildsApiFp(this.configuration).syncWithGitRepo(repo, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * EndpointApi - axios parameter creator
 * @export
 */
export const EndpointApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * submit scan as json
         * @summary submit json scan
         * @param {PipelineScan} scan Scan object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitScan: async (scan: PipelineScan, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'scan' is not null or undefined
            assertParamExists('submitScan', 'scan', scan)
            const localVarPath = `/endpoint/submit-scan`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(scan, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * submit json scan to authenticated endpoint
         * @summary submit json scan to authenticated endpoint
         * @param {PipelineScan} scan Scan object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitScanAuth: async (scan: PipelineScan, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'scan' is not null or undefined
            assertParamExists('submitScanAuth', 'scan', scan)
            const localVarPath = `/endpoint/submit-scan-auth`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(scan, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EndpointApi - functional programming interface
 * @export
 */
export const EndpointApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EndpointApiAxiosParamCreator(configuration)
    return {
        /**
         * submit scan as json
         * @summary submit json scan
         * @param {PipelineScan} scan Scan object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async submitScan(scan: PipelineScan, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EndpointScanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.submitScan(scan, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['EndpointApi.submitScan']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * submit json scan to authenticated endpoint
         * @summary submit json scan to authenticated endpoint
         * @param {PipelineScan} scan Scan object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async submitScanAuth(scan: PipelineScan, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EndpointScanStatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.submitScanAuth(scan, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['EndpointApi.submitScanAuth']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * EndpointApi - factory interface
 * @export
 */
export const EndpointApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EndpointApiFp(configuration)
    return {
        /**
         * submit scan as json
         * @summary submit json scan
         * @param {PipelineScan} scan Scan object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitScan(scan: PipelineScan, options?: any): AxiosPromise<EndpointScanResponse> {
            return localVarFp.submitScan(scan, options).then((request) => request(axios, basePath));
        },
        /**
         * submit json scan to authenticated endpoint
         * @summary submit json scan to authenticated endpoint
         * @param {PipelineScan} scan Scan object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitScanAuth(scan: PipelineScan, options?: any): AxiosPromise<EndpointScanStatusResponse> {
            return localVarFp.submitScanAuth(scan, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EndpointApi - object-oriented interface
 * @export
 * @class EndpointApi
 * @extends {BaseAPI}
 */
export class EndpointApi extends BaseAPI {
    /**
     * submit scan as json
     * @summary submit json scan
     * @param {PipelineScan} scan Scan object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EndpointApi
     */
    public submitScan(scan: PipelineScan, options?: RawAxiosRequestConfig) {
        return EndpointApiFp(this.configuration).submitScan(scan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * submit json scan to authenticated endpoint
     * @summary submit json scan to authenticated endpoint
     * @param {PipelineScan} scan Scan object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EndpointApi
     */
    public submitScanAuth(scan: PipelineScan, options?: RawAxiosRequestConfig) {
        return EndpointApiFp(this.configuration).submitScanAuth(scan, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * EpcApi - axios parameter creator
 * @export
 */
export const EpcApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * sequnce of epc codes
         * @summary create item
         * @param {EpcEpcSetRequest} request Quantity of codes to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestRange: async (request: EpcEpcSetRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('requestRange', 'request', request)
            const localVarPath = `/epc/request-range`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * validate an epc code
         * @summary validate epc code
         * @param {string} epc EPC code to validate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateEpc: async (epc: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'epc' is not null or undefined
            assertParamExists('validateEpc', 'epc', epc)
            const localVarPath = `/epc/validate-epc/{epc}`
                .replace(`{${"epc"}}`, encodeURIComponent(String(epc)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EpcApi - functional programming interface
 * @export
 */
export const EpcApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EpcApiAxiosParamCreator(configuration)
    return {
        /**
         * sequnce of epc codes
         * @summary create item
         * @param {EpcEpcSetRequest} request Quantity of codes to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async requestRange(request: EpcEpcSetRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EpcEpcSetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.requestRange(request, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['EpcApi.requestRange']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * validate an epc code
         * @summary validate epc code
         * @param {string} epc EPC code to validate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateEpc(epc: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EpcEpcValidationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validateEpc(epc, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['EpcApi.validateEpc']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * EpcApi - factory interface
 * @export
 */
export const EpcApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EpcApiFp(configuration)
    return {
        /**
         * sequnce of epc codes
         * @summary create item
         * @param {EpcEpcSetRequest} request Quantity of codes to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestRange(request: EpcEpcSetRequest, options?: any): AxiosPromise<EpcEpcSetResponse> {
            return localVarFp.requestRange(request, options).then((request) => request(axios, basePath));
        },
        /**
         * validate an epc code
         * @summary validate epc code
         * @param {string} epc EPC code to validate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateEpc(epc: string, options?: any): AxiosPromise<EpcEpcValidationResponse> {
            return localVarFp.validateEpc(epc, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EpcApi - object-oriented interface
 * @export
 * @class EpcApi
 * @extends {BaseAPI}
 */
export class EpcApi extends BaseAPI {
    /**
     * sequnce of epc codes
     * @summary create item
     * @param {EpcEpcSetRequest} request Quantity of codes to create
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EpcApi
     */
    public requestRange(request: EpcEpcSetRequest, options?: RawAxiosRequestConfig) {
        return EpcApiFp(this.configuration).requestRange(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * validate an epc code
     * @summary validate epc code
     * @param {string} epc EPC code to validate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EpcApi
     */
    public validateEpc(epc: string, options?: RawAxiosRequestConfig) {
        return EpcApiFp(this.configuration).validateEpc(epc, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * FeaturesApi - axios parameter creator
 * @export
 */
export const FeaturesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * create an feature object
         * @summary create feature
         * @param {FeatureRequestFeature} feature feature object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFeature: async (feature: FeatureRequestFeature, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'feature' is not null or undefined
            assertParamExists('createFeature', 'feature', feature)
            const localVarPath = `/features`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(feature, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * delete feature
         * @summary delete feature
         * @param {string} featureUuid feature UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFeature: async (featureUuid: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'featureUuid' is not null or undefined
            assertParamExists('deleteFeature', 'featureUuid', featureUuid)
            const localVarPath = `/features/{feature_uuid}`
                .replace(`{${"feature_uuid"}}`, encodeURIComponent(String(featureUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get feature object by UUID
         * @summary get feature
         * @param {string} featureUuid Feature UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeatureById: async (featureUuid: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'featureUuid' is not null or undefined
            assertParamExists('getFeatureById', 'featureUuid', featureUuid)
            const localVarPath = `/features/{feature_uuid}`
                .replace(`{${"feature_uuid"}}`, encodeURIComponent(String(featureUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * dependencies of the feature
         * @summary dependencies of the feature
         * @param {string} featureUuid Feature UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeatureDependencies: async (featureUuid: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'featureUuid' is not null or undefined
            assertParamExists('getFeatureDependencies', 'featureUuid', featureUuid)
            const localVarPath = `/features/{feature_uuid}/dependencies`
                .replace(`{${"feature_uuid"}}`, encodeURIComponent(String(featureUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * list features
         * @summary list features
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeatures: async (sortBy?: string, offset?: number, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/features`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * update feature object
         * @summary update feature
         * @param {string} featureUuid feature UUID
         * @param {FeatureRequestFeature} feature feature object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFeature: async (featureUuid: string, feature: FeatureRequestFeature, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'featureUuid' is not null or undefined
            assertParamExists('updateFeature', 'featureUuid', featureUuid)
            // verify required parameter 'feature' is not null or undefined
            assertParamExists('updateFeature', 'feature', feature)
            const localVarPath = `/features/{feature_uuid}`
                .replace(`{${"feature_uuid"}}`, encodeURIComponent(String(featureUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(feature, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FeaturesApi - functional programming interface
 * @export
 */
export const FeaturesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FeaturesApiAxiosParamCreator(configuration)
    return {
        /**
         * create an feature object
         * @summary create feature
         * @param {FeatureRequestFeature} feature feature object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createFeature(feature: FeatureRequestFeature, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeatureObjectResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createFeature(feature, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['FeaturesApi.createFeature']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * delete feature
         * @summary delete feature
         * @param {string} featureUuid feature UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFeature(featureUuid: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HandlerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFeature(featureUuid, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['FeaturesApi.deleteFeature']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * get feature object by UUID
         * @summary get feature
         * @param {string} featureUuid Feature UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFeatureById(featureUuid: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeatureObjectResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFeatureById(featureUuid, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['FeaturesApi.getFeatureById']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * dependencies of the feature
         * @summary dependencies of the feature
         * @param {string} featureUuid Feature UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFeatureDependencies(featureUuid: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeatureDependenciesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFeatureDependencies(featureUuid, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['FeaturesApi.getFeatureDependencies']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * list features
         * @summary list features
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFeatures(sortBy?: string, offset?: number, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeatureListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFeatures(sortBy, offset, limit, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['FeaturesApi.getFeatures']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * update feature object
         * @summary update feature
         * @param {string} featureUuid feature UUID
         * @param {FeatureRequestFeature} feature feature object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFeature(featureUuid: string, feature: FeatureRequestFeature, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeatureObjectResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFeature(featureUuid, feature, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['FeaturesApi.updateFeature']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * FeaturesApi - factory interface
 * @export
 */
export const FeaturesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FeaturesApiFp(configuration)
    return {
        /**
         * create an feature object
         * @summary create feature
         * @param {FeatureRequestFeature} feature feature object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFeature(feature: FeatureRequestFeature, options?: any): AxiosPromise<FeatureObjectResponse> {
            return localVarFp.createFeature(feature, options).then((request) => request(axios, basePath));
        },
        /**
         * delete feature
         * @summary delete feature
         * @param {string} featureUuid feature UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFeature(featureUuid: string, options?: any): AxiosPromise<HandlerResponse> {
            return localVarFp.deleteFeature(featureUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * get feature object by UUID
         * @summary get feature
         * @param {string} featureUuid Feature UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeatureById(featureUuid: string, options?: any): AxiosPromise<FeatureObjectResponse> {
            return localVarFp.getFeatureById(featureUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * dependencies of the feature
         * @summary dependencies of the feature
         * @param {string} featureUuid Feature UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeatureDependencies(featureUuid: string, options?: any): AxiosPromise<FeatureDependenciesResponse> {
            return localVarFp.getFeatureDependencies(featureUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * list features
         * @summary list features
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeatures(sortBy?: string, offset?: number, limit?: number, options?: any): AxiosPromise<FeatureListResponse> {
            return localVarFp.getFeatures(sortBy, offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * update feature object
         * @summary update feature
         * @param {string} featureUuid feature UUID
         * @param {FeatureRequestFeature} feature feature object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFeature(featureUuid: string, feature: FeatureRequestFeature, options?: any): AxiosPromise<FeatureObjectResponse> {
            return localVarFp.updateFeature(featureUuid, feature, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FeaturesApi - object-oriented interface
 * @export
 * @class FeaturesApi
 * @extends {BaseAPI}
 */
export class FeaturesApi extends BaseAPI {
    /**
     * create an feature object
     * @summary create feature
     * @param {FeatureRequestFeature} feature feature object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeaturesApi
     */
    public createFeature(feature: FeatureRequestFeature, options?: RawAxiosRequestConfig) {
        return FeaturesApiFp(this.configuration).createFeature(feature, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * delete feature
     * @summary delete feature
     * @param {string} featureUuid feature UUID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeaturesApi
     */
    public deleteFeature(featureUuid: string, options?: RawAxiosRequestConfig) {
        return FeaturesApiFp(this.configuration).deleteFeature(featureUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get feature object by UUID
     * @summary get feature
     * @param {string} featureUuid Feature UUID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeaturesApi
     */
    public getFeatureById(featureUuid: string, options?: RawAxiosRequestConfig) {
        return FeaturesApiFp(this.configuration).getFeatureById(featureUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * dependencies of the feature
     * @summary dependencies of the feature
     * @param {string} featureUuid Feature UUID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeaturesApi
     */
    public getFeatureDependencies(featureUuid: string, options?: RawAxiosRequestConfig) {
        return FeaturesApiFp(this.configuration).getFeatureDependencies(featureUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * list features
     * @summary list features
     * @param {string} [sortBy] First sort column
     * @param {number} [offset] Offset
     * @param {number} [limit] Limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeaturesApi
     */
    public getFeatures(sortBy?: string, offset?: number, limit?: number, options?: RawAxiosRequestConfig) {
        return FeaturesApiFp(this.configuration).getFeatures(sortBy, offset, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * update feature object
     * @summary update feature
     * @param {string} featureUuid feature UUID
     * @param {FeatureRequestFeature} feature feature object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeaturesApi
     */
    public updateFeature(featureUuid: string, feature: FeatureRequestFeature, options?: RawAxiosRequestConfig) {
        return FeaturesApiFp(this.configuration).updateFeature(featureUuid, feature, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ImagesApi - axios parameter creator
 * @export
 */
export const ImagesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * create image object
         * @summary create image
         * @param {ImageRequestUpdateItem} newImage Image object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createImage: async (newImage: ImageRequestUpdateItem, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'newImage' is not null or undefined
            assertParamExists('createImage', 'newImage', newImage)
            const localVarPath = `/images`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newImage, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * delete image by ownerID
         * @summary delete image by ownerID
         * @param {string} ownerUuid Owner ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteImageByOwnerId: async (ownerUuid: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ownerUuid' is not null or undefined
            assertParamExists('deleteImageByOwnerId', 'ownerUuid', ownerUuid)
            const localVarPath = `/images/owner/{owner_uuid}`
                .replace(`{${"owner_uuid"}}`, encodeURIComponent(String(ownerUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get image by key
         * @summary get image by key
         * @param {string} imageKey Image key
         * @param {string} [width] scale image to this width if present in the query
         * @param {string} [height] scale image to this height if present in the query
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getImagesByKey: async (imageKey: string, width?: string, height?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'imageKey' is not null or undefined
            assertParamExists('getImagesByKey', 'imageKey', imageKey)
            const localVarPath = `/images/{image_key}`
                .replace(`{${"image_key"}}`, encodeURIComponent(String(imageKey)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (width !== undefined) {
                localVarQueryParameter['width'] = width;
            }

            if (height !== undefined) {
                localVarQueryParameter['height'] = height;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * returns list of image keys
         * @summary returns list of image meta records
         * @param {string} ownerUuid Owner ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listImagesByOwnerId: async (ownerUuid: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ownerUuid' is not null or undefined
            assertParamExists('listImagesByOwnerId', 'ownerUuid', ownerUuid)
            const localVarPath = `/images/owner/{owner_uuid}`
                .replace(`{${"owner_uuid"}}`, encodeURIComponent(String(ownerUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ImagesApi - functional programming interface
 * @export
 */
export const ImagesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ImagesApiAxiosParamCreator(configuration)
    return {
        /**
         * create image object
         * @summary create image
         * @param {ImageRequestUpdateItem} newImage Image object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createImage(newImage: ImageRequestUpdateItem, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImageCreateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createImage(newImage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ImagesApi.createImage']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * delete image by ownerID
         * @summary delete image by ownerID
         * @param {string} ownerUuid Owner ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteImageByOwnerId(ownerUuid: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HandlerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteImageByOwnerId(ownerUuid, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ImagesApi.deleteImageByOwnerId']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * get image by key
         * @summary get image by key
         * @param {string} imageKey Image key
         * @param {string} [width] scale image to this width if present in the query
         * @param {string} [height] scale image to this height if present in the query
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getImagesByKey(imageKey: string, width?: string, height?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getImagesByKey(imageKey, width, height, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ImagesApi.getImagesByKey']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * returns list of image keys
         * @summary returns list of image meta records
         * @param {string} ownerUuid Owner ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listImagesByOwnerId(ownerUuid: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ImageImageResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listImagesByOwnerId(ownerUuid, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ImagesApi.listImagesByOwnerId']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * ImagesApi - factory interface
 * @export
 */
export const ImagesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ImagesApiFp(configuration)
    return {
        /**
         * create image object
         * @summary create image
         * @param {ImageRequestUpdateItem} newImage Image object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createImage(newImage: ImageRequestUpdateItem, options?: any): AxiosPromise<ImageCreateResponse> {
            return localVarFp.createImage(newImage, options).then((request) => request(axios, basePath));
        },
        /**
         * delete image by ownerID
         * @summary delete image by ownerID
         * @param {string} ownerUuid Owner ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteImageByOwnerId(ownerUuid: string, options?: any): AxiosPromise<HandlerResponse> {
            return localVarFp.deleteImageByOwnerId(ownerUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * get image by key
         * @summary get image by key
         * @param {string} imageKey Image key
         * @param {string} [width] scale image to this width if present in the query
         * @param {string} [height] scale image to this height if present in the query
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getImagesByKey(imageKey: string, width?: string, height?: string, options?: any): AxiosPromise<Array<number>> {
            return localVarFp.getImagesByKey(imageKey, width, height, options).then((request) => request(axios, basePath));
        },
        /**
         * returns list of image keys
         * @summary returns list of image meta records
         * @param {string} ownerUuid Owner ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listImagesByOwnerId(ownerUuid: string, options?: any): AxiosPromise<Array<ImageImageResponse>> {
            return localVarFp.listImagesByOwnerId(ownerUuid, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ImagesApi - object-oriented interface
 * @export
 * @class ImagesApi
 * @extends {BaseAPI}
 */
export class ImagesApi extends BaseAPI {
    /**
     * create image object
     * @summary create image
     * @param {ImageRequestUpdateItem} newImage Image object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImagesApi
     */
    public createImage(newImage: ImageRequestUpdateItem, options?: RawAxiosRequestConfig) {
        return ImagesApiFp(this.configuration).createImage(newImage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * delete image by ownerID
     * @summary delete image by ownerID
     * @param {string} ownerUuid Owner ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImagesApi
     */
    public deleteImageByOwnerId(ownerUuid: string, options?: RawAxiosRequestConfig) {
        return ImagesApiFp(this.configuration).deleteImageByOwnerId(ownerUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get image by key
     * @summary get image by key
     * @param {string} imageKey Image key
     * @param {string} [width] scale image to this width if present in the query
     * @param {string} [height] scale image to this height if present in the query
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImagesApi
     */
    public getImagesByKey(imageKey: string, width?: string, height?: string, options?: RawAxiosRequestConfig) {
        return ImagesApiFp(this.configuration).getImagesByKey(imageKey, width, height, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * returns list of image keys
     * @summary returns list of image meta records
     * @param {string} ownerUuid Owner ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImagesApi
     */
    public listImagesByOwnerId(ownerUuid: string, options?: RawAxiosRequestConfig) {
        return ImagesApiFp(this.configuration).listImagesByOwnerId(ownerUuid, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ItemsApi - axios parameter creator
 * @export
 */
export const ItemsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * create item object
         * @summary create item
         * @param {ItemRequestNewItem} newItem Item object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createItem: async (newItem: ItemRequestNewItem, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'newItem' is not null or undefined
            assertParamExists('createItem', 'newItem', newItem)
            const localVarPath = `/items`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newItem, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * delete item
         * @summary delete item
         * @param {string} itemId Item ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteItem: async (itemId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            assertParamExists('deleteItem', 'itemId', itemId)
            const localVarPath = `/items/{item_id}`
                .replace(`{${"item_id"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get item object by UUID
         * @summary get item object
         * @param {string} itemId Item ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getItemById: async (itemId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            assertParamExists('getItemById', 'itemId', itemId)
            const localVarPath = `/items/{item_id}`
                .replace(`{${"item_id"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get item dependencies
         * @summary Check for dependencies on item
         * @param {string} itemId Item ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getItemDependencies: async (itemId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            assertParamExists('getItemDependencies', 'itemId', itemId)
            const localVarPath = `/items/{item_id}/dependencies`
                .replace(`{${"item_id"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get next serial number for and item
         * @summary get next serial number for and item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getItemNextSerialNumber: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/items/get-serial-number`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * list all product items object
         * @summary list all product items object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemCategories: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/dashboards/items/categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * list  activity decodes
         * @summary list activity decodes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listActivityDecodes: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/items/activity/decodes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * list item activity
         * @summary list aggregate item activity
         * @param {string} [activityBegin] Begin time
         * @param {string} [activityEnd] End time
         * @param {string} [activityTypeIds] Activity type IDs to return
         * @param {string} [itemId] ItemID to return activity for return
         * @param {string} [vehicleId] VehicleID to return activity
         * @param {string} [locationVisitId] LocationVisitID to return activity
         * @param {string} [siteId] SiteID to return activity
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listItemActivity: async (activityBegin?: string, activityEnd?: string, activityTypeIds?: string, itemId?: string, vehicleId?: string, locationVisitId?: string, siteId?: string, sortBy?: string, offset?: number, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/items/activity`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (activityBegin !== undefined) {
                localVarQueryParameter['activity_begin'] = activityBegin;
            }

            if (activityEnd !== undefined) {
                localVarQueryParameter['activity_end'] = activityEnd;
            }

            if (activityTypeIds !== undefined) {
                localVarQueryParameter['activity_type_ids'] = activityTypeIds;
            }

            if (itemId !== undefined) {
                localVarQueryParameter['item_id'] = itemId;
            }

            if (vehicleId !== undefined) {
                localVarQueryParameter['vehicle_id'] = vehicleId;
            }

            if (locationVisitId !== undefined) {
                localVarQueryParameter['location_visit_id'] = locationVisitId;
            }

            if (siteId !== undefined) {
                localVarQueryParameter['site_id'] = siteId;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * list all items object
         * @summary list all items object
         * @param {string} [ownerId] owner_id to query
         * @param {string} [ownerWarehouseId] warehouse owning the items
         * @param {string} [trackingWarehouseId] warehouse tracking the items
         * @param {string} [scanningOwnerId] id of the vehicle or tool room that last scanned the items
         * @param {string} [productId] product_id to query
         * @param {string} [manufacturerId] manufacturer_id to query
         * @param {string} [productTypeId] product_type_id to query
         * @param {number} [ownerTypeId] owner_type_id to query
         * @param {string} [locationVisitId] location_visit_id to query
         * @param {string} [trackingSiteId] tracking_site_id to query
         * @param {string} [jobSiteId] job_site_id to query
         * @param {string} [scanStatus] list of scan status integer values as a string i.e. \&#39;2,3\&#39;
         * @param {string} [active] active status of item (0/1 or true/false) - defaults to true
         * @param {string} [createdBegin] Begin time
         * @param {string} [createdEnd] End time
         * @param {string} [search] Text wildcard search
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listItems: async (ownerId?: string, ownerWarehouseId?: string, trackingWarehouseId?: string, scanningOwnerId?: string, productId?: string, manufacturerId?: string, productTypeId?: string, ownerTypeId?: number, locationVisitId?: string, trackingSiteId?: string, jobSiteId?: string, scanStatus?: string, active?: string, createdBegin?: string, createdEnd?: string, search?: string, sortBy?: string, offset?: number, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/items`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (ownerId !== undefined) {
                localVarQueryParameter['owner_id'] = ownerId;
            }

            if (ownerWarehouseId !== undefined) {
                localVarQueryParameter['owner_warehouse_id'] = ownerWarehouseId;
            }

            if (trackingWarehouseId !== undefined) {
                localVarQueryParameter['tracking_warehouse_id'] = trackingWarehouseId;
            }

            if (scanningOwnerId !== undefined) {
                localVarQueryParameter['scanning_owner_id'] = scanningOwnerId;
            }

            if (productId !== undefined) {
                localVarQueryParameter['product_id'] = productId;
            }

            if (manufacturerId !== undefined) {
                localVarQueryParameter['manufacturer_id'] = manufacturerId;
            }

            if (productTypeId !== undefined) {
                localVarQueryParameter['product_type_id'] = productTypeId;
            }

            if (ownerTypeId !== undefined) {
                localVarQueryParameter['owner_type_id'] = ownerTypeId;
            }

            if (locationVisitId !== undefined) {
                localVarQueryParameter['location_visit_id'] = locationVisitId;
            }

            if (trackingSiteId !== undefined) {
                localVarQueryParameter['tracking_site_id'] = trackingSiteId;
            }

            if (jobSiteId !== undefined) {
                localVarQueryParameter['job_site_id'] = jobSiteId;
            }

            if (scanStatus !== undefined) {
                localVarQueryParameter['scan_status'] = scanStatus;
            }

            if (active !== undefined) {
                localVarQueryParameter['active'] = active;
            }

            if (createdBegin !== undefined) {
                localVarQueryParameter['created_begin'] = createdBegin;
            }

            if (createdEnd !== undefined) {
                localVarQueryParameter['created_end'] = createdEnd;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * list aggregate items left behind per job site
         * @summary list aggregate items left behind
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listItemsLeftBehindAgg: async (sortBy?: string, offset?: number, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/items-left-behind-agg`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * list aggregate items recovered completely
         * @summary list aggregate items recovered completely
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listItemsRecoveredAgg: async (sortBy?: string, offset?: number, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/items-recovered-agg`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * update product item
         * @summary update product item
         * @param {ItemRequestUpdateItem} item Item item object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateItem: async (item: ItemRequestUpdateItem, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'item' is not null or undefined
            assertParamExists('updateItem', 'item', item)
            const localVarPath = `/items`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(item, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ItemsApi - functional programming interface
 * @export
 */
export const ItemsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ItemsApiAxiosParamCreator(configuration)
    return {
        /**
         * create item object
         * @summary create item
         * @param {ItemRequestNewItem} newItem Item object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createItem(newItem: ItemRequestNewItem, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ItemCreateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createItem(newItem, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ItemsApi.createItem']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * delete item
         * @summary delete item
         * @param {string} itemId Item ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteItem(itemId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HandlerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteItem(itemId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ItemsApi.deleteItem']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * get item object by UUID
         * @summary get item object
         * @param {string} itemId Item ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getItemById(itemId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PkgHandlerItemDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getItemById(itemId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ItemsApi.getItemById']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * get item dependencies
         * @summary Check for dependencies on item
         * @param {string} itemId Item ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getItemDependencies(itemId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ItemDependenciesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getItemDependencies(itemId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ItemsApi.getItemDependencies']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * get next serial number for and item
         * @summary get next serial number for and item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getItemNextSerialNumber(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ItemSerialNumberResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getItemNextSerialNumber(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ItemsApi.getItemNextSerialNumber']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * list all product items object
         * @summary list all product items object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async itemCategories(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ItemCategoryListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.itemCategories(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ItemsApi.itemCategories']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * list  activity decodes
         * @summary list activity decodes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listActivityDecodes(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ItemListActivityDecodesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listActivityDecodes(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ItemsApi.listActivityDecodes']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * list item activity
         * @summary list aggregate item activity
         * @param {string} [activityBegin] Begin time
         * @param {string} [activityEnd] End time
         * @param {string} [activityTypeIds] Activity type IDs to return
         * @param {string} [itemId] ItemID to return activity for return
         * @param {string} [vehicleId] VehicleID to return activity
         * @param {string} [locationVisitId] LocationVisitID to return activity
         * @param {string} [siteId] SiteID to return activity
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listItemActivity(activityBegin?: string, activityEnd?: string, activityTypeIds?: string, itemId?: string, vehicleId?: string, locationVisitId?: string, siteId?: string, sortBy?: string, offset?: number, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ItemListActivityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listItemActivity(activityBegin, activityEnd, activityTypeIds, itemId, vehicleId, locationVisitId, siteId, sortBy, offset, limit, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ItemsApi.listItemActivity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * list all items object
         * @summary list all items object
         * @param {string} [ownerId] owner_id to query
         * @param {string} [ownerWarehouseId] warehouse owning the items
         * @param {string} [trackingWarehouseId] warehouse tracking the items
         * @param {string} [scanningOwnerId] id of the vehicle or tool room that last scanned the items
         * @param {string} [productId] product_id to query
         * @param {string} [manufacturerId] manufacturer_id to query
         * @param {string} [productTypeId] product_type_id to query
         * @param {number} [ownerTypeId] owner_type_id to query
         * @param {string} [locationVisitId] location_visit_id to query
         * @param {string} [trackingSiteId] tracking_site_id to query
         * @param {string} [jobSiteId] job_site_id to query
         * @param {string} [scanStatus] list of scan status integer values as a string i.e. \&#39;2,3\&#39;
         * @param {string} [active] active status of item (0/1 or true/false) - defaults to true
         * @param {string} [createdBegin] Begin time
         * @param {string} [createdEnd] End time
         * @param {string} [search] Text wildcard search
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listItems(ownerId?: string, ownerWarehouseId?: string, trackingWarehouseId?: string, scanningOwnerId?: string, productId?: string, manufacturerId?: string, productTypeId?: string, ownerTypeId?: number, locationVisitId?: string, trackingSiteId?: string, jobSiteId?: string, scanStatus?: string, active?: string, createdBegin?: string, createdEnd?: string, search?: string, sortBy?: string, offset?: number, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PkgHandlerItemListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listItems(ownerId, ownerWarehouseId, trackingWarehouseId, scanningOwnerId, productId, manufacturerId, productTypeId, ownerTypeId, locationVisitId, trackingSiteId, jobSiteId, scanStatus, active, createdBegin, createdEnd, search, sortBy, offset, limit, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ItemsApi.listItems']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * list aggregate items left behind per job site
         * @summary list aggregate items left behind
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listItemsLeftBehindAgg(sortBy?: string, offset?: number, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ItemListAggregateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listItemsLeftBehindAgg(sortBy, offset, limit, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ItemsApi.listItemsLeftBehindAgg']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * list aggregate items recovered completely
         * @summary list aggregate items recovered completely
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listItemsRecoveredAgg(sortBy?: string, offset?: number, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ItemListAggregateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listItemsRecoveredAgg(sortBy, offset, limit, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ItemsApi.listItemsRecoveredAgg']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * update product item
         * @summary update product item
         * @param {ItemRequestUpdateItem} item Item item object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateItem(item: ItemRequestUpdateItem, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PkgHandlerItemObjectResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateItem(item, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ItemsApi.updateItem']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * ItemsApi - factory interface
 * @export
 */
export const ItemsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ItemsApiFp(configuration)
    return {
        /**
         * create item object
         * @summary create item
         * @param {ItemRequestNewItem} newItem Item object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createItem(newItem: ItemRequestNewItem, options?: any): AxiosPromise<ItemCreateResponse> {
            return localVarFp.createItem(newItem, options).then((request) => request(axios, basePath));
        },
        /**
         * delete item
         * @summary delete item
         * @param {string} itemId Item ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteItem(itemId: string, options?: any): AxiosPromise<HandlerResponse> {
            return localVarFp.deleteItem(itemId, options).then((request) => request(axios, basePath));
        },
        /**
         * get item object by UUID
         * @summary get item object
         * @param {string} itemId Item ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getItemById(itemId: string, options?: any): AxiosPromise<PkgHandlerItemDetailResponse> {
            return localVarFp.getItemById(itemId, options).then((request) => request(axios, basePath));
        },
        /**
         * get item dependencies
         * @summary Check for dependencies on item
         * @param {string} itemId Item ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getItemDependencies(itemId: string, options?: any): AxiosPromise<ItemDependenciesResponse> {
            return localVarFp.getItemDependencies(itemId, options).then((request) => request(axios, basePath));
        },
        /**
         * get next serial number for and item
         * @summary get next serial number for and item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getItemNextSerialNumber(options?: any): AxiosPromise<ItemSerialNumberResponse> {
            return localVarFp.getItemNextSerialNumber(options).then((request) => request(axios, basePath));
        },
        /**
         * list all product items object
         * @summary list all product items object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemCategories(options?: any): AxiosPromise<ItemCategoryListResponse> {
            return localVarFp.itemCategories(options).then((request) => request(axios, basePath));
        },
        /**
         * list  activity decodes
         * @summary list activity decodes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listActivityDecodes(options?: any): AxiosPromise<ItemListActivityDecodesResponse> {
            return localVarFp.listActivityDecodes(options).then((request) => request(axios, basePath));
        },
        /**
         * list item activity
         * @summary list aggregate item activity
         * @param {string} [activityBegin] Begin time
         * @param {string} [activityEnd] End time
         * @param {string} [activityTypeIds] Activity type IDs to return
         * @param {string} [itemId] ItemID to return activity for return
         * @param {string} [vehicleId] VehicleID to return activity
         * @param {string} [locationVisitId] LocationVisitID to return activity
         * @param {string} [siteId] SiteID to return activity
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listItemActivity(activityBegin?: string, activityEnd?: string, activityTypeIds?: string, itemId?: string, vehicleId?: string, locationVisitId?: string, siteId?: string, sortBy?: string, offset?: number, limit?: number, options?: any): AxiosPromise<ItemListActivityResponse> {
            return localVarFp.listItemActivity(activityBegin, activityEnd, activityTypeIds, itemId, vehicleId, locationVisitId, siteId, sortBy, offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * list all items object
         * @summary list all items object
         * @param {string} [ownerId] owner_id to query
         * @param {string} [ownerWarehouseId] warehouse owning the items
         * @param {string} [trackingWarehouseId] warehouse tracking the items
         * @param {string} [scanningOwnerId] id of the vehicle or tool room that last scanned the items
         * @param {string} [productId] product_id to query
         * @param {string} [manufacturerId] manufacturer_id to query
         * @param {string} [productTypeId] product_type_id to query
         * @param {number} [ownerTypeId] owner_type_id to query
         * @param {string} [locationVisitId] location_visit_id to query
         * @param {string} [trackingSiteId] tracking_site_id to query
         * @param {string} [jobSiteId] job_site_id to query
         * @param {string} [scanStatus] list of scan status integer values as a string i.e. \&#39;2,3\&#39;
         * @param {string} [active] active status of item (0/1 or true/false) - defaults to true
         * @param {string} [createdBegin] Begin time
         * @param {string} [createdEnd] End time
         * @param {string} [search] Text wildcard search
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listItems(ownerId?: string, ownerWarehouseId?: string, trackingWarehouseId?: string, scanningOwnerId?: string, productId?: string, manufacturerId?: string, productTypeId?: string, ownerTypeId?: number, locationVisitId?: string, trackingSiteId?: string, jobSiteId?: string, scanStatus?: string, active?: string, createdBegin?: string, createdEnd?: string, search?: string, sortBy?: string, offset?: number, limit?: number, options?: any): AxiosPromise<PkgHandlerItemListResponse> {
            return localVarFp.listItems(ownerId, ownerWarehouseId, trackingWarehouseId, scanningOwnerId, productId, manufacturerId, productTypeId, ownerTypeId, locationVisitId, trackingSiteId, jobSiteId, scanStatus, active, createdBegin, createdEnd, search, sortBy, offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * list aggregate items left behind per job site
         * @summary list aggregate items left behind
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listItemsLeftBehindAgg(sortBy?: string, offset?: number, limit?: number, options?: any): AxiosPromise<ItemListAggregateResponse> {
            return localVarFp.listItemsLeftBehindAgg(sortBy, offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * list aggregate items recovered completely
         * @summary list aggregate items recovered completely
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listItemsRecoveredAgg(sortBy?: string, offset?: number, limit?: number, options?: any): AxiosPromise<ItemListAggregateResponse> {
            return localVarFp.listItemsRecoveredAgg(sortBy, offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * update product item
         * @summary update product item
         * @param {ItemRequestUpdateItem} item Item item object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateItem(item: ItemRequestUpdateItem, options?: any): AxiosPromise<PkgHandlerItemObjectResponse> {
            return localVarFp.updateItem(item, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ItemsApi - object-oriented interface
 * @export
 * @class ItemsApi
 * @extends {BaseAPI}
 */
export class ItemsApi extends BaseAPI {
    /**
     * create item object
     * @summary create item
     * @param {ItemRequestNewItem} newItem Item object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public createItem(newItem: ItemRequestNewItem, options?: RawAxiosRequestConfig) {
        return ItemsApiFp(this.configuration).createItem(newItem, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * delete item
     * @summary delete item
     * @param {string} itemId Item ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public deleteItem(itemId: string, options?: RawAxiosRequestConfig) {
        return ItemsApiFp(this.configuration).deleteItem(itemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get item object by UUID
     * @summary get item object
     * @param {string} itemId Item ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public getItemById(itemId: string, options?: RawAxiosRequestConfig) {
        return ItemsApiFp(this.configuration).getItemById(itemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get item dependencies
     * @summary Check for dependencies on item
     * @param {string} itemId Item ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public getItemDependencies(itemId: string, options?: RawAxiosRequestConfig) {
        return ItemsApiFp(this.configuration).getItemDependencies(itemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get next serial number for and item
     * @summary get next serial number for and item
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public getItemNextSerialNumber(options?: RawAxiosRequestConfig) {
        return ItemsApiFp(this.configuration).getItemNextSerialNumber(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * list all product items object
     * @summary list all product items object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public itemCategories(options?: RawAxiosRequestConfig) {
        return ItemsApiFp(this.configuration).itemCategories(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * list  activity decodes
     * @summary list activity decodes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public listActivityDecodes(options?: RawAxiosRequestConfig) {
        return ItemsApiFp(this.configuration).listActivityDecodes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * list item activity
     * @summary list aggregate item activity
     * @param {string} [activityBegin] Begin time
     * @param {string} [activityEnd] End time
     * @param {string} [activityTypeIds] Activity type IDs to return
     * @param {string} [itemId] ItemID to return activity for return
     * @param {string} [vehicleId] VehicleID to return activity
     * @param {string} [locationVisitId] LocationVisitID to return activity
     * @param {string} [siteId] SiteID to return activity
     * @param {string} [sortBy] First sort column
     * @param {number} [offset] Offset
     * @param {number} [limit] Limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public listItemActivity(activityBegin?: string, activityEnd?: string, activityTypeIds?: string, itemId?: string, vehicleId?: string, locationVisitId?: string, siteId?: string, sortBy?: string, offset?: number, limit?: number, options?: RawAxiosRequestConfig) {
        return ItemsApiFp(this.configuration).listItemActivity(activityBegin, activityEnd, activityTypeIds, itemId, vehicleId, locationVisitId, siteId, sortBy, offset, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * list all items object
     * @summary list all items object
     * @param {string} [ownerId] owner_id to query
     * @param {string} [ownerWarehouseId] warehouse owning the items
     * @param {string} [trackingWarehouseId] warehouse tracking the items
     * @param {string} [scanningOwnerId] id of the vehicle or tool room that last scanned the items
     * @param {string} [productId] product_id to query
     * @param {string} [manufacturerId] manufacturer_id to query
     * @param {string} [productTypeId] product_type_id to query
     * @param {number} [ownerTypeId] owner_type_id to query
     * @param {string} [locationVisitId] location_visit_id to query
     * @param {string} [trackingSiteId] tracking_site_id to query
     * @param {string} [jobSiteId] job_site_id to query
     * @param {string} [scanStatus] list of scan status integer values as a string i.e. \&#39;2,3\&#39;
     * @param {string} [active] active status of item (0/1 or true/false) - defaults to true
     * @param {string} [createdBegin] Begin time
     * @param {string} [createdEnd] End time
     * @param {string} [search] Text wildcard search
     * @param {string} [sortBy] First sort column
     * @param {number} [offset] Offset
     * @param {number} [limit] Limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public listItems(ownerId?: string, ownerWarehouseId?: string, trackingWarehouseId?: string, scanningOwnerId?: string, productId?: string, manufacturerId?: string, productTypeId?: string, ownerTypeId?: number, locationVisitId?: string, trackingSiteId?: string, jobSiteId?: string, scanStatus?: string, active?: string, createdBegin?: string, createdEnd?: string, search?: string, sortBy?: string, offset?: number, limit?: number, options?: RawAxiosRequestConfig) {
        return ItemsApiFp(this.configuration).listItems(ownerId, ownerWarehouseId, trackingWarehouseId, scanningOwnerId, productId, manufacturerId, productTypeId, ownerTypeId, locationVisitId, trackingSiteId, jobSiteId, scanStatus, active, createdBegin, createdEnd, search, sortBy, offset, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * list aggregate items left behind per job site
     * @summary list aggregate items left behind
     * @param {string} [sortBy] First sort column
     * @param {number} [offset] Offset
     * @param {number} [limit] Limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public listItemsLeftBehindAgg(sortBy?: string, offset?: number, limit?: number, options?: RawAxiosRequestConfig) {
        return ItemsApiFp(this.configuration).listItemsLeftBehindAgg(sortBy, offset, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * list aggregate items recovered completely
     * @summary list aggregate items recovered completely
     * @param {string} [sortBy] First sort column
     * @param {number} [offset] Offset
     * @param {number} [limit] Limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public listItemsRecoveredAgg(sortBy?: string, offset?: number, limit?: number, options?: RawAxiosRequestConfig) {
        return ItemsApiFp(this.configuration).listItemsRecoveredAgg(sortBy, offset, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * update product item
     * @summary update product item
     * @param {ItemRequestUpdateItem} item Item item object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public updateItem(item: ItemRequestUpdateItem, options?: RawAxiosRequestConfig) {
        return ItemsApiFp(this.configuration).updateItem(item, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * LocationVisitsApi - axios parameter creator
 * @export
 */
export const LocationVisitsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * get location-visit by location_visit_id
         * @summary Get location visit details
         * @param {string} locationVisitId LocationVisit ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocationVisitById: async (locationVisitId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'locationVisitId' is not null or undefined
            assertParamExists('getLocationVisitById', 'locationVisitId', locationVisitId)
            const localVarPath = `/location-visits/{location_visit_id}`
                .replace(`{${"location_visit_id"}}`, encodeURIComponent(String(locationVisitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get location-visits-items
         * @summary Get location visit items
         * @param {string} [usageType] item usage type (comma delimitted): usage, left behind, recovered
         * @param {string} [processedBegin] Begin time
         * @param {string} [processedEnd] End time
         * @param {string} [productTypeId] Product Type ID
         * @param {string} [vehicleId] Vehicle ID
         * @param {string} [siteId] Site ID
         * @param {string} [manufacturerId] Manufacturer ID
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocationVisitItems: async (usageType?: string, processedBegin?: string, processedEnd?: string, productTypeId?: string, vehicleId?: string, siteId?: string, manufacturerId?: string, sortBy?: string, offset?: number, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/location-visit-items`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (usageType !== undefined) {
                localVarQueryParameter['usage_type'] = usageType;
            }

            if (processedBegin !== undefined) {
                localVarQueryParameter['processed_begin'] = processedBegin;
            }

            if (processedEnd !== undefined) {
                localVarQueryParameter['processed_end'] = processedEnd;
            }

            if (productTypeId !== undefined) {
                localVarQueryParameter['product_type_id'] = productTypeId;
            }

            if (vehicleId !== undefined) {
                localVarQueryParameter['vehicle_id'] = vehicleId;
            }

            if (siteId !== undefined) {
                localVarQueryParameter['site_id'] = siteId;
            }

            if (manufacturerId !== undefined) {
                localVarQueryParameter['manufacturer_id'] = manufacturerId;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get location-visits
         * @summary Get location visits for warehouse
         * @param {string} [warehouseId] warehouse_id to query
         * @param {string} [vehicleId] vehicle_id to query
         * @param {string} [siteId] site_id to query
         * @param {string} [itemId] item_id to query
         * @param {string} [productId] product_id to query
         * @param {string} [siteType] site type (company or customer)
         * @param {string} [processedBegin] Begin time
         * @param {string} [processedEnd] End time
         * @param {string} [search] Text wildcard search
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocationVisits: async (warehouseId?: string, vehicleId?: string, siteId?: string, itemId?: string, productId?: string, siteType?: string, processedBegin?: string, processedEnd?: string, search?: string, sortBy?: string, offset?: number, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/location-visits`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (warehouseId !== undefined) {
                localVarQueryParameter['warehouse_id'] = warehouseId;
            }

            if (vehicleId !== undefined) {
                localVarQueryParameter['vehicle_id'] = vehicleId;
            }

            if (siteId !== undefined) {
                localVarQueryParameter['site_id'] = siteId;
            }

            if (itemId !== undefined) {
                localVarQueryParameter['item_id'] = itemId;
            }

            if (productId !== undefined) {
                localVarQueryParameter['product_id'] = productId;
            }

            if (siteType !== undefined) {
                localVarQueryParameter['site_type'] = siteType;
            }

            if (processedBegin !== undefined) {
                localVarQueryParameter['processed_begin'] = processedBegin;
            }

            if (processedEnd !== undefined) {
                localVarQueryParameter['processed_end'] = processedEnd;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * update the jobsite of a location visit
         * @summary update the jobsite of a location visit
         * @param {LocationvisitRequestUpdateVisit} site Location Visit Update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLocationvisitJobsite: async (site: LocationvisitRequestUpdateVisit, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'site' is not null or undefined
            assertParamExists('updateLocationvisitJobsite', 'site', site)
            const localVarPath = `/location-visits`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(site, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LocationVisitsApi - functional programming interface
 * @export
 */
export const LocationVisitsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LocationVisitsApiAxiosParamCreator(configuration)
    return {
        /**
         * get location-visit by location_visit_id
         * @summary Get location visit details
         * @param {string} locationVisitId LocationVisit ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLocationVisitById(locationVisitId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LocationvisitObjectResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLocationVisitById(locationVisitId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['LocationVisitsApi.getLocationVisitById']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * get location-visits-items
         * @summary Get location visit items
         * @param {string} [usageType] item usage type (comma delimitted): usage, left behind, recovered
         * @param {string} [processedBegin] Begin time
         * @param {string} [processedEnd] End time
         * @param {string} [productTypeId] Product Type ID
         * @param {string} [vehicleId] Vehicle ID
         * @param {string} [siteId] Site ID
         * @param {string} [manufacturerId] Manufacturer ID
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLocationVisitItems(usageType?: string, processedBegin?: string, processedEnd?: string, productTypeId?: string, vehicleId?: string, siteId?: string, manufacturerId?: string, sortBy?: string, offset?: number, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LocationvisitListItemResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLocationVisitItems(usageType, processedBegin, processedEnd, productTypeId, vehicleId, siteId, manufacturerId, sortBy, offset, limit, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['LocationVisitsApi.getLocationVisitItems']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * get location-visits
         * @summary Get location visits for warehouse
         * @param {string} [warehouseId] warehouse_id to query
         * @param {string} [vehicleId] vehicle_id to query
         * @param {string} [siteId] site_id to query
         * @param {string} [itemId] item_id to query
         * @param {string} [productId] product_id to query
         * @param {string} [siteType] site type (company or customer)
         * @param {string} [processedBegin] Begin time
         * @param {string} [processedEnd] End time
         * @param {string} [search] Text wildcard search
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLocationVisits(warehouseId?: string, vehicleId?: string, siteId?: string, itemId?: string, productId?: string, siteType?: string, processedBegin?: string, processedEnd?: string, search?: string, sortBy?: string, offset?: number, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LocationvisitListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLocationVisits(warehouseId, vehicleId, siteId, itemId, productId, siteType, processedBegin, processedEnd, search, sortBy, offset, limit, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['LocationVisitsApi.getLocationVisits']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * update the jobsite of a location visit
         * @summary update the jobsite of a location visit
         * @param {LocationvisitRequestUpdateVisit} site Location Visit Update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateLocationvisitJobsite(site: LocationvisitRequestUpdateVisit, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LocationvisitObjectResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateLocationvisitJobsite(site, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['LocationVisitsApi.updateLocationvisitJobsite']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * LocationVisitsApi - factory interface
 * @export
 */
export const LocationVisitsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LocationVisitsApiFp(configuration)
    return {
        /**
         * get location-visit by location_visit_id
         * @summary Get location visit details
         * @param {string} locationVisitId LocationVisit ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocationVisitById(locationVisitId: string, options?: any): AxiosPromise<LocationvisitObjectResponse> {
            return localVarFp.getLocationVisitById(locationVisitId, options).then((request) => request(axios, basePath));
        },
        /**
         * get location-visits-items
         * @summary Get location visit items
         * @param {string} [usageType] item usage type (comma delimitted): usage, left behind, recovered
         * @param {string} [processedBegin] Begin time
         * @param {string} [processedEnd] End time
         * @param {string} [productTypeId] Product Type ID
         * @param {string} [vehicleId] Vehicle ID
         * @param {string} [siteId] Site ID
         * @param {string} [manufacturerId] Manufacturer ID
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocationVisitItems(usageType?: string, processedBegin?: string, processedEnd?: string, productTypeId?: string, vehicleId?: string, siteId?: string, manufacturerId?: string, sortBy?: string, offset?: number, limit?: number, options?: any): AxiosPromise<LocationvisitListItemResponse> {
            return localVarFp.getLocationVisitItems(usageType, processedBegin, processedEnd, productTypeId, vehicleId, siteId, manufacturerId, sortBy, offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * get location-visits
         * @summary Get location visits for warehouse
         * @param {string} [warehouseId] warehouse_id to query
         * @param {string} [vehicleId] vehicle_id to query
         * @param {string} [siteId] site_id to query
         * @param {string} [itemId] item_id to query
         * @param {string} [productId] product_id to query
         * @param {string} [siteType] site type (company or customer)
         * @param {string} [processedBegin] Begin time
         * @param {string} [processedEnd] End time
         * @param {string} [search] Text wildcard search
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocationVisits(warehouseId?: string, vehicleId?: string, siteId?: string, itemId?: string, productId?: string, siteType?: string, processedBegin?: string, processedEnd?: string, search?: string, sortBy?: string, offset?: number, limit?: number, options?: any): AxiosPromise<LocationvisitListResponse> {
            return localVarFp.getLocationVisits(warehouseId, vehicleId, siteId, itemId, productId, siteType, processedBegin, processedEnd, search, sortBy, offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * update the jobsite of a location visit
         * @summary update the jobsite of a location visit
         * @param {LocationvisitRequestUpdateVisit} site Location Visit Update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLocationvisitJobsite(site: LocationvisitRequestUpdateVisit, options?: any): AxiosPromise<LocationvisitObjectResponse> {
            return localVarFp.updateLocationvisitJobsite(site, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LocationVisitsApi - object-oriented interface
 * @export
 * @class LocationVisitsApi
 * @extends {BaseAPI}
 */
export class LocationVisitsApi extends BaseAPI {
    /**
     * get location-visit by location_visit_id
     * @summary Get location visit details
     * @param {string} locationVisitId LocationVisit ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationVisitsApi
     */
    public getLocationVisitById(locationVisitId: string, options?: RawAxiosRequestConfig) {
        return LocationVisitsApiFp(this.configuration).getLocationVisitById(locationVisitId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get location-visits-items
     * @summary Get location visit items
     * @param {string} [usageType] item usage type (comma delimitted): usage, left behind, recovered
     * @param {string} [processedBegin] Begin time
     * @param {string} [processedEnd] End time
     * @param {string} [productTypeId] Product Type ID
     * @param {string} [vehicleId] Vehicle ID
     * @param {string} [siteId] Site ID
     * @param {string} [manufacturerId] Manufacturer ID
     * @param {string} [sortBy] First sort column
     * @param {number} [offset] Offset
     * @param {number} [limit] Limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationVisitsApi
     */
    public getLocationVisitItems(usageType?: string, processedBegin?: string, processedEnd?: string, productTypeId?: string, vehicleId?: string, siteId?: string, manufacturerId?: string, sortBy?: string, offset?: number, limit?: number, options?: RawAxiosRequestConfig) {
        return LocationVisitsApiFp(this.configuration).getLocationVisitItems(usageType, processedBegin, processedEnd, productTypeId, vehicleId, siteId, manufacturerId, sortBy, offset, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get location-visits
     * @summary Get location visits for warehouse
     * @param {string} [warehouseId] warehouse_id to query
     * @param {string} [vehicleId] vehicle_id to query
     * @param {string} [siteId] site_id to query
     * @param {string} [itemId] item_id to query
     * @param {string} [productId] product_id to query
     * @param {string} [siteType] site type (company or customer)
     * @param {string} [processedBegin] Begin time
     * @param {string} [processedEnd] End time
     * @param {string} [search] Text wildcard search
     * @param {string} [sortBy] First sort column
     * @param {number} [offset] Offset
     * @param {number} [limit] Limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationVisitsApi
     */
    public getLocationVisits(warehouseId?: string, vehicleId?: string, siteId?: string, itemId?: string, productId?: string, siteType?: string, processedBegin?: string, processedEnd?: string, search?: string, sortBy?: string, offset?: number, limit?: number, options?: RawAxiosRequestConfig) {
        return LocationVisitsApiFp(this.configuration).getLocationVisits(warehouseId, vehicleId, siteId, itemId, productId, siteType, processedBegin, processedEnd, search, sortBy, offset, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * update the jobsite of a location visit
     * @summary update the jobsite of a location visit
     * @param {LocationvisitRequestUpdateVisit} site Location Visit Update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationVisitsApi
     */
    public updateLocationvisitJobsite(site: LocationvisitRequestUpdateVisit, options?: RawAxiosRequestConfig) {
        return LocationVisitsApiFp(this.configuration).updateLocationvisitJobsite(site, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * MaintenanceApi - axios parameter creator
 * @export
 */
export const MaintenanceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * create maintenance_event object
         * @summary create maintenance_event
         * @param {MaintenanceEventRequestNewEvent} newItem maintenance_event object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMaintenanceEvent: async (newItem: MaintenanceEventRequestNewEvent, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'newItem' is not null or undefined
            assertParamExists('createMaintenanceEvent', 'newItem', newItem)
            const localVarPath = `/maintenance-events`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newItem, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * delete maintenance_event
         * @summary delete maintenance_event
         * @param {string} id Maintenance Event ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMaintenanceEventById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteMaintenanceEventById', 'id', id)
            const localVarPath = `/maintenance-events/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get maintenance_event object by UUID
         * @summary get maintenance_event object
         * @param {string} id Maintenance Event ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMaintenanceEventById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getMaintenanceEventById', 'id', id)
            const localVarPath = `/maintenance-events/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * list all maintenance-events object
         * @summary list all maintenance-events object
         * @param {string} [itemId] item_id to query
         * @param {string} [dueDateBegin] Due date begin
         * @param {string} [dueDateEnd] Due date end
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMaintenanceEvents: async (itemId?: string, dueDateBegin?: string, dueDateEnd?: string, sortBy?: string, offset?: number, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/maintenance-events`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (itemId !== undefined) {
                localVarQueryParameter['item_id'] = itemId;
            }

            if (dueDateBegin !== undefined) {
                localVarQueryParameter['due_date_begin'] = dueDateBegin;
            }

            if (dueDateEnd !== undefined) {
                localVarQueryParameter['due_date_end'] = dueDateEnd;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * list all recurrence types
         * @summary list all recurrence types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRecurrenceTypes: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/recurrence-types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * update maintenance_event
         * @summary update maintenance_event
         * @param {MaintenanceEventRequestUpdateEvent} event Maintenance Event object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMaintenanceEvent: async (event: MaintenanceEventRequestUpdateEvent, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'event' is not null or undefined
            assertParamExists('updateMaintenanceEvent', 'event', event)
            const localVarPath = `/maintenance-events`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(event, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MaintenanceApi - functional programming interface
 * @export
 */
export const MaintenanceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MaintenanceApiAxiosParamCreator(configuration)
    return {
        /**
         * create maintenance_event object
         * @summary create maintenance_event
         * @param {MaintenanceEventRequestNewEvent} newItem maintenance_event object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createMaintenanceEvent(newItem: MaintenanceEventRequestNewEvent, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MaintenanceEventObjectResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createMaintenanceEvent(newItem, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['MaintenanceApi.createMaintenanceEvent']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * delete maintenance_event
         * @summary delete maintenance_event
         * @param {string} id Maintenance Event ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMaintenanceEventById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HandlerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMaintenanceEventById(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['MaintenanceApi.deleteMaintenanceEventById']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * get maintenance_event object by UUID
         * @summary get maintenance_event object
         * @param {string} id Maintenance Event ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMaintenanceEventById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MaintenanceEventObjectResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMaintenanceEventById(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['MaintenanceApi.getMaintenanceEventById']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * list all maintenance-events object
         * @summary list all maintenance-events object
         * @param {string} [itemId] item_id to query
         * @param {string} [dueDateBegin] Due date begin
         * @param {string} [dueDateEnd] Due date end
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listMaintenanceEvents(itemId?: string, dueDateBegin?: string, dueDateEnd?: string, sortBy?: string, offset?: number, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MaintenanceEventListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listMaintenanceEvents(itemId, dueDateBegin, dueDateEnd, sortBy, offset, limit, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['MaintenanceApi.listMaintenanceEvents']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * list all recurrence types
         * @summary list all recurrence types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listRecurrenceTypes(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MaintenanceEventListRecurrenceTypeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listRecurrenceTypes(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['MaintenanceApi.listRecurrenceTypes']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * update maintenance_event
         * @summary update maintenance_event
         * @param {MaintenanceEventRequestUpdateEvent} event Maintenance Event object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMaintenanceEvent(event: MaintenanceEventRequestUpdateEvent, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MaintenanceEventObjectResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMaintenanceEvent(event, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['MaintenanceApi.updateMaintenanceEvent']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * MaintenanceApi - factory interface
 * @export
 */
export const MaintenanceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MaintenanceApiFp(configuration)
    return {
        /**
         * create maintenance_event object
         * @summary create maintenance_event
         * @param {MaintenanceEventRequestNewEvent} newItem maintenance_event object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMaintenanceEvent(newItem: MaintenanceEventRequestNewEvent, options?: any): AxiosPromise<MaintenanceEventObjectResponse> {
            return localVarFp.createMaintenanceEvent(newItem, options).then((request) => request(axios, basePath));
        },
        /**
         * delete maintenance_event
         * @summary delete maintenance_event
         * @param {string} id Maintenance Event ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMaintenanceEventById(id: string, options?: any): AxiosPromise<HandlerResponse> {
            return localVarFp.deleteMaintenanceEventById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * get maintenance_event object by UUID
         * @summary get maintenance_event object
         * @param {string} id Maintenance Event ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMaintenanceEventById(id: string, options?: any): AxiosPromise<MaintenanceEventObjectResponse> {
            return localVarFp.getMaintenanceEventById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * list all maintenance-events object
         * @summary list all maintenance-events object
         * @param {string} [itemId] item_id to query
         * @param {string} [dueDateBegin] Due date begin
         * @param {string} [dueDateEnd] Due date end
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMaintenanceEvents(itemId?: string, dueDateBegin?: string, dueDateEnd?: string, sortBy?: string, offset?: number, limit?: number, options?: any): AxiosPromise<MaintenanceEventListResponse> {
            return localVarFp.listMaintenanceEvents(itemId, dueDateBegin, dueDateEnd, sortBy, offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * list all recurrence types
         * @summary list all recurrence types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRecurrenceTypes(options?: any): AxiosPromise<MaintenanceEventListRecurrenceTypeResponse> {
            return localVarFp.listRecurrenceTypes(options).then((request) => request(axios, basePath));
        },
        /**
         * update maintenance_event
         * @summary update maintenance_event
         * @param {MaintenanceEventRequestUpdateEvent} event Maintenance Event object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMaintenanceEvent(event: MaintenanceEventRequestUpdateEvent, options?: any): AxiosPromise<MaintenanceEventObjectResponse> {
            return localVarFp.updateMaintenanceEvent(event, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MaintenanceApi - object-oriented interface
 * @export
 * @class MaintenanceApi
 * @extends {BaseAPI}
 */
export class MaintenanceApi extends BaseAPI {
    /**
     * create maintenance_event object
     * @summary create maintenance_event
     * @param {MaintenanceEventRequestNewEvent} newItem maintenance_event object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaintenanceApi
     */
    public createMaintenanceEvent(newItem: MaintenanceEventRequestNewEvent, options?: RawAxiosRequestConfig) {
        return MaintenanceApiFp(this.configuration).createMaintenanceEvent(newItem, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * delete maintenance_event
     * @summary delete maintenance_event
     * @param {string} id Maintenance Event ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaintenanceApi
     */
    public deleteMaintenanceEventById(id: string, options?: RawAxiosRequestConfig) {
        return MaintenanceApiFp(this.configuration).deleteMaintenanceEventById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get maintenance_event object by UUID
     * @summary get maintenance_event object
     * @param {string} id Maintenance Event ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaintenanceApi
     */
    public getMaintenanceEventById(id: string, options?: RawAxiosRequestConfig) {
        return MaintenanceApiFp(this.configuration).getMaintenanceEventById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * list all maintenance-events object
     * @summary list all maintenance-events object
     * @param {string} [itemId] item_id to query
     * @param {string} [dueDateBegin] Due date begin
     * @param {string} [dueDateEnd] Due date end
     * @param {string} [sortBy] First sort column
     * @param {number} [offset] Offset
     * @param {number} [limit] Limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaintenanceApi
     */
    public listMaintenanceEvents(itemId?: string, dueDateBegin?: string, dueDateEnd?: string, sortBy?: string, offset?: number, limit?: number, options?: RawAxiosRequestConfig) {
        return MaintenanceApiFp(this.configuration).listMaintenanceEvents(itemId, dueDateBegin, dueDateEnd, sortBy, offset, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * list all recurrence types
     * @summary list all recurrence types
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaintenanceApi
     */
    public listRecurrenceTypes(options?: RawAxiosRequestConfig) {
        return MaintenanceApiFp(this.configuration).listRecurrenceTypes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * update maintenance_event
     * @summary update maintenance_event
     * @param {MaintenanceEventRequestUpdateEvent} event Maintenance Event object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaintenanceApi
     */
    public updateMaintenanceEvent(event: MaintenanceEventRequestUpdateEvent, options?: RawAxiosRequestConfig) {
        return MaintenanceApiFp(this.configuration).updateMaintenanceEvent(event, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ManufacturersApi - axios parameter creator
 * @export
 */
export const ManufacturersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * create manufacturer object
         * @summary create manufacturer
         * @param {ManufacturerRequestNewManufacturer} manufacturer Manufacturer object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createManufacturer: async (manufacturer: ManufacturerRequestNewManufacturer, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'manufacturer' is not null or undefined
            assertParamExists('createManufacturer', 'manufacturer', manufacturer)
            const localVarPath = `/manufacturers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(manufacturer, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * delete manufacturer
         * @summary delete manufacturer
         * @param {string} manufacturerId manufacturer ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteManufacturer: async (manufacturerId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'manufacturerId' is not null or undefined
            assertParamExists('deleteManufacturer', 'manufacturerId', manufacturerId)
            const localVarPath = `/manufacturers/{manufacturer_id}`
                .replace(`{${"manufacturer_id"}}`, encodeURIComponent(String(manufacturerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get manufacturer object by UUID
         * @summary get manufacturer object
         * @param {string} manufacturerId Manufacturer ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManufacturerById: async (manufacturerId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'manufacturerId' is not null or undefined
            assertParamExists('getManufacturerById', 'manufacturerId', manufacturerId)
            const localVarPath = `/manufacturers/details/{manufacturer_id}`
                .replace(`{${"manufacturer_id"}}`, encodeURIComponent(String(manufacturerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get manufacturer dependencies
         * @summary Check for dependencies on manufacturer
         * @param {string} manufacturerId Manufacturer ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManufacturerDependencies: async (manufacturerId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'manufacturerId' is not null or undefined
            assertParamExists('getManufacturerDependencies', 'manufacturerId', manufacturerId)
            const localVarPath = `/manufacturers/dependencies/{manufacturer_id}`
                .replace(`{${"manufacturer_id"}}`, encodeURIComponent(String(manufacturerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * list all manufacturers object
         * @summary list all manufacturers object
         * @param {string} [owned] owned item (1)
         * @param {string} [active] active flag
         * @param {string} [productType] product type (all, tool, stock)
         * @param {string} [verticalMarketId] vertical_market_id
         * @param {string} [search] Text wildcard search
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listManufacturers: async (owned?: string, active?: string, productType?: string, verticalMarketId?: string, search?: string, sortBy?: string, offset?: number, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/manufacturers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (owned !== undefined) {
                localVarQueryParameter['owned'] = owned;
            }

            if (active !== undefined) {
                localVarQueryParameter['active'] = active;
            }

            if (productType !== undefined) {
                localVarQueryParameter['product_type'] = productType;
            }

            if (verticalMarketId !== undefined) {
                localVarQueryParameter['vertical_market_id'] = verticalMarketId;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * update manufacturer
         * @summary update manufacturer
         * @param {ManufacturerRequestManufacturer} manufacturer Manufacturer object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateManufacturer: async (manufacturer: ManufacturerRequestManufacturer, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'manufacturer' is not null or undefined
            assertParamExists('updateManufacturer', 'manufacturer', manufacturer)
            const localVarPath = `/manufacturers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(manufacturer, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ManufacturersApi - functional programming interface
 * @export
 */
export const ManufacturersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ManufacturersApiAxiosParamCreator(configuration)
    return {
        /**
         * create manufacturer object
         * @summary create manufacturer
         * @param {ManufacturerRequestNewManufacturer} manufacturer Manufacturer object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createManufacturer(manufacturer: ManufacturerRequestNewManufacturer, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ManufacturerCreateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createManufacturer(manufacturer, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ManufacturersApi.createManufacturer']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * delete manufacturer
         * @summary delete manufacturer
         * @param {string} manufacturerId manufacturer ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteManufacturer(manufacturerId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HandlerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteManufacturer(manufacturerId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ManufacturersApi.deleteManufacturer']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * get manufacturer object by UUID
         * @summary get manufacturer object
         * @param {string} manufacturerId Manufacturer ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getManufacturerById(manufacturerId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ManufacturerCreateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getManufacturerById(manufacturerId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ManufacturersApi.getManufacturerById']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * get manufacturer dependencies
         * @summary Check for dependencies on manufacturer
         * @param {string} manufacturerId Manufacturer ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getManufacturerDependencies(manufacturerId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ManufacturerDependenciesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getManufacturerDependencies(manufacturerId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ManufacturersApi.getManufacturerDependencies']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * list all manufacturers object
         * @summary list all manufacturers object
         * @param {string} [owned] owned item (1)
         * @param {string} [active] active flag
         * @param {string} [productType] product type (all, tool, stock)
         * @param {string} [verticalMarketId] vertical_market_id
         * @param {string} [search] Text wildcard search
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listManufacturers(owned?: string, active?: string, productType?: string, verticalMarketId?: string, search?: string, sortBy?: string, offset?: number, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ManufacturerListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listManufacturers(owned, active, productType, verticalMarketId, search, sortBy, offset, limit, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ManufacturersApi.listManufacturers']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * update manufacturer
         * @summary update manufacturer
         * @param {ManufacturerRequestManufacturer} manufacturer Manufacturer object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateManufacturer(manufacturer: ManufacturerRequestManufacturer, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ManufacturerObjectResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateManufacturer(manufacturer, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ManufacturersApi.updateManufacturer']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * ManufacturersApi - factory interface
 * @export
 */
export const ManufacturersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ManufacturersApiFp(configuration)
    return {
        /**
         * create manufacturer object
         * @summary create manufacturer
         * @param {ManufacturerRequestNewManufacturer} manufacturer Manufacturer object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createManufacturer(manufacturer: ManufacturerRequestNewManufacturer, options?: any): AxiosPromise<ManufacturerCreateResponse> {
            return localVarFp.createManufacturer(manufacturer, options).then((request) => request(axios, basePath));
        },
        /**
         * delete manufacturer
         * @summary delete manufacturer
         * @param {string} manufacturerId manufacturer ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteManufacturer(manufacturerId: string, options?: any): AxiosPromise<HandlerResponse> {
            return localVarFp.deleteManufacturer(manufacturerId, options).then((request) => request(axios, basePath));
        },
        /**
         * get manufacturer object by UUID
         * @summary get manufacturer object
         * @param {string} manufacturerId Manufacturer ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManufacturerById(manufacturerId: string, options?: any): AxiosPromise<ManufacturerCreateResponse> {
            return localVarFp.getManufacturerById(manufacturerId, options).then((request) => request(axios, basePath));
        },
        /**
         * get manufacturer dependencies
         * @summary Check for dependencies on manufacturer
         * @param {string} manufacturerId Manufacturer ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManufacturerDependencies(manufacturerId: string, options?: any): AxiosPromise<ManufacturerDependenciesResponse> {
            return localVarFp.getManufacturerDependencies(manufacturerId, options).then((request) => request(axios, basePath));
        },
        /**
         * list all manufacturers object
         * @summary list all manufacturers object
         * @param {string} [owned] owned item (1)
         * @param {string} [active] active flag
         * @param {string} [productType] product type (all, tool, stock)
         * @param {string} [verticalMarketId] vertical_market_id
         * @param {string} [search] Text wildcard search
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listManufacturers(owned?: string, active?: string, productType?: string, verticalMarketId?: string, search?: string, sortBy?: string, offset?: number, limit?: number, options?: any): AxiosPromise<ManufacturerListResponse> {
            return localVarFp.listManufacturers(owned, active, productType, verticalMarketId, search, sortBy, offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * update manufacturer
         * @summary update manufacturer
         * @param {ManufacturerRequestManufacturer} manufacturer Manufacturer object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateManufacturer(manufacturer: ManufacturerRequestManufacturer, options?: any): AxiosPromise<ManufacturerObjectResponse> {
            return localVarFp.updateManufacturer(manufacturer, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ManufacturersApi - object-oriented interface
 * @export
 * @class ManufacturersApi
 * @extends {BaseAPI}
 */
export class ManufacturersApi extends BaseAPI {
    /**
     * create manufacturer object
     * @summary create manufacturer
     * @param {ManufacturerRequestNewManufacturer} manufacturer Manufacturer object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManufacturersApi
     */
    public createManufacturer(manufacturer: ManufacturerRequestNewManufacturer, options?: RawAxiosRequestConfig) {
        return ManufacturersApiFp(this.configuration).createManufacturer(manufacturer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * delete manufacturer
     * @summary delete manufacturer
     * @param {string} manufacturerId manufacturer ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManufacturersApi
     */
    public deleteManufacturer(manufacturerId: string, options?: RawAxiosRequestConfig) {
        return ManufacturersApiFp(this.configuration).deleteManufacturer(manufacturerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get manufacturer object by UUID
     * @summary get manufacturer object
     * @param {string} manufacturerId Manufacturer ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManufacturersApi
     */
    public getManufacturerById(manufacturerId: string, options?: RawAxiosRequestConfig) {
        return ManufacturersApiFp(this.configuration).getManufacturerById(manufacturerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get manufacturer dependencies
     * @summary Check for dependencies on manufacturer
     * @param {string} manufacturerId Manufacturer ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManufacturersApi
     */
    public getManufacturerDependencies(manufacturerId: string, options?: RawAxiosRequestConfig) {
        return ManufacturersApiFp(this.configuration).getManufacturerDependencies(manufacturerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * list all manufacturers object
     * @summary list all manufacturers object
     * @param {string} [owned] owned item (1)
     * @param {string} [active] active flag
     * @param {string} [productType] product type (all, tool, stock)
     * @param {string} [verticalMarketId] vertical_market_id
     * @param {string} [search] Text wildcard search
     * @param {string} [sortBy] First sort column
     * @param {number} [offset] Offset
     * @param {number} [limit] Limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManufacturersApi
     */
    public listManufacturers(owned?: string, active?: string, productType?: string, verticalMarketId?: string, search?: string, sortBy?: string, offset?: number, limit?: number, options?: RawAxiosRequestConfig) {
        return ManufacturersApiFp(this.configuration).listManufacturers(owned, active, productType, verticalMarketId, search, sortBy, offset, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * update manufacturer
     * @summary update manufacturer
     * @param {ManufacturerRequestManufacturer} manufacturer Manufacturer object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManufacturersApi
     */
    public updateManufacturer(manufacturer: ManufacturerRequestManufacturer, options?: RawAxiosRequestConfig) {
        return ManufacturersApiFp(this.configuration).updateManufacturer(manufacturer, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * MonitoringApi - axios parameter creator
 * @export
 */
export const MonitoringApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * all payloads submitted today
         * @summary recent payloads submitted for all organizations & vehicles
         * @param {string} [organizationId] Organization ID
         * @param {string} [vehicleId] Vehicle ID
         * @param {string} [processedBegin] Processed begin date/time
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        monitorPayloadsSubmitted: async (organizationId?: string, vehicleId?: string, processedBegin?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/monitoring/submitted_payloads`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (organizationId !== undefined) {
                localVarQueryParameter['organization_id'] = organizationId;
            }

            if (vehicleId !== undefined) {
                localVarQueryParameter['vehicle_id'] = vehicleId;
            }

            if (processedBegin !== undefined) {
                localVarQueryParameter['processed_begin'] = processedBegin;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MonitoringApi - functional programming interface
 * @export
 */
export const MonitoringApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MonitoringApiAxiosParamCreator(configuration)
    return {
        /**
         * all payloads submitted today
         * @summary recent payloads submitted for all organizations & vehicles
         * @param {string} [organizationId] Organization ID
         * @param {string} [vehicleId] Vehicle ID
         * @param {string} [processedBegin] Processed begin date/time
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async monitorPayloadsSubmitted(organizationId?: string, vehicleId?: string, processedBegin?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MonitoringPayloadsSubmittedResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.monitorPayloadsSubmitted(organizationId, vehicleId, processedBegin, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['MonitoringApi.monitorPayloadsSubmitted']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * MonitoringApi - factory interface
 * @export
 */
export const MonitoringApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MonitoringApiFp(configuration)
    return {
        /**
         * all payloads submitted today
         * @summary recent payloads submitted for all organizations & vehicles
         * @param {string} [organizationId] Organization ID
         * @param {string} [vehicleId] Vehicle ID
         * @param {string} [processedBegin] Processed begin date/time
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        monitorPayloadsSubmitted(organizationId?: string, vehicleId?: string, processedBegin?: string, options?: any): AxiosPromise<MonitoringPayloadsSubmittedResponse> {
            return localVarFp.monitorPayloadsSubmitted(organizationId, vehicleId, processedBegin, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MonitoringApi - object-oriented interface
 * @export
 * @class MonitoringApi
 * @extends {BaseAPI}
 */
export class MonitoringApi extends BaseAPI {
    /**
     * all payloads submitted today
     * @summary recent payloads submitted for all organizations & vehicles
     * @param {string} [organizationId] Organization ID
     * @param {string} [vehicleId] Vehicle ID
     * @param {string} [processedBegin] Processed begin date/time
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MonitoringApi
     */
    public monitorPayloadsSubmitted(organizationId?: string, vehicleId?: string, processedBegin?: string, options?: RawAxiosRequestConfig) {
        return MonitoringApiFp(this.configuration).monitorPayloadsSubmitted(organizationId, vehicleId, processedBegin, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * OperationsApi - axios parameter creator
 * @export
 */
export const OperationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * get delayed payloads
         * @summary get delayed payloads
         * @param {string} dateCutoff UTC cutoff for payloads (2006-01-02 15:04)
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDelayedPayloads: async (dateCutoff: string, sortBy?: string, offset?: number, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dateCutoff' is not null or undefined
            assertParamExists('getDelayedPayloads', 'dateCutoff', dateCutoff)
            const localVarPath = `/operations/delayed-payloads-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (dateCutoff !== undefined) {
                localVarQueryParameter['date_cutoff'] = dateCutoff;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get device events
         * @summary get device events
         * @param {string} dateCutoff UTC cutoff for device event metrics (2006-01-02 15:04)
         * @param {string} [errorFilter] Flag to show success or error return_codes
         * @param {string} [batchId] batch_id to filter on
         * @param {string} [deviceId] device_id to filter on
         * @param {string} [source] source to filter on
         * @param {string} [event] event to filter on
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeviceEvents: async (dateCutoff: string, errorFilter?: string, batchId?: string, deviceId?: string, source?: string, event?: string, sortBy?: string, offset?: number, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dateCutoff' is not null or undefined
            assertParamExists('getDeviceEvents', 'dateCutoff', dateCutoff)
            const localVarPath = `/operations/device-events-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (dateCutoff !== undefined) {
                localVarQueryParameter['date_cutoff'] = dateCutoff;
            }

            if (errorFilter !== undefined) {
                localVarQueryParameter['error_filter'] = errorFilter;
            }

            if (batchId !== undefined) {
                localVarQueryParameter['batch_id'] = batchId;
            }

            if (deviceId !== undefined) {
                localVarQueryParameter['device_id'] = deviceId;
            }

            if (source !== undefined) {
                localVarQueryParameter['source'] = source;
            }

            if (event !== undefined) {
                localVarQueryParameter['event'] = event;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get aggregate device events
         * @summary get aggregate device events
         * @param {string} dateCutoff UTC cutoff for device event metrics (2006-01-02 15:04)
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeviceEventsAggregate: async (dateCutoff: string, sortBy?: string, offset?: number, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dateCutoff' is not null or undefined
            assertParamExists('getDeviceEventsAggregate', 'dateCutoff', dateCutoff)
            const localVarPath = `/operations/device-events-aggregate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (dateCutoff !== undefined) {
                localVarQueryParameter['date_cutoff'] = dateCutoff;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get device metrics
         * @summary get device metrics
         * @param {string} [dateCutoff] UTC cutoff for device event metrics
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeviceMetrics: async (dateCutoff?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/operations/device-metrics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (dateCutoff !== undefined) {
                localVarQueryParameter['date_cutoff'] = dateCutoff;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get device statuses
         * @summary get device statuses
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeviceStatuses: async (sortBy?: string, offset?: number, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/operations/device-statuses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get phantom onroad payloads
         * @summary get phantom onroad payloads
         * @param {string} dateCutoff UTC cutoff for payloads (2006-01-02 15:04)
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPhantomOnroadPayloads: async (dateCutoff: string, sortBy?: string, offset?: number, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dateCutoff' is not null or undefined
            assertParamExists('getPhantomOnroadPayloads', 'dateCutoff', dateCutoff)
            const localVarPath = `/operations/phantom-onroad-payloads-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (dateCutoff !== undefined) {
                localVarQueryParameter['date_cutoff'] = dateCutoff;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * returns list of major BE services and their status
         * @summary returns list of major BE services and their status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServiceStatusList: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/operations/service-status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OperationsApi - functional programming interface
 * @export
 */
export const OperationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OperationsApiAxiosParamCreator(configuration)
    return {
        /**
         * get delayed payloads
         * @summary get delayed payloads
         * @param {string} dateCutoff UTC cutoff for payloads (2006-01-02 15:04)
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDelayedPayloads(dateCutoff: string, sortBy?: string, offset?: number, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OperationsDelayedPayloadsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDelayedPayloads(dateCutoff, sortBy, offset, limit, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['OperationsApi.getDelayedPayloads']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * get device events
         * @summary get device events
         * @param {string} dateCutoff UTC cutoff for device event metrics (2006-01-02 15:04)
         * @param {string} [errorFilter] Flag to show success or error return_codes
         * @param {string} [batchId] batch_id to filter on
         * @param {string} [deviceId] device_id to filter on
         * @param {string} [source] source to filter on
         * @param {string} [event] event to filter on
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDeviceEvents(dateCutoff: string, errorFilter?: string, batchId?: string, deviceId?: string, source?: string, event?: string, sortBy?: string, offset?: number, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OperationsDeviceEventResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDeviceEvents(dateCutoff, errorFilter, batchId, deviceId, source, event, sortBy, offset, limit, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['OperationsApi.getDeviceEvents']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * get aggregate device events
         * @summary get aggregate device events
         * @param {string} dateCutoff UTC cutoff for device event metrics (2006-01-02 15:04)
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDeviceEventsAggregate(dateCutoff: string, sortBy?: string, offset?: number, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OperationsDeviceEventAggregateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDeviceEventsAggregate(dateCutoff, sortBy, offset, limit, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['OperationsApi.getDeviceEventsAggregate']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * get device metrics
         * @summary get device metrics
         * @param {string} [dateCutoff] UTC cutoff for device event metrics
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDeviceMetrics(dateCutoff?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OperationsMetricsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDeviceMetrics(dateCutoff, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['OperationsApi.getDeviceMetrics']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * get device statuses
         * @summary get device statuses
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDeviceStatuses(sortBy?: string, offset?: number, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OperationsDeviceStatusesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDeviceStatuses(sortBy, offset, limit, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['OperationsApi.getDeviceStatuses']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * get phantom onroad payloads
         * @summary get phantom onroad payloads
         * @param {string} dateCutoff UTC cutoff for payloads (2006-01-02 15:04)
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPhantomOnroadPayloads(dateCutoff: string, sortBy?: string, offset?: number, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OperationsPhantomOnroadPayloadsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPhantomOnroadPayloads(dateCutoff, sortBy, offset, limit, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['OperationsApi.getPhantomOnroadPayloads']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * returns list of major BE services and their status
         * @summary returns list of major BE services and their status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getServiceStatusList(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OperationsServiceStatusListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getServiceStatusList(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['OperationsApi.getServiceStatusList']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * OperationsApi - factory interface
 * @export
 */
export const OperationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OperationsApiFp(configuration)
    return {
        /**
         * get delayed payloads
         * @summary get delayed payloads
         * @param {string} dateCutoff UTC cutoff for payloads (2006-01-02 15:04)
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDelayedPayloads(dateCutoff: string, sortBy?: string, offset?: number, limit?: number, options?: any): AxiosPromise<OperationsDelayedPayloadsResponse> {
            return localVarFp.getDelayedPayloads(dateCutoff, sortBy, offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * get device events
         * @summary get device events
         * @param {string} dateCutoff UTC cutoff for device event metrics (2006-01-02 15:04)
         * @param {string} [errorFilter] Flag to show success or error return_codes
         * @param {string} [batchId] batch_id to filter on
         * @param {string} [deviceId] device_id to filter on
         * @param {string} [source] source to filter on
         * @param {string} [event] event to filter on
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeviceEvents(dateCutoff: string, errorFilter?: string, batchId?: string, deviceId?: string, source?: string, event?: string, sortBy?: string, offset?: number, limit?: number, options?: any): AxiosPromise<OperationsDeviceEventResponse> {
            return localVarFp.getDeviceEvents(dateCutoff, errorFilter, batchId, deviceId, source, event, sortBy, offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * get aggregate device events
         * @summary get aggregate device events
         * @param {string} dateCutoff UTC cutoff for device event metrics (2006-01-02 15:04)
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeviceEventsAggregate(dateCutoff: string, sortBy?: string, offset?: number, limit?: number, options?: any): AxiosPromise<OperationsDeviceEventAggregateResponse> {
            return localVarFp.getDeviceEventsAggregate(dateCutoff, sortBy, offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * get device metrics
         * @summary get device metrics
         * @param {string} [dateCutoff] UTC cutoff for device event metrics
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeviceMetrics(dateCutoff?: string, options?: any): AxiosPromise<OperationsMetricsResponse> {
            return localVarFp.getDeviceMetrics(dateCutoff, options).then((request) => request(axios, basePath));
        },
        /**
         * get device statuses
         * @summary get device statuses
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeviceStatuses(sortBy?: string, offset?: number, limit?: number, options?: any): AxiosPromise<OperationsDeviceStatusesResponse> {
            return localVarFp.getDeviceStatuses(sortBy, offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * get phantom onroad payloads
         * @summary get phantom onroad payloads
         * @param {string} dateCutoff UTC cutoff for payloads (2006-01-02 15:04)
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPhantomOnroadPayloads(dateCutoff: string, sortBy?: string, offset?: number, limit?: number, options?: any): AxiosPromise<OperationsPhantomOnroadPayloadsResponse> {
            return localVarFp.getPhantomOnroadPayloads(dateCutoff, sortBy, offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * returns list of major BE services and their status
         * @summary returns list of major BE services and their status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServiceStatusList(options?: any): AxiosPromise<OperationsServiceStatusListResponse> {
            return localVarFp.getServiceStatusList(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OperationsApi - object-oriented interface
 * @export
 * @class OperationsApi
 * @extends {BaseAPI}
 */
export class OperationsApi extends BaseAPI {
    /**
     * get delayed payloads
     * @summary get delayed payloads
     * @param {string} dateCutoff UTC cutoff for payloads (2006-01-02 15:04)
     * @param {string} [sortBy] First sort column
     * @param {number} [offset] Offset
     * @param {number} [limit] Limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperationsApi
     */
    public getDelayedPayloads(dateCutoff: string, sortBy?: string, offset?: number, limit?: number, options?: RawAxiosRequestConfig) {
        return OperationsApiFp(this.configuration).getDelayedPayloads(dateCutoff, sortBy, offset, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get device events
     * @summary get device events
     * @param {string} dateCutoff UTC cutoff for device event metrics (2006-01-02 15:04)
     * @param {string} [errorFilter] Flag to show success or error return_codes
     * @param {string} [batchId] batch_id to filter on
     * @param {string} [deviceId] device_id to filter on
     * @param {string} [source] source to filter on
     * @param {string} [event] event to filter on
     * @param {string} [sortBy] First sort column
     * @param {number} [offset] Offset
     * @param {number} [limit] Limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperationsApi
     */
    public getDeviceEvents(dateCutoff: string, errorFilter?: string, batchId?: string, deviceId?: string, source?: string, event?: string, sortBy?: string, offset?: number, limit?: number, options?: RawAxiosRequestConfig) {
        return OperationsApiFp(this.configuration).getDeviceEvents(dateCutoff, errorFilter, batchId, deviceId, source, event, sortBy, offset, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get aggregate device events
     * @summary get aggregate device events
     * @param {string} dateCutoff UTC cutoff for device event metrics (2006-01-02 15:04)
     * @param {string} [sortBy] First sort column
     * @param {number} [offset] Offset
     * @param {number} [limit] Limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperationsApi
     */
    public getDeviceEventsAggregate(dateCutoff: string, sortBy?: string, offset?: number, limit?: number, options?: RawAxiosRequestConfig) {
        return OperationsApiFp(this.configuration).getDeviceEventsAggregate(dateCutoff, sortBy, offset, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get device metrics
     * @summary get device metrics
     * @param {string} [dateCutoff] UTC cutoff for device event metrics
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperationsApi
     */
    public getDeviceMetrics(dateCutoff?: string, options?: RawAxiosRequestConfig) {
        return OperationsApiFp(this.configuration).getDeviceMetrics(dateCutoff, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get device statuses
     * @summary get device statuses
     * @param {string} [sortBy] First sort column
     * @param {number} [offset] Offset
     * @param {number} [limit] Limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperationsApi
     */
    public getDeviceStatuses(sortBy?: string, offset?: number, limit?: number, options?: RawAxiosRequestConfig) {
        return OperationsApiFp(this.configuration).getDeviceStatuses(sortBy, offset, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get phantom onroad payloads
     * @summary get phantom onroad payloads
     * @param {string} dateCutoff UTC cutoff for payloads (2006-01-02 15:04)
     * @param {string} [sortBy] First sort column
     * @param {number} [offset] Offset
     * @param {number} [limit] Limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperationsApi
     */
    public getPhantomOnroadPayloads(dateCutoff: string, sortBy?: string, offset?: number, limit?: number, options?: RawAxiosRequestConfig) {
        return OperationsApiFp(this.configuration).getPhantomOnroadPayloads(dateCutoff, sortBy, offset, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * returns list of major BE services and their status
     * @summary returns list of major BE services and their status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperationsApi
     */
    public getServiceStatusList(options?: RawAxiosRequestConfig) {
        return OperationsApiFp(this.configuration).getServiceStatusList(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * OrganizationsApi - axios parameter creator
 * @export
 */
export const OrganizationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * add features for organization object
         * @summary add organization features
         * @param {string} organizationUuid Organization UUID
         * @param {OrganizationFeaturesAddRequest} features List of features UUIDs to add
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addFeaturesForOrganization: async (organizationUuid: string, features: OrganizationFeaturesAddRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationUuid' is not null or undefined
            assertParamExists('addFeaturesForOrganization', 'organizationUuid', organizationUuid)
            // verify required parameter 'features' is not null or undefined
            assertParamExists('addFeaturesForOrganization', 'features', features)
            const localVarPath = `/organizations/{organization_uuid}/features`
                .replace(`{${"organization_uuid"}}`, encodeURIComponent(String(organizationUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(features, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * create organization object
         * @summary create organization
         * @param {ModelOrganization} organization Organization object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrganization: async (organization: ModelOrganization, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organization' is not null or undefined
            assertParamExists('createOrganization', 'organization', organization)
            const localVarPath = `/organizations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(organization, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * delete features for organization object
         * @summary delete features from the organization
         * @param {string} organizationUuid Organization UUID
         * @param {OrganizationFeaturesDeleteRequest} features List of features UUIDs to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFeaturesFromOrganization: async (organizationUuid: string, features: OrganizationFeaturesDeleteRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationUuid' is not null or undefined
            assertParamExists('deleteFeaturesFromOrganization', 'organizationUuid', organizationUuid)
            // verify required parameter 'features' is not null or undefined
            assertParamExists('deleteFeaturesFromOrganization', 'features', features)
            const localVarPath = `/organizations/{organization_uuid}/features`
                .replace(`{${"organization_uuid"}}`, encodeURIComponent(String(organizationUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(features, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * delete organization by UUID
         * @summary delete organization by UUID
         * @param {string} organizationUuid Organization ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOrganization: async (organizationUuid: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationUuid' is not null or undefined
            assertParamExists('deleteOrganization', 'organizationUuid', organizationUuid)
            const localVarPath = `/organizations/{organization_uuid}`
                .replace(`{${"organization_uuid"}}`, encodeURIComponent(String(organizationUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get features for organization object
         * @summary get organization features
         * @param {string} organizationUuid Organization ID
         * @param {string} [unassigned] Unassigned (0/1)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeaturesForOrganization: async (organizationUuid: string, unassigned?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationUuid' is not null or undefined
            assertParamExists('getFeaturesForOrganization', 'organizationUuid', organizationUuid)
            const localVarPath = `/organizations/{organization_uuid}/features`
                .replace(`{${"organization_uuid"}}`, encodeURIComponent(String(organizationUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (unassigned !== undefined) {
                localVarQueryParameter['unassigned'] = unassigned;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get manufacturers for organization object
         * @summary get organization manufacturers
         * @param {string} organizationUuid Organization ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManufacturersForOrganization: async (organizationUuid: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationUuid' is not null or undefined
            assertParamExists('getManufacturersForOrganization', 'organizationUuid', organizationUuid)
            const localVarPath = `/organizations/{organization_uuid}/manufacturers`
                .replace(`{${"organization_uuid"}}`, encodeURIComponent(String(organizationUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get organization object by UUID
         * @summary get organization object
         * @param {string} organizationUuid Organization ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationByUuid: async (organizationUuid: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationUuid' is not null or undefined
            assertParamExists('getOrganizationByUuid', 'organizationUuid', organizationUuid)
            const localVarPath = `/organizations/{organization_uuid}`
                .replace(`{${"organization_uuid"}}`, encodeURIComponent(String(organizationUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get list of all organizations
         * @summary get list of all organizations
         * @param {string} [active] active status of item (0/1 or true/false) - defaults to true
         * @param {string} [verticalMarketId] vertical market id
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOrganizations: async (active?: string, verticalMarketId?: string, sortBy?: string, offset?: number, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/organizations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (active !== undefined) {
                localVarQueryParameter['active'] = active;
            }

            if (verticalMarketId !== undefined) {
                localVarQueryParameter['vertical_market_id'] = verticalMarketId;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * set manufacturers for organization object
         * @summary set organization manufacturers
         * @param {string} organizationUuid Organization ID
         * @param {OrganizationManufacturerSetRequest} manufacturers List of manufacturers ID to add and remove
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setManufacturersForOrganization: async (organizationUuid: string, manufacturers: OrganizationManufacturerSetRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationUuid' is not null or undefined
            assertParamExists('setManufacturersForOrganization', 'organizationUuid', organizationUuid)
            // verify required parameter 'manufacturers' is not null or undefined
            assertParamExists('setManufacturersForOrganization', 'manufacturers', manufacturers)
            const localVarPath = `/organizations/{organization_uuid}/manufacturers`
                .replace(`{${"organization_uuid"}}`, encodeURIComponent(String(organizationUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(manufacturers, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * update organization object
         * @summary update organization
         * @param {OrganizationOrganization} organization Organization object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrganization: async (organization: OrganizationOrganization, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organization' is not null or undefined
            assertParamExists('updateOrganization', 'organization', organization)
            const localVarPath = `/organizations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(organization, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrganizationsApi - functional programming interface
 * @export
 */
export const OrganizationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrganizationsApiAxiosParamCreator(configuration)
    return {
        /**
         * add features for organization object
         * @summary add organization features
         * @param {string} organizationUuid Organization UUID
         * @param {OrganizationFeaturesAddRequest} features List of features UUIDs to add
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addFeaturesForOrganization(organizationUuid: string, features: OrganizationFeaturesAddRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HandlerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addFeaturesForOrganization(organizationUuid, features, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['OrganizationsApi.addFeaturesForOrganization']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * create organization object
         * @summary create organization
         * @param {ModelOrganization} organization Organization object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOrganization(organization: ModelOrganization, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationOrganizationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOrganization(organization, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['OrganizationsApi.createOrganization']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * delete features for organization object
         * @summary delete features from the organization
         * @param {string} organizationUuid Organization UUID
         * @param {OrganizationFeaturesDeleteRequest} features List of features UUIDs to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFeaturesFromOrganization(organizationUuid: string, features: OrganizationFeaturesDeleteRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HandlerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFeaturesFromOrganization(organizationUuid, features, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['OrganizationsApi.deleteFeaturesFromOrganization']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * delete organization by UUID
         * @summary delete organization by UUID
         * @param {string} organizationUuid Organization ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOrganization(organizationUuid: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationOrganizationDeleteResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteOrganization(organizationUuid, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['OrganizationsApi.deleteOrganization']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * get features for organization object
         * @summary get organization features
         * @param {string} organizationUuid Organization ID
         * @param {string} [unassigned] Unassigned (0/1)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFeaturesForOrganization(organizationUuid: string, unassigned?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationFeaturesListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFeaturesForOrganization(organizationUuid, unassigned, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['OrganizationsApi.getFeaturesForOrganization']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * get manufacturers for organization object
         * @summary get organization manufacturers
         * @param {string} organizationUuid Organization ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getManufacturersForOrganization(organizationUuid: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationManufacturerListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getManufacturersForOrganization(organizationUuid, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['OrganizationsApi.getManufacturersForOrganization']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * get organization object by UUID
         * @summary get organization object
         * @param {string} organizationUuid Organization ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrganizationByUuid(organizationUuid: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationOrganizationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrganizationByUuid(organizationUuid, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['OrganizationsApi.getOrganizationByUuid']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * get list of all organizations
         * @summary get list of all organizations
         * @param {string} [active] active status of item (0/1 or true/false) - defaults to true
         * @param {string} [verticalMarketId] vertical market id
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listOrganizations(active?: string, verticalMarketId?: string, sortBy?: string, offset?: number, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listOrganizations(active, verticalMarketId, sortBy, offset, limit, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['OrganizationsApi.listOrganizations']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * set manufacturers for organization object
         * @summary set organization manufacturers
         * @param {string} organizationUuid Organization ID
         * @param {OrganizationManufacturerSetRequest} manufacturers List of manufacturers ID to add and remove
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setManufacturersForOrganization(organizationUuid: string, manufacturers: OrganizationManufacturerSetRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HandlerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setManufacturersForOrganization(organizationUuid, manufacturers, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['OrganizationsApi.setManufacturersForOrganization']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * update organization object
         * @summary update organization
         * @param {OrganizationOrganization} organization Organization object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOrganization(organization: OrganizationOrganization, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationOrganizationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOrganization(organization, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['OrganizationsApi.updateOrganization']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * OrganizationsApi - factory interface
 * @export
 */
export const OrganizationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrganizationsApiFp(configuration)
    return {
        /**
         * add features for organization object
         * @summary add organization features
         * @param {string} organizationUuid Organization UUID
         * @param {OrganizationFeaturesAddRequest} features List of features UUIDs to add
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addFeaturesForOrganization(organizationUuid: string, features: OrganizationFeaturesAddRequest, options?: any): AxiosPromise<HandlerResponse> {
            return localVarFp.addFeaturesForOrganization(organizationUuid, features, options).then((request) => request(axios, basePath));
        },
        /**
         * create organization object
         * @summary create organization
         * @param {ModelOrganization} organization Organization object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrganization(organization: ModelOrganization, options?: any): AxiosPromise<OrganizationOrganizationResponse> {
            return localVarFp.createOrganization(organization, options).then((request) => request(axios, basePath));
        },
        /**
         * delete features for organization object
         * @summary delete features from the organization
         * @param {string} organizationUuid Organization UUID
         * @param {OrganizationFeaturesDeleteRequest} features List of features UUIDs to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFeaturesFromOrganization(organizationUuid: string, features: OrganizationFeaturesDeleteRequest, options?: any): AxiosPromise<HandlerResponse> {
            return localVarFp.deleteFeaturesFromOrganization(organizationUuid, features, options).then((request) => request(axios, basePath));
        },
        /**
         * delete organization by UUID
         * @summary delete organization by UUID
         * @param {string} organizationUuid Organization ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOrganization(organizationUuid: string, options?: any): AxiosPromise<OrganizationOrganizationDeleteResponse> {
            return localVarFp.deleteOrganization(organizationUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * get features for organization object
         * @summary get organization features
         * @param {string} organizationUuid Organization ID
         * @param {string} [unassigned] Unassigned (0/1)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeaturesForOrganization(organizationUuid: string, unassigned?: string, options?: any): AxiosPromise<OrganizationFeaturesListResponse> {
            return localVarFp.getFeaturesForOrganization(organizationUuid, unassigned, options).then((request) => request(axios, basePath));
        },
        /**
         * get manufacturers for organization object
         * @summary get organization manufacturers
         * @param {string} organizationUuid Organization ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManufacturersForOrganization(organizationUuid: string, options?: any): AxiosPromise<OrganizationManufacturerListResponse> {
            return localVarFp.getManufacturersForOrganization(organizationUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * get organization object by UUID
         * @summary get organization object
         * @param {string} organizationUuid Organization ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationByUuid(organizationUuid: string, options?: any): AxiosPromise<OrganizationOrganizationResponse> {
            return localVarFp.getOrganizationByUuid(organizationUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * get list of all organizations
         * @summary get list of all organizations
         * @param {string} [active] active status of item (0/1 or true/false) - defaults to true
         * @param {string} [verticalMarketId] vertical market id
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOrganizations(active?: string, verticalMarketId?: string, sortBy?: string, offset?: number, limit?: number, options?: any): AxiosPromise<OrganizationListResponse> {
            return localVarFp.listOrganizations(active, verticalMarketId, sortBy, offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * set manufacturers for organization object
         * @summary set organization manufacturers
         * @param {string} organizationUuid Organization ID
         * @param {OrganizationManufacturerSetRequest} manufacturers List of manufacturers ID to add and remove
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setManufacturersForOrganization(organizationUuid: string, manufacturers: OrganizationManufacturerSetRequest, options?: any): AxiosPromise<HandlerResponse> {
            return localVarFp.setManufacturersForOrganization(organizationUuid, manufacturers, options).then((request) => request(axios, basePath));
        },
        /**
         * update organization object
         * @summary update organization
         * @param {OrganizationOrganization} organization Organization object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrganization(organization: OrganizationOrganization, options?: any): AxiosPromise<OrganizationOrganizationResponse> {
            return localVarFp.updateOrganization(organization, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrganizationsApi - object-oriented interface
 * @export
 * @class OrganizationsApi
 * @extends {BaseAPI}
 */
export class OrganizationsApi extends BaseAPI {
    /**
     * add features for organization object
     * @summary add organization features
     * @param {string} organizationUuid Organization UUID
     * @param {OrganizationFeaturesAddRequest} features List of features UUIDs to add
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public addFeaturesForOrganization(organizationUuid: string, features: OrganizationFeaturesAddRequest, options?: RawAxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).addFeaturesForOrganization(organizationUuid, features, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * create organization object
     * @summary create organization
     * @param {ModelOrganization} organization Organization object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public createOrganization(organization: ModelOrganization, options?: RawAxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).createOrganization(organization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * delete features for organization object
     * @summary delete features from the organization
     * @param {string} organizationUuid Organization UUID
     * @param {OrganizationFeaturesDeleteRequest} features List of features UUIDs to delete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public deleteFeaturesFromOrganization(organizationUuid: string, features: OrganizationFeaturesDeleteRequest, options?: RawAxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).deleteFeaturesFromOrganization(organizationUuid, features, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * delete organization by UUID
     * @summary delete organization by UUID
     * @param {string} organizationUuid Organization ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public deleteOrganization(organizationUuid: string, options?: RawAxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).deleteOrganization(organizationUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get features for organization object
     * @summary get organization features
     * @param {string} organizationUuid Organization ID
     * @param {string} [unassigned] Unassigned (0/1)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public getFeaturesForOrganization(organizationUuid: string, unassigned?: string, options?: RawAxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).getFeaturesForOrganization(organizationUuid, unassigned, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get manufacturers for organization object
     * @summary get organization manufacturers
     * @param {string} organizationUuid Organization ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public getManufacturersForOrganization(organizationUuid: string, options?: RawAxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).getManufacturersForOrganization(organizationUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get organization object by UUID
     * @summary get organization object
     * @param {string} organizationUuid Organization ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public getOrganizationByUuid(organizationUuid: string, options?: RawAxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).getOrganizationByUuid(organizationUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get list of all organizations
     * @summary get list of all organizations
     * @param {string} [active] active status of item (0/1 or true/false) - defaults to true
     * @param {string} [verticalMarketId] vertical market id
     * @param {string} [sortBy] First sort column
     * @param {number} [offset] Offset
     * @param {number} [limit] Limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public listOrganizations(active?: string, verticalMarketId?: string, sortBy?: string, offset?: number, limit?: number, options?: RawAxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).listOrganizations(active, verticalMarketId, sortBy, offset, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * set manufacturers for organization object
     * @summary set organization manufacturers
     * @param {string} organizationUuid Organization ID
     * @param {OrganizationManufacturerSetRequest} manufacturers List of manufacturers ID to add and remove
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public setManufacturersForOrganization(organizationUuid: string, manufacturers: OrganizationManufacturerSetRequest, options?: RawAxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).setManufacturersForOrganization(organizationUuid, manufacturers, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * update organization object
     * @summary update organization
     * @param {OrganizationOrganization} organization Organization object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public updateOrganization(organization: OrganizationOrganization, options?: RawAxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).updateOrganization(organization, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PayloadsApi - axios parameter creator
 * @export
 */
export const PayloadsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * get payload object by UUID
         * @summary get sumitted payload
         * @param {string} payloadUuid Payload ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPayloadByUuid: async (payloadUuid: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'payloadUuid' is not null or undefined
            assertParamExists('getPayloadByUuid', 'payloadUuid', payloadUuid)
            const localVarPath = `/payloads/{payload_uuid}`
                .replace(`{${"payload_uuid"}}`, encodeURIComponent(String(payloadUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get payload object by warehouse UUID
         * @summary get sumitted payload for warehouse
         * @param {string} [ownerId] Owner ID
         * @param {string} [deviceId] Device ID
         * @param {string} [warehouseId] Warehouse ID
         * @param {string} [locationVisitId] Location Visit ID
         * @param {string} [processedBegin] Begin time
         * @param {string} [processedEnd] End time
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPayloads: async (ownerId?: string, deviceId?: string, warehouseId?: string, locationVisitId?: string, processedBegin?: string, processedEnd?: string, sortBy?: string, offset?: number, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/payloads`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (ownerId !== undefined) {
                localVarQueryParameter['owner_id'] = ownerId;
            }

            if (deviceId !== undefined) {
                localVarQueryParameter['device_id'] = deviceId;
            }

            if (warehouseId !== undefined) {
                localVarQueryParameter['warehouse_id'] = warehouseId;
            }

            if (locationVisitId !== undefined) {
                localVarQueryParameter['location_visit_id'] = locationVisitId;
            }

            if (processedBegin !== undefined) {
                localVarQueryParameter['processed_begin'] = processedBegin;
            }

            if (processedEnd !== undefined) {
                localVarQueryParameter['processed_end'] = processedEnd;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get list locations for a vehicle
         * @summary get list locations for a vehicle
         * @param {string} vehicleId Vehicle ID
         * @param {string} processedBegin Begin time
         * @param {string} processedEnd End time
         * @param {string} [waypoints] Add intermediate waypoints (true/false)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVehicleLocations: async (vehicleId: string, processedBegin: string, processedEnd: string, waypoints?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vehicleId' is not null or undefined
            assertParamExists('getVehicleLocations', 'vehicleId', vehicleId)
            // verify required parameter 'processedBegin' is not null or undefined
            assertParamExists('getVehicleLocations', 'processedBegin', processedBegin)
            // verify required parameter 'processedEnd' is not null or undefined
            assertParamExists('getVehicleLocations', 'processedEnd', processedEnd)
            const localVarPath = `/payloads/locations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (vehicleId !== undefined) {
                localVarQueryParameter['vehicle_id'] = vehicleId;
            }

            if (processedBegin !== undefined) {
                localVarQueryParameter['processed_begin'] = processedBegin;
            }

            if (processedEnd !== undefined) {
                localVarQueryParameter['processed_end'] = processedEnd;
            }

            if (waypoints !== undefined) {
                localVarQueryParameter['waypoints'] = waypoints;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PayloadsApi - functional programming interface
 * @export
 */
export const PayloadsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PayloadsApiAxiosParamCreator(configuration)
    return {
        /**
         * get payload object by UUID
         * @summary get sumitted payload
         * @param {string} payloadUuid Payload ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPayloadByUuid(payloadUuid: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PayloadObjectResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPayloadByUuid(payloadUuid, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PayloadsApi.getPayloadByUuid']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * get payload object by warehouse UUID
         * @summary get sumitted payload for warehouse
         * @param {string} [ownerId] Owner ID
         * @param {string} [deviceId] Device ID
         * @param {string} [warehouseId] Warehouse ID
         * @param {string} [locationVisitId] Location Visit ID
         * @param {string} [processedBegin] Begin time
         * @param {string} [processedEnd] End time
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPayloads(ownerId?: string, deviceId?: string, warehouseId?: string, locationVisitId?: string, processedBegin?: string, processedEnd?: string, sortBy?: string, offset?: number, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PayloadListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPayloads(ownerId, deviceId, warehouseId, locationVisitId, processedBegin, processedEnd, sortBy, offset, limit, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PayloadsApi.getPayloads']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * get list locations for a vehicle
         * @summary get list locations for a vehicle
         * @param {string} vehicleId Vehicle ID
         * @param {string} processedBegin Begin time
         * @param {string} processedEnd End time
         * @param {string} [waypoints] Add intermediate waypoints (true/false)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVehicleLocations(vehicleId: string, processedBegin: string, processedEnd: string, waypoints?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PayloadLocationListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVehicleLocations(vehicleId, processedBegin, processedEnd, waypoints, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PayloadsApi.getVehicleLocations']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * PayloadsApi - factory interface
 * @export
 */
export const PayloadsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PayloadsApiFp(configuration)
    return {
        /**
         * get payload object by UUID
         * @summary get sumitted payload
         * @param {string} payloadUuid Payload ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPayloadByUuid(payloadUuid: string, options?: any): AxiosPromise<PayloadObjectResponse> {
            return localVarFp.getPayloadByUuid(payloadUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * get payload object by warehouse UUID
         * @summary get sumitted payload for warehouse
         * @param {string} [ownerId] Owner ID
         * @param {string} [deviceId] Device ID
         * @param {string} [warehouseId] Warehouse ID
         * @param {string} [locationVisitId] Location Visit ID
         * @param {string} [processedBegin] Begin time
         * @param {string} [processedEnd] End time
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPayloads(ownerId?: string, deviceId?: string, warehouseId?: string, locationVisitId?: string, processedBegin?: string, processedEnd?: string, sortBy?: string, offset?: number, limit?: number, options?: any): AxiosPromise<PayloadListResponse> {
            return localVarFp.getPayloads(ownerId, deviceId, warehouseId, locationVisitId, processedBegin, processedEnd, sortBy, offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * get list locations for a vehicle
         * @summary get list locations for a vehicle
         * @param {string} vehicleId Vehicle ID
         * @param {string} processedBegin Begin time
         * @param {string} processedEnd End time
         * @param {string} [waypoints] Add intermediate waypoints (true/false)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVehicleLocations(vehicleId: string, processedBegin: string, processedEnd: string, waypoints?: string, options?: any): AxiosPromise<PayloadLocationListResponse> {
            return localVarFp.getVehicleLocations(vehicleId, processedBegin, processedEnd, waypoints, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PayloadsApi - object-oriented interface
 * @export
 * @class PayloadsApi
 * @extends {BaseAPI}
 */
export class PayloadsApi extends BaseAPI {
    /**
     * get payload object by UUID
     * @summary get sumitted payload
     * @param {string} payloadUuid Payload ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PayloadsApi
     */
    public getPayloadByUuid(payloadUuid: string, options?: RawAxiosRequestConfig) {
        return PayloadsApiFp(this.configuration).getPayloadByUuid(payloadUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get payload object by warehouse UUID
     * @summary get sumitted payload for warehouse
     * @param {string} [ownerId] Owner ID
     * @param {string} [deviceId] Device ID
     * @param {string} [warehouseId] Warehouse ID
     * @param {string} [locationVisitId] Location Visit ID
     * @param {string} [processedBegin] Begin time
     * @param {string} [processedEnd] End time
     * @param {string} [sortBy] First sort column
     * @param {number} [offset] Offset
     * @param {number} [limit] Limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PayloadsApi
     */
    public getPayloads(ownerId?: string, deviceId?: string, warehouseId?: string, locationVisitId?: string, processedBegin?: string, processedEnd?: string, sortBy?: string, offset?: number, limit?: number, options?: RawAxiosRequestConfig) {
        return PayloadsApiFp(this.configuration).getPayloads(ownerId, deviceId, warehouseId, locationVisitId, processedBegin, processedEnd, sortBy, offset, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get list locations for a vehicle
     * @summary get list locations for a vehicle
     * @param {string} vehicleId Vehicle ID
     * @param {string} processedBegin Begin time
     * @param {string} processedEnd End time
     * @param {string} [waypoints] Add intermediate waypoints (true/false)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PayloadsApi
     */
    public getVehicleLocations(vehicleId: string, processedBegin: string, processedEnd: string, waypoints?: string, options?: RawAxiosRequestConfig) {
        return PayloadsApiFp(this.configuration).getVehicleLocations(vehicleId, processedBegin, processedEnd, waypoints, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ProductsApi - axios parameter creator
 * @export
 */
export const ProductsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * create product object
         * @summary create product
         * @param {ProductRequestNewProduct} newProduct Product object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProduct: async (newProduct: ProductRequestNewProduct, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'newProduct' is not null or undefined
            assertParamExists('createProduct', 'newProduct', newProduct)
            const localVarPath = `/products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newProduct, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * delete product
         * @summary delete product
         * @param {string} productUuid product ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProduct: async (productUuid: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productUuid' is not null or undefined
            assertParamExists('deleteProduct', 'productUuid', productUuid)
            const localVarPath = `/products/{product_uuid}`
                .replace(`{${"product_uuid"}}`, encodeURIComponent(String(productUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get product object by UUID
         * @summary get product object
         * @param {string} productUuid Product ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductByUuid: async (productUuid: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productUuid' is not null or undefined
            assertParamExists('getProductByUuid', 'productUuid', productUuid)
            const localVarPath = `/products/{product_uuid}`
                .replace(`{${"product_uuid"}}`, encodeURIComponent(String(productUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get product dependencies
         * @summary Check for dependencies on product
         * @param {string} productUuid Product ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductDependencies: async (productUuid: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productUuid' is not null or undefined
            assertParamExists('getProductDependencies', 'productUuid', productUuid)
            const localVarPath = `/products/{product_uuid}/dependencies`
                .replace(`{${"product_uuid"}}`, encodeURIComponent(String(productUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * list all product categories object
         * @summary list all product categories object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProductCategories: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/products/categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * list all product types
         * @summary list all product types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProductTypes: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/product-types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * list all products object
         * @summary list all products object
         * @param {string} [manufacturerId] manufacturer_id to query
         * @param {string} [productTypeId] product_type_id to query
         * @param {string} [owned] owned item (1)
         * @param {string} [search] Text wildcard search
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProducts: async (manufacturerId?: string, productTypeId?: string, owned?: string, search?: string, sortBy?: string, offset?: number, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (manufacturerId !== undefined) {
                localVarQueryParameter['manufacturer_id'] = manufacturerId;
            }

            if (productTypeId !== undefined) {
                localVarQueryParameter['product_type_id'] = productTypeId;
            }

            if (owned !== undefined) {
                localVarQueryParameter['owned'] = owned;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * update product
         * @summary update product
         * @param {ProductRequestProduct} product Product object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProduct: async (product: ProductRequestProduct, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'product' is not null or undefined
            assertParamExists('updateProduct', 'product', product)
            const localVarPath = `/products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(product, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductsApi - functional programming interface
 * @export
 */
export const ProductsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductsApiAxiosParamCreator(configuration)
    return {
        /**
         * create product object
         * @summary create product
         * @param {ProductRequestNewProduct} newProduct Product object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createProduct(newProduct: ProductRequestNewProduct, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductObjectResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createProduct(newProduct, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProductsApi.createProduct']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * delete product
         * @summary delete product
         * @param {string} productUuid product ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProduct(productUuid: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HandlerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProduct(productUuid, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProductsApi.deleteProduct']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * get product object by UUID
         * @summary get product object
         * @param {string} productUuid Product ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductByUuid(productUuid: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductObjectResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductByUuid(productUuid, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProductsApi.getProductByUuid']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * get product dependencies
         * @summary Check for dependencies on product
         * @param {string} productUuid Product ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductDependencies(productUuid: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductDependenciesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductDependencies(productUuid, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProductsApi.getProductDependencies']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * list all product categories object
         * @summary list all product categories object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listProductCategories(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductListCategoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listProductCategories(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProductsApi.listProductCategories']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * list all product types
         * @summary list all product types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listProductTypes(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductListProductTypesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listProductTypes(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProductsApi.listProductTypes']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * list all products object
         * @summary list all products object
         * @param {string} [manufacturerId] manufacturer_id to query
         * @param {string} [productTypeId] product_type_id to query
         * @param {string} [owned] owned item (1)
         * @param {string} [search] Text wildcard search
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listProducts(manufacturerId?: string, productTypeId?: string, owned?: string, search?: string, sortBy?: string, offset?: number, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listProducts(manufacturerId, productTypeId, owned, search, sortBy, offset, limit, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProductsApi.listProducts']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * update product
         * @summary update product
         * @param {ProductRequestProduct} product Product object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProduct(product: ProductRequestProduct, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductObjectResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProduct(product, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProductsApi.updateProduct']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * ProductsApi - factory interface
 * @export
 */
export const ProductsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductsApiFp(configuration)
    return {
        /**
         * create product object
         * @summary create product
         * @param {ProductRequestNewProduct} newProduct Product object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProduct(newProduct: ProductRequestNewProduct, options?: any): AxiosPromise<ProductObjectResponse> {
            return localVarFp.createProduct(newProduct, options).then((request) => request(axios, basePath));
        },
        /**
         * delete product
         * @summary delete product
         * @param {string} productUuid product ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProduct(productUuid: string, options?: any): AxiosPromise<HandlerResponse> {
            return localVarFp.deleteProduct(productUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * get product object by UUID
         * @summary get product object
         * @param {string} productUuid Product ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductByUuid(productUuid: string, options?: any): AxiosPromise<ProductObjectResponse> {
            return localVarFp.getProductByUuid(productUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * get product dependencies
         * @summary Check for dependencies on product
         * @param {string} productUuid Product ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductDependencies(productUuid: string, options?: any): AxiosPromise<ProductDependenciesResponse> {
            return localVarFp.getProductDependencies(productUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * list all product categories object
         * @summary list all product categories object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProductCategories(options?: any): AxiosPromise<ProductListCategoryResponse> {
            return localVarFp.listProductCategories(options).then((request) => request(axios, basePath));
        },
        /**
         * list all product types
         * @summary list all product types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProductTypes(options?: any): AxiosPromise<ProductListProductTypesResponse> {
            return localVarFp.listProductTypes(options).then((request) => request(axios, basePath));
        },
        /**
         * list all products object
         * @summary list all products object
         * @param {string} [manufacturerId] manufacturer_id to query
         * @param {string} [productTypeId] product_type_id to query
         * @param {string} [owned] owned item (1)
         * @param {string} [search] Text wildcard search
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProducts(manufacturerId?: string, productTypeId?: string, owned?: string, search?: string, sortBy?: string, offset?: number, limit?: number, options?: any): AxiosPromise<ProductListResponse> {
            return localVarFp.listProducts(manufacturerId, productTypeId, owned, search, sortBy, offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * update product
         * @summary update product
         * @param {ProductRequestProduct} product Product object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProduct(product: ProductRequestProduct, options?: any): AxiosPromise<ProductObjectResponse> {
            return localVarFp.updateProduct(product, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductsApi - object-oriented interface
 * @export
 * @class ProductsApi
 * @extends {BaseAPI}
 */
export class ProductsApi extends BaseAPI {
    /**
     * create product object
     * @summary create product
     * @param {ProductRequestNewProduct} newProduct Product object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public createProduct(newProduct: ProductRequestNewProduct, options?: RawAxiosRequestConfig) {
        return ProductsApiFp(this.configuration).createProduct(newProduct, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * delete product
     * @summary delete product
     * @param {string} productUuid product ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public deleteProduct(productUuid: string, options?: RawAxiosRequestConfig) {
        return ProductsApiFp(this.configuration).deleteProduct(productUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get product object by UUID
     * @summary get product object
     * @param {string} productUuid Product ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public getProductByUuid(productUuid: string, options?: RawAxiosRequestConfig) {
        return ProductsApiFp(this.configuration).getProductByUuid(productUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get product dependencies
     * @summary Check for dependencies on product
     * @param {string} productUuid Product ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public getProductDependencies(productUuid: string, options?: RawAxiosRequestConfig) {
        return ProductsApiFp(this.configuration).getProductDependencies(productUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * list all product categories object
     * @summary list all product categories object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public listProductCategories(options?: RawAxiosRequestConfig) {
        return ProductsApiFp(this.configuration).listProductCategories(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * list all product types
     * @summary list all product types
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public listProductTypes(options?: RawAxiosRequestConfig) {
        return ProductsApiFp(this.configuration).listProductTypes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * list all products object
     * @summary list all products object
     * @param {string} [manufacturerId] manufacturer_id to query
     * @param {string} [productTypeId] product_type_id to query
     * @param {string} [owned] owned item (1)
     * @param {string} [search] Text wildcard search
     * @param {string} [sortBy] First sort column
     * @param {number} [offset] Offset
     * @param {number} [limit] Limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public listProducts(manufacturerId?: string, productTypeId?: string, owned?: string, search?: string, sortBy?: string, offset?: number, limit?: number, options?: RawAxiosRequestConfig) {
        return ProductsApiFp(this.configuration).listProducts(manufacturerId, productTypeId, owned, search, sortBy, offset, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * update product
     * @summary update product
     * @param {ProductRequestProduct} product Product object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public updateProduct(product: ProductRequestProduct, options?: RawAxiosRequestConfig) {
        return ProductsApiFp(this.configuration).updateProduct(product, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ReadSummaryApi - axios parameter creator
 * @export
 */
export const ReadSummaryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * list read summary items
         * @summary list read summary items
         * @param {string} [itemId] item_id to query
         * @param {string} [processedBegin] Begin time
         * @param {string} [processedEnd] End time
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSummaryList: async (itemId?: string, processedBegin?: string, processedEnd?: string, sortBy?: string, offset?: number, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/read-summary/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (itemId !== undefined) {
                localVarQueryParameter['item_id'] = itemId;
            }

            if (processedBegin !== undefined) {
                localVarQueryParameter['processed_begin'] = processedBegin;
            }

            if (processedEnd !== undefined) {
                localVarQueryParameter['processed_end'] = processedEnd;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReadSummaryApi - functional programming interface
 * @export
 */
export const ReadSummaryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReadSummaryApiAxiosParamCreator(configuration)
    return {
        /**
         * list read summary items
         * @summary list read summary items
         * @param {string} [itemId] item_id to query
         * @param {string} [processedBegin] Begin time
         * @param {string} [processedEnd] End time
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readSummaryList(itemId?: string, processedBegin?: string, processedEnd?: string, sortBy?: string, offset?: number, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReadSummaryListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readSummaryList(itemId, processedBegin, processedEnd, sortBy, offset, limit, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ReadSummaryApi.readSummaryList']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * ReadSummaryApi - factory interface
 * @export
 */
export const ReadSummaryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReadSummaryApiFp(configuration)
    return {
        /**
         * list read summary items
         * @summary list read summary items
         * @param {string} [itemId] item_id to query
         * @param {string} [processedBegin] Begin time
         * @param {string} [processedEnd] End time
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readSummaryList(itemId?: string, processedBegin?: string, processedEnd?: string, sortBy?: string, offset?: number, limit?: number, options?: any): AxiosPromise<ReadSummaryListResponse> {
            return localVarFp.readSummaryList(itemId, processedBegin, processedEnd, sortBy, offset, limit, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReadSummaryApi - object-oriented interface
 * @export
 * @class ReadSummaryApi
 * @extends {BaseAPI}
 */
export class ReadSummaryApi extends BaseAPI {
    /**
     * list read summary items
     * @summary list read summary items
     * @param {string} [itemId] item_id to query
     * @param {string} [processedBegin] Begin time
     * @param {string} [processedEnd] End time
     * @param {string} [sortBy] First sort column
     * @param {number} [offset] Offset
     * @param {number} [limit] Limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReadSummaryApi
     */
    public readSummaryList(itemId?: string, processedBegin?: string, processedEnd?: string, sortBy?: string, offset?: number, limit?: number, options?: RawAxiosRequestConfig) {
        return ReadSummaryApiFp(this.configuration).readSummaryList(itemId, processedBegin, processedEnd, sortBy, offset, limit, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ReportsApi - axios parameter creator
 * @export
 */
export const ReportsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary create batch report file, uploads it to S3 and sents Emails
         * @param {ItemBatchReportRunCreateParams} repo Batch Report Run Create Params
         * @param {string} [contentType] Report content type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchReportCreate: async (repo: ItemBatchReportRunCreateParams, contentType?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'repo' is not null or undefined
            assertParamExists('batchReportCreate', 'repo', repo)
            const localVarPath = `/reports/batch/runs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (contentType !== undefined) {
                localVarQueryParameter['content_type'] = contentType;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(repo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary creates batch report file download URL from S3
         * @param {string} runId Batch Report Run ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchReportRunCreateDownload: async (runId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'runId' is not null or undefined
            assertParamExists('batchReportRunCreateDownload', 'runId', runId)
            const localVarPath = `/reports/batch/runs/{run_id}/download`
                .replace(`{${"run_id"}}`, encodeURIComponent(String(runId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary returns batch report run record details by it\'s ID
         * @param {string} runId Batch Report Run ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchReportRunGetDetails: async (runId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'runId' is not null or undefined
            assertParamExists('batchReportRunGetDetails', 'runId', runId)
            const localVarPath = `/reports/batch/runs/{run_id}/details`
                .replace(`{${"run_id"}}`, encodeURIComponent(String(runId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary returns list of batch report runs for given report and organization
         * @param {string} organizationId Organization ID
         * @param {string} reportId Batch Report ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchReportRunGetList: async (organizationId: string, reportId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('batchReportRunGetList', 'organizationId', organizationId)
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('batchReportRunGetList', 'reportId', reportId)
            const localVarPath = `/reports/batch/runs/list/{organization_id}/{report_id}`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"report_id"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * excel report
         * @summary excel report download
         * @param {ItemExcelReportRequest} report Excel report metadata to be downloaded
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadReport: async (report: ItemExcelReportRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'report' is not null or undefined
            assertParamExists('downloadReport', 'report', report)
            const localVarPath = `/report/run`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(report, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * list recent batch report runs
         * @summary list recent batch report runs
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listBatchReportRuns: async (sortBy?: string, offset?: number, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/reports/batch/run-history`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * returns report details
         * @summary returns report details
         * @param {string} reportId Report ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportDetails: async (reportId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('reportDetails', 'reportId', reportId)
            const localVarPath = `/reports/batch/{report_id}/details`
                .replace(`{${"report_id"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * returns list of reports
         * @summary returns list of reports
         * @param {string} [category] report category
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportList: async (category?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * returns list of reports
         * @summary returns list of reports
         * @param {string} isAdmin filter by reports for admins
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportListBatch: async (isAdmin: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'isAdmin' is not null or undefined
            assertParamExists('reportListBatch', 'isAdmin', isAdmin)
            const localVarPath = `/reports/batch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (isAdmin !== undefined) {
                localVarQueryParameter['is_admin'] = isAdmin;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * returns aditional possible users
         * @summary returns aditional possible users
         * @param {string} reportId Report ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportPossibleUsers: async (reportId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('reportPossibleUsers', 'reportId', reportId)
            const localVarPath = `/reports/batch/{report_id}/possible-users`
                .replace(`{${"report_id"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * update list of recipients for batch report
         * @summary update list of recipients for batch report
         * @param {string} reportId Report ID
         * @param {ItemReportRecipientRequest} recipients recipient changes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReportRecipients: async (reportId: string, recipients: ItemReportRecipientRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('updateReportRecipients', 'reportId', reportId)
            // verify required parameter 'recipients' is not null or undefined
            assertParamExists('updateReportRecipients', 'recipients', recipients)
            const localVarPath = `/reports/batch/{report_id}`
                .replace(`{${"report_id"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(recipients, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportsApi - functional programming interface
 * @export
 */
export const ReportsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary create batch report file, uploads it to S3 and sents Emails
         * @param {ItemBatchReportRunCreateParams} repo Batch Report Run Create Params
         * @param {string} [contentType] Report content type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async batchReportCreate(repo: ItemBatchReportRunCreateParams, contentType?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ItemBatchReportRunCreateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.batchReportCreate(repo, contentType, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ReportsApi.batchReportCreate']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary creates batch report file download URL from S3
         * @param {string} runId Batch Report Run ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async batchReportRunCreateDownload(runId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ItemBatchReportRunURLResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.batchReportRunCreateDownload(runId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ReportsApi.batchReportRunCreateDownload']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary returns batch report run record details by it\'s ID
         * @param {string} runId Batch Report Run ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async batchReportRunGetDetails(runId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ItemBatchReportRunDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.batchReportRunGetDetails(runId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ReportsApi.batchReportRunGetDetails']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary returns list of batch report runs for given report and organization
         * @param {string} organizationId Organization ID
         * @param {string} reportId Batch Report ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async batchReportRunGetList(organizationId: string, reportId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ItemBatchReportRunListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.batchReportRunGetList(organizationId, reportId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ReportsApi.batchReportRunGetList']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * excel report
         * @summary excel report download
         * @param {ItemExcelReportRequest} report Excel report metadata to be downloaded
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadReport(report: ItemExcelReportRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadReport(report, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ReportsApi.downloadReport']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * list recent batch report runs
         * @summary list recent batch report runs
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listBatchReportRuns(sortBy?: string, offset?: number, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ItemBatchReportRunHistoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listBatchReportRuns(sortBy, offset, limit, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ReportsApi.listBatchReportRuns']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * returns report details
         * @summary returns report details
         * @param {string} reportId Report ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportDetails(reportId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ItemReportScheduleResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportDetails(reportId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ReportsApi.reportDetails']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * returns list of reports
         * @summary returns list of reports
         * @param {string} [category] report category
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportList(category?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PkgHandlerReportListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportList(category, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ReportsApi.reportList']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * returns list of reports
         * @summary returns list of reports
         * @param {string} isAdmin filter by reports for admins
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportListBatch(isAdmin: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ItemListReportScheduleResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportListBatch(isAdmin, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ReportsApi.reportListBatch']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * returns aditional possible users
         * @summary returns aditional possible users
         * @param {string} reportId Report ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportPossibleUsers(reportId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ItemListReportRecipientResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportPossibleUsers(reportId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ReportsApi.reportPossibleUsers']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * update list of recipients for batch report
         * @summary update list of recipients for batch report
         * @param {string} reportId Report ID
         * @param {ItemReportRecipientRequest} recipients recipient changes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateReportRecipients(reportId: string, recipients: ItemReportRecipientRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ItemReportScheduleResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateReportRecipients(reportId, recipients, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ReportsApi.updateReportRecipients']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * ReportsApi - factory interface
 * @export
 */
export const ReportsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportsApiFp(configuration)
    return {
        /**
         * 
         * @summary create batch report file, uploads it to S3 and sents Emails
         * @param {ItemBatchReportRunCreateParams} repo Batch Report Run Create Params
         * @param {string} [contentType] Report content type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchReportCreate(repo: ItemBatchReportRunCreateParams, contentType?: string, options?: any): AxiosPromise<ItemBatchReportRunCreateResponse> {
            return localVarFp.batchReportCreate(repo, contentType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary creates batch report file download URL from S3
         * @param {string} runId Batch Report Run ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchReportRunCreateDownload(runId: string, options?: any): AxiosPromise<ItemBatchReportRunURLResponse> {
            return localVarFp.batchReportRunCreateDownload(runId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary returns batch report run record details by it\'s ID
         * @param {string} runId Batch Report Run ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchReportRunGetDetails(runId: string, options?: any): AxiosPromise<ItemBatchReportRunDetailResponse> {
            return localVarFp.batchReportRunGetDetails(runId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary returns list of batch report runs for given report and organization
         * @param {string} organizationId Organization ID
         * @param {string} reportId Batch Report ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchReportRunGetList(organizationId: string, reportId: string, options?: any): AxiosPromise<ItemBatchReportRunListResponse> {
            return localVarFp.batchReportRunGetList(organizationId, reportId, options).then((request) => request(axios, basePath));
        },
        /**
         * excel report
         * @summary excel report download
         * @param {ItemExcelReportRequest} report Excel report metadata to be downloaded
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadReport(report: ItemExcelReportRequest, options?: any): AxiosPromise<File> {
            return localVarFp.downloadReport(report, options).then((request) => request(axios, basePath));
        },
        /**
         * list recent batch report runs
         * @summary list recent batch report runs
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listBatchReportRuns(sortBy?: string, offset?: number, limit?: number, options?: any): AxiosPromise<ItemBatchReportRunHistoryResponse> {
            return localVarFp.listBatchReportRuns(sortBy, offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * returns report details
         * @summary returns report details
         * @param {string} reportId Report ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportDetails(reportId: string, options?: any): AxiosPromise<ItemReportScheduleResponse> {
            return localVarFp.reportDetails(reportId, options).then((request) => request(axios, basePath));
        },
        /**
         * returns list of reports
         * @summary returns list of reports
         * @param {string} [category] report category
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportList(category?: string, options?: any): AxiosPromise<PkgHandlerReportListResponse> {
            return localVarFp.reportList(category, options).then((request) => request(axios, basePath));
        },
        /**
         * returns list of reports
         * @summary returns list of reports
         * @param {string} isAdmin filter by reports for admins
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportListBatch(isAdmin: string, options?: any): AxiosPromise<ItemListReportScheduleResponse> {
            return localVarFp.reportListBatch(isAdmin, options).then((request) => request(axios, basePath));
        },
        /**
         * returns aditional possible users
         * @summary returns aditional possible users
         * @param {string} reportId Report ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportPossibleUsers(reportId: string, options?: any): AxiosPromise<ItemListReportRecipientResponse> {
            return localVarFp.reportPossibleUsers(reportId, options).then((request) => request(axios, basePath));
        },
        /**
         * update list of recipients for batch report
         * @summary update list of recipients for batch report
         * @param {string} reportId Report ID
         * @param {ItemReportRecipientRequest} recipients recipient changes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReportRecipients(reportId: string, recipients: ItemReportRecipientRequest, options?: any): AxiosPromise<ItemReportScheduleResponse> {
            return localVarFp.updateReportRecipients(reportId, recipients, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReportsApi - object-oriented interface
 * @export
 * @class ReportsApi
 * @extends {BaseAPI}
 */
export class ReportsApi extends BaseAPI {
    /**
     * 
     * @summary create batch report file, uploads it to S3 and sents Emails
     * @param {ItemBatchReportRunCreateParams} repo Batch Report Run Create Params
     * @param {string} [contentType] Report content type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public batchReportCreate(repo: ItemBatchReportRunCreateParams, contentType?: string, options?: RawAxiosRequestConfig) {
        return ReportsApiFp(this.configuration).batchReportCreate(repo, contentType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary creates batch report file download URL from S3
     * @param {string} runId Batch Report Run ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public batchReportRunCreateDownload(runId: string, options?: RawAxiosRequestConfig) {
        return ReportsApiFp(this.configuration).batchReportRunCreateDownload(runId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary returns batch report run record details by it\'s ID
     * @param {string} runId Batch Report Run ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public batchReportRunGetDetails(runId: string, options?: RawAxiosRequestConfig) {
        return ReportsApiFp(this.configuration).batchReportRunGetDetails(runId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary returns list of batch report runs for given report and organization
     * @param {string} organizationId Organization ID
     * @param {string} reportId Batch Report ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public batchReportRunGetList(organizationId: string, reportId: string, options?: RawAxiosRequestConfig) {
        return ReportsApiFp(this.configuration).batchReportRunGetList(organizationId, reportId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * excel report
     * @summary excel report download
     * @param {ItemExcelReportRequest} report Excel report metadata to be downloaded
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public downloadReport(report: ItemExcelReportRequest, options?: RawAxiosRequestConfig) {
        return ReportsApiFp(this.configuration).downloadReport(report, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * list recent batch report runs
     * @summary list recent batch report runs
     * @param {string} [sortBy] First sort column
     * @param {number} [offset] Offset
     * @param {number} [limit] Limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public listBatchReportRuns(sortBy?: string, offset?: number, limit?: number, options?: RawAxiosRequestConfig) {
        return ReportsApiFp(this.configuration).listBatchReportRuns(sortBy, offset, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * returns report details
     * @summary returns report details
     * @param {string} reportId Report ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public reportDetails(reportId: string, options?: RawAxiosRequestConfig) {
        return ReportsApiFp(this.configuration).reportDetails(reportId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * returns list of reports
     * @summary returns list of reports
     * @param {string} [category] report category
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public reportList(category?: string, options?: RawAxiosRequestConfig) {
        return ReportsApiFp(this.configuration).reportList(category, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * returns list of reports
     * @summary returns list of reports
     * @param {string} isAdmin filter by reports for admins
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public reportListBatch(isAdmin: string, options?: RawAxiosRequestConfig) {
        return ReportsApiFp(this.configuration).reportListBatch(isAdmin, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * returns aditional possible users
     * @summary returns aditional possible users
     * @param {string} reportId Report ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public reportPossibleUsers(reportId: string, options?: RawAxiosRequestConfig) {
        return ReportsApiFp(this.configuration).reportPossibleUsers(reportId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * update list of recipients for batch report
     * @summary update list of recipients for batch report
     * @param {string} reportId Report ID
     * @param {ItemReportRecipientRequest} recipients recipient changes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public updateReportRecipients(reportId: string, recipients: ItemReportRecipientRequest, options?: RawAxiosRequestConfig) {
        return ReportsApiFp(this.configuration).updateReportRecipients(reportId, recipients, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * RoomsApi - axios parameter creator
 * @export
 */
export const RoomsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * create room object with an existing device
         * @summary create room with dan existing evice
         * @param {RoomRequestRoomWithDevice} room Room object with device
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRoom: async (room: RoomRequestRoomWithDevice, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'room' is not null or undefined
            assertParamExists('createRoom', 'room', room)
            const localVarPath = `/rooms`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(room, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * delete room by UUID
         * @summary delete room by UUID
         * @param {string} roomId Room ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRoom: async (roomId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'roomId' is not null or undefined
            assertParamExists('deleteRoom', 'roomId', roomId)
            const localVarPath = `/rooms/{room_id}`
                .replace(`{${"room_id"}}`, encodeURIComponent(String(roomId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get room object
         * @summary get room
         * @param {string} roomId Room ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoom: async (roomId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'roomId' is not null or undefined
            assertParamExists('getRoom', 'roomId', roomId)
            const localVarPath = `/rooms/{room_id}`
                .replace(`{${"room_id"}}`, encodeURIComponent(String(roomId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get room dependencies
         * @summary Check for dependencies on room
         * @param {string} roomId Room ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoomDependencies: async (roomId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'roomId' is not null or undefined
            assertParamExists('getRoomDependencies', 'roomId', roomId)
            const localVarPath = `/rooms/{room_id}/dependencies`
                .replace(`{${"room_id"}}`, encodeURIComponent(String(roomId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get []Room object
         * @summary get rooms
         * @param {string} [organizationId] Organization ID
         * @param {string} [siteId] Parent Site ID 
         * @param {string} [active] active status of item (0/1 or true/false) - defaults to true
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRooms: async (organizationId?: string, siteId?: string, active?: string, sortBy?: string, offset?: number, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/rooms`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (organizationId !== undefined) {
                localVarQueryParameter['organization_id'] = organizationId;
            }

            if (siteId !== undefined) {
                localVarQueryParameter['site_id'] = siteId;
            }

            if (active !== undefined) {
                localVarQueryParameter['active'] = active;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * create room object
         * @summary update room
         * @param {RoomRequestUpdateRoom} updateRoom Room object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRoom: async (updateRoom: RoomRequestUpdateRoom, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateRoom' is not null or undefined
            assertParamExists('updateRoom', 'updateRoom', updateRoom)
            const localVarPath = `/rooms`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateRoom, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RoomsApi - functional programming interface
 * @export
 */
export const RoomsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RoomsApiAxiosParamCreator(configuration)
    return {
        /**
         * create room object with an existing device
         * @summary create room with dan existing evice
         * @param {RoomRequestRoomWithDevice} room Room object with device
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createRoom(room: RoomRequestRoomWithDevice, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoomRoomResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createRoom(room, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['RoomsApi.createRoom']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * delete room by UUID
         * @summary delete room by UUID
         * @param {string} roomId Room ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteRoom(roomId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HandlerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteRoom(roomId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['RoomsApi.deleteRoom']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * get room object
         * @summary get room
         * @param {string} roomId Room ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRoom(roomId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoomRoomResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRoom(roomId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['RoomsApi.getRoom']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * get room dependencies
         * @summary Check for dependencies on room
         * @param {string} roomId Room ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRoomDependencies(roomId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoomDependenciesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRoomDependencies(roomId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['RoomsApi.getRoomDependencies']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * get []Room object
         * @summary get rooms
         * @param {string} [organizationId] Organization ID
         * @param {string} [siteId] Parent Site ID 
         * @param {string} [active] active status of item (0/1 or true/false) - defaults to true
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRooms(organizationId?: string, siteId?: string, active?: string, sortBy?: string, offset?: number, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoomListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRooms(organizationId, siteId, active, sortBy, offset, limit, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['RoomsApi.getRooms']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * create room object
         * @summary update room
         * @param {RoomRequestUpdateRoom} updateRoom Room object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRoom(updateRoom: RoomRequestUpdateRoom, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoomRoomResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateRoom(updateRoom, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['RoomsApi.updateRoom']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * RoomsApi - factory interface
 * @export
 */
export const RoomsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RoomsApiFp(configuration)
    return {
        /**
         * create room object with an existing device
         * @summary create room with dan existing evice
         * @param {RoomRequestRoomWithDevice} room Room object with device
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRoom(room: RoomRequestRoomWithDevice, options?: any): AxiosPromise<RoomRoomResponse> {
            return localVarFp.createRoom(room, options).then((request) => request(axios, basePath));
        },
        /**
         * delete room by UUID
         * @summary delete room by UUID
         * @param {string} roomId Room ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRoom(roomId: string, options?: any): AxiosPromise<HandlerResponse> {
            return localVarFp.deleteRoom(roomId, options).then((request) => request(axios, basePath));
        },
        /**
         * get room object
         * @summary get room
         * @param {string} roomId Room ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoom(roomId: string, options?: any): AxiosPromise<RoomRoomResponse> {
            return localVarFp.getRoom(roomId, options).then((request) => request(axios, basePath));
        },
        /**
         * get room dependencies
         * @summary Check for dependencies on room
         * @param {string} roomId Room ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoomDependencies(roomId: string, options?: any): AxiosPromise<RoomDependenciesResponse> {
            return localVarFp.getRoomDependencies(roomId, options).then((request) => request(axios, basePath));
        },
        /**
         * get []Room object
         * @summary get rooms
         * @param {string} [organizationId] Organization ID
         * @param {string} [siteId] Parent Site ID 
         * @param {string} [active] active status of item (0/1 or true/false) - defaults to true
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRooms(organizationId?: string, siteId?: string, active?: string, sortBy?: string, offset?: number, limit?: number, options?: any): AxiosPromise<RoomListResponse> {
            return localVarFp.getRooms(organizationId, siteId, active, sortBy, offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * create room object
         * @summary update room
         * @param {RoomRequestUpdateRoom} updateRoom Room object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRoom(updateRoom: RoomRequestUpdateRoom, options?: any): AxiosPromise<RoomRoomResponse> {
            return localVarFp.updateRoom(updateRoom, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RoomsApi - object-oriented interface
 * @export
 * @class RoomsApi
 * @extends {BaseAPI}
 */
export class RoomsApi extends BaseAPI {
    /**
     * create room object with an existing device
     * @summary create room with dan existing evice
     * @param {RoomRequestRoomWithDevice} room Room object with device
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoomsApi
     */
    public createRoom(room: RoomRequestRoomWithDevice, options?: RawAxiosRequestConfig) {
        return RoomsApiFp(this.configuration).createRoom(room, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * delete room by UUID
     * @summary delete room by UUID
     * @param {string} roomId Room ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoomsApi
     */
    public deleteRoom(roomId: string, options?: RawAxiosRequestConfig) {
        return RoomsApiFp(this.configuration).deleteRoom(roomId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get room object
     * @summary get room
     * @param {string} roomId Room ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoomsApi
     */
    public getRoom(roomId: string, options?: RawAxiosRequestConfig) {
        return RoomsApiFp(this.configuration).getRoom(roomId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get room dependencies
     * @summary Check for dependencies on room
     * @param {string} roomId Room ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoomsApi
     */
    public getRoomDependencies(roomId: string, options?: RawAxiosRequestConfig) {
        return RoomsApiFp(this.configuration).getRoomDependencies(roomId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get []Room object
     * @summary get rooms
     * @param {string} [organizationId] Organization ID
     * @param {string} [siteId] Parent Site ID 
     * @param {string} [active] active status of item (0/1 or true/false) - defaults to true
     * @param {string} [sortBy] First sort column
     * @param {number} [offset] Offset
     * @param {number} [limit] Limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoomsApi
     */
    public getRooms(organizationId?: string, siteId?: string, active?: string, sortBy?: string, offset?: number, limit?: number, options?: RawAxiosRequestConfig) {
        return RoomsApiFp(this.configuration).getRooms(organizationId, siteId, active, sortBy, offset, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * create room object
     * @summary update room
     * @param {RoomRequestUpdateRoom} updateRoom Room object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoomsApi
     */
    public updateRoom(updateRoom: RoomRequestUpdateRoom, options?: RawAxiosRequestConfig) {
        return RoomsApiFp(this.configuration).updateRoom(updateRoom, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ScanEventsApi - axios parameter creator
 * @export
 */
export const ScanEventsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * get scan_events
         * @summary Get scan event list
         * @param {string} [locationVisitId] LocationVisit ID
         * @param {string} [payloadId] Payload ID
         * @param {string} [deviceId] Device ID
         * @param {string} [trackingWarehouseId] Tracking Warehouse ID
         * @param {string} [processingWarehouseId] Processing Warehouse ID
         * @param {string} [vehicleId] Vehicle ID
         * @param {string} [siteId] Site ID
         * @param {string} [processedBegin] Begin time
         * @param {string} [processedEnd] End time
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScanEvents: async (locationVisitId?: string, payloadId?: string, deviceId?: string, trackingWarehouseId?: string, processingWarehouseId?: string, vehicleId?: string, siteId?: string, processedBegin?: string, processedEnd?: string, sortBy?: string, offset?: number, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/scan-events`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (locationVisitId !== undefined) {
                localVarQueryParameter['location_visit_id'] = locationVisitId;
            }

            if (payloadId !== undefined) {
                localVarQueryParameter['payload_id'] = payloadId;
            }

            if (deviceId !== undefined) {
                localVarQueryParameter['device_id'] = deviceId;
            }

            if (trackingWarehouseId !== undefined) {
                localVarQueryParameter['tracking_warehouse_id'] = trackingWarehouseId;
            }

            if (processingWarehouseId !== undefined) {
                localVarQueryParameter['processing_warehouse_id'] = processingWarehouseId;
            }

            if (vehicleId !== undefined) {
                localVarQueryParameter['vehicle_id'] = vehicleId;
            }

            if (siteId !== undefined) {
                localVarQueryParameter['site_id'] = siteId;
            }

            if (processedBegin !== undefined) {
                localVarQueryParameter['processed_begin'] = processedBegin;
            }

            if (processedEnd !== undefined) {
                localVarQueryParameter['processed_end'] = processedEnd;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ScanEventsApi - functional programming interface
 * @export
 */
export const ScanEventsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ScanEventsApiAxiosParamCreator(configuration)
    return {
        /**
         * get scan_events
         * @summary Get scan event list
         * @param {string} [locationVisitId] LocationVisit ID
         * @param {string} [payloadId] Payload ID
         * @param {string} [deviceId] Device ID
         * @param {string} [trackingWarehouseId] Tracking Warehouse ID
         * @param {string} [processingWarehouseId] Processing Warehouse ID
         * @param {string} [vehicleId] Vehicle ID
         * @param {string} [siteId] Site ID
         * @param {string} [processedBegin] Begin time
         * @param {string} [processedEnd] End time
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getScanEvents(locationVisitId?: string, payloadId?: string, deviceId?: string, trackingWarehouseId?: string, processingWarehouseId?: string, vehicleId?: string, siteId?: string, processedBegin?: string, processedEnd?: string, sortBy?: string, offset?: number, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ScaneventListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getScanEvents(locationVisitId, payloadId, deviceId, trackingWarehouseId, processingWarehouseId, vehicleId, siteId, processedBegin, processedEnd, sortBy, offset, limit, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ScanEventsApi.getScanEvents']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * ScanEventsApi - factory interface
 * @export
 */
export const ScanEventsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ScanEventsApiFp(configuration)
    return {
        /**
         * get scan_events
         * @summary Get scan event list
         * @param {string} [locationVisitId] LocationVisit ID
         * @param {string} [payloadId] Payload ID
         * @param {string} [deviceId] Device ID
         * @param {string} [trackingWarehouseId] Tracking Warehouse ID
         * @param {string} [processingWarehouseId] Processing Warehouse ID
         * @param {string} [vehicleId] Vehicle ID
         * @param {string} [siteId] Site ID
         * @param {string} [processedBegin] Begin time
         * @param {string} [processedEnd] End time
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScanEvents(locationVisitId?: string, payloadId?: string, deviceId?: string, trackingWarehouseId?: string, processingWarehouseId?: string, vehicleId?: string, siteId?: string, processedBegin?: string, processedEnd?: string, sortBy?: string, offset?: number, limit?: number, options?: any): AxiosPromise<ScaneventListResponse> {
            return localVarFp.getScanEvents(locationVisitId, payloadId, deviceId, trackingWarehouseId, processingWarehouseId, vehicleId, siteId, processedBegin, processedEnd, sortBy, offset, limit, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ScanEventsApi - object-oriented interface
 * @export
 * @class ScanEventsApi
 * @extends {BaseAPI}
 */
export class ScanEventsApi extends BaseAPI {
    /**
     * get scan_events
     * @summary Get scan event list
     * @param {string} [locationVisitId] LocationVisit ID
     * @param {string} [payloadId] Payload ID
     * @param {string} [deviceId] Device ID
     * @param {string} [trackingWarehouseId] Tracking Warehouse ID
     * @param {string} [processingWarehouseId] Processing Warehouse ID
     * @param {string} [vehicleId] Vehicle ID
     * @param {string} [siteId] Site ID
     * @param {string} [processedBegin] Begin time
     * @param {string} [processedEnd] End time
     * @param {string} [sortBy] First sort column
     * @param {number} [offset] Offset
     * @param {number} [limit] Limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScanEventsApi
     */
    public getScanEvents(locationVisitId?: string, payloadId?: string, deviceId?: string, trackingWarehouseId?: string, processingWarehouseId?: string, vehicleId?: string, siteId?: string, processedBegin?: string, processedEnd?: string, sortBy?: string, offset?: number, limit?: number, options?: RawAxiosRequestConfig) {
        return ScanEventsApiFp(this.configuration).getScanEvents(locationVisitId, payloadId, deviceId, trackingWarehouseId, processingWarehouseId, vehicleId, siteId, processedBegin, processedEnd, sortBy, offset, limit, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SearchApi - axios parameter creator
 * @export
 */
export const SearchApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * returns summary of searches by object type
         * @summary company wide search
         * @param {string} search Search token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchCompany: async (search: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'search' is not null or undefined
            assertParamExists('searchCompany', 'search', search)
            const localVarPath = `/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * returns summary of direct search for an item
         * @summary directly search for an item (tool or stock)
         * @param {string} epc Item EPC to search for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchDirect: async (epc: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'epc' is not null or undefined
            assertParamExists('searchDirect', 'epc', epc)
            const localVarPath = `/search-direct/{epc}`
                .replace(`{${"epc"}}`, encodeURIComponent(String(epc)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SearchApi - functional programming interface
 * @export
 */
export const SearchApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SearchApiAxiosParamCreator(configuration)
    return {
        /**
         * returns summary of searches by object type
         * @summary company wide search
         * @param {string} search Search token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchCompany(search: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchCompany(search, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SearchApi.searchCompany']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * returns summary of direct search for an item
         * @summary directly search for an item (tool or stock)
         * @param {string} epc Item EPC to search for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchDirect(epc: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchObjectResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchDirect(epc, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SearchApi.searchDirect']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * SearchApi - factory interface
 * @export
 */
export const SearchApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SearchApiFp(configuration)
    return {
        /**
         * returns summary of searches by object type
         * @summary company wide search
         * @param {string} search Search token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchCompany(search: string, options?: any): AxiosPromise<SearchListResponse> {
            return localVarFp.searchCompany(search, options).then((request) => request(axios, basePath));
        },
        /**
         * returns summary of direct search for an item
         * @summary directly search for an item (tool or stock)
         * @param {string} epc Item EPC to search for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchDirect(epc: string, options?: any): AxiosPromise<SearchObjectResponse> {
            return localVarFp.searchDirect(epc, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SearchApi - object-oriented interface
 * @export
 * @class SearchApi
 * @extends {BaseAPI}
 */
export class SearchApi extends BaseAPI {
    /**
     * returns summary of searches by object type
     * @summary company wide search
     * @param {string} search Search token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApi
     */
    public searchCompany(search: string, options?: RawAxiosRequestConfig) {
        return SearchApiFp(this.configuration).searchCompany(search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * returns summary of direct search for an item
     * @summary directly search for an item (tool or stock)
     * @param {string} epc Item EPC to search for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApi
     */
    public searchDirect(epc: string, options?: RawAxiosRequestConfig) {
        return SearchApiFp(this.configuration).searchDirect(epc, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SitesApi - axios parameter creator
 * @export
 */
export const SitesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * add site
         * @summary add site
         * @param {SiteRequestSite} newSite Site object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSite: async (newSite: SiteRequestSite, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'newSite' is not null or undefined
            assertParamExists('createSite', 'newSite', newSite)
            const localVarPath = `/sites`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newSite, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * delete site by UUID
         * @summary delete site by UUID
         * @param {string} siteId Site ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSite: async (siteId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'siteId' is not null or undefined
            assertParamExists('deleteSite', 'siteId', siteId)
            const localVarPath = `/sites/{site_id}`
                .replace(`{${"site_id"}}`, encodeURIComponent(String(siteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get site object by UUID
         * @summary get site object
         * @param {string} siteId Site ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSiteById: async (siteId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'siteId' is not null or undefined
            assertParamExists('getSiteById', 'siteId', siteId)
            const localVarPath = `/sites/{site_id}`
                .replace(`{${"site_id"}}`, encodeURIComponent(String(siteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get site dependencies
         * @summary Check for dependencies on site
         * @param {string} siteId Site ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSiteDependencies: async (siteId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'siteId' is not null or undefined
            assertParamExists('getSiteDependencies', 'siteId', siteId)
            const localVarPath = `/sites/{site_id}/dependencies`
                .replace(`{${"site_id"}}`, encodeURIComponent(String(siteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get KPIs for vehicle
         * @summary get KPIs for vehicle
         * @param {string} siteId Site ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSiteKpis: async (siteId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'siteId' is not null or undefined
            assertParamExists('getSiteKpis', 'siteId', siteId)
            const localVarPath = `/sites/{site_id}/kpis`
                .replace(`{${"site_id"}}`, encodeURIComponent(String(siteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get site max created_at
         * @summary get site max created_at
         * @param {string} organizationId Organization ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSiteMaxCreatedAt: async (organizationId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('getSiteMaxCreatedAt', 'organizationId', organizationId)
            const localVarPath = `/sites/max-create-at/{organization_id}`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get list of all alternate jobs sites for organization
         * @summary get list of all alternate jobs sites for organization
         * @param {string} siteId site id to find other sites from
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAlternateSites: async (siteId: string, sortBy?: string, offset?: number, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'siteId' is not null or undefined
            assertParamExists('listAlternateSites', 'siteId', siteId)
            const localVarPath = `/sites/{site_id}/alternate`
                .replace(`{${"site_id"}}`, encodeURIComponent(String(siteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get list of all sties for organization
         * @summary get list of all sites for organization
         * @param {string} [organizationId] Organization ID
         * @param {string} [siteOwner] site owner to filter query
         * @param {string} [search] Text wildcard search
         * @param {string} [customerUuid] customer_id to query
         * @param {string} [active] active status of item (0/1 or true/false) - defaults to true
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSite: async (organizationId?: string, siteOwner?: string, search?: string, customerUuid?: string, active?: string, sortBy?: string, offset?: number, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/sites`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (organizationId !== undefined) {
                localVarQueryParameter['organization_id'] = organizationId;
            }

            if (siteOwner !== undefined) {
                localVarQueryParameter['site_owner'] = siteOwner;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (customerUuid !== undefined) {
                localVarQueryParameter['customer_uuid'] = customerUuid;
            }

            if (active !== undefined) {
                localVarQueryParameter['active'] = active;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * merge one site into another by ID
         * @summary merge one site into another by ID
         * @param {string} siteId Site ID
         * @param {string} targetSiteId Target Site ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mergeSite: async (siteId: string, targetSiteId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'siteId' is not null or undefined
            assertParamExists('mergeSite', 'siteId', siteId)
            // verify required parameter 'targetSiteId' is not null or undefined
            assertParamExists('mergeSite', 'targetSiteId', targetSiteId)
            const localVarPath = `/sites/{site_id}/merge`
                .replace(`{${"site_id"}}`, encodeURIComponent(String(siteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (targetSiteId !== undefined) {
                localVarQueryParameter['target_site_id'] = targetSiteId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * set geofences for site
         * @summary set geofences for site
         * @param {string} siteId Site ID
         * @param {SiteRequestSiteGeofence} geofence Site object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setSiteGeofence: async (siteId: string, geofence: SiteRequestSiteGeofence, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'siteId' is not null or undefined
            assertParamExists('setSiteGeofence', 'siteId', siteId)
            // verify required parameter 'geofence' is not null or undefined
            assertParamExists('setSiteGeofence', 'geofence', geofence)
            const localVarPath = `/sites/{site_id}/coordinates`
                .replace(`{${"site_id"}}`, encodeURIComponent(String(siteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(geofence, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * update site
         * @summary update site
         * @param {SiteRequestSite} site Site object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSite: async (site: SiteRequestSite, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'site' is not null or undefined
            assertParamExists('updateSite', 'site', site)
            const localVarPath = `/sites`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(site, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SitesApi - functional programming interface
 * @export
 */
export const SitesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SitesApiAxiosParamCreator(configuration)
    return {
        /**
         * add site
         * @summary add site
         * @param {SiteRequestSite} newSite Site object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSite(newSite: SiteRequestSite, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SiteSiteResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSite(newSite, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SitesApi.createSite']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * delete site by UUID
         * @summary delete site by UUID
         * @param {string} siteId Site ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSite(siteId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HandlerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSite(siteId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SitesApi.deleteSite']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * get site object by UUID
         * @summary get site object
         * @param {string} siteId Site ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSiteById(siteId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SiteSiteResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSiteById(siteId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SitesApi.getSiteById']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * get site dependencies
         * @summary Check for dependencies on site
         * @param {string} siteId Site ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSiteDependencies(siteId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SiteDependenciesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSiteDependencies(siteId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SitesApi.getSiteDependencies']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * get KPIs for vehicle
         * @summary get KPIs for vehicle
         * @param {string} siteId Site ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSiteKpis(siteId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SiteKpisResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSiteKpis(siteId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SitesApi.getSiteKpis']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * get site max created_at
         * @summary get site max created_at
         * @param {string} organizationId Organization ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSiteMaxCreatedAt(organizationId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SiteSiteMaxCreatedAtResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSiteMaxCreatedAt(organizationId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SitesApi.getSiteMaxCreatedAt']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * get list of all alternate jobs sites for organization
         * @summary get list of all alternate jobs sites for organization
         * @param {string} siteId site id to find other sites from
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAlternateSites(siteId: string, sortBy?: string, offset?: number, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SiteSiteAlternateListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAlternateSites(siteId, sortBy, offset, limit, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SitesApi.listAlternateSites']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * get list of all sties for organization
         * @summary get list of all sites for organization
         * @param {string} [organizationId] Organization ID
         * @param {string} [siteOwner] site owner to filter query
         * @param {string} [search] Text wildcard search
         * @param {string} [customerUuid] customer_id to query
         * @param {string} [active] active status of item (0/1 or true/false) - defaults to true
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listSite(organizationId?: string, siteOwner?: string, search?: string, customerUuid?: string, active?: string, sortBy?: string, offset?: number, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SiteSiteListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listSite(organizationId, siteOwner, search, customerUuid, active, sortBy, offset, limit, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SitesApi.listSite']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * merge one site into another by ID
         * @summary merge one site into another by ID
         * @param {string} siteId Site ID
         * @param {string} targetSiteId Target Site ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mergeSite(siteId: string, targetSiteId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HandlerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mergeSite(siteId, targetSiteId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SitesApi.mergeSite']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * set geofences for site
         * @summary set geofences for site
         * @param {string} siteId Site ID
         * @param {SiteRequestSiteGeofence} geofence Site object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setSiteGeofence(siteId: string, geofence: SiteRequestSiteGeofence, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SiteSiteResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setSiteGeofence(siteId, geofence, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SitesApi.setSiteGeofence']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * update site
         * @summary update site
         * @param {SiteRequestSite} site Site object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSite(site: SiteRequestSite, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SiteSiteResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSite(site, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SitesApi.updateSite']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * SitesApi - factory interface
 * @export
 */
export const SitesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SitesApiFp(configuration)
    return {
        /**
         * add site
         * @summary add site
         * @param {SiteRequestSite} newSite Site object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSite(newSite: SiteRequestSite, options?: any): AxiosPromise<SiteSiteResponse> {
            return localVarFp.createSite(newSite, options).then((request) => request(axios, basePath));
        },
        /**
         * delete site by UUID
         * @summary delete site by UUID
         * @param {string} siteId Site ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSite(siteId: string, options?: any): AxiosPromise<HandlerResponse> {
            return localVarFp.deleteSite(siteId, options).then((request) => request(axios, basePath));
        },
        /**
         * get site object by UUID
         * @summary get site object
         * @param {string} siteId Site ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSiteById(siteId: string, options?: any): AxiosPromise<SiteSiteResponse> {
            return localVarFp.getSiteById(siteId, options).then((request) => request(axios, basePath));
        },
        /**
         * get site dependencies
         * @summary Check for dependencies on site
         * @param {string} siteId Site ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSiteDependencies(siteId: string, options?: any): AxiosPromise<SiteDependenciesResponse> {
            return localVarFp.getSiteDependencies(siteId, options).then((request) => request(axios, basePath));
        },
        /**
         * get KPIs for vehicle
         * @summary get KPIs for vehicle
         * @param {string} siteId Site ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSiteKpis(siteId: string, options?: any): AxiosPromise<SiteKpisResponse> {
            return localVarFp.getSiteKpis(siteId, options).then((request) => request(axios, basePath));
        },
        /**
         * get site max created_at
         * @summary get site max created_at
         * @param {string} organizationId Organization ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSiteMaxCreatedAt(organizationId: string, options?: any): AxiosPromise<SiteSiteMaxCreatedAtResponse> {
            return localVarFp.getSiteMaxCreatedAt(organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * get list of all alternate jobs sites for organization
         * @summary get list of all alternate jobs sites for organization
         * @param {string} siteId site id to find other sites from
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAlternateSites(siteId: string, sortBy?: string, offset?: number, limit?: number, options?: any): AxiosPromise<SiteSiteAlternateListResponse> {
            return localVarFp.listAlternateSites(siteId, sortBy, offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * get list of all sties for organization
         * @summary get list of all sites for organization
         * @param {string} [organizationId] Organization ID
         * @param {string} [siteOwner] site owner to filter query
         * @param {string} [search] Text wildcard search
         * @param {string} [customerUuid] customer_id to query
         * @param {string} [active] active status of item (0/1 or true/false) - defaults to true
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSite(organizationId?: string, siteOwner?: string, search?: string, customerUuid?: string, active?: string, sortBy?: string, offset?: number, limit?: number, options?: any): AxiosPromise<SiteSiteListResponse> {
            return localVarFp.listSite(organizationId, siteOwner, search, customerUuid, active, sortBy, offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * merge one site into another by ID
         * @summary merge one site into another by ID
         * @param {string} siteId Site ID
         * @param {string} targetSiteId Target Site ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mergeSite(siteId: string, targetSiteId: string, options?: any): AxiosPromise<HandlerResponse> {
            return localVarFp.mergeSite(siteId, targetSiteId, options).then((request) => request(axios, basePath));
        },
        /**
         * set geofences for site
         * @summary set geofences for site
         * @param {string} siteId Site ID
         * @param {SiteRequestSiteGeofence} geofence Site object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setSiteGeofence(siteId: string, geofence: SiteRequestSiteGeofence, options?: any): AxiosPromise<SiteSiteResponse> {
            return localVarFp.setSiteGeofence(siteId, geofence, options).then((request) => request(axios, basePath));
        },
        /**
         * update site
         * @summary update site
         * @param {SiteRequestSite} site Site object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSite(site: SiteRequestSite, options?: any): AxiosPromise<SiteSiteResponse> {
            return localVarFp.updateSite(site, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SitesApi - object-oriented interface
 * @export
 * @class SitesApi
 * @extends {BaseAPI}
 */
export class SitesApi extends BaseAPI {
    /**
     * add site
     * @summary add site
     * @param {SiteRequestSite} newSite Site object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SitesApi
     */
    public createSite(newSite: SiteRequestSite, options?: RawAxiosRequestConfig) {
        return SitesApiFp(this.configuration).createSite(newSite, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * delete site by UUID
     * @summary delete site by UUID
     * @param {string} siteId Site ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SitesApi
     */
    public deleteSite(siteId: string, options?: RawAxiosRequestConfig) {
        return SitesApiFp(this.configuration).deleteSite(siteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get site object by UUID
     * @summary get site object
     * @param {string} siteId Site ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SitesApi
     */
    public getSiteById(siteId: string, options?: RawAxiosRequestConfig) {
        return SitesApiFp(this.configuration).getSiteById(siteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get site dependencies
     * @summary Check for dependencies on site
     * @param {string} siteId Site ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SitesApi
     */
    public getSiteDependencies(siteId: string, options?: RawAxiosRequestConfig) {
        return SitesApiFp(this.configuration).getSiteDependencies(siteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get KPIs for vehicle
     * @summary get KPIs for vehicle
     * @param {string} siteId Site ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SitesApi
     */
    public getSiteKpis(siteId: string, options?: RawAxiosRequestConfig) {
        return SitesApiFp(this.configuration).getSiteKpis(siteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get site max created_at
     * @summary get site max created_at
     * @param {string} organizationId Organization ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SitesApi
     */
    public getSiteMaxCreatedAt(organizationId: string, options?: RawAxiosRequestConfig) {
        return SitesApiFp(this.configuration).getSiteMaxCreatedAt(organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get list of all alternate jobs sites for organization
     * @summary get list of all alternate jobs sites for organization
     * @param {string} siteId site id to find other sites from
     * @param {string} [sortBy] First sort column
     * @param {number} [offset] Offset
     * @param {number} [limit] Limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SitesApi
     */
    public listAlternateSites(siteId: string, sortBy?: string, offset?: number, limit?: number, options?: RawAxiosRequestConfig) {
        return SitesApiFp(this.configuration).listAlternateSites(siteId, sortBy, offset, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get list of all sties for organization
     * @summary get list of all sites for organization
     * @param {string} [organizationId] Organization ID
     * @param {string} [siteOwner] site owner to filter query
     * @param {string} [search] Text wildcard search
     * @param {string} [customerUuid] customer_id to query
     * @param {string} [active] active status of item (0/1 or true/false) - defaults to true
     * @param {string} [sortBy] First sort column
     * @param {number} [offset] Offset
     * @param {number} [limit] Limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SitesApi
     */
    public listSite(organizationId?: string, siteOwner?: string, search?: string, customerUuid?: string, active?: string, sortBy?: string, offset?: number, limit?: number, options?: RawAxiosRequestConfig) {
        return SitesApiFp(this.configuration).listSite(organizationId, siteOwner, search, customerUuid, active, sortBy, offset, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * merge one site into another by ID
     * @summary merge one site into another by ID
     * @param {string} siteId Site ID
     * @param {string} targetSiteId Target Site ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SitesApi
     */
    public mergeSite(siteId: string, targetSiteId: string, options?: RawAxiosRequestConfig) {
        return SitesApiFp(this.configuration).mergeSite(siteId, targetSiteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * set geofences for site
     * @summary set geofences for site
     * @param {string} siteId Site ID
     * @param {SiteRequestSiteGeofence} geofence Site object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SitesApi
     */
    public setSiteGeofence(siteId: string, geofence: SiteRequestSiteGeofence, options?: RawAxiosRequestConfig) {
        return SitesApiFp(this.configuration).setSiteGeofence(siteId, geofence, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * update site
     * @summary update site
     * @param {SiteRequestSite} site Site object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SitesApi
     */
    public updateSite(site: SiteRequestSite, options?: RawAxiosRequestConfig) {
        return SitesApiFp(this.configuration).updateSite(site, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * StockApi - axios parameter creator
 * @export
 */
export const StockApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * get returns all job sites with assigned stock still open or recent
         * @summary get returns all job sites with assigned stock still open or recent
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listJobsitesAssignedStock: async (sortBy?: string, offset?: number, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/stock/job-sites-assigned-stock`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * list all stock available
         * @summary list all stock available
         * @param {string} [lastSeenById] owner_id of the last room or vehicle that scanned the item
         * @param {string} [manufacturerId] manufacturer_id of the stock item
         * @param {string} [productId] product_id of the stock item
         * @param {string} [warehouseId] warehouse_id who last saw the stock item
         * @param {string} [groupByField] additional group by field (
         * @param {string} [search] Text wildcard search
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listStockAvailable: async (lastSeenById?: string, manufacturerId?: string, productId?: string, warehouseId?: string, groupByField?: string, search?: string, sortBy?: string, offset?: number, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/stock/available`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (lastSeenById !== undefined) {
                localVarQueryParameter['last_seen_by_id'] = lastSeenById;
            }

            if (manufacturerId !== undefined) {
                localVarQueryParameter['manufacturer_id'] = manufacturerId;
            }

            if (productId !== undefined) {
                localVarQueryParameter['product_id'] = productId;
            }

            if (warehouseId !== undefined) {
                localVarQueryParameter['warehouse_id'] = warehouseId;
            }

            if (groupByField !== undefined) {
                localVarQueryParameter['group_by_field'] = groupByField;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * list all stock installed
         * @summary list all stock installed
         * @param {string} [vehicleId] id vehicle that installed the stock item
         * @param {string} [manufacturerId] manufacturer of the stock item
         * @param {string} [productId] product of the stock item installed
         * @param {string} [siteId] site where the stock item was instaled
         * @param {string} [processedBegin] Begin time
         * @param {string} [processedEnd] End time
         * @param {string} [search] Text wildcard search
         * @param {string} [recent] true/false for recent/historical
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listStockInstalled: async (vehicleId?: string, manufacturerId?: string, productId?: string, siteId?: string, processedBegin?: string, processedEnd?: string, search?: string, recent?: string, sortBy?: string, offset?: number, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/stock/installed`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (vehicleId !== undefined) {
                localVarQueryParameter['vehicle_id'] = vehicleId;
            }

            if (manufacturerId !== undefined) {
                localVarQueryParameter['manufacturer_id'] = manufacturerId;
            }

            if (productId !== undefined) {
                localVarQueryParameter['product_id'] = productId;
            }

            if (siteId !== undefined) {
                localVarQueryParameter['site_id'] = siteId;
            }

            if (processedBegin !== undefined) {
                localVarQueryParameter['processed_begin'] = processedBegin;
            }

            if (processedEnd !== undefined) {
                localVarQueryParameter['processed_end'] = processedEnd;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (recent !== undefined) {
                localVarQueryParameter['recent'] = recent;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * stock summary widgets
         * @summary stock summary widgets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockCategories: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/stock/categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StockApi - functional programming interface
 * @export
 */
export const StockApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StockApiAxiosParamCreator(configuration)
    return {
        /**
         * get returns all job sites with assigned stock still open or recent
         * @summary get returns all job sites with assigned stock still open or recent
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listJobsitesAssignedStock(sortBy?: string, offset?: number, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockListJobSiteAssignedStockResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listJobsitesAssignedStock(sortBy, offset, limit, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['StockApi.listJobsitesAssignedStock']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * list all stock available
         * @summary list all stock available
         * @param {string} [lastSeenById] owner_id of the last room or vehicle that scanned the item
         * @param {string} [manufacturerId] manufacturer_id of the stock item
         * @param {string} [productId] product_id of the stock item
         * @param {string} [warehouseId] warehouse_id who last saw the stock item
         * @param {string} [groupByField] additional group by field (
         * @param {string} [search] Text wildcard search
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listStockAvailable(lastSeenById?: string, manufacturerId?: string, productId?: string, warehouseId?: string, groupByField?: string, search?: string, sortBy?: string, offset?: number, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockAvailableListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listStockAvailable(lastSeenById, manufacturerId, productId, warehouseId, groupByField, search, sortBy, offset, limit, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['StockApi.listStockAvailable']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * list all stock installed
         * @summary list all stock installed
         * @param {string} [vehicleId] id vehicle that installed the stock item
         * @param {string} [manufacturerId] manufacturer of the stock item
         * @param {string} [productId] product of the stock item installed
         * @param {string} [siteId] site where the stock item was instaled
         * @param {string} [processedBegin] Begin time
         * @param {string} [processedEnd] End time
         * @param {string} [search] Text wildcard search
         * @param {string} [recent] true/false for recent/historical
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listStockInstalled(vehicleId?: string, manufacturerId?: string, productId?: string, siteId?: string, processedBegin?: string, processedEnd?: string, search?: string, recent?: string, sortBy?: string, offset?: number, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockInstalledListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listStockInstalled(vehicleId, manufacturerId, productId, siteId, processedBegin, processedEnd, search, recent, sortBy, offset, limit, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['StockApi.listStockInstalled']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * stock summary widgets
         * @summary stock summary widgets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockCategories(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockCategoryListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockCategories(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['StockApi.stockCategories']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * StockApi - factory interface
 * @export
 */
export const StockApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StockApiFp(configuration)
    return {
        /**
         * get returns all job sites with assigned stock still open or recent
         * @summary get returns all job sites with assigned stock still open or recent
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listJobsitesAssignedStock(sortBy?: string, offset?: number, limit?: number, options?: any): AxiosPromise<StockListJobSiteAssignedStockResponse> {
            return localVarFp.listJobsitesAssignedStock(sortBy, offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * list all stock available
         * @summary list all stock available
         * @param {string} [lastSeenById] owner_id of the last room or vehicle that scanned the item
         * @param {string} [manufacturerId] manufacturer_id of the stock item
         * @param {string} [productId] product_id of the stock item
         * @param {string} [warehouseId] warehouse_id who last saw the stock item
         * @param {string} [groupByField] additional group by field (
         * @param {string} [search] Text wildcard search
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listStockAvailable(lastSeenById?: string, manufacturerId?: string, productId?: string, warehouseId?: string, groupByField?: string, search?: string, sortBy?: string, offset?: number, limit?: number, options?: any): AxiosPromise<StockAvailableListResponse> {
            return localVarFp.listStockAvailable(lastSeenById, manufacturerId, productId, warehouseId, groupByField, search, sortBy, offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * list all stock installed
         * @summary list all stock installed
         * @param {string} [vehicleId] id vehicle that installed the stock item
         * @param {string} [manufacturerId] manufacturer of the stock item
         * @param {string} [productId] product of the stock item installed
         * @param {string} [siteId] site where the stock item was instaled
         * @param {string} [processedBegin] Begin time
         * @param {string} [processedEnd] End time
         * @param {string} [search] Text wildcard search
         * @param {string} [recent] true/false for recent/historical
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listStockInstalled(vehicleId?: string, manufacturerId?: string, productId?: string, siteId?: string, processedBegin?: string, processedEnd?: string, search?: string, recent?: string, sortBy?: string, offset?: number, limit?: number, options?: any): AxiosPromise<StockInstalledListResponse> {
            return localVarFp.listStockInstalled(vehicleId, manufacturerId, productId, siteId, processedBegin, processedEnd, search, recent, sortBy, offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * stock summary widgets
         * @summary stock summary widgets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockCategories(options?: any): AxiosPromise<StockCategoryListResponse> {
            return localVarFp.stockCategories(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StockApi - object-oriented interface
 * @export
 * @class StockApi
 * @extends {BaseAPI}
 */
export class StockApi extends BaseAPI {
    /**
     * get returns all job sites with assigned stock still open or recent
     * @summary get returns all job sites with assigned stock still open or recent
     * @param {string} [sortBy] First sort column
     * @param {number} [offset] Offset
     * @param {number} [limit] Limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockApi
     */
    public listJobsitesAssignedStock(sortBy?: string, offset?: number, limit?: number, options?: RawAxiosRequestConfig) {
        return StockApiFp(this.configuration).listJobsitesAssignedStock(sortBy, offset, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * list all stock available
     * @summary list all stock available
     * @param {string} [lastSeenById] owner_id of the last room or vehicle that scanned the item
     * @param {string} [manufacturerId] manufacturer_id of the stock item
     * @param {string} [productId] product_id of the stock item
     * @param {string} [warehouseId] warehouse_id who last saw the stock item
     * @param {string} [groupByField] additional group by field (
     * @param {string} [search] Text wildcard search
     * @param {string} [sortBy] First sort column
     * @param {number} [offset] Offset
     * @param {number} [limit] Limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockApi
     */
    public listStockAvailable(lastSeenById?: string, manufacturerId?: string, productId?: string, warehouseId?: string, groupByField?: string, search?: string, sortBy?: string, offset?: number, limit?: number, options?: RawAxiosRequestConfig) {
        return StockApiFp(this.configuration).listStockAvailable(lastSeenById, manufacturerId, productId, warehouseId, groupByField, search, sortBy, offset, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * list all stock installed
     * @summary list all stock installed
     * @param {string} [vehicleId] id vehicle that installed the stock item
     * @param {string} [manufacturerId] manufacturer of the stock item
     * @param {string} [productId] product of the stock item installed
     * @param {string} [siteId] site where the stock item was instaled
     * @param {string} [processedBegin] Begin time
     * @param {string} [processedEnd] End time
     * @param {string} [search] Text wildcard search
     * @param {string} [recent] true/false for recent/historical
     * @param {string} [sortBy] First sort column
     * @param {number} [offset] Offset
     * @param {number} [limit] Limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockApi
     */
    public listStockInstalled(vehicleId?: string, manufacturerId?: string, productId?: string, siteId?: string, processedBegin?: string, processedEnd?: string, search?: string, recent?: string, sortBy?: string, offset?: number, limit?: number, options?: RawAxiosRequestConfig) {
        return StockApiFp(this.configuration).listStockInstalled(vehicleId, manufacturerId, productId, siteId, processedBegin, processedEnd, search, recent, sortBy, offset, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * stock summary widgets
     * @summary stock summary widgets
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockApi
     */
    public stockCategories(options?: RawAxiosRequestConfig) {
        return StockApiFp(this.configuration).stockCategories(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * StorageApi - axios parameter creator
 * @export
 */
export const StorageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create file upload object and file upload link
         * @param {StorageCreateStorageUploadRequest} uploads Create Upload Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageCreateUpload: async (uploads: StorageCreateStorageUploadRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uploads' is not null or undefined
            assertParamExists('storageCreateUpload', 'uploads', uploads)
            const localVarPath = `/storage/uploads`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(uploads, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete upload data by ID
         * @param {string} uuid Upload UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageDeleteUpload: async (uuid: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('storageDeleteUpload', 'uuid', uuid)
            const localVarPath = `/storage/uploads/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Mark file upload process as finished in DB
         * @param {string} uuid Upload UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageFinishUpload: async (uuid: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('storageFinishUpload', 'uuid', uuid)
            const localVarPath = `/storage/uploads/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get upload data by ID
         * @param {string} uuid Upload UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageGetUpload: async (uuid: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('storageGetUpload', 'uuid', uuid)
            const localVarPath = `/storage/uploads/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get list of uploads by filtering
         * @param {string} [fileKey] filter uploads by file key (can use % for like matching)
         * @param {string} [ownerId] filter uploads by owner_id (exact match)
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageListUpload: async (fileKey?: string, ownerId?: string, offset?: number, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/uploads`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fileKey !== undefined) {
                localVarQueryParameter['fileKey'] = fileKey;
            }

            if (ownerId !== undefined) {
                localVarQueryParameter['ownerId'] = ownerId;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update some storage upload fields allowed to change after upload
         * @param {string} uuid Upload UUID
         * @param {StorageUpdateStorageUploadRequest} upload Update storage upload object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageUpdateUpload: async (uuid: string, upload: StorageUpdateStorageUploadRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('storageUpdateUpload', 'uuid', uuid)
            // verify required parameter 'upload' is not null or undefined
            assertParamExists('storageUpdateUpload', 'upload', upload)
            const localVarPath = `/storage/uploads/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(upload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StorageApi - functional programming interface
 * @export
 */
export const StorageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StorageApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create file upload object and file upload link
         * @param {StorageCreateStorageUploadRequest} uploads Create Upload Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storageCreateUpload(uploads: StorageCreateStorageUploadRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StorageCreateStorageUploadResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storageCreateUpload(uploads, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['StorageApi.storageCreateUpload']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Delete upload data by ID
         * @param {string} uuid Upload UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storageDeleteUpload(uuid: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StorageDeleteStorageUploadResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storageDeleteUpload(uuid, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['StorageApi.storageDeleteUpload']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Mark file upload process as finished in DB
         * @param {string} uuid Upload UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storageFinishUpload(uuid: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StorageFinishStorageUploadResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storageFinishUpload(uuid, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['StorageApi.storageFinishUpload']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get upload data by ID
         * @param {string} uuid Upload UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storageGetUpload(uuid: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StorageGetStorageUploadResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storageGetUpload(uuid, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['StorageApi.storageGetUpload']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get list of uploads by filtering
         * @param {string} [fileKey] filter uploads by file key (can use % for like matching)
         * @param {string} [ownerId] filter uploads by owner_id (exact match)
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storageListUpload(fileKey?: string, ownerId?: string, offset?: number, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StorageListStorageUploadResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storageListUpload(fileKey, ownerId, offset, limit, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['StorageApi.storageListUpload']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Update some storage upload fields allowed to change after upload
         * @param {string} uuid Upload UUID
         * @param {StorageUpdateStorageUploadRequest} upload Update storage upload object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storageUpdateUpload(uuid: string, upload: StorageUpdateStorageUploadRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StorageUpdateStorageUploadResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storageUpdateUpload(uuid, upload, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['StorageApi.storageUpdateUpload']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * StorageApi - factory interface
 * @export
 */
export const StorageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StorageApiFp(configuration)
    return {
        /**
         * 
         * @summary Create file upload object and file upload link
         * @param {StorageCreateStorageUploadRequest} uploads Create Upload Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageCreateUpload(uploads: StorageCreateStorageUploadRequest, options?: any): AxiosPromise<StorageCreateStorageUploadResponse> {
            return localVarFp.storageCreateUpload(uploads, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete upload data by ID
         * @param {string} uuid Upload UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageDeleteUpload(uuid: string, options?: any): AxiosPromise<StorageDeleteStorageUploadResponse> {
            return localVarFp.storageDeleteUpload(uuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Mark file upload process as finished in DB
         * @param {string} uuid Upload UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageFinishUpload(uuid: string, options?: any): AxiosPromise<StorageFinishStorageUploadResponse> {
            return localVarFp.storageFinishUpload(uuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get upload data by ID
         * @param {string} uuid Upload UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageGetUpload(uuid: string, options?: any): AxiosPromise<StorageGetStorageUploadResponse> {
            return localVarFp.storageGetUpload(uuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get list of uploads by filtering
         * @param {string} [fileKey] filter uploads by file key (can use % for like matching)
         * @param {string} [ownerId] filter uploads by owner_id (exact match)
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageListUpload(fileKey?: string, ownerId?: string, offset?: number, limit?: number, options?: any): AxiosPromise<StorageListStorageUploadResponse> {
            return localVarFp.storageListUpload(fileKey, ownerId, offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update some storage upload fields allowed to change after upload
         * @param {string} uuid Upload UUID
         * @param {StorageUpdateStorageUploadRequest} upload Update storage upload object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageUpdateUpload(uuid: string, upload: StorageUpdateStorageUploadRequest, options?: any): AxiosPromise<StorageUpdateStorageUploadResponse> {
            return localVarFp.storageUpdateUpload(uuid, upload, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StorageApi - object-oriented interface
 * @export
 * @class StorageApi
 * @extends {BaseAPI}
 */
export class StorageApi extends BaseAPI {
    /**
     * 
     * @summary Create file upload object and file upload link
     * @param {StorageCreateStorageUploadRequest} uploads Create Upload Request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageApi
     */
    public storageCreateUpload(uploads: StorageCreateStorageUploadRequest, options?: RawAxiosRequestConfig) {
        return StorageApiFp(this.configuration).storageCreateUpload(uploads, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete upload data by ID
     * @param {string} uuid Upload UUID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageApi
     */
    public storageDeleteUpload(uuid: string, options?: RawAxiosRequestConfig) {
        return StorageApiFp(this.configuration).storageDeleteUpload(uuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Mark file upload process as finished in DB
     * @param {string} uuid Upload UUID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageApi
     */
    public storageFinishUpload(uuid: string, options?: RawAxiosRequestConfig) {
        return StorageApiFp(this.configuration).storageFinishUpload(uuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get upload data by ID
     * @param {string} uuid Upload UUID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageApi
     */
    public storageGetUpload(uuid: string, options?: RawAxiosRequestConfig) {
        return StorageApiFp(this.configuration).storageGetUpload(uuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get list of uploads by filtering
     * @param {string} [fileKey] filter uploads by file key (can use % for like matching)
     * @param {string} [ownerId] filter uploads by owner_id (exact match)
     * @param {number} [offset] Offset
     * @param {number} [limit] Limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageApi
     */
    public storageListUpload(fileKey?: string, ownerId?: string, offset?: number, limit?: number, options?: RawAxiosRequestConfig) {
        return StorageApiFp(this.configuration).storageListUpload(fileKey, ownerId, offset, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update some storage upload fields allowed to change after upload
     * @param {string} uuid Upload UUID
     * @param {StorageUpdateStorageUploadRequest} upload Update storage upload object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageApi
     */
    public storageUpdateUpload(uuid: string, upload: StorageUpdateStorageUploadRequest, options?: RawAxiosRequestConfig) {
        return StorageApiFp(this.configuration).storageUpdateUpload(uuid, upload, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TagHealthApi - axios parameter creator
 * @export
 */
export const TagHealthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * get patag health item reads
         * @summary get tag health item reads
         * @param {string} [payloadId] Payload ID
         * @param {string} [productId] Product ID
         * @param {string} [itemId] Item ID
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTagHealthItemReads: async (payloadId?: string, productId?: string, itemId?: string, sortBy?: string, offset?: number, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/tag-health/item-reads`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (payloadId !== undefined) {
                localVarQueryParameter['payload_id'] = payloadId;
            }

            if (productId !== undefined) {
                localVarQueryParameter['product_id'] = productId;
            }

            if (itemId !== undefined) {
                localVarQueryParameter['item_id'] = itemId;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get patag health last for for each item
         * @summary get tag health last for for each item
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTagHealthLastItemReads: async (sortBy?: string, offset?: number, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/tag-health/items-last-reads`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get patag health tag reads
         * @summary get tag health tag reads
         * @param {string} [payloadId] Payload ID
         * @param {string} [productId] Product ID
         * @param {string} [itemId] Item ID
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTagHealthTagReads: async (payloadId?: string, productId?: string, itemId?: string, sortBy?: string, offset?: number, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/tag-health/tag-reads`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (payloadId !== undefined) {
                localVarQueryParameter['payload_id'] = payloadId;
            }

            if (productId !== undefined) {
                localVarQueryParameter['product_id'] = productId;
            }

            if (itemId !== undefined) {
                localVarQueryParameter['item_id'] = itemId;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TagHealthApi - functional programming interface
 * @export
 */
export const TagHealthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TagHealthApiAxiosParamCreator(configuration)
    return {
        /**
         * get patag health item reads
         * @summary get tag health item reads
         * @param {string} [payloadId] Payload ID
         * @param {string} [productId] Product ID
         * @param {string} [itemId] Item ID
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTagHealthItemReads(payloadId?: string, productId?: string, itemId?: string, sortBy?: string, offset?: number, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PayloadTagHealthItemListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTagHealthItemReads(payloadId, productId, itemId, sortBy, offset, limit, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TagHealthApi.getTagHealthItemReads']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * get patag health last for for each item
         * @summary get tag health last for for each item
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTagHealthLastItemReads(sortBy?: string, offset?: number, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PayloadTagHealthItemListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTagHealthLastItemReads(sortBy, offset, limit, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TagHealthApi.getTagHealthLastItemReads']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * get patag health tag reads
         * @summary get tag health tag reads
         * @param {string} [payloadId] Payload ID
         * @param {string} [productId] Product ID
         * @param {string} [itemId] Item ID
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTagHealthTagReads(payloadId?: string, productId?: string, itemId?: string, sortBy?: string, offset?: number, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PayloadTagHealthTagListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTagHealthTagReads(payloadId, productId, itemId, sortBy, offset, limit, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TagHealthApi.getTagHealthTagReads']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * TagHealthApi - factory interface
 * @export
 */
export const TagHealthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TagHealthApiFp(configuration)
    return {
        /**
         * get patag health item reads
         * @summary get tag health item reads
         * @param {string} [payloadId] Payload ID
         * @param {string} [productId] Product ID
         * @param {string} [itemId] Item ID
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTagHealthItemReads(payloadId?: string, productId?: string, itemId?: string, sortBy?: string, offset?: number, limit?: number, options?: any): AxiosPromise<PayloadTagHealthItemListResponse> {
            return localVarFp.getTagHealthItemReads(payloadId, productId, itemId, sortBy, offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * get patag health last for for each item
         * @summary get tag health last for for each item
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTagHealthLastItemReads(sortBy?: string, offset?: number, limit?: number, options?: any): AxiosPromise<PayloadTagHealthItemListResponse> {
            return localVarFp.getTagHealthLastItemReads(sortBy, offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * get patag health tag reads
         * @summary get tag health tag reads
         * @param {string} [payloadId] Payload ID
         * @param {string} [productId] Product ID
         * @param {string} [itemId] Item ID
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTagHealthTagReads(payloadId?: string, productId?: string, itemId?: string, sortBy?: string, offset?: number, limit?: number, options?: any): AxiosPromise<PayloadTagHealthTagListResponse> {
            return localVarFp.getTagHealthTagReads(payloadId, productId, itemId, sortBy, offset, limit, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TagHealthApi - object-oriented interface
 * @export
 * @class TagHealthApi
 * @extends {BaseAPI}
 */
export class TagHealthApi extends BaseAPI {
    /**
     * get patag health item reads
     * @summary get tag health item reads
     * @param {string} [payloadId] Payload ID
     * @param {string} [productId] Product ID
     * @param {string} [itemId] Item ID
     * @param {string} [sortBy] First sort column
     * @param {number} [offset] Offset
     * @param {number} [limit] Limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagHealthApi
     */
    public getTagHealthItemReads(payloadId?: string, productId?: string, itemId?: string, sortBy?: string, offset?: number, limit?: number, options?: RawAxiosRequestConfig) {
        return TagHealthApiFp(this.configuration).getTagHealthItemReads(payloadId, productId, itemId, sortBy, offset, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get patag health last for for each item
     * @summary get tag health last for for each item
     * @param {string} [sortBy] First sort column
     * @param {number} [offset] Offset
     * @param {number} [limit] Limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagHealthApi
     */
    public getTagHealthLastItemReads(sortBy?: string, offset?: number, limit?: number, options?: RawAxiosRequestConfig) {
        return TagHealthApiFp(this.configuration).getTagHealthLastItemReads(sortBy, offset, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get patag health tag reads
     * @summary get tag health tag reads
     * @param {string} [payloadId] Payload ID
     * @param {string} [productId] Product ID
     * @param {string} [itemId] Item ID
     * @param {string} [sortBy] First sort column
     * @param {number} [offset] Offset
     * @param {number} [limit] Limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagHealthApi
     */
    public getTagHealthTagReads(payloadId?: string, productId?: string, itemId?: string, sortBy?: string, offset?: number, limit?: number, options?: RawAxiosRequestConfig) {
        return TagHealthApiFp(this.configuration).getTagHealthTagReads(payloadId, productId, itemId, sortBy, offset, limit, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TrackingApi - axios parameter creator
 * @export
 */
export const TrackingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * get last processed scan time
         * @summary get last processed scan time
         * @param {string} [processedAtMin] Min time query from  in RFC3339 format
         * @param {string} [organizationIds] Organization IDs (1 or more \&#39;,\&#39; separated)
         * @param {string} [warehouseId] Warehouse ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trackingLastProcessed: async (processedAtMin?: string, organizationIds?: string, warehouseId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/tracking/last-processed`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (processedAtMin !== undefined) {
                localVarQueryParameter['processed-at-min'] = processedAtMin;
            }

            if (organizationIds !== undefined) {
                localVarQueryParameter['organization-ids'] = organizationIds;
            }

            if (warehouseId !== undefined) {
                localVarQueryParameter['warehouse-id'] = warehouseId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TrackingApi - functional programming interface
 * @export
 */
export const TrackingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TrackingApiAxiosParamCreator(configuration)
    return {
        /**
         * get last processed scan time
         * @summary get last processed scan time
         * @param {string} [processedAtMin] Min time query from  in RFC3339 format
         * @param {string} [organizationIds] Organization IDs (1 or more \&#39;,\&#39; separated)
         * @param {string} [warehouseId] Warehouse ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async trackingLastProcessed(processedAtMin?: string, organizationIds?: string, warehouseId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrackingLastProcessedResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.trackingLastProcessed(processedAtMin, organizationIds, warehouseId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TrackingApi.trackingLastProcessed']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * TrackingApi - factory interface
 * @export
 */
export const TrackingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TrackingApiFp(configuration)
    return {
        /**
         * get last processed scan time
         * @summary get last processed scan time
         * @param {string} [processedAtMin] Min time query from  in RFC3339 format
         * @param {string} [organizationIds] Organization IDs (1 or more \&#39;,\&#39; separated)
         * @param {string} [warehouseId] Warehouse ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trackingLastProcessed(processedAtMin?: string, organizationIds?: string, warehouseId?: string, options?: any): AxiosPromise<TrackingLastProcessedResponse> {
            return localVarFp.trackingLastProcessed(processedAtMin, organizationIds, warehouseId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TrackingApi - object-oriented interface
 * @export
 * @class TrackingApi
 * @extends {BaseAPI}
 */
export class TrackingApi extends BaseAPI {
    /**
     * get last processed scan time
     * @summary get last processed scan time
     * @param {string} [processedAtMin] Min time query from  in RFC3339 format
     * @param {string} [organizationIds] Organization IDs (1 or more \&#39;,\&#39; separated)
     * @param {string} [warehouseId] Warehouse ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrackingApi
     */
    public trackingLastProcessed(processedAtMin?: string, organizationIds?: string, warehouseId?: string, options?: RawAxiosRequestConfig) {
        return TrackingApiFp(this.configuration).trackingLastProcessed(processedAtMin, organizationIds, warehouseId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * create user and and bind it to organization
         * @summary create user
         * @param {UserRequestUserObject} user User object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser: async (user: UserRequestUserObject, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'user' is not null or undefined
            assertParamExists('createUser', 'user', user)
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(user, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * send email with password reset link
         * @summary send email with password reset link
         * @param {UserRequestEmailPasswordResetConfirm} request Password reset confirm request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailPasswordConfirm: async (request: UserRequestEmailPasswordResetConfirm, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('emailPasswordConfirm', 'request', request)
            const localVarPath = `/user/password/reset/confirm/email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * send email with password reset link
         * @summary send email with password reset link
         * @param {string} email Email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailPasswordResetRequest: async (email: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('emailPasswordResetRequest', 'email', email)
            const localVarPath = `/user/password/reset/request/email/{email}`
                .replace(`{${"email"}}`, encodeURIComponent(String(email)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get current authorized user
         * @summary get current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentUser: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/current`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get list of all users from organization
         * @summary get list of all users from organization
         * @param {string} userUuid User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser: async (userUuid: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userUuid' is not null or undefined
            assertParamExists('getUser', 'userUuid', userUuid)
            const localVarPath = `/users/details/{user_uuid}`
                .replace(`{${"user_uuid"}}`, encodeURIComponent(String(userUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get current authorized user
         * @summary get current user
         * @param {string} userUuid User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserDependencies: async (userUuid: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userUuid' is not null or undefined
            assertParamExists('getUserDependencies', 'userUuid', userUuid)
            const localVarPath = `/users/dependencies/{user_uuid}`
                .replace(`{${"user_uuid"}}`, encodeURIComponent(String(userUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get list of all users from organization
         * @summary get list of all users from organization
         * @param {string} [organizationId] Organization ID
         * @param {string} [id] User ID
         * @param {string} [role] Role
         * @param {string} [firstName] First Name
         * @param {string} [search] Text wildcard search
         * @param {string} [active] active status of item (0/1 or true/false) - defaults to true
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsers: async (organizationId?: string, id?: string, role?: string, firstName?: string, search?: string, active?: string, sortBy?: string, offset?: number, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (organizationId !== undefined) {
                localVarQueryParameter['organization_id'] = organizationId;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (role !== undefined) {
                localVarQueryParameter['role'] = role;
            }

            if (firstName !== undefined) {
                localVarQueryParameter['first_name'] = firstName;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (active !== undefined) {
                localVarQueryParameter['active'] = active;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ask server to verify code & save password
         * @summary submit sms code for verification and phone generated password
         * @param {UserUserSmsConfirmRequest} user User SMS code object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        phoneConfirmCode: async (user: UserUserSmsConfirmRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'user' is not null or undefined
            assertParamExists('phoneConfirmCode', 'user', user)
            const localVarPath = `/users/phone-confirm-code`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(user, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ask server to sms verification code to operator
         * @summary request sms code for the user
         * @param {UserUserSmsCodeRequest} user User SMS code object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        phoneRequestCode: async (user: UserUserSmsCodeRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'user' is not null or undefined
            assertParamExists('phoneRequestCode', 'user', user)
            const localVarPath = `/users/phone-request-code`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(user, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * reinvite a user that has not been verified
         * @summary reinvite user
         * @param {UserReinviteUserRequest} user User object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reinviteUser: async (user: UserReinviteUserRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'user' is not null or undefined
            assertParamExists('reinviteUser', 'user', user)
            const localVarPath = `/users/reinvite`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(user, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary set email password
         * @param {UserUserEmailPasswordChangeRequest} user User email password change object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setEmailPassword: async (user: UserUserEmailPasswordChangeRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'user' is not null or undefined
            assertParamExists('setEmailPassword', 'user', user)
            const localVarPath = `/users/set-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(user, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update the users Apns device token
         * @summary Update the users Apns device token
         * @param {UserRequestUpdateApnsToken} user User object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateApnsToken: async (user: UserRequestUpdateApnsToken, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'user' is not null or undefined
            assertParamExists('updateApnsToken', 'user', user)
            const localVarPath = `/users/update_apns_token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(user, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * update user handler
         * @summary update user record
         * @param {UserRequestUserUpdateObject} user User update request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser: async (user: UserRequestUserUpdateObject, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'user' is not null or undefined
            assertParamExists('updateUser', 'user', user)
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(user, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get current authorized user
         * @summary get current user
         * @param {string} userUuid User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userDelete: async (userUuid: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userUuid' is not null or undefined
            assertParamExists('userDelete', 'userUuid', userUuid)
            const localVarPath = `/users/details/{user_uuid}`
                .replace(`{${"user_uuid"}}`, encodeURIComponent(String(userUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary validate user email
         * @param {UserUserValidateEmailRequest} user User validate object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateEmail: async (user: UserUserValidateEmailRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'user' is not null or undefined
            assertParamExists('validateEmail', 'user', user)
            const localVarPath = `/users/validate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(user, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary validate user invite or password reset URL parameters
         * @param {UserValidateURLRequest} validate Pasword reset or invite validation object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateUrl: async (validate: UserValidateURLRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'validate' is not null or undefined
            assertParamExists('validateUrl', 'validate', validate)
            const localVarPath = `/users/validate/url`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(validate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * create user and and bind it to organization
         * @summary create user
         * @param {UserRequestUserObject} user User object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUser(user: UserRequestUserObject, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUser(user, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UsersApi.createUser']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * send email with password reset link
         * @summary send email with password reset link
         * @param {UserRequestEmailPasswordResetConfirm} request Password reset confirm request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emailPasswordConfirm(request: UserRequestEmailPasswordResetConfirm, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HandlerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emailPasswordConfirm(request, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UsersApi.emailPasswordConfirm']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * send email with password reset link
         * @summary send email with password reset link
         * @param {string} email Email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emailPasswordResetRequest(email: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HandlerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emailPasswordResetRequest(email, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UsersApi.emailPasswordResetRequest']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * get current authorized user
         * @summary get current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCurrentUser(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserCurrentUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCurrentUser(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UsersApi.getCurrentUser']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * get list of all users from organization
         * @summary get list of all users from organization
         * @param {string} userUuid User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUser(userUuid: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUser(userUuid, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UsersApi.getUser']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * get current authorized user
         * @summary get current user
         * @param {string} userUuid User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserDependencies(userUuid: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserUserDependenciesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserDependencies(userUuid, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UsersApi.getUserDependencies']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * get list of all users from organization
         * @summary get list of all users from organization
         * @param {string} [organizationId] Organization ID
         * @param {string} [id] User ID
         * @param {string} [role] Role
         * @param {string} [firstName] First Name
         * @param {string} [search] Text wildcard search
         * @param {string} [active] active status of item (0/1 or true/false) - defaults to true
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listUsers(organizationId?: string, id?: string, role?: string, firstName?: string, search?: string, active?: string, sortBy?: string, offset?: number, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listUsers(organizationId, id, role, firstName, search, active, sortBy, offset, limit, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UsersApi.listUsers']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * ask server to verify code & save password
         * @summary submit sms code for verification and phone generated password
         * @param {UserUserSmsConfirmRequest} user User SMS code object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async phoneConfirmCode(user: UserUserSmsConfirmRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserSmsConfirmResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.phoneConfirmCode(user, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UsersApi.phoneConfirmCode']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * ask server to sms verification code to operator
         * @summary request sms code for the user
         * @param {UserUserSmsCodeRequest} user User SMS code object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async phoneRequestCode(user: UserUserSmsCodeRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserSmsConfirmResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.phoneRequestCode(user, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UsersApi.phoneRequestCode']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * reinvite a user that has not been verified
         * @summary reinvite user
         * @param {UserReinviteUserRequest} user User object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reinviteUser(user: UserReinviteUserRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reinviteUser(user, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UsersApi.reinviteUser']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary set email password
         * @param {UserUserEmailPasswordChangeRequest} user User email password change object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setEmailPassword(user: UserUserEmailPasswordChangeRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserEmailPasswordResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setEmailPassword(user, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UsersApi.setEmailPassword']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Update the users Apns device token
         * @summary Update the users Apns device token
         * @param {UserRequestUpdateApnsToken} user User object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateApnsToken(user: UserRequestUpdateApnsToken, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HandlerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateApnsToken(user, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UsersApi.updateApnsToken']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * update user handler
         * @summary update user record
         * @param {UserRequestUserUpdateObject} user User update request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUser(user: UserRequestUserUpdateObject, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUser(user, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UsersApi.updateUser']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * get current authorized user
         * @summary get current user
         * @param {string} userUuid User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userDelete(userUuid: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserUserDependenciesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userDelete(userUuid, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UsersApi.userDelete']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary validate user email
         * @param {UserUserValidateEmailRequest} user User validate object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateEmail(user: UserUserValidateEmailRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validateEmail(user, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UsersApi.validateEmail']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary validate user invite or password reset URL parameters
         * @param {UserValidateURLRequest} validate Pasword reset or invite validation object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateUrl(validate: UserValidateURLRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserValidateURLResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validateUrl(validate, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UsersApi.validateUrl']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * create user and and bind it to organization
         * @summary create user
         * @param {UserRequestUserObject} user User object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser(user: UserRequestUserObject, options?: any): AxiosPromise<UserUserResponse> {
            return localVarFp.createUser(user, options).then((request) => request(axios, basePath));
        },
        /**
         * send email with password reset link
         * @summary send email with password reset link
         * @param {UserRequestEmailPasswordResetConfirm} request Password reset confirm request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailPasswordConfirm(request: UserRequestEmailPasswordResetConfirm, options?: any): AxiosPromise<HandlerResponse> {
            return localVarFp.emailPasswordConfirm(request, options).then((request) => request(axios, basePath));
        },
        /**
         * send email with password reset link
         * @summary send email with password reset link
         * @param {string} email Email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailPasswordResetRequest(email: string, options?: any): AxiosPromise<HandlerResponse> {
            return localVarFp.emailPasswordResetRequest(email, options).then((request) => request(axios, basePath));
        },
        /**
         * get current authorized user
         * @summary get current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentUser(options?: any): AxiosPromise<UserCurrentUserResponse> {
            return localVarFp.getCurrentUser(options).then((request) => request(axios, basePath));
        },
        /**
         * get list of all users from organization
         * @summary get list of all users from organization
         * @param {string} userUuid User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser(userUuid: string, options?: any): AxiosPromise<UserUserResponse> {
            return localVarFp.getUser(userUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * get current authorized user
         * @summary get current user
         * @param {string} userUuid User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserDependencies(userUuid: string, options?: any): AxiosPromise<UserUserDependenciesResponse> {
            return localVarFp.getUserDependencies(userUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * get list of all users from organization
         * @summary get list of all users from organization
         * @param {string} [organizationId] Organization ID
         * @param {string} [id] User ID
         * @param {string} [role] Role
         * @param {string} [firstName] First Name
         * @param {string} [search] Text wildcard search
         * @param {string} [active] active status of item (0/1 or true/false) - defaults to true
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsers(organizationId?: string, id?: string, role?: string, firstName?: string, search?: string, active?: string, sortBy?: string, offset?: number, limit?: number, options?: any): AxiosPromise<UserListResponse> {
            return localVarFp.listUsers(organizationId, id, role, firstName, search, active, sortBy, offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * ask server to verify code & save password
         * @summary submit sms code for verification and phone generated password
         * @param {UserUserSmsConfirmRequest} user User SMS code object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        phoneConfirmCode(user: UserUserSmsConfirmRequest, options?: any): AxiosPromise<UserSmsConfirmResponse> {
            return localVarFp.phoneConfirmCode(user, options).then((request) => request(axios, basePath));
        },
        /**
         * ask server to sms verification code to operator
         * @summary request sms code for the user
         * @param {UserUserSmsCodeRequest} user User SMS code object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        phoneRequestCode(user: UserUserSmsCodeRequest, options?: any): AxiosPromise<UserSmsConfirmResponse> {
            return localVarFp.phoneRequestCode(user, options).then((request) => request(axios, basePath));
        },
        /**
         * reinvite a user that has not been verified
         * @summary reinvite user
         * @param {UserReinviteUserRequest} user User object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reinviteUser(user: UserReinviteUserRequest, options?: any): AxiosPromise<UserUserResponse> {
            return localVarFp.reinviteUser(user, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary set email password
         * @param {UserUserEmailPasswordChangeRequest} user User email password change object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setEmailPassword(user: UserUserEmailPasswordChangeRequest, options?: any): AxiosPromise<UserEmailPasswordResponse> {
            return localVarFp.setEmailPassword(user, options).then((request) => request(axios, basePath));
        },
        /**
         * Update the users Apns device token
         * @summary Update the users Apns device token
         * @param {UserRequestUpdateApnsToken} user User object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateApnsToken(user: UserRequestUpdateApnsToken, options?: any): AxiosPromise<HandlerResponse> {
            return localVarFp.updateApnsToken(user, options).then((request) => request(axios, basePath));
        },
        /**
         * update user handler
         * @summary update user record
         * @param {UserRequestUserUpdateObject} user User update request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser(user: UserRequestUserUpdateObject, options?: any): AxiosPromise<UserUserResponse> {
            return localVarFp.updateUser(user, options).then((request) => request(axios, basePath));
        },
        /**
         * get current authorized user
         * @summary get current user
         * @param {string} userUuid User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userDelete(userUuid: string, options?: any): AxiosPromise<UserUserDependenciesResponse> {
            return localVarFp.userDelete(userUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary validate user email
         * @param {UserUserValidateEmailRequest} user User validate object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateEmail(user: UserUserValidateEmailRequest, options?: any): AxiosPromise<UserUserResponse> {
            return localVarFp.validateEmail(user, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary validate user invite or password reset URL parameters
         * @param {UserValidateURLRequest} validate Pasword reset or invite validation object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateUrl(validate: UserValidateURLRequest, options?: any): AxiosPromise<UserValidateURLResponse> {
            return localVarFp.validateUrl(validate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * create user and and bind it to organization
     * @summary create user
     * @param {UserRequestUserObject} user User object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public createUser(user: UserRequestUserObject, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).createUser(user, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * send email with password reset link
     * @summary send email with password reset link
     * @param {UserRequestEmailPasswordResetConfirm} request Password reset confirm request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public emailPasswordConfirm(request: UserRequestEmailPasswordResetConfirm, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).emailPasswordConfirm(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * send email with password reset link
     * @summary send email with password reset link
     * @param {string} email Email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public emailPasswordResetRequest(email: string, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).emailPasswordResetRequest(email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get current authorized user
     * @summary get current user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getCurrentUser(options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).getCurrentUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get list of all users from organization
     * @summary get list of all users from organization
     * @param {string} userUuid User ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUser(userUuid: string, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).getUser(userUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get current authorized user
     * @summary get current user
     * @param {string} userUuid User ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUserDependencies(userUuid: string, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).getUserDependencies(userUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get list of all users from organization
     * @summary get list of all users from organization
     * @param {string} [organizationId] Organization ID
     * @param {string} [id] User ID
     * @param {string} [role] Role
     * @param {string} [firstName] First Name
     * @param {string} [search] Text wildcard search
     * @param {string} [active] active status of item (0/1 or true/false) - defaults to true
     * @param {string} [sortBy] First sort column
     * @param {number} [offset] Offset
     * @param {number} [limit] Limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public listUsers(organizationId?: string, id?: string, role?: string, firstName?: string, search?: string, active?: string, sortBy?: string, offset?: number, limit?: number, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).listUsers(organizationId, id, role, firstName, search, active, sortBy, offset, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ask server to verify code & save password
     * @summary submit sms code for verification and phone generated password
     * @param {UserUserSmsConfirmRequest} user User SMS code object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public phoneConfirmCode(user: UserUserSmsConfirmRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).phoneConfirmCode(user, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ask server to sms verification code to operator
     * @summary request sms code for the user
     * @param {UserUserSmsCodeRequest} user User SMS code object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public phoneRequestCode(user: UserUserSmsCodeRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).phoneRequestCode(user, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * reinvite a user that has not been verified
     * @summary reinvite user
     * @param {UserReinviteUserRequest} user User object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public reinviteUser(user: UserReinviteUserRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).reinviteUser(user, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary set email password
     * @param {UserUserEmailPasswordChangeRequest} user User email password change object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public setEmailPassword(user: UserUserEmailPasswordChangeRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).setEmailPassword(user, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update the users Apns device token
     * @summary Update the users Apns device token
     * @param {UserRequestUpdateApnsToken} user User object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public updateApnsToken(user: UserRequestUpdateApnsToken, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).updateApnsToken(user, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * update user handler
     * @summary update user record
     * @param {UserRequestUserUpdateObject} user User update request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public updateUser(user: UserRequestUserUpdateObject, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).updateUser(user, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get current authorized user
     * @summary get current user
     * @param {string} userUuid User ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public userDelete(userUuid: string, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).userDelete(userUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary validate user email
     * @param {UserUserValidateEmailRequest} user User validate object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public validateEmail(user: UserUserValidateEmailRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).validateEmail(user, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary validate user invite or password reset URL parameters
     * @param {UserValidateURLRequest} validate Pasword reset or invite validation object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public validateUrl(validate: UserValidateURLRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).validateUrl(validate, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * VehiclesApi - axios parameter creator
 * @export
 */
export const VehiclesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * create vehicle object with an existing device
         * @summary create vehicle with an existing evice
         * @param {VehicleRequestVehicleWithDevice} vehicle Vehicle object with device
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createVehicle: async (vehicle: VehicleRequestVehicleWithDevice, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vehicle' is not null or undefined
            assertParamExists('createVehicle', 'vehicle', vehicle)
            const localVarPath = `/vehicles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vehicle, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * delete vehicle by UUID
         * @summary delete vehicle by UUID
         * @param {string} vehicleUuid Vehicle ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteVehicle: async (vehicleUuid: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vehicleUuid' is not null or undefined
            assertParamExists('deleteVehicle', 'vehicleUuid', vehicleUuid)
            const localVarPath = `/vehicles/{vehicle_uuid}`
                .replace(`{${"vehicle_uuid"}}`, encodeURIComponent(String(vehicleUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get vehicle object by UUID
         * @summary get vehicle object
         * @param {string} vehicleUuid Vehicle ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVehicleByUuid: async (vehicleUuid: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vehicleUuid' is not null or undefined
            assertParamExists('getVehicleByUuid', 'vehicleUuid', vehicleUuid)
            const localVarPath = `/vehicles/{vehicle_uuid}`
                .replace(`{${"vehicle_uuid"}}`, encodeURIComponent(String(vehicleUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get vehicle dependencies
         * @summary Check for dependencies on site
         * @param {string} vehicleUuid Vehicle ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVehicleDependencies: async (vehicleUuid: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vehicleUuid' is not null or undefined
            assertParamExists('getVehicleDependencies', 'vehicleUuid', vehicleUuid)
            const localVarPath = `/vehicles/{vehicle_uuid}/dependencies`
                .replace(`{${"vehicle_uuid"}}`, encodeURIComponent(String(vehicleUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get KPIs for vehicle
         * @summary get KPIs for vehicle
         * @param {string} vehicleUuid Vehicle ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVehicleKpis: async (vehicleUuid: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vehicleUuid' is not null or undefined
            assertParamExists('getVehicleKpis', 'vehicleUuid', vehicleUuid)
            const localVarPath = `/vehicles/{vehicle_uuid}/kpis`
                .replace(`{${"vehicle_uuid"}}`, encodeURIComponent(String(vehicleUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get list of all vehicles
         * @summary get list of all vehicles
         * @param {string} [organizationId] Organization ID
         * @param {string} [search] Text wildcard search
         * @param {string} [active] active status of item (0/1 or true/false) - defaults to true
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listVehicles: async (organizationId?: string, search?: string, active?: string, sortBy?: string, offset?: number, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/vehicles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (organizationId !== undefined) {
                localVarQueryParameter['organization_id'] = organizationId;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (active !== undefined) {
                localVarQueryParameter['active'] = active;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * update vehicle object
         * @summary update vehicle
         * @param {VehicleRequestUpdateVehicle} vehicle Vehicle object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVehicle: async (vehicle: VehicleRequestUpdateVehicle, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vehicle' is not null or undefined
            assertParamExists('updateVehicle', 'vehicle', vehicle)
            const localVarPath = `/vehicles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vehicle, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VehiclesApi - functional programming interface
 * @export
 */
export const VehiclesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VehiclesApiAxiosParamCreator(configuration)
    return {
        /**
         * create vehicle object with an existing device
         * @summary create vehicle with an existing evice
         * @param {VehicleRequestVehicleWithDevice} vehicle Vehicle object with device
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createVehicle(vehicle: VehicleRequestVehicleWithDevice, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VehicleCreateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createVehicle(vehicle, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['VehiclesApi.createVehicle']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * delete vehicle by UUID
         * @summary delete vehicle by UUID
         * @param {string} vehicleUuid Vehicle ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteVehicle(vehicleUuid: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HandlerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteVehicle(vehicleUuid, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['VehiclesApi.deleteVehicle']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * get vehicle object by UUID
         * @summary get vehicle object
         * @param {string} vehicleUuid Vehicle ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVehicleByUuid(vehicleUuid: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VehicleVehicleResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVehicleByUuid(vehicleUuid, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['VehiclesApi.getVehicleByUuid']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * get vehicle dependencies
         * @summary Check for dependencies on site
         * @param {string} vehicleUuid Vehicle ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVehicleDependencies(vehicleUuid: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VehicleDependenciesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVehicleDependencies(vehicleUuid, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['VehiclesApi.getVehicleDependencies']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * get KPIs for vehicle
         * @summary get KPIs for vehicle
         * @param {string} vehicleUuid Vehicle ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVehicleKpis(vehicleUuid: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VehicleKpisResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVehicleKpis(vehicleUuid, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['VehiclesApi.getVehicleKpis']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * get list of all vehicles
         * @summary get list of all vehicles
         * @param {string} [organizationId] Organization ID
         * @param {string} [search] Text wildcard search
         * @param {string} [active] active status of item (0/1 or true/false) - defaults to true
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listVehicles(organizationId?: string, search?: string, active?: string, sortBy?: string, offset?: number, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VehicleListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listVehicles(organizationId, search, active, sortBy, offset, limit, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['VehiclesApi.listVehicles']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * update vehicle object
         * @summary update vehicle
         * @param {VehicleRequestUpdateVehicle} vehicle Vehicle object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateVehicle(vehicle: VehicleRequestUpdateVehicle, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VehicleVehicleResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateVehicle(vehicle, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['VehiclesApi.updateVehicle']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * VehiclesApi - factory interface
 * @export
 */
export const VehiclesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VehiclesApiFp(configuration)
    return {
        /**
         * create vehicle object with an existing device
         * @summary create vehicle with an existing evice
         * @param {VehicleRequestVehicleWithDevice} vehicle Vehicle object with device
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createVehicle(vehicle: VehicleRequestVehicleWithDevice, options?: any): AxiosPromise<VehicleCreateResponse> {
            return localVarFp.createVehicle(vehicle, options).then((request) => request(axios, basePath));
        },
        /**
         * delete vehicle by UUID
         * @summary delete vehicle by UUID
         * @param {string} vehicleUuid Vehicle ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteVehicle(vehicleUuid: string, options?: any): AxiosPromise<HandlerResponse> {
            return localVarFp.deleteVehicle(vehicleUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * get vehicle object by UUID
         * @summary get vehicle object
         * @param {string} vehicleUuid Vehicle ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVehicleByUuid(vehicleUuid: string, options?: any): AxiosPromise<VehicleVehicleResponse> {
            return localVarFp.getVehicleByUuid(vehicleUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * get vehicle dependencies
         * @summary Check for dependencies on site
         * @param {string} vehicleUuid Vehicle ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVehicleDependencies(vehicleUuid: string, options?: any): AxiosPromise<VehicleDependenciesResponse> {
            return localVarFp.getVehicleDependencies(vehicleUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * get KPIs for vehicle
         * @summary get KPIs for vehicle
         * @param {string} vehicleUuid Vehicle ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVehicleKpis(vehicleUuid: string, options?: any): AxiosPromise<VehicleKpisResponse> {
            return localVarFp.getVehicleKpis(vehicleUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * get list of all vehicles
         * @summary get list of all vehicles
         * @param {string} [organizationId] Organization ID
         * @param {string} [search] Text wildcard search
         * @param {string} [active] active status of item (0/1 or true/false) - defaults to true
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listVehicles(organizationId?: string, search?: string, active?: string, sortBy?: string, offset?: number, limit?: number, options?: any): AxiosPromise<VehicleListResponse> {
            return localVarFp.listVehicles(organizationId, search, active, sortBy, offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * update vehicle object
         * @summary update vehicle
         * @param {VehicleRequestUpdateVehicle} vehicle Vehicle object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVehicle(vehicle: VehicleRequestUpdateVehicle, options?: any): AxiosPromise<VehicleVehicleResponse> {
            return localVarFp.updateVehicle(vehicle, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VehiclesApi - object-oriented interface
 * @export
 * @class VehiclesApi
 * @extends {BaseAPI}
 */
export class VehiclesApi extends BaseAPI {
    /**
     * create vehicle object with an existing device
     * @summary create vehicle with an existing evice
     * @param {VehicleRequestVehicleWithDevice} vehicle Vehicle object with device
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehiclesApi
     */
    public createVehicle(vehicle: VehicleRequestVehicleWithDevice, options?: RawAxiosRequestConfig) {
        return VehiclesApiFp(this.configuration).createVehicle(vehicle, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * delete vehicle by UUID
     * @summary delete vehicle by UUID
     * @param {string} vehicleUuid Vehicle ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehiclesApi
     */
    public deleteVehicle(vehicleUuid: string, options?: RawAxiosRequestConfig) {
        return VehiclesApiFp(this.configuration).deleteVehicle(vehicleUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get vehicle object by UUID
     * @summary get vehicle object
     * @param {string} vehicleUuid Vehicle ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehiclesApi
     */
    public getVehicleByUuid(vehicleUuid: string, options?: RawAxiosRequestConfig) {
        return VehiclesApiFp(this.configuration).getVehicleByUuid(vehicleUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get vehicle dependencies
     * @summary Check for dependencies on site
     * @param {string} vehicleUuid Vehicle ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehiclesApi
     */
    public getVehicleDependencies(vehicleUuid: string, options?: RawAxiosRequestConfig) {
        return VehiclesApiFp(this.configuration).getVehicleDependencies(vehicleUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get KPIs for vehicle
     * @summary get KPIs for vehicle
     * @param {string} vehicleUuid Vehicle ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehiclesApi
     */
    public getVehicleKpis(vehicleUuid: string, options?: RawAxiosRequestConfig) {
        return VehiclesApiFp(this.configuration).getVehicleKpis(vehicleUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get list of all vehicles
     * @summary get list of all vehicles
     * @param {string} [organizationId] Organization ID
     * @param {string} [search] Text wildcard search
     * @param {string} [active] active status of item (0/1 or true/false) - defaults to true
     * @param {string} [sortBy] First sort column
     * @param {number} [offset] Offset
     * @param {number} [limit] Limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehiclesApi
     */
    public listVehicles(organizationId?: string, search?: string, active?: string, sortBy?: string, offset?: number, limit?: number, options?: RawAxiosRequestConfig) {
        return VehiclesApiFp(this.configuration).listVehicles(organizationId, search, active, sortBy, offset, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * update vehicle object
     * @summary update vehicle
     * @param {VehicleRequestUpdateVehicle} vehicle Vehicle object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehiclesApi
     */
    public updateVehicle(vehicle: VehicleRequestUpdateVehicle, options?: RawAxiosRequestConfig) {
        return VehiclesApiFp(this.configuration).updateVehicle(vehicle, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * VerticalMarketsApi - axios parameter creator
 * @export
 */
export const VerticalMarketsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * create an vertical market object
         * @summary create vertical market
         * @param {VerticalMarketRequestVerticalMarket} verticalMarket Vertical Market object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createVerticalMarket: async (verticalMarket: VerticalMarketRequestVerticalMarket, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'verticalMarket' is not null or undefined
            assertParamExists('createVerticalMarket', 'verticalMarket', verticalMarket)
            const localVarPath = `/vertical-markets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(verticalMarket, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * delete vertical market
         * @summary delete vertical market
         * @param {string} marketId Vertical Market ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteVerticalMarket: async (marketId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'marketId' is not null or undefined
            assertParamExists('deleteVerticalMarket', 'marketId', marketId)
            const localVarPath = `/vertical-markets/{market_id}`
                .replace(`{${"market_id"}}`, encodeURIComponent(String(marketId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get vertical market object by ID
         * @summary get vertical market
         * @param {string} marketId Market ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVerticalMarketById: async (marketId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'marketId' is not null or undefined
            assertParamExists('getVerticalMarketById', 'marketId', marketId)
            const localVarPath = `/vertical-markets/{market_id}`
                .replace(`{${"market_id"}}`, encodeURIComponent(String(marketId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get vertical market dependencies
         * @summary Check for dependencies on vertical market
         * @param {string} marketId Vertical Market ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVerticalMarketDependencies: async (marketId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'marketId' is not null or undefined
            assertParamExists('getVerticalMarketDependencies', 'marketId', marketId)
            const localVarPath = `/vertical-markets/{market_id}/dependencies`
                .replace(`{${"market_id"}}`, encodeURIComponent(String(marketId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * list manufacturers set for vertical market object by ID
         * @summary get vertical market manufacturers list
         * @param {string} marketId Market ID
         * @param {string} [unassigned] flag for assigned/unassigned manufacturers (0/1)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVerticalMarketManufacturersById: async (marketId: string, unassigned?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'marketId' is not null or undefined
            assertParamExists('getVerticalMarketManufacturersById', 'marketId', marketId)
            const localVarPath = `/vertical-markets/{market_id}/manufacturers`
                .replace(`{${"market_id"}}`, encodeURIComponent(String(marketId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (unassigned !== undefined) {
                localVarQueryParameter['unassigned'] = unassigned;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get vertical markets
         * @summary get vertical markets
         * @param {string} [active] active flag
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVerticalMarkets: async (active?: string, sortBy?: string, offset?: number, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/vertical-markets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (active !== undefined) {
                localVarQueryParameter['active'] = active;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * set manufacturers for vertical market object
         * @summary set vertical market manufacturers
         * @param {string} marketId Market ID
         * @param {VerticalMarketManufacturerSetRequest} manufacturers List of manufacturers ID to add and remove
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setManufacturersForVerticalMarket: async (marketId: string, manufacturers: VerticalMarketManufacturerSetRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'marketId' is not null or undefined
            assertParamExists('setManufacturersForVerticalMarket', 'marketId', marketId)
            // verify required parameter 'manufacturers' is not null or undefined
            assertParamExists('setManufacturersForVerticalMarket', 'manufacturers', manufacturers)
            const localVarPath = `/vertical-markets/{market_id}/manufacturers`
                .replace(`{${"market_id"}}`, encodeURIComponent(String(marketId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(manufacturers, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * update vertical market object
         * @summary update vertical market
         * @param {string} marketId Vertical Market ID
         * @param {VerticalMarketRequestVerticalMarket} verticalMarket Vertical Market object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVerticalMarket: async (marketId: string, verticalMarket: VerticalMarketRequestVerticalMarket, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'marketId' is not null or undefined
            assertParamExists('updateVerticalMarket', 'marketId', marketId)
            // verify required parameter 'verticalMarket' is not null or undefined
            assertParamExists('updateVerticalMarket', 'verticalMarket', verticalMarket)
            const localVarPath = `/vertical-markets/{market_id}`
                .replace(`{${"market_id"}}`, encodeURIComponent(String(marketId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(verticalMarket, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VerticalMarketsApi - functional programming interface
 * @export
 */
export const VerticalMarketsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VerticalMarketsApiAxiosParamCreator(configuration)
    return {
        /**
         * create an vertical market object
         * @summary create vertical market
         * @param {VerticalMarketRequestVerticalMarket} verticalMarket Vertical Market object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createVerticalMarket(verticalMarket: VerticalMarketRequestVerticalMarket, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VerticalMarketObjectResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createVerticalMarket(verticalMarket, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['VerticalMarketsApi.createVerticalMarket']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * delete vertical market
         * @summary delete vertical market
         * @param {string} marketId Vertical Market ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteVerticalMarket(marketId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HandlerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteVerticalMarket(marketId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['VerticalMarketsApi.deleteVerticalMarket']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * get vertical market object by ID
         * @summary get vertical market
         * @param {string} marketId Market ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVerticalMarketById(marketId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VerticalMarketObjectResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVerticalMarketById(marketId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['VerticalMarketsApi.getVerticalMarketById']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * get vertical market dependencies
         * @summary Check for dependencies on vertical market
         * @param {string} marketId Vertical Market ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVerticalMarketDependencies(marketId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VerticalMarketDependenciesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVerticalMarketDependencies(marketId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['VerticalMarketsApi.getVerticalMarketDependencies']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * list manufacturers set for vertical market object by ID
         * @summary get vertical market manufacturers list
         * @param {string} marketId Market ID
         * @param {string} [unassigned] flag for assigned/unassigned manufacturers (0/1)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVerticalMarketManufacturersById(marketId: string, unassigned?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VerticalMarketManufacturerListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVerticalMarketManufacturersById(marketId, unassigned, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['VerticalMarketsApi.getVerticalMarketManufacturersById']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * get vertical markets
         * @summary get vertical markets
         * @param {string} [active] active flag
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVerticalMarkets(active?: string, sortBy?: string, offset?: number, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VerticalMarketListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVerticalMarkets(active, sortBy, offset, limit, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['VerticalMarketsApi.getVerticalMarkets']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * set manufacturers for vertical market object
         * @summary set vertical market manufacturers
         * @param {string} marketId Market ID
         * @param {VerticalMarketManufacturerSetRequest} manufacturers List of manufacturers ID to add and remove
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setManufacturersForVerticalMarket(marketId: string, manufacturers: VerticalMarketManufacturerSetRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VerticalMarketManufacturerListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setManufacturersForVerticalMarket(marketId, manufacturers, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['VerticalMarketsApi.setManufacturersForVerticalMarket']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * update vertical market object
         * @summary update vertical market
         * @param {string} marketId Vertical Market ID
         * @param {VerticalMarketRequestVerticalMarket} verticalMarket Vertical Market object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateVerticalMarket(marketId: string, verticalMarket: VerticalMarketRequestVerticalMarket, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VerticalMarketObjectResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateVerticalMarket(marketId, verticalMarket, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['VerticalMarketsApi.updateVerticalMarket']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * VerticalMarketsApi - factory interface
 * @export
 */
export const VerticalMarketsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VerticalMarketsApiFp(configuration)
    return {
        /**
         * create an vertical market object
         * @summary create vertical market
         * @param {VerticalMarketRequestVerticalMarket} verticalMarket Vertical Market object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createVerticalMarket(verticalMarket: VerticalMarketRequestVerticalMarket, options?: any): AxiosPromise<VerticalMarketObjectResponse> {
            return localVarFp.createVerticalMarket(verticalMarket, options).then((request) => request(axios, basePath));
        },
        /**
         * delete vertical market
         * @summary delete vertical market
         * @param {string} marketId Vertical Market ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteVerticalMarket(marketId: string, options?: any): AxiosPromise<HandlerResponse> {
            return localVarFp.deleteVerticalMarket(marketId, options).then((request) => request(axios, basePath));
        },
        /**
         * get vertical market object by ID
         * @summary get vertical market
         * @param {string} marketId Market ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVerticalMarketById(marketId: string, options?: any): AxiosPromise<VerticalMarketObjectResponse> {
            return localVarFp.getVerticalMarketById(marketId, options).then((request) => request(axios, basePath));
        },
        /**
         * get vertical market dependencies
         * @summary Check for dependencies on vertical market
         * @param {string} marketId Vertical Market ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVerticalMarketDependencies(marketId: string, options?: any): AxiosPromise<VerticalMarketDependenciesResponse> {
            return localVarFp.getVerticalMarketDependencies(marketId, options).then((request) => request(axios, basePath));
        },
        /**
         * list manufacturers set for vertical market object by ID
         * @summary get vertical market manufacturers list
         * @param {string} marketId Market ID
         * @param {string} [unassigned] flag for assigned/unassigned manufacturers (0/1)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVerticalMarketManufacturersById(marketId: string, unassigned?: string, options?: any): AxiosPromise<VerticalMarketManufacturerListResponse> {
            return localVarFp.getVerticalMarketManufacturersById(marketId, unassigned, options).then((request) => request(axios, basePath));
        },
        /**
         * get vertical markets
         * @summary get vertical markets
         * @param {string} [active] active flag
         * @param {string} [sortBy] First sort column
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVerticalMarkets(active?: string, sortBy?: string, offset?: number, limit?: number, options?: any): AxiosPromise<VerticalMarketListResponse> {
            return localVarFp.getVerticalMarkets(active, sortBy, offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * set manufacturers for vertical market object
         * @summary set vertical market manufacturers
         * @param {string} marketId Market ID
         * @param {VerticalMarketManufacturerSetRequest} manufacturers List of manufacturers ID to add and remove
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setManufacturersForVerticalMarket(marketId: string, manufacturers: VerticalMarketManufacturerSetRequest, options?: any): AxiosPromise<VerticalMarketManufacturerListResponse> {
            return localVarFp.setManufacturersForVerticalMarket(marketId, manufacturers, options).then((request) => request(axios, basePath));
        },
        /**
         * update vertical market object
         * @summary update vertical market
         * @param {string} marketId Vertical Market ID
         * @param {VerticalMarketRequestVerticalMarket} verticalMarket Vertical Market object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVerticalMarket(marketId: string, verticalMarket: VerticalMarketRequestVerticalMarket, options?: any): AxiosPromise<VerticalMarketObjectResponse> {
            return localVarFp.updateVerticalMarket(marketId, verticalMarket, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VerticalMarketsApi - object-oriented interface
 * @export
 * @class VerticalMarketsApi
 * @extends {BaseAPI}
 */
export class VerticalMarketsApi extends BaseAPI {
    /**
     * create an vertical market object
     * @summary create vertical market
     * @param {VerticalMarketRequestVerticalMarket} verticalMarket Vertical Market object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerticalMarketsApi
     */
    public createVerticalMarket(verticalMarket: VerticalMarketRequestVerticalMarket, options?: RawAxiosRequestConfig) {
        return VerticalMarketsApiFp(this.configuration).createVerticalMarket(verticalMarket, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * delete vertical market
     * @summary delete vertical market
     * @param {string} marketId Vertical Market ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerticalMarketsApi
     */
    public deleteVerticalMarket(marketId: string, options?: RawAxiosRequestConfig) {
        return VerticalMarketsApiFp(this.configuration).deleteVerticalMarket(marketId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get vertical market object by ID
     * @summary get vertical market
     * @param {string} marketId Market ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerticalMarketsApi
     */
    public getVerticalMarketById(marketId: string, options?: RawAxiosRequestConfig) {
        return VerticalMarketsApiFp(this.configuration).getVerticalMarketById(marketId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get vertical market dependencies
     * @summary Check for dependencies on vertical market
     * @param {string} marketId Vertical Market ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerticalMarketsApi
     */
    public getVerticalMarketDependencies(marketId: string, options?: RawAxiosRequestConfig) {
        return VerticalMarketsApiFp(this.configuration).getVerticalMarketDependencies(marketId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * list manufacturers set for vertical market object by ID
     * @summary get vertical market manufacturers list
     * @param {string} marketId Market ID
     * @param {string} [unassigned] flag for assigned/unassigned manufacturers (0/1)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerticalMarketsApi
     */
    public getVerticalMarketManufacturersById(marketId: string, unassigned?: string, options?: RawAxiosRequestConfig) {
        return VerticalMarketsApiFp(this.configuration).getVerticalMarketManufacturersById(marketId, unassigned, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get vertical markets
     * @summary get vertical markets
     * @param {string} [active] active flag
     * @param {string} [sortBy] First sort column
     * @param {number} [offset] Offset
     * @param {number} [limit] Limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerticalMarketsApi
     */
    public getVerticalMarkets(active?: string, sortBy?: string, offset?: number, limit?: number, options?: RawAxiosRequestConfig) {
        return VerticalMarketsApiFp(this.configuration).getVerticalMarkets(active, sortBy, offset, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * set manufacturers for vertical market object
     * @summary set vertical market manufacturers
     * @param {string} marketId Market ID
     * @param {VerticalMarketManufacturerSetRequest} manufacturers List of manufacturers ID to add and remove
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerticalMarketsApi
     */
    public setManufacturersForVerticalMarket(marketId: string, manufacturers: VerticalMarketManufacturerSetRequest, options?: RawAxiosRequestConfig) {
        return VerticalMarketsApiFp(this.configuration).setManufacturersForVerticalMarket(marketId, manufacturers, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * update vertical market object
     * @summary update vertical market
     * @param {string} marketId Vertical Market ID
     * @param {VerticalMarketRequestVerticalMarket} verticalMarket Vertical Market object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerticalMarketsApi
     */
    public updateVerticalMarket(marketId: string, verticalMarket: VerticalMarketRequestVerticalMarket, options?: RawAxiosRequestConfig) {
        return VerticalMarketsApiFp(this.configuration).updateVerticalMarket(marketId, verticalMarket, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * WarehousesApi - axios parameter creator
 * @export
 */
export const WarehousesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * assign device to warehouse
         * @summary assign device to warehouse
         * @param {WarehouseAssignDeviceRequest} request Assign Device to Warehouse request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignDeviceToWarehouse: async (request: WarehouseAssignDeviceRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('assignDeviceToWarehouse', 'request', request)
            const localVarPath = `/warehouses/device`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get warehouse object by UUID
         * @summary get warehouse object
         * @param {string} warehouseUuid Vehicle ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWarehouseByUuid: async (warehouseUuid: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'warehouseUuid' is not null or undefined
            assertParamExists('getWarehouseByUuid', 'warehouseUuid', warehouseUuid)
            const localVarPath = `/warehouses/{warehouse_uuid}`
                .replace(`{${"warehouse_uuid"}}`, encodeURIComponent(String(warehouseUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * list all warehouses object
         * @summary list all warehouses object
         * @param {string} [warehouseTypeId] Warehouse Type IDs (comma delimitted)
         * @param {string} [typeFilter] filter room type \&#39;stock\&#39;
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listWarehouses: async (warehouseTypeId?: string, typeFilter?: string, offset?: number, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/warehouses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (warehouseTypeId !== undefined) {
                localVarQueryParameter['warehouse_type_id'] = warehouseTypeId;
            }

            if (typeFilter !== undefined) {
                localVarQueryParameter['type_filter'] = typeFilter;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WarehousesApi - functional programming interface
 * @export
 */
export const WarehousesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WarehousesApiAxiosParamCreator(configuration)
    return {
        /**
         * assign device to warehouse
         * @summary assign device to warehouse
         * @param {WarehouseAssignDeviceRequest} request Assign Device to Warehouse request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assignDeviceToWarehouse(request: WarehouseAssignDeviceRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HandlerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assignDeviceToWarehouse(request, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['WarehousesApi.assignDeviceToWarehouse']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * get warehouse object by UUID
         * @summary get warehouse object
         * @param {string} warehouseUuid Vehicle ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWarehouseByUuid(warehouseUuid: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WarehouseObjectResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWarehouseByUuid(warehouseUuid, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['WarehousesApi.getWarehouseByUuid']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * list all warehouses object
         * @summary list all warehouses object
         * @param {string} [warehouseTypeId] Warehouse Type IDs (comma delimitted)
         * @param {string} [typeFilter] filter room type \&#39;stock\&#39;
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listWarehouses(warehouseTypeId?: string, typeFilter?: string, offset?: number, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WarehouseListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listWarehouses(warehouseTypeId, typeFilter, offset, limit, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['WarehousesApi.listWarehouses']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * WarehousesApi - factory interface
 * @export
 */
export const WarehousesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WarehousesApiFp(configuration)
    return {
        /**
         * assign device to warehouse
         * @summary assign device to warehouse
         * @param {WarehouseAssignDeviceRequest} request Assign Device to Warehouse request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignDeviceToWarehouse(request: WarehouseAssignDeviceRequest, options?: any): AxiosPromise<HandlerResponse> {
            return localVarFp.assignDeviceToWarehouse(request, options).then((request) => request(axios, basePath));
        },
        /**
         * get warehouse object by UUID
         * @summary get warehouse object
         * @param {string} warehouseUuid Vehicle ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWarehouseByUuid(warehouseUuid: string, options?: any): AxiosPromise<WarehouseObjectResponse> {
            return localVarFp.getWarehouseByUuid(warehouseUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * list all warehouses object
         * @summary list all warehouses object
         * @param {string} [warehouseTypeId] Warehouse Type IDs (comma delimitted)
         * @param {string} [typeFilter] filter room type \&#39;stock\&#39;
         * @param {number} [offset] Offset
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listWarehouses(warehouseTypeId?: string, typeFilter?: string, offset?: number, limit?: number, options?: any): AxiosPromise<WarehouseListResponse> {
            return localVarFp.listWarehouses(warehouseTypeId, typeFilter, offset, limit, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WarehousesApi - object-oriented interface
 * @export
 * @class WarehousesApi
 * @extends {BaseAPI}
 */
export class WarehousesApi extends BaseAPI {
    /**
     * assign device to warehouse
     * @summary assign device to warehouse
     * @param {WarehouseAssignDeviceRequest} request Assign Device to Warehouse request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WarehousesApi
     */
    public assignDeviceToWarehouse(request: WarehouseAssignDeviceRequest, options?: RawAxiosRequestConfig) {
        return WarehousesApiFp(this.configuration).assignDeviceToWarehouse(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get warehouse object by UUID
     * @summary get warehouse object
     * @param {string} warehouseUuid Vehicle ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WarehousesApi
     */
    public getWarehouseByUuid(warehouseUuid: string, options?: RawAxiosRequestConfig) {
        return WarehousesApiFp(this.configuration).getWarehouseByUuid(warehouseUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * list all warehouses object
     * @summary list all warehouses object
     * @param {string} [warehouseTypeId] Warehouse Type IDs (comma delimitted)
     * @param {string} [typeFilter] filter room type \&#39;stock\&#39;
     * @param {number} [offset] Offset
     * @param {number} [limit] Limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WarehousesApi
     */
    public listWarehouses(warehouseTypeId?: string, typeFilter?: string, offset?: number, limit?: number, options?: RawAxiosRequestConfig) {
        return WarehousesApiFp(this.configuration).listWarehouses(warehouseTypeId, typeFilter, offset, limit, options).then((request) => request(this.axios, this.basePath));
    }
}



