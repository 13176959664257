import { Chip } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ItemRequestNewItemMappedType } from '../../redux/slices/tools/toolsInterface';
import { listItemRequestNewAllMapped } from '../../redux/slices/tools/toolsSlice';
import { Card } from '../../styled/components';
import Table from '../table/Table';

export const BatchEnrollment: React.FC = () => {
  const items = useSelector(listItemRequestNewAllMapped);

  const columns = useMemo(
    () => [
      {
        Header: 'Manufacturer',
        accessor: 'manufacturer_name',
      },

      {
        Header: 'Product',
        accessor: 'product_name',
      },
      {
        Header: 'Created at',
        accessor: 'created_at_mapped',
      },

      {
        Header: 'Identifier',
        accessor: 'identifier',
      },
      {
        Header: 'Tags',
        accessor: 'tags_mapped',
        Cell: ({ row }: { row: { original: ItemRequestNewItemMappedType } }) =>
          row.original.tags?.map((tag: string) => <Chip key={tag} label={tag} sx={{ mx: 0.5, my: 0.5 }} />),
      },
    ],
    [],
  );

  const getExportData = useCallback(async () => {
    new Promise(() => {});
  }, []);

  return (
    <Card>
      <Table columns={columns} data={items} exportFileName="Where_Added_Items" size="small" dataExport={items} getExportData={getExportData} />
    </Card>
  );
};
